import axios from "axios";
import moment from "moment";
import Modal from "react-modal";
import { AES } from "crypto-js";
import { DatePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import subDays from "date-fns/subDays";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import css from "./InvoiceCreateAuto.css";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ThreeDots } from "react-loader-spinner";
import Tooltip from "../InvoiceCreateManual/Tooltip";
import userConstent from "../../../constants/user.js";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState, useRef } from "react";
import userTypeConsts from "../../../constants/userTypeConsts";
import { logout, reset } from "../../../features/auth/authSlice";
import whatis from "../../../images/singleinvoice/whatisthis.svg";
import cancelImage from "../../../images/singleinvoice/cancel.svg";
import crssrImage from "../../../images/singleinvoice/cross-red.svg";
import addicoplus from "../../../images/singleinvoice/plus-add-icon.svg";
import CompanyDocumentField from "../../User/elements/CompanyDocumentField";
import { reset as invoiceReset } from "../../../features/invoice/invoiceSlice.js";
import { setNotification } from "../../../features/notification/notificationSlice.js";
import { reset as headerReset } from "../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../features/appSettings/appSettingsSlice.js";
import { getBankFromCompany, getBankFromCompanyWiseSettingsId } from "../InvoicesSingle/services/invoiceSingle.js";

const InvoiceCreateAutocom = () => {
  const [showTooltip, setShowTooltip] = useState(false);
  const qs = require("qs");
  const handleTooltipToggle = () => {
    setShowTooltip(!showTooltip);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selUser, setSelUser] = useState("");
  const [invType, setInvType] = useState("Regular");
  const [userInvoiceData, setUserInvoiceData] = useState([]);
  const [timerData, setTimerData] = useState([]);
  const [newInvoiceNumber, setNewInvoiceNumber] = useState([]);
  const [userBankData, setUserBankData] = useState([]);
  const [issueDate, setIssueDate] = useState(new Date());
  const [userName, setUserName] = useState("");
  const [dataForm, setDataForm] = useState([]);
  const [defaultbank, setDefaultBank] = useState(false);
  const [defaultbankAccount, setDefaultBankAccount] = useState(false);
  const [wiseProfile, setWiseProfile] = useState(null);
  const [dueDate, setDueDate] = useState(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const lastDayOfMonth = new Date(year, month + 1, 0);
    return lastDayOfMonth;
  });
  const [title, setTitle] = useState("");
  const [invFiles, setInvFiles] = useState([]);
  const [otherComnt, setOtherComnt] = useState("");
  const [vatSum, setVatSum] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [bankAccount, setBankAccount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [bankAccountDropdownOpen, setBankAccountDropdownOpen] = useState(false);
  const [invoiceCurrency, setInvoiceCurrency] = useState("USD");
  const [selectedInvoiceBank, setSelectedInvoiceBank] = useState(null);
  const [isItemLoading, setIsItemLoading] = useState(false);
  const [bankModalIsOpen, setBankModalIsOpen] = useState(false);
  const [timerDataModalIsOpen, setTimerDataModalIsOpen] = useState(false);

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [fetchComplete, setFetchComplete] = useState(false);
  const [wxComapny, setWxCompany] = useState(null);
  const [wxComProfileId, setWxComProfileId] = useState(null);
  const bankAccountDropdownRef = useRef(null);
  const dropdownListRef = useRef(null);
  const [showWxCompanyModal, setShowWxCompanyModal] = useState(false);
  const [userWiseProfile, setUserWiseProfile] = useState(null);
  const [issueDateIsValid, setIssueDateIsValid] = useState(false);
  const issueDateRef = useRef(null);
  const [dueDateIsValid, setDueDateIsValid] = useState(false);
  const dueDateRef = useRef(null);
  const [titleIsValid, setTitleIsValid] = useState(false);
  const titleRef = useRef(null);
  const [bankAccSelectIsValid, setBankAccSelectIsValid] = useState(false);
  const bankRef = useRef(null);
  const invoice = useSelector((state) => state.invoice);
  const [vat, setVat] = useState([]);
  const [cost, setCost] = useState([]);
  const [autoCurrency, setAutoCurrency] = useState([]);
  const [contractType, setContractType] = useState([]);
  const { appSettings } = useSelector((state) => state.appSettings);
  const [wiseCompany, setWiseCompany] = useState([]);
  const [wiseModalIsOpen, setWiseModalIsOpen] = useState(false);
  const [isWiseProfileLoading, setIsWiseProfileLoading] = useState(true);

  const [personCost, setPersonalCost] = useState(null);
  const [totalCalculatedHours, setTotalCalculatedHours] = useState(null);
  const invTypeDropdownRef = useRef(null);
  const [invTypeDropdownOpen, setInvTypeDropdownOpen] = useState(false);
  const [invTypeWiseDropdownOpen, setInvTypeWiseDropdownOpen] = useState(false);
  const [companyDropdownOpen, setCompanyDropdownOpen] = useState(false);
  const [wiseProfileLabel, setWiseProfileLabel] = useState(null);
  const [isBankLoading, setIsBankLoading] = useState(false);
  const companyeDropdownRef = useRef(null);

  let createdBy = null;
  let token = null;
  const { user } = useSelector((state) => state.auth);
  if (user) {
    if (user && user._id) {
      token = user.token;
      createdBy = user._id;
    }
  }

  const tz = global.config.getTimeZone();

  const handleInvTypeWiseToggleDropdown = () => {
    setInvTypeWiseDropdownOpen(!invTypeWiseDropdownOpen);
  };

  const handleCompanyToggleDropdown = () => {
    setCompanyDropdownOpen(!companyDropdownOpen);
  };

  // const handleCompanyselect = async (profile) => {
  //   setWiseProfile(profile);
  //   setUserBankData([]);
  //   setInvTypeWiseDropdownOpen(false);
  //   setIsBankLoading(true);
  //   const getAllBanks = await getBankFromCompany(
  //     profile,
  //     userInvoiceData.length > 0 && userInvoiceData[0].email,
  //     token
  //   );
  //   setUserBankData(getAllBanks);
  //   setIsBankLoading(false);
  // };

  const handleCompanySelect = async (item) => {
    setWiseProfile(item.profile_id);
    setUserBankData([]);
    setWxCompany(item.wx_company);
    setCompanyDropdownOpen(false);
    setIsBankLoading(true);
    const getAllBanks = await getBankFromCompanyWiseSettingsId(item.wiseSettingsId, item.profile_id, userInvoiceData.length > 0 && userInvoiceData[0].email, token);
    setUserBankData(getAllBanks);
    setIsBankLoading(false);
  }
  useEffect(() => {
    async function getUserWiseProfile() {
      setIsWiseProfileLoading(true);
      try {
        await axios
          .post(
            "/api/wise/user-wise-profile",
            {
              userId: `${invoice.selectedUser && invoice.selectedUser[1]}`,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            // setWiseCompany(response?.data);
            if (response.data.status === false) {
              setWiseModalIsOpen(true);
            } else {
              setWiseCompany(response?.data);
            }
          });
      } catch (error) {
        console.error("Error fetching user-wise profile:", error);
        setWiseModalIsOpen(true);
      } finally {
        setIsWiseProfileLoading(false);
      }
    }
    getUserWiseProfile();
  }, []);

  const closeWiseModal = () => {
    setWiseModalIsOpen(false);
    navigate("/user/invoices");
  };

  const goToWisePage = () => {
    setWiseModalIsOpen(false);
    if (user.userType === userTypeConsts.SUPER_ADMIN || user.userType === userTypeConsts.ADMIN) {
      localStorage.setItem('activeTab', '1');
      navigate("/user/account/settings");
    } else {
      navigate("/user/invoices");
    }
  };

  useEffect(() => {
    setFetchComplete(false);

    async function getPerProjectCost() {
      try {
        let data = qs.stringify({
          projectId: invoice.selectedUser && invoice.selectedUser[1],
          userId: invoice.selectedUser && invoice.selectedUser[1],
          token: token,
        });
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: "/api/project-user-costs",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
          data: data,
        };
        axios.request(config).then((response) => { });
      } catch (error) {
        global.config.slackMessage(error.toString());
        console.log(error);
        alert("Something went wrong");
      }
    }

    async function fetchInvoiceUserData() {
      try {
        setIsItemLoading(true);
        var startDate = new Date(
          invoice.selectedUser && invoice.selectedUser[0][0]
        );
        var endDate = new Date(
          invoice.selectedUser && invoice.selectedUser[0][1]
        );
        var startDateFormatted = startDate.toLocaleDateString("en-US", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });
        var [startMonth, startDay, startYear] = startDateFormatted.split("/");
        // Create the new formatted string in the "day - month - year" format
        var formattedStartDateString = `${startDay}/${startMonth}/${startYear}`;
        var endDateFormatted = endDate.toLocaleDateString("en-US", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });
        var [month, day, year] = endDateFormatted.split("/");
        // Create the new formatted string in the "day - month - year" format
        const formattedEndDateString = `${day}/${month}/${year}`;
        var formattedDateRange = `${formattedStartDateString} - ${formattedEndDateString}`;
        setSelUser(invoice.selectedUser && invoice.selectedUser[1]);
        let data = qs.stringify({
          user: invoice.selectedUser && invoice.selectedUser[1],
          dateRange: formattedDateRange,
          token: token,
        });
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: "/api/invoice/automatic/generate",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
          data: data,
        };
        axios
          .request(config)
          .then(async (response) => {
            if (response.data.status === "timerError") {
              setIsItemLoading(false);
              setTimerDataModalIsOpen(true);
            } else if (response.data.status === "bankError") {
              setIsItemLoading(false);
              setBankModalIsOpen(true);
            }
            const groupedData = response.data[0].reduce((acc, obj) => {
              const projectId = obj.project._id;
              if (!acc[projectId]) {
                acc[projectId] = {
                  project: obj.project,
                  totalDuration: 0,
                };
              }
              acc[projectId].totalDuration += obj.duration;
              return acc;
            }, {});

            setTimerData(groupedData);
            setNewInvoiceNumber(response.data[1]);
            setUserInvoiceData(response.data[2]);
            // setUserBankData(response.data[3]);
            setWxCompany(response.data[2][0]["user_wx_comapny"].wx_company);
            setWxComProfileId(response.data[2][0]["user_wx_comapny"].profile_id);
            if (
              response.data[2][0].typeOfContract._id ===
              userConstent.CONTRACT_TYPE.HOURLY
            ) {
              var contractType = "hr";
            }
            if (
              response.data[2][0].typeOfContract._id ===
              userConstent.CONTRACT_TYPE.FULL_TIME
            ) {
              var contractType = "full";
            }
            if (
              response.data[2][0].typeOfContract._id ===
              userConstent.CONTRACT_TYPE.PART_TIME
            ) {
              var contractType = "part";
            }
            const sumOfDuration = response.data[0].reduce(
              (sum, item) => sum + item.duration,
              0
            );
            if (
              response.data[2][0].typeOfContract.typeOfContract ===
              "Hourly Rate"
            ) {
              setPersonalCost(response.data[2][0].cost);
              var totalCalculatedHours = sumOfDuration / 3600;
            } else if (
              response.data[2][0].typeOfContract.typeOfContract === "Full Time" ||
              response.data[2][0].typeOfContract.typeOfContract === "Part Time"
            ) {
              let cost = response.data[2][0].cost;
              let totalCalculatedHours = sumOfDuration / 3600;
              let perCost = cost / parseInt(response.data[2][0].monthly_hours ? response.data[2][0].monthly_hours : 168);
              setPersonalCost(perCost);
            }

            setTimeout(() => {
              const inputs = document.querySelectorAll('input[name="cost"]');
              let sum = 0;
              inputs.forEach((input) => {
                const vatValue = parseFloat(input.value);
                if (!isNaN(vatValue)) {
                  sum += vatValue;
                }
              });
              setVatSum(sum);
              const amountInputs = document.querySelectorAll(
                'input[name="amount"]'
              );
              let amountSum = 0;
              amountInputs.forEach((amountInputs) => {
                const amountValue = parseFloat(amountInputs.value);
                if (!isNaN(amountValue)) {
                  amountSum += amountValue;
                }
              });
              setTotalAmount(amountSum);
              setIsItemLoading(false);
            }, 3000);
          })
          .catch((error) => {
            global.config.slackMessage(error.toString());
          });
      } catch (error) {
        console.log(error);
        global.config.slackMessage(error.toString());
        alert("Something went wrong");
      }
    }
    fetchInvoiceUserData();

    async function getUserWxCompany() {
      await axios
        .get("/api/get-wx-comapies", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setUserWiseProfile(
            response.data.filter((compny) => compny.wx_company !== "Any")
          );
        });
    }
    getUserWxCompany();
    setFetchComplete(!fetchComplete);
  }, [invoice && invoice.selectedUser, qs]);

  useEffect(() => {
    if (userInvoiceData.length > 0) {
      if (userInvoiceData[0] && userInvoiceData[0]["user_wx_comapny"].wx_company === "Any") {
        setUserBankData([]);
      } else {
        setWiseProfile(wxComProfileId);
        setIsBankLoading(true);
        const ft = async () => {
          const getAllBanks = await getBankFromCompany(
            wxComProfileId,
            userInvoiceData.length > 0 && userInvoiceData[0].email,
            token
          );
          setUserBankData(getAllBanks);
          setIsBankLoading(false);
        };
        ft();
      }
    }
  }, [userInvoiceData]);
  const [userData, setUserData] = useState([]);
  useEffect(() => {
    async function fetchUser() {
      try {
        const response = await axios.get(
          `/api/user2/${invoice.selectedUser && invoice.selectedUser[1]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        let userName = "";

        setAutoCurrency(response.data.payment_currency);
        setContractType(response.data?.typeOfContract?.typeOfContract);

        setUserData(response.data);

        if (
          response.data.first_name &&
          response.data.first_name?.toLowerCase() !== "null"
        ) {
          userName = response.data.first_name;

          if (
            response.data.middle_name &&
            response.data.middle_name?.toLowerCase() !== "null"
          ) {
            userName += " " + response.data.middle_name;
          }

          userName += " " + response.data.last_name;
        } else {
          userName = response.data.company_name;
        }
        if (response.data) {
          setUserName(userName);
        }
        if (response.data.defaultBank) {
          setDefaultBankAccount(response.data.defaultBank);
        }
      } catch (err) {
        global.config.slackMessage(err.toString());
        console.log(err);
      }
    }
    fetchUser();
  }, [invoice.selectedUser, token]);

  useEffect(() => {
    setTimeout(() => {
      const amountInputs = document.querySelectorAll('input[name="amount"]');
      let amountSum = 0;
      amountInputs.forEach((amountInputs) => {
        const amountValue = parseFloat(amountInputs.value);
        if (!isNaN(amountValue)) {
          amountSum += amountValue;
        }
      });
      setTotalAmount(amountSum);
    }, 3000);
  }, [fetchComplete, cost, vat]);

  function addBank() {
    if (
      user.userType === userTypeConsts.SUPER_ADMIN ||
      user.userType === userTypeConsts.ADMIN
    ) {
      const encryptedId = AES.encrypt(selUser, "encryptionKey").toString();
      const encodedId = encodeURIComponent(encryptedId).replace(/\//g, "%2F");
      localStorage.setItem("activeTab", "1");
      navigate(`../profile/${encodedId}`);
    } else {
      localStorage.setItem("activeTab", "1");
      navigate(`../profile/settings`);
    }
  }

  function closeBankModal() {
    setBankModalIsOpen(false);
    navigate("/user/invoices");
  }

  function closeTimerDataModal() {
    setTimerDataModalIsOpen(false);
    navigate("/user/invoices");
  }

  var autoCurrencyFilteredBankDetails =
    autoCurrency &&
    Object.values(userBankData).filter(
      (entry) => entry.currency === autoCurrency
    );

  var filteredBankData = autoCurrencyFilteredBankDetails.filter(
    (item) =>
      item.details.accountNumber
        ?.toLowerCase()
        .includes(searchTerm?.toLowerCase()) ||
      item.details?.IBAN?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      item.currency?.toLowerCase().includes(searchTerm?.toLowerCase())
  );
  // filteredBankData = autoCurrency && Object.values(filteredBankData).filter(entry => entry.currency === autoCurrency);

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideBankAccountDropdown =
      bankAccountDropdownRef.current &&
      bankAccountDropdownRef.current.contains(event.target);
    const isClickInsideCompanyDropdown =
      companyeDropdownRef.current &&
      companyeDropdownRef.current.contains(event.target);
    if (!isClickInsideBankAccountDropdown) {
      setBankAccountDropdownOpen(false);
    }
    if (!isClickInsideCompanyDropdown) {
      setCompanyDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideDropdown);
    return () => {
      document.removeEventListener("click", handleClickOutsideDropdown);
    };
  }, []);

  useEffect(() => {
    // Get the selected item
    const selectedItem = document.querySelector(".selected-item");
    // Get the container element
    const dropdownListContainer = dropdownListRef.current;

    // Scroll to the selected item and center it in the view
    if (selectedItem && dropdownListContainer) {
      selectedItem.scrollIntoView({ behavior: "smooth", block: "center" });
      // selectedItem.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [bankAccountDropdownOpen, searchTerm, filteredBankData, bankAccount]);

  const handleDueDateChange = (date) => {
    if (!issueDate || new Date(date) >= new Date(issueDate)) {
      setDueDate(date);
    } else {
      toast.error("Due date must be after issue date", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const ranges = [
    {
      label: "today",
      value: new Date(), // Use a single date instead of an array
    },
    {
      label: "yesterday",
      value: subDays(new Date(), 1),
    },
  ];

  const allowedExtensions = [
    "pdf",
    "doc",
    "docx",
    "msword",
    "xls",
    "xlsx",
    "csv",
  ];
  // const handleInvDocChange = (event) => {
  //   const selectedFile = event.target.files[0];
  //   if (selectedFile) {
  //     const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
  //     if (allowedExtensions.includes(fileExtension)) {
  //       setInvDocument(selectedFile);
  //       const fileName = selectedFile ? selectedFile.name : '';
  //       setInvDocumentFileName(fileName);
  //     } else {
  //       toast.error("Invalid file format. Allowed formats are pdf, doc, docx, msword, xls, xlsx, csv.!!", {
  //         position: "bottom-right",
  //         autoClose: 3500,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });

  //       fileInputRef.current.value = "";
  //     }
  //   }

  // };
  const handleInvDocChange = (event) => {
    const selectedFiles = event.target.files;

    if (selectedFiles.length > 0) {
      if (selectedFiles.length + invFiles.length > 10) {
        // Display toast error message when the total number of files exceeds 10
        toast.error("Maximum of 10 files can be uploaded!", {
          position: "bottom-right",
          autoClose: 3500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
      const newFiles = Array.from(selectedFiles);

      // Validate file extensions and update state
      const validFiles = newFiles.filter((file) => {
        const fileExtension = file.name.split(".").pop()?.toLowerCase();
        if (allowedExtensions.includes(fileExtension)) {
          return true;
        } else {
          toast.error(
            `Invalid file format for ${file.name}. Allowed formats are pdf, doc, docx, msword, xls, xlsx, csv.!!`,
            {
              position: "bottom-right",
              autoClose: 3500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          return false;
        }
      });

      // Update the state with the valid files
      setInvFiles((prevFiles) => [...prevFiles, ...validFiles]);

      // Clear the file input for multiple selections
      fileInputRef.current.value = "";
    }
  };
  const removeFile = (index) => {
    // Remove the file at the specified index
    setInvFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });
  };

  // const openPaymentModal = () => {
  //   setIssueDateIsValid(false);
  //   setTitleIsValid(false);
  //   setBankAccSelectIsValid(false);
  //   setDueDateIsValid(false);
  //   if (
  //     issueDate === null ||
  //     dueDate === null ||
  //     selectedInvoiceBank === null ||
  //     title === ""
  //   ) {
  //     if (issueDate === null) {
  //       setIssueDateIsValid(true);
  //       toast.error("Issue Date cannot be empty!", {
  //         position: "bottom-right",
  //         autoClose: 2000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //       issueDateRef.current.focus();
  //       // return;
  //     } else if (dueDate === null) {
  //       setDueDateIsValid(true);
  //       toast.error("Due Date cannot be empty!", {
  //         position: "bottom-right",
  //         autoClose: 2000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //       dueDateRef.current.focus();
  //       // return;
  //     } else if (title === "") {
  //       setTitleIsValid(true);
  //       toast.error("Title cannot be empty!", {
  //         position: "bottom-right",
  //         autoClose: 2000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //       titleRef.current.focus();
  //       // return;
  //     }

  //     toast.error("Please fill all required fields!", {
  //       position: "bottom-right",
  //       autoClose: 2000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //     return;
  //   }

  //   // if (invoiceBank === null) {
  //   //   setBankIsValid(true);
  //   //   toast.error('Please Select a Bank Account!', {
  //   //     position: "bottom-right",
  //   //     autoClose: 2000,
  //   //     hideProgressBar: false,
  //   //     closeOnClick: true,
  //   //     pauseOnHover: true,
  //   //     draggable: true,
  //   //     progress: undefined,
  //   //   });
  //   //   return;
  //   // }

  //   setShowWxCompanyModal(true);
  // }

  function closeWXCompanyModal() {
    if (wxComapny && (wxComapny === "WX" || wxComapny === "Xpertos")) {
      setWxCompany("Any");
    }
    setShowWxCompanyModal(false);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIssueDateIsValid(false);
    setTitleIsValid(false);
    setBankAccSelectIsValid(false);
    setDueDateIsValid(false);
    if (
      issueDate === null ||
      dueDate === null ||
      selectedInvoiceBank === null ||
      title === "" ||
      totalAmount <= 0
    ) {
      if (issueDate === null) {
        setIssueDateIsValid(true);
        toast.error("Issue date cannot be empty!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        issueDateRef.current.focus();
        // return;
      } else if (dueDate === null) {
        setDueDateIsValid(true);
        toast.error("Due date cannot be empty!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        dueDateRef.current.focus();
        // return;
      } else if (title === "") {
        setTitleIsValid(true);
        toast.error("Title cannot be empty!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        titleRef.current.focus();
        // return;
      } else if (totalAmount <= 0) {
        toast.error("Total must be greater than 0!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
      if (selectedInvoiceBank === null) {
        setBankAccSelectIsValid(true);
        toast.error("Please select a bank account!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        bankRef.current.focus();
        return;
      }

      toast.error("Please fill all required fields!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    const form = event.target;
    const formData = new FormData(form);
    setDataForm(formData);
    // if (userInvoiceData[0] && userInvoiceData[0]["user_wx_comapny"].wx_company === "Any") {
    //   setShowWxCompanyModal(true);
    // } else {
    if (
      issueDate === null ||
      dueDate === null ||
      selectedInvoiceBank === null ||
      title === "" ||
      totalAmount <= 0
    ) {
      toast.error("Please fill all required fields!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    setIsSubmitLoading(true);
    const formEvent = event.target;
    const formDataEvent = new FormData(form);
    const invoiceBank = selectedInvoiceBank;
    const invoiceBankCurrency = invoiceCurrency;
    const descriptions = Array.from(formDataEvent.getAll("description"));
    const projectIds = Array.from(formDataEvent.getAll("projectId"));
    const costs = Array.from(formDataEvent.getAll("cost"));
    const taxes = Array.from(formDataEvent.getAll("vat"));
    const amounts = Array.from(formDataEvent.getAll("amount"));
    const invoice_number_regular = formDataEvent.get("invoice_number_regular");
    const invoice_type = formDataEvent.get("invoice_type");
    const date_issue = formDataEvent.get("date_issue");
    var email = "";
    if (userInvoiceData) {
      email = userInvoiceData[0].email;
    }
    const date_range = formDataEvent.get("date_range");
    const [startDate, endDate] = date_range.split(" - ");
    var totTax = totalAmount - vatSum;
    let company = wxComapny;

    if (wiseProfile === 10873693) {
      company = "Xpertos";
    } else if (wiseProfile === 14760922) {
      company = "WX";
    }

    const invoiceData = {
      inv_number: invoice_number_regular,
      inv_strat_date: invoice.selectedUser && invoice.selectedUser[0][0],
      inv_end_date: invoice.selectedUser && invoice.selectedUser[0][1],
      inv_issue_date: issueDate,
      inv_due_date: dueDate,
      inv_title: title,
      inv_company_name: company,
      inv_email: email,
      inv_description: JSON.stringify(descriptions),
      inv_projectId: JSON.stringify(projectIds),
      inv_price: JSON.stringify(amounts),
      inv_cost: JSON.stringify(costs),
      inv_tax: JSON.stringify(taxes),
      inv_sub_total: vatSum,
      inv_for: selUser,
      inv_total_tax: totTax,
      inv_total_amount: totalAmount,
      inv_currency: invoiceBankCurrency,
      inv_type: invType,
      inv_bank: selectedInvoiceBank,
      inv_other_comments: otherComnt,
      is_automatic_inv: true,
      inv_created_by: createdBy,
      inv_bank_profile: wiseProfile,
    };
    // Append the profile image to the form data
    // formData.append("inv_file", invDocument);
    for (let i = 0; i < invFiles.length; i++) {
      formDataEvent.append("inv_file", invFiles[i]);
    }
    // Append the other request data to the form data
    for (let key in invoiceData) {
      formDataEvent.append(key, invoiceData[key]);
    }
    if (invoiceBank) {
      try {
        const response = await axios.post("/api/invoice", formDataEvent, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = response.data;
        if (data.status === "Error") {
        } else {
          global.config.activityLog(
            window.location.href,
            "Invoice",
            `Invoice ${invoice_number_regular} (automatic) created Successsfully.`
          );
          setIsSubmitLoading(false);
          toast("Invoice Created Successsfully!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          dispatch(setNotification(Date.now()));
          navigate("/user/invoices");
        }
      } catch (error) {
        global.config.slackMessage(error.toString());
        if (error.response.data.status === "mailfailed") {
          setIsSubmitLoading(false);
          toast.error(
            "Invoice created successsfully! but something went wrong in sending Mail!!",
            {
              position: "bottom-right",
              autoClose: 3500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          global.config.activityLog(
            window.location.href,
            "Invoice",
            `Invoice ${invoice_number_regular} (automatic) created Successsfully but Something went wrong in sending Mail.`
          );
          navigate("/user/invoices");
        } else if (error.response?.data?.status === "deleted") {
          localStorage.removeItem("user");
          dispatch(logout());
          dispatch(reset());
          dispatch(invoiceReset());
          dispatch(headerReset());
          dispatch(appSettingsReset());
          toast.error("This user is deleted!", {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate("/");
          return;
        } else {
          console.log(error);
          setIsSubmitLoading(false);
          if (descriptions.length > 0) {
            alert(`Something went wrong${descriptions.length}`);
          } else {
            alert("Nothing on items");
          }
        }
      }
    } else {
      setShowWxCompanyModal(false);
      toast.error("Please select a bank account!", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    // }
  };

  const handleFormSubmit = async () => {
    if (
      issueDate === null ||
      dueDate === null ||
      selectedInvoiceBank === null ||
      title === ""
    ) {
      toast.error("Please fill all required fields!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    setIsSubmitLoading(true);
    const invoiceBank = selectedInvoiceBank;
    const invoiceBankCurrency = invoiceCurrency;
    const descriptions = Array.from(dataForm.getAll("description"));
    const projectIds = Array.from(dataForm.getAll("projectId"));
    const costs = Array.from(dataForm.getAll("cost"));
    const taxes = Array.from(dataForm.getAll("vat"));
    const amounts = Array.from(dataForm.getAll("amount"));
    const invoice_number_regular = dataForm.get("invoice_number_regular");
    const invoice_type = dataForm.get("invoice_type");
    const date_issue = dataForm.get("date_issue");
    var email = "";
    if (userInvoiceData) {
      email = userInvoiceData[0].email;
    }
    const date_range = dataForm.get("date_range");
    const [startDate, endDate] = date_range.split(" - ");
    var totTax = totalAmount - vatSum;
    const invoiceData = {
      inv_number: invoice_number_regular,
      inv_strat_date: invoice.selectedUser && invoice.selectedUser[0][0],
      inv_end_date: invoice.selectedUser && invoice.selectedUser[0][1],
      inv_issue_date: issueDate,
      inv_due_date: dueDate,
      inv_title: title,
      inv_company_name: wxComapny,
      inv_email: email,
      inv_description: JSON.stringify(descriptions),
      inv_projectId: JSON.stringify(projectIds),
      inv_price: JSON.stringify(amounts),
      inv_cost: JSON.stringify(costs),
      inv_tax: JSON.stringify(taxes),
      inv_sub_total: vatSum,
      inv_for: selUser,
      inv_total_tax: totTax,
      inv_total_amount: totalAmount,
      inv_currency: invoiceBankCurrency,
      inv_type: invType,
      inv_bank: selectedInvoiceBank,
      inv_other_comments: otherComnt,
      is_automatic_inv: true,
      inv_created_by: createdBy,
      inv_bank_profile: wiseProfile,
    };
    // Append the profile image to the form data
    // formData.append("inv_file", invDocument);
    for (let i = 0; i < invFiles.length; i++) {
      dataForm.append("inv_file", invFiles[i]);
    }
    // Append the other request data to the form data
    for (let key in invoiceData) {
      dataForm.append(key, invoiceData[key]);
    }
    if (invoiceBank) {
      try {
        const response = await axios.post("/api/invoice", dataForm, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = response.data;
        if (data.status === "Error") {
        } else {
          global.config.activityLog(
            window.location.href,
            "Invoice",
            `Invoice ${invoice_number_regular} (automatic) created Successsfully.`
          );
          setIsSubmitLoading(false);
          toast("Invoice Created Successsfully!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          dispatch(setNotification(Date.now()));
          navigate("/user/invoices");
        }
      } catch (error) {
        global.config.slackMessage(error.toString());
        if (error.response.data.status === "mailfailed") {
          setIsSubmitLoading(false);
          toast.error(
            "Invoice created successsfully! but something went wrong in sending Mail!!",
            {
              position: "bottom-right",
              autoClose: 3500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          global.config.activityLog(
            window.location.href,
            "Invoice",
            `Invoice ${invoice_number_regular} (automatic) created Successsfully but Something went wrong in sending Mail.`
          );
          navigate("/user/invoices");
        } else if (error.response?.data?.status === "deleted") {
          localStorage.removeItem("user");
          dispatch(logout());
          dispatch(reset());
          dispatch(invoiceReset());
          dispatch(headerReset());
          dispatch(appSettingsReset());
          toast.error("This user is deleted!", {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate("/");
          return;
        } else {
          console.log(error);
          setIsSubmitLoading(false);
          if (descriptions.length > 0) {
            alert(`Something went wrong${descriptions.length}`);
          } else {
            alert("Nothing on items");
          }
        }
      }
    } else {
      setShowWxCompanyModal(false);
      toast.error("Please select a bank account!", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   if (
  //     issueDate === null ||
  //     dueDate === null ||
  //     selectedInvoiceBank === null ||
  //     title === ""
  //   ) {
  //     toast.error("Please fill all required fields!", {
  //       position: "bottom-right",
  //       autoClose: 2000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //     return;
  //   }

  //   setIsSubmitLoading(true);
  //   const form = event.target;
  //   const formData = new FormData(form);
  //   const invoiceBank = selectedInvoiceBank;
  //   const invoiceBankCurrency = invoiceCurrency
  //   const descriptions = Array.from(formData.getAll('description'));
  //   const projectIds = Array.from(formData.getAll('projectId'));
  //   const costs = Array.from(formData.getAll('cost'));
  //   const taxes = Array.from(formData.getAll('vat'));
  //   const amounts = Array.from(formData.getAll('amount'));
  //   const invoice_number_regular = formData.get('invoice_number_regular');
  //   const invoice_type = formData.get('invoice_type');
  //   const date_issue = formData.get('date_issue');
  //   var email = "";
  //   if (userInvoiceData) {
  //     email = userInvoiceData[0].email;
  //   }
  //   const date_range = formData.get('date_range');
  //   const [startDate, endDate] = date_range.split(" - ");
  //   var totTax = totalAmount - vatSum;
  //   const invoiceData = {
  //     inv_number: invoice_number_regular,
  //     inv_strat_date: invoice.selectedUser && invoice.selectedUser[0][0],
  //     inv_end_date: invoice.selectedUser && invoice.selectedUser[0][1],
  //     inv_issue_date: issueDate,
  //     inv_due_date: dueDate,
  //     inv_title: title,
  //     inv_company_name: wxComapny,
  //     inv_email: email,
  //     inv_description: JSON.stringify(descriptions),
  //     inv_projectId: JSON.stringify(projectIds),
  //     inv_price: JSON.stringify(amounts),
  //     inv_cost: JSON.stringify(costs),
  //     inv_tax: JSON.stringify(taxes),
  //     inv_sub_total: vatSum,
  //     inv_for: selUser,
  //     inv_total_tax: totTax,
  //     inv_total_amount: totalAmount,
  //     inv_currency: invoiceBankCurrency,
  //     inv_type: invType,
  //     inv_bank: selectedInvoiceBank,
  //     inv_other_comments: otherComnt,
  //     is_automatic_inv: true,
  //     inv_created_by: createdBy,
  //   };
  //   // Append the profile image to the form data
  //   // formData.append("inv_file", invDocument);
  //   for (let i = 0; i < invFiles.length; i++) {
  //     formData.append("inv_file", invFiles[i]);
  //   }
  //   // Append the other request data to the form data
  //   for (let key in invoiceData) {
  //     formData.append(key, invoiceData[key]);
  //   }
  //   if (invoiceBank) {
  //     try {
  //       const response = await axios.post("/api/invoice", formData,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         });
  //       const data = response.data;
  //       if (data.status === "Error") {

  //       } else {
  //         global.config.activityLog(window.location.href, 'Invoice', `Invoice ${invoice_number_regular} (automatic) created Successsfully.`);
  //         setIsSubmitLoading(false);
  //         toast('Invoice Created Successsfully!', {
  //           position: "bottom-right",
  //           autoClose: 2000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "light",
  //         });
  //         dispatch(setNotification(Date.now()));
  //         navigate("/user/invoices");
  //       }
  //     } catch (error) {
  //       global.config.slackMessage(error.toString());
  //       if (error.response.data.status === "mailfailed") {
  //         setIsSubmitLoading(false);
  //         toast.error("Invoice Created Successsfully! but Something went wrong in sending Mail!!", {
  //           position: "bottom-right",
  //           autoClose: 3500,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //         });
  //         global.config.activityLog(window.location.href, 'Invoice', `Invoice ${invoice_number_regular} (automatic) created Successsfully but Something went wrong in sending Mail.`);
  //         navigate("/user/invoices");
  //       } else if (error.response?.data?.status === "deleted") {
  //         localStorage.removeItem("user");
  //         dispatch(logout());
  //         dispatch(reset());
  //         dispatch(invoiceReset());
  //         dispatch(headerReset());
  //         dispatch(appSettingsReset());
  //         toast.error('This user is deleted!', {
  //           position: "bottom-right",
  //           autoClose: 1500,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "light",
  //         });
  //         navigate("/");
  //         return;
  //       } else {
  //         console.log(error);
  //         setIsSubmitLoading(false);
  //         if (descriptions.length > 0) {
  //           alert(`Something went wrong${descriptions.length}`);
  //         } else {
  //           alert("Nothing on items");
  //         }
  //       }
  //     }
  //   } else {
  //     setShowWxCompanyModal(false);
  //     toast.error('Please Select a Bank Account!', {
  //       position: "bottom-right",
  //       autoClose: 1500,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //   }
  // };
  const calculateValue = (vat, amount, duration) => {
    var result = (vat / 100) * amount;
    return parseFloat(amount) + parseFloat(result);
  };
  const selectBankAccount = (getBank) => {
    const bank = getBank.split("_");
    if (bank[0]) {
      setInvoiceCurrency(bank[1]);
      setSelectedInvoiceBank(bank[0]);
      setBankAccount(getBank);
    }
  };

  const fileInputRef = useRef(null);
  const handleClick = () => {
    fileInputRef.current.click();
  };
  // const [perCost, setPerCost] = useState([]);
  useEffect(() => {
    if (defaultbankAccount) {
      const targetObject =
        userBankData &&
        defaultbankAccount &&
        userBankData.find((item) => item.id === parseInt(defaultbankAccount));

      if ((targetObject && targetObject.currency) === autoCurrency) {
        setBankAccount(
          defaultbankAccount &&
          userBankData &&
          targetObject &&
          `${defaultbankAccount}_${targetObject && targetObject.currency}`
        );
        setSelectedInvoiceBank(
          defaultbankAccount && userBankData && defaultbankAccount
        );
      }

      setSelectedInvoiceBank(defaultbankAccount && defaultbankAccount);
      setInvoiceCurrency(
        defaultbankAccount && userBankData && targetObject && autoCurrency
      ); //targetObject.currency
    }
    setInvoiceCurrency(autoCurrency && autoCurrency);

    // const getPersonCost = async () => {
    //   try {
    //     const response = await axios.post(
    //       "/api/project-user-costs-all",
    //       { userId: invoice.selectedUser && invoice.selectedUser[1] },
    //       {
    //         headers: {
    //           Authorization: `Bearer ${token}`,
    //         },
    //       }
    //     );
    //     setPerCost(response.data);
    //   } catch (error) {
    //     global.config.slackMessage(error.toString());
    //     console.error("Error fetching data:", error);
    //   }
    // };

    // getPersonCost();
  }, [
    userBankData,
    defaultbankAccount,
    autoCurrency,
    invoice.selectedUser,
    token,
  ]);

  const onChangeCost = (value, index) => {
    const newCost = [...cost];
    newCost[index] = value;
    setCost(newCost);
  };

  const onChangeVat = (value, index) => {
    const newVat = [...vat];
    newVat[index] = value;
    setVat(newVat);
  };

  const [amount, setAmount] = useState([]);
  const onChangeAmount = (value, index) => {
    const newAmount = [...amount];
    newAmount[index] = value;
    setAmount(newAmount);
  };

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  return (
    <div className="s1-containauto">
      <div className="fs-head-autoinvo">
        <div className="bill-num-si">
          <Link className="invoice-num-01">
            {newInvoiceNumber ? (
              newInvoiceNumber
            ) : (
              <ThreeDots
                height="18"
                width="50"
                radius="9"
                color="#6479f9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            )}
          </Link>
        </div>
        <div className="btn-aary">
          <Link to="/user/invoices" className="cancelbtn-link">
            <img src={cancelImage} alt="cancel" /> Cancel
          </Link>
        </div>
      </div>
      <div className="m-edit-main">
        <form onSubmit={handleSubmit}>
          <input
            type="hidden"
            name="invoice_number_regular"
            value={newInvoiceNumber}
          />
          <div className="edit-sheet-manual01">
            <div className="headder-outter-res-mainu">
              <div className="sheet-manual-header">
                <div className="type-dropdown-em type-dropdown-em-text-input">
                  <div className="dp-main-head-em">Type</div>
                  <div className="read-only-input text-input">
                    Regular Invoice
                  </div>
                  {/* <div className="custom-dropdown">
                    <select
                      className="type-select-me"
                      name="invoice_type"
                      value={invType}
                      onChange={(e) => setInvType(e.target.value)}
                      disabled
                    >
                      <option selected value="Regular">
                        Regular
                      </option>
                    </select>
                    <span className="custom-arrow"></span>
                  </div> */}
                  <div className="tactic00i">
                    <img
                      src={whatis}
                      alt="tool-tip"
                      onMouseEnter={handleTooltipToggle}
                      onMouseLeave={handleTooltipToggle}
                    />
                  </div>
                </div>
                <div className="invo-date-rangeme">
                  <div className="invo-date-range-tile">Date Range</div>
                  <div className="invo-date-range-cal">
                    <div className="calender-date-range-picker invoiceauto-date-range-picker">
                      {invoice && invoice.selectedUser ? (
                        <input
                          type="text"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Pick date range"
                          id="kt_daterangepicker_1"
                          name="date_range"
                          required
                          value={`${moment(
                            invoice.selectedUser && invoice.selectedUser[0][0]
                          )
                            .tz(global.config.getTimeZone())
                            .format("DD/MM/YY")
                            .toString()} - ${moment(
                              invoice.selectedUser && invoice.selectedUser[0][1]
                            )
                              .tz(global.config.getTimeZone())
                              .format("DD/MM/YY")
                              .toString()}`}
                        />
                      ) : (
                        <ThreeDots
                          height="18"
                          width="50"
                          radius="9"
                          color="#6479f9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="invo-date-issueme">
                  <div className="invo-date-issue-tile">Issue Date</div>
                  <div className="invo-date-issue-cal">
                    <div
                      className="calender-date-due-pic invo-autodate-due-pic "
                      ref={issueDateRef}
                    >
                      <input
                        type="text"
                        id="date-issue"
                        data-message="Please add Date Issue"
                        required
                        name="date_issue"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={moment()
                          .tz(global.config.getTimeZone())
                          .format("DD-MM-YY")}
                      />
                    </div>
                  </div>
                </div>
                <div className="date-due-manual-edm">
                  <div className="invo-date-issue-tile also-for-dued-cal">
                    Due Date
                  </div>
                  <div className="invo-date-due-cal">
                    <div className="calender-date-due-pic" ref={dueDateRef}>
                      <div className="date-picker-outer date-picker-outer-all">
                        <div className="custom-picker-icon custom-picker-icon-all">
                          <svg
                            width="1em"
                            height="1em"
                            viewBox="0 0 14 14"
                            fill="currentColor"
                            aria-hidden="true"
                            focusable="false"
                            class="rs-picker-toggle-caret rs-icon"
                            aria-label="calendar"
                            data-category="legacy"
                          >
                            <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                          </svg>
                        </div>
                        <div className="custoom-icon-calender custoom-icon-calender-all">
                          <DatePicker
                            oneTap
                            showOneCalendar
                            ranges={ranges}
                            format="dd/MM/yyyy"
                            value={dueDate}
                            onChange={handleDueDateChange}
                            placement="bottomEnd"
                            required
                            isoWeek={
                              appSettings.weekStartDay &&
                              appSettings.weekStartDay === "Monday"
                            }
                            timeZone={global.config.getTimeZone()}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="sheed-sec-after-headem">
              <div className="tooltip-boxmain">
                {showTooltip && (
                  <Tooltip
                    className="custom-tooltip"
                    content={[
                      {
                        heading: "Regular Invoice",
                        content:
                          "Records the products or services delivered, the total amount due, and preferred payment method",
                        headingClass: "heading1tool",
                        contentClass: "content1tool",
                      },
                      {
                        heading: "Reinbursement Invoice",
                        content:
                          "Used when the subject is paid back for expenses.",
                        headingClass: "heading1tool second-toolhead",
                        contentClass: "content1tool",
                      },
                    ]}
                  />
                )}
              </div>
              <div className="inp-sec2-with2">
                <div className="input-field-inof">
                  <label htmlFor="invoice-title">User Name</label>
                  <input
                    type="text"
                    placeholder="Enter User Name"
                    className="read-only-input"
                    id="user-name"
                    name="user-name"
                    value={userName}
                    required
                  />
                </div>
                <div
                  className={`input-field-inof ${userInvoiceData[0] && userInvoiceData[0]["user_wx_comapny"].wx_company === "Any" ? "inline-section" : ""
                    }`}
                  ref={bankRef}
                >
                  {userInvoiceData[0] && userInvoiceData[0]["user_wx_comapny"].wx_company === "Any" && (
                    <div className="type-dropdown-em">
                      {/* <div className="dp-main-head-em">Company</div> */}
                      <label htmlFor="bank-account">Company</label>
                      <div className="custom-dropdown">
                        <div
                          ref={companyeDropdownRef}
                          className={`custom-dropdown-user-new-outer custom-dropdown-select ${invTypeDropdownOpen ? "open" : ""
                            }`}
                        >
                          <div
                            className="selected-option custom-dropdown-user-new"
                            onClick={handleCompanyToggleDropdown}
                          >
                            {wiseProfile ? (
                              userWiseProfile?.find((wxCompany) => wxCompany.profile_id === wiseProfile)?.wx_company
                            ) : 'Select Company'}
                          </div>
                          {companyDropdownOpen && (
                            <div className="custom-dropdown-new">
                              <ul className="type-list">
                                {/* {wiseCompany &&
                                  wiseCompany?.id.map((item) => {
                                    return (
                                      <li
                                        className={
                                          invType === "Regularw"
                                            ? "selected-item"
                                            : ""
                                        }
                                        onClick={() => {
                                          handleCompanyselect(item.id);
                                          setWiseProfileLabel(item.fullName);
                                        }}
                                      >
                                        {item.fullName}
                                      </li>
                                    );
                                  })} */}
                                {userWiseProfile?.length > 0 &&
                                  userWiseProfile.map((item) => (
                                    <li
                                      key={item._id}
                                      className={wiseProfile === item.profile_id ? 'selected-item' : ''}
                                      onClick={() => handleCompanySelect(item)}
                                    >
                                      {item.wx_company}
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          )}
                        </div>
                        <span className="custom-arrow"></span>
                      </div>
                    </div>
                  )}

                  <Modal
                    className="delete-modal"
                    isOpen={wiseModalIsOpen}
                    onRequestClose={closeWiseModal}
                    contentLabel="No Wise Account Modal"
                    style={{
                      content: {
                        height: "41%",
                        width: "30%",
                        position: "fixed",
                        top: "40%",
                        left: "50%",
                        transform: "translate(-17.5%, 0%)",
                        parent: document.querySelector(".admin-outer.time.tracker"),
                      },
                    }}
                  >
                    <div className="delete-modal-content">
                      <div className="outter-modal-main">
                        <a className="close-image-clr1" onClick={closeWiseModal}>
                          <img src={crssrImage} alt="Close Icon" />{" "}
                        </a>
                      </div>
                      <h2>No Wise Account Registered</h2>
                      <p>
                        There is no Wise account registered for this account. Please add a Wise account to proceed further.
                      </p>
                      {isWiseProfileLoading ? (
                        <div className="outter-load-table">
                          <ThreeDots
                            height="38"
                            width="40"
                            radius="9"
                            color="#6479f9"
                            ariaLabel="three-dots-loading"
                            visible={true}
                          />
                        </div>
                      ) : (
                        <div className="delete-modal-buttons">
                          {(user.userType === userTypeConsts.SUPER_ADMIN || user.userType === userTypeConsts.ADMIN) && (
                            <button className="buttons-approved" onClick={goToWisePage}>
                              Add Wise Account
                            </button>
                          )}
                          <button onClick={closeWiseModal}>Close</button>
                        </div>
                      )}
                    </div>
                  </Modal>
                  {isBankLoading ? (
                    <div className="outter-load-table">
                      <ThreeDots
                        height="50"
                        width="50"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div>
                  ) : (
                    userBankData.length > 0 && (
                      <>


                        {userBankData.length > 0 && (
                          // <select
                          //   required
                          //   className="type-select-me"
                          //   value={bankAccount}
                          //   onChange={(e) => selectBankAccount(e.target.value)}
                          // >
                          //   <option value="" >Select</option>
                          //   {userBankData.length > 0 && userBankData.map((item) => {
                          //     return (
                          //       <> <option value={`${item.id}_${item.currency}`}>{item.details.accountNumber}{item.details.IBAN} ({item.currency})</option></>
                          //     )
                          //   })}
                          // </select>

                          <div
                            ref={bankAccountDropdownRef}
                            className="custom-dropdown-user-new-outer"
                          >
                            <label htmlFor="bank-account">
                              Bank Account{" "}
                            </label>
                            {isBankLoading ? (
                              <div className="outter-load-table">
                                <ThreeDots
                                  height="50"
                                  width="50"
                                  radius="9"
                                  color="#6479f9"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              </div>
                            ) : (
                              <>
                                <div
                                  className="selected-option custom-dropdown-user-new"
                                  onClick={() => {
                                    setBankAccountDropdownOpen(!bankAccountDropdownOpen);
                                    setSearchTerm("");
                                  }}
                                >
                                  {bankAccount ? (
                                    <>
                                      {/* {`${filteredBankData.find(item => `${item.id}` === selectedInvoiceBank)?.details.accountNumber || ''}`}
                                  {`${filteredBankData.find(item => `${item.id}` === selectedInvoiceBank)?.details.accountNumber && filteredBankData.find(item => `${item.id}` === selectedInvoiceBank)?.details.IBAN ? ' ' : ''}`}
                                  {`${filteredBankData.find(item => `${item.id}` === selectedInvoiceBank)?.details.IBAN || ''} (${filteredBankData.find(item => `${item.id}` === selectedInvoiceBank)?.currency || ''})`} */}
                                      {/* {selectedInvoiceBank}*/}
                                      {
                                        filteredBankData.find((item) => item.id === parseInt(selectedInvoiceBank))?.details.accountNumber
                                      }{" "}
                                      {
                                        filteredBankData.find(
                                          (item) =>
                                            item.id ===
                                            parseInt(selectedInvoiceBank)
                                        )?.details.IBAN
                                      }{" "}
                                      ({autoCurrency})
                                      {/* {filteredBankData && filteredBankData.find(item => item.id === parseInt(selectedInvoiceBank))?.accountHolderName} */}
                                    </>
                                  ) : (
                                    "Select Bank Account"
                                  )}
                                </div>
                                {bankAccountDropdownOpen && (
                                  <div
                                    className="custom-dropdown-new"
                                    ref={dropdownListRef}
                                  >
                                    {autoCurrencyFilteredBankDetails.length >
                                      0 ? (
                                      <>
                                        <input
                                          type="text"
                                          className="search-bar"
                                          placeholder="Search..."
                                          value={searchTerm}
                                          onChange={(e) =>
                                            setSearchTerm(e.target.value)
                                          }
                                        />
                                        <ul className="bank-account-list">
                                          {filteredBankData.map((item) => (
                                            <li
                                              key={item.id}
                                              className={
                                                `${item.id}_${item.currency}` ===
                                                  bankAccount
                                                  ? "selected-item"
                                                  : ""
                                              }
                                              onClick={() => {
                                                setBankAccount(
                                                  `${item.id}_${item.currency}`
                                                );
                                                setSelectedInvoiceBank(item.id);
                                                setBankAccountDropdownOpen(false);
                                              }}
                                            >
                                              {`${item.details.accountNumber
                                                ? item.details.accountNumber
                                                : ""
                                                }${item.details.accountNumber &&
                                                  item.details.IBAN
                                                  ? " "
                                                  : ""
                                                }${item.details.IBAN
                                                  ? item.details.IBAN
                                                  : ""
                                                } (${item.currency})`}
                                            </li>
                                          ))}
                                        </ul>
                                      </>
                                    ) : (
                                      <div>
                                        No bank accounts available in the
                                        preferred currency
                                      </div>
                                    )}
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        )}
                        {bankAccSelectIsValid && (
                          <span className="text-red-500 text-xs italic">
                            No Bank is selected
                          </span>
                        )}

                      </>
                    )
                  )}
                  < Modal
                    className="delete-modal"
                    isOpen={bankModalIsOpen}
                    onRequestClose={closeBankModal}
                    contentLabel="Approve Modal"
                    style={{
                      content: {
                        height: "41%",
                        width: "30%",
                        position: "fixed",
                        top: "40%",
                        left: "50%",
                        transform: "translate(-17.5%, 0%)",
                        parent: document.querySelector(
                          ".admin-outer.time.tracker"
                        ),
                      },
                    }}
                  >
                    <div className="delete-modal-content">
                      <div className="outter-modal-main">
                        <a
                          className="close-image-clr1"
                          onClick={closeBankModal}
                        >
                          <img src={crssrImage} alt="Close Icon" />{" "}
                        </a>
                      </div>
                      <h2>No Bank Registered</h2>
                      <p>
                        No Bank Registered in this Account Please add a bank
                        account to proceed further
                      </p>
                      {isItemLoading ? (
                        <div className="outter-load-table">
                          <ThreeDots
                            height="38"
                            width="40"
                            radius="9"
                            color="#6479f9"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </div>
                      ) : (
                        <div className="delete-modal-buttons">
                          <button
                            className="buttons-approved"
                            onClick={addBank}
                            disabled={isItemLoading}
                          >
                            Add Bank
                          </button>
                          <button onClick={closeBankModal}>Cancel</button>
                        </div>
                      )}
                    </div>
                  </Modal>
                </div>
              </div>
              <div className="inp-sec2-with2 inp-sec2-with2-one">
                <div className="input-field-inof">
                  <label htmlFor="invoice-title">Invoice Title</label>
                  <input
                    type="text"
                    placeholder="Enter invoice Title"
                    id="invoice-title"
                    name="title"
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                    ref={titleRef}
                    required
                  />
                  {titleIsValid && (
                    <span className="text-red-500 text-xs italic">
                      Title Cannot be empty
                    </span>
                  )}
                </div>
              </div>
              <div className="detail-add-parteditmanual">
                <table className="invoice-table-editmanual editmanual-table-auto-inpu">
                  <thead className="the-abantant-edittxh">
                    <tr>
                      <th>Description</th>
                      <th>Duration (Hours)</th>
                      <th>
                        {contractType && contractType === "Hourly Rate"
                          ? "Cost/hour"
                          : "Cost"}
                      </th>
                      {/* <th>Cost</th> */}
                      <th>VAT %</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.values(timerData).length > 0 ? (
                      Object.values(timerData).map((project, index) => (
                        <tr key={project.project._id}>
                          <td className="description-manual">
                            <input
                              type="text"
                              name="description"
                              value={project.project.project_name}
                              placeholder="Item name goes here"
                            />
                            <input
                              type="hidden"
                              name="projectId"
                              value={project.project._id}
                              placeholder="Item name goes here"
                            />
                          </td>

                          <td className="edit-manual-costtr">
                            <input
                              type="text"
                              name="duration"
                              placeholder="1,200.00"
                              value={(project.totalDuration / 3600).toFixed(2)}
                            />
                          </td>

                          <td className="edit-manual-costtr">
                            <input
                              type={
                                contractType && contractType === "Hourly Rate"
                                  ? "number"
                                  : "hidden"
                              }
                              name="costPerHour"
                              className="whitebg-sec"
                              placeholder="1,200.00"
                              // value={(
                              //   (project.totalDuration / 3600) *
                              //   (
                              //     cost[index] >= 0
                              //       ? cost[index]
                              //       : perCost && perCost.length > 0
                              //         ? perCost
                              //           .filter((item) => item.projectId === project.project._id)
                              //           .some((item) => item.amount > 0)
                              //           ? perCost
                              //             .filter((item) => item.projectId === project.project._id)
                              //             .map((item) => item.amount)
                              //             .reduce((acc, amount) => acc + amount, 0)
                              //           : personCost
                              //         : personCost || 0
                              //   )
                              // ).toFixed(2)}
                              value={(
                                (project.totalDuration / 3600) *
                                (
                                  cost[index] >= 0
                                    ? cost[index]
                                    : personCost || 0
                                )
                              ).toFixed(2)}

                              onChange={(e) =>
                                onChangeCost(e.target.value, index)
                              }
                              style={{ backgroundColor: "white" }} // Add inline CSS for background color
                            />

                            <input
                              type={
                                contractType && contractType === "Hourly Rate"
                                  ? "hidden"
                                  : "number"
                              }
                              name="cost"
                              placeholder="0.00"
                              // value={(
                              //   ((project.totalDuration / 3600) *
                              //     (
                              //       cost[index] >= 0
                              //         ? cost[index]
                              //         : perCost && perCost.length > 0
                              //           ? perCost
                              //             .filter((item) => item.projectId === project.project._id)
                              //             .some((item) => item.amount > 0)
                              //             ? perCost
                              //               .filter((item) => item.projectId === project.project._id)
                              //               .map((item) => item.amount)
                              //               .reduce((acc, amount) => acc + amount, 0) // Sum amounts
                              //             : personCost || 0
                              //           : personCost || 0
                              //     ))
                              // ).toFixed(2)}
                              value={(
                                (project.totalDuration / 3600) *
                                (
                                  cost[index] >= 0
                                    ? cost[index]
                                    : personCost || 0
                                )
                              ).toFixed(2)}
                            />
                          </td>

                          <td className="editmanual-vattd">
                            <input
                              type="number"
                              className="whitebg-sec"
                              name="vat"
                              value={
                                vat[index] >= 0
                                  ? vat[index]
                                  : userInvoiceData[0]?.vat
                              }
                              onChange={(e) =>
                                onChangeVat(e.target.value, index)
                              }
                              onWheel={numberInputOnWheelPreventChange}
                            // value={userInvoiceData[0]?.vat}
                            />
                          </td>

                          <td className="editmanual-amount-tr">
                            <div className="amount-cell">
                              <button className="delete-icon">
                                {/* {invoiceCurrency &&
                                  new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: autoCurrency,
                                  }).format(
                                    calculateValue(
                                      vat[index] >= 0 ? vat[index] : userInvoiceData[0]?.vat,
                                      (project.totalDuration / 3600) *
                                      (cost[index] >= 0
                                        ? cost[index]
                                        : perCost && perCost.length > 0
                                          ? perCost
                                            .filter((item) => item.projectId === project.project._id)
                                            .some((item) => item.amount > 0)
                                            ? perCost
                                              .filter((item) => item.projectId === project.project._id)
                                              .reduce((acc, item) => acc + item.amount, 0) // Sum amounts
                                            : personCost || 0
                                          : personCost || 0
                                      ),
                                      project.totalDuration / 3600
                                    ).toFixed(5)
                                  )} */}
                                {invoiceCurrency &&
                                  new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: autoCurrency,
                                  }).format(
                                    calculateValue(
                                      vat[index] >= 0 ? vat[index] : userInvoiceData[0]?.vat,
                                      (project.totalDuration / 3600) *
                                      (cost[index] >= 0
                                        ? cost[index]
                                        : personCost || 0
                                      ),
                                      project.totalDuration / 3600
                                    ).toFixed(5)
                                  )}
                              </button>
                              <input
                                type="hidden"
                                name="amount"
                                // value={calculateValue(
                                //   vat[index] >= 0 ? vat[index] : userInvoiceData[0]?.vat,
                                //   (project.totalDuration / 3600) *
                                //   (cost[index] >= 0
                                //     ? cost[index]
                                //     : perCost && perCost.length > 0
                                //       ? perCost
                                //         .filter((item) => item.projectId === project.project._id)
                                //         .some((item) => item.amount > 0)
                                //         ? perCost
                                //           .filter((item) => item.projectId === project.project._id)
                                //           .reduce((acc, item) => acc + item.amount, 0)
                                //         : personCost || 0
                                //       : personCost || 0
                                //   ),
                                //   project.totalDuration / 3600
                                // ).toFixed(5)}
                                value={calculateValue(
                                  vat[index] >= 0 ? vat[index] : userInvoiceData[0]?.vat,
                                  (project.totalDuration / 3600) *
                                  (cost[index] >= 0
                                    ? cost[index]
                                    : personCost || 0
                                  ),
                                  project.totalDuration / 3600
                                ).toFixed(5)}
                              />
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : isItemLoading === true ? (
                      <ThreeDots
                        height="18"
                        width="50"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    ) : (
                      <tr>
                        <td>Nothing to list</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <Modal
                  className="delete-modal"
                  isOpen={timerDataModalIsOpen}
                  onRequestClose={closeTimerDataModal}
                  contentLabel="Approve Modal"
                  style={{
                    content: {
                      height: "41%",
                      width: "30%",
                      position: "fixed",
                      top: "40%",
                      left: "50%",
                      transform: "translate(-17.5%, 0%)",
                      parent: document.querySelector(
                        ".admin-outer.time.tracker"
                      ),
                    },
                  }}
                >
                  <div className="delete-modal-content">
                    <div className="outter-modal-main">
                      <a
                        className="close-image-clr1"
                        onClick={closeTimerDataModal}
                      >
                        <img src={crssrImage} alt="Close Icon" />{" "}
                      </a>
                    </div>
                    <h2>No Time Logged Found</h2>
                    <p>No Time Logged activity found in the selected period.</p>
                    {isItemLoading ? (
                      <div className="outter-load-table">
                        <ThreeDots
                          height="38"
                          width="40"
                          radius="9"
                          color="#6479f9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div>
                    ) : (
                      <div className="delete-modal-buttons">
                        <button
                          onClick={closeTimerDataModal}
                          disabled={isItemLoading}
                        >
                          Cancel
                        </button>
                      </div>
                    )}
                  </div>
                </Modal>
              </div>
            </div>
            <div className="sec-after-tab-ls-part">
              <div className="sec-withother-and-add">
                <div className="other-secp1">
                  <div className="other-head1">Other comments</div>
                  <div className="other-subhead1">
                    <textarea
                      type="text"
                      className=""
                      placeholder="Other Comments"
                      value={otherComnt}
                      onChange={(e) => setOtherComnt(e.target.value)}
                      style={{ resize: "none" }}
                    ></textarea>
                  </div>
                </div>
                <div className="file-add-p1-attachment">
                  <div className="attachment-head">Attachments</div>
                  <div
                    className="style-portion-browsetext"
                    style={{ cursor: "pointer" }}
                  >
                    <label className="add-item-button-end-browse">
                      <img src={addicoplus} alt="addicon" /> Browse
                      <input
                        ref={fileInputRef}
                        type="file"
                        style={{ display: "none" }}
                        onChange={handleInvDocChange}
                        multiple // Allow multiple file selection
                      />
                    </label>
                    <div className="upload-file-name-br">
                      {invFiles.map((file, index) => (
                        <div key={index} className="uploaded-file-item">
                          <span>{file.name}</span>
                          <a onClick={() => removeFile(index)}>Remove</a>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="invo-total-specialcard">
                <div className="specialcard-outter">
                  <table className="to-ino-to-special">
                    <tr>
                      <th className="sub-lem-tb-fisl">Subtotal</th>
                      <td className="sub-price-tb-fisl">
                        {invoiceCurrency && totalAmount > 0
                          ? new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: invoiceCurrency,
                          }).format(vatSum)
                          : "0.00"}
                      </td>
                    </tr>
                    <tr>
                      <th className="sub-vat-tb-fisl">VAT</th>
                      <td className="sub-vatpr-tb-fisl">
                        {invoiceCurrency && totalAmount > 0
                          ? new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: invoiceCurrency,
                          }).format(totalAmount - vatSum)
                          : "0.00"}
                      </td>
                    </tr>
                    <tr>
                      <th className="sub-invoto-tb-fisl">Invoice Total</th>
                      <td className="sub-ivvoto-price-tb-fisl">
                        {invoiceCurrency && totalAmount > 0
                          ? new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: invoiceCurrency,
                          }).format(totalAmount)
                          : "0.00"}
                      </td>
                    </tr>
                  </table>
                  {Object.values(timerData).length > 0 ? (
                    isSubmitLoading === false ? (
                      <>
                        <button type="submit" className="largecreate-invo-btn">
                          Create Invoice{" "}
                        </button>
                      </>
                    ) : (
                      <>
                        <a className="largecreate-invo-btn1">
                          <ThreeDots
                            height="18"
                            width="50"
                            style={{ paddingLeft: "100px" }}
                            radius="9"
                            color="white"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </a>
                      </>
                    )
                  ) : (
                    <>
                      <a disabled className="largecreate-invo-btn1">
                        Create Invoice
                      </a>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div>
            <Modal
              className="delete-modal"
              isOpen={showWxCompanyModal}
              onRequestClose={closeWXCompanyModal}
              contentLabel="Payment"
              style={{
                content: {
                  height: "41%",
                  width: "30%",
                  position: "fixed",
                  top: "40%",
                  left: "50%",
                  transform: "translate(-17.5%, 0%)",
                  parent: document.querySelector(".admin-outer.time.tracker"),
                },
              }}
            >
              <div className="delete-modal-content">
                <div className="outter-modal-main">
                  <a className="close-image-clr1" onClick={closeWXCompanyModal}>
                    <img src={crssrImage} alt="Close Icon" />{" "}
                  </a>
                </div>
                <h2>Select Wise Account</h2>
                <p>Select Wise Account to Create this Invoice?</p>

                {/* {userWiseProfile && userWiseProfile.length === 2 && ( */}
                <div className="form-field-payment">
                  <label htmlFor="client" className="form-label">
                    Select Wise Account
                  </label>
                  <select
                    required
                    onChange={(e) => setWxCompany(e.target.value)}
                    className="form-select"
                    value={wxComapny}
                  >
                    <option value="">Select Wise Account </option>

                    {userWiseProfile?.length > 0 &&
                      userWiseProfile?.map((item) => {
                        return (
                          <option value={item.wx_company}>
                            {item.wx_company}
                          </option>
                        );
                      })}
                  </select>
                </div>
                {/* )} */}
                <div className="delete-modal-buttons paynow-invoice-sec">
                  {userBankData.length > 0 &&
                    invoice.selectedUser &&
                    userWiseProfile.length > 0 &&
                    wxComapny ? (
                    <button
                      className="buttons-submit"
                      // type="submit"
                      onClick={handleFormSubmit}
                      disabled={isSubmitLoading}
                    >
                      {isSubmitLoading ? (
                        <div className="outter-load-table">
                          <ThreeDots
                            height="38"
                            width="40"
                            radius="9"
                            color="white"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </div>
                      ) : (
                        "Create Invoice"
                      )}
                    </button>
                  ) : (
                    <button
                      className="buttons-pay buttons-pay-disabled"
                      disabled
                    >
                      Create Invoice
                    </button>
                  )}
                  <button
                    className="buttons-pay-cancel"
                    onClick={closeWXCompanyModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Modal>
          </div>
        </form>
      </div >
    </div >
  );
};

export default InvoiceCreateAutocom;
