import React from 'react'
import { ThreeDots } from 'react-loader-spinner'
import { Parser } from 'html-to-react';
import userCategoryName from "../../../../constants/userCategory";
const XPERTOS = "10873693";

const InvoiceAddress = (invoiceData) => {
    return (
        <>
            <div className="invo-from-to">
                <div className="invo-from-sing">
                    <div className="from-sing-head">From</div>
                    <div className="name-sing-head">{invoiceData.invoiceData ? invoiceData.invoiceData.user_category && [userCategoryName.Business, userCategoryName.Vendor, userCategoryName.Client].includes(invoiceData.invoiceData.user_category) ? (invoiceData.invoiceData.company_name) : (`${String(invoiceData.invoiceData.first_name) === "null" ? "" : invoiceData.invoiceData.first_name} ${String(invoiceData.invoiceData.middle_name) === "null" ? "" : invoiceData.invoiceData.middle_name} ${String(invoiceData.invoiceData.last_name) === "null" ? "" : invoiceData.invoiceData.last_name}`) : (<ThreeDots height="18" width="20" radius="9" color="#6479f9" ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />)}</div>
                    <div className="addres-sing-head styll2">{invoiceData.invoiceData ? (invoiceData.invoiceData.address) : (<ThreeDots height="18" width="20" radius="9" color="#6479f9" ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />)}</div>
                    <div className="tele-sing-head styll2"> {invoiceData.invoiceData ? (`Tel: ${invoiceData.invoiceData.mobile_no}`) : (<ThreeDots height="18" width="20" radius="9" color="#6479f9" ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />)}</div>
                    <div className="mail-sing-head styll2"> {invoiceData.invoiceData ? (`Email: ${invoiceData.invoiceData.email}`) : (<ThreeDots height="18" width="20" radius="9" color="#6479f9" ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />)}</div>
                </div>
                <div className="invo-to-sing">
                    <div className="to-sing-head">To</div>
                    {/* {invoiceData.invoiceData ?
                        invoiceData.Company === XPERTOS || invoiceData.invCompany==="Xpertos" ?
                            (<><div className="toname-sing-head">XPERTOS GROUP INC</div>
                                <div className="toaddres-sing-head styll2">Calle 50, F&F Tower, 37th Floor Office D, Republic of Panama</div>
                                <div className="totele-sing-head styll2">Tel: +1 561 229 4778</div>
                                <div className="tomail-sing-head styll2">Email: info@wx.agency</div></>)
                            :
                            (<><div className="toname-sing-head">WX EUROPE BV</div>
                                <div className="toaddres-sing-head styll2">De Vriesstraat 34, Oud-Beijerland, The Netherlands</div>
                                <div className="totele-sing-head styll2">Tel: +1 561 229 4778</div>
                                <div className="tomail-sing-head styll2">Email: info@wx.agency</div></>)
                        :
                        (<ThreeDots height="18" width="20" radius="9" color="#6479f9" ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />)
                    } */}
                    <div className="toname-sing-head">
                        {invoiceData.invoiceData ? (
                            `${String(invoiceData.invoiceCompanyDetails?.legal_entity_company_name) === "null" ? "" : invoiceData.invoiceCompanyDetails?.legal_entity_company_name}` || `${String(invoiceData.invoiceCompanyDetails?.wx_company) === "null" ? "" : invoiceData.invoiceCompanyDetails?.wx_company}`
                        ) : (
                            <ThreeDots height="18" width="20" radius="9" color="#6479f9" ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
                        )}
                    </div>
                    <div className="toaddres-sing-head styll2">
                        {invoiceData?.invoiceData ? (
                            <pre style={{ fontFamily: 'halyard-display' }}>
                                {Parser().parse(invoiceData.invoiceCompanyDetails?.address)}
                            </pre>
                        ) : (
                            <ThreeDots height="18" width="20" radius="9" color="#6479f9" ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
                        )}
                    </div>
                    <div className="totele-sing-head styll2">
                        {invoiceData.invoiceData ? (
                            `Tel: ${invoiceData.invoiceCompanyDetails?.mobile_no}`
                        ) : (
                            <ThreeDots height="18" width="20" radius="9" color="#6479f9" ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
                        )}
                    </div>
                    <div className="tomail-sing-head styll2">
                        {invoiceData.invoiceData ? (
                            `Email: ${invoiceData.invoiceCompanyDetails?.email}`
                        ) : (
                            <ThreeDots height="18" width="20" radius="9" color="#6479f9" ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default InvoiceAddress