import React from 'react';
import './subscription.css';
import SubscriptionCurrentPlan from '../../components/Subscription/SubscriptionCurrentPlan';
import SubscriptionOverviewTable from '../../components/Subscription/SubscriptionOverviewTable';
import SubscriptionPricingTable from '../../components/Subscription/SubscriptionPricingTable';

const SubscriptionOverview = () => {
  return (
    <>
    <div className='subscription-outer-overview'>
      <SubscriptionCurrentPlan />
      <SubscriptionOverviewTable />
    </div>
    <div className='pricing-table-outer'>
        <SubscriptionPricingTable />
    </div>
    </>
  );
};

export default SubscriptionOverview;