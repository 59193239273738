import React, { useState, useEffect } from 'react';
import "./ProjectOverviewListingSingleActivities.css";
import { ThreeDots } from "react-loader-spinner";
import { useSelector } from 'react-redux';
import axios from 'axios';
import { DateRangePicker } from "rsuite";
import AES from "crypto-js/aes";
import { Link, useParams } from 'react-router-dom';
import TimeIccon from "../../../../images/icons/clock.svg";
import DollarIccon from "../../../../images/icons/billable.svg";
import CloseDollarIccon from "../../../../images/icons/non-billable.svg";
import PurseMmoney from "../../../../images/icons/purses-money.svg";
import NotepadIcon from "../../../../images/icons/notepad.svg";
import DollarReverse from "../../../../images/icons/dollar-reverse.svg";

const ClientActivities = (props) => {
    const { forex } = useSelector((state) => state.forex);
    const settings = JSON.parse(localStorage.getItem("settings"));
    const { projectId } = useParams();
    const projectLoading = props.isLoadingProject;

    const totalHours = props?.project?.timers && props?.project?.timers.reduce((total, timer) => total + timer.duration, 0);
    const totalCost = (props?.project?.timers || []).reduce((sum, timer) => {
        return sum + (timer.totalCost || 0);
    }, 0);
    const totalBudget = (props?.project?.sprints || []).reduce((sum, sprint) => {
        return sum + (sprint.budget || 0);
    }, 0);

    const changeDurationFormat = (sec) => {
        let second = sec;
        const hours = Math.floor(second / 3600);
        const minutes = Math.floor((second % 3600) / 60);
        const seconds = second % 60;
        const formattedDuration = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        return formattedDuration;
    }

    const Roi = (Cbudget, cCost) => {
        const budget = Cbudget;
        const cost = cCost;
        if (cost === 0 && budget === 0) {
            return "0%";
        } else if (cost === 0 && budget !== 0) {
            return "N/A";
        } else {
            const rev = budget - cost;
            const Rvalue = (rev / cost) * 100;
            return isNaN(Rvalue) ? "0%" : Rvalue.toFixed(2) + "%";
        }
    }

    function ConvertHelper(currencyFrom, currencyTo, amount, forex) {
        const currencyFromRate = forex[currencyFrom];
        const currencyToRate = forex[currencyTo];
        const amountConvertedUSD = amount / currencyFromRate;
        const amountConvertTo = amountConvertedUSD * currencyToRate;
        return amountConvertTo;
    }

    const preferredCurrency = props.project?.client_name?.preferred_currency || 'USD';
    const convertedBudget = ConvertHelper(preferredCurrency, settings.currency, totalBudget, forex);

    return (
        <div className="client-activities-outer">
            <div className="client-activity-columns">
                <div className="activity-column">
                    <div className="activity-with-image">
                        <div className="activity-name">
                            Total Hours
                        </div>
                        <img src={TimeIccon} alt="time-iccon" />
                    </div>
                    <div className="activity-count">
                        {projectLoading ? (
                            <div className="outter-load-table">
                                <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                />
                            </div>
                        ) : (
                            <span>{totalHours ? changeDurationFormat(totalHours) : '00:00:00'}</span>
                        )}
                    </div>
                </div>
                <div className="activity-column">
                    <div className="activity-with-image">
                        <div className="activity-name">
                            Total Billable
                        </div>
                        <img src={DollarIccon} alt="dollar-iccon" />
                    </div>
                    <div className="activity-count">
                        {projectLoading ? (
                            <div className="outter-load-table">
                                <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                />
                            </div>
                        ) : (
                            <span>
                                {props.project.is_billable && totalHours ? changeDurationFormat(totalHours) : '00:00:00'}
                            </span>
                        )}
                    </div>
                </div>
                <div className="activity-column">
                    <div className="activity-with-image">
                        <div className="activity-name">
                            Total Non-Billable
                        </div>
                        <img src={CloseDollarIccon} alt="close-dollar-iccon" />
                    </div>
                    <div className="activity-count">
                        {projectLoading ? (
                            <div className="outter-load-table">
                                <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                />
                            </div>
                        ) : (
                            <span>
                                {!props.project.is_billable && totalHours ? changeDurationFormat(totalHours) : '00:00:00'}
                            </span>
                        )}
                    </div>
                </div>
                <div className="activity-column">
                    <div className="activity-with-image">
                        <div className="activity-name">
                            Total Budget
                        </div>
                        <img src={PurseMmoney} alt="purses-of-money" />
                    </div>
                    {props.isLoadingProject ? (
                        <div className="outter-load-table">
                            <ThreeDots
                                height="18"
                                width="20"
                                radius="9"
                                color="#6479f9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            />
                        </div>
                    ) : (
                        <div className="activity-count">
                            {(preferredCurrency || projectLoading) ? (
                                <>
                                    {totalBudget ? (
                                        <>
                                            {/* {props.project?.budget.toFixed(2)} */}
                                            {new Intl.NumberFormat("en-US", {
                                                style: "currency",
                                                currency: preferredCurrency || 'USD',
                                            }).format(ConvertHelper(props.project?.client_name?.preferred_currency, preferredCurrency, totalBudget, forex))}
                                        </>
                                    ) : (
                                        // "00.00"
                                        <>
                                            {new Intl.NumberFormat("en-US", {
                                                style: "currency",
                                                currency: preferredCurrency || 'USD',
                                            }).format("00.00")}
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    {totalBudget ? (
                                        <>
                                            {/* {props.project?.budget.toFixed(2)} */}
                                            {new Intl.NumberFormat("en-US", {
                                                style: "currency",
                                                currency: preferredCurrency || "USD",
                                            }).format(ConvertHelper("USD", preferredCurrency || "USD", totalBudget, forex))}
                                        </>
                                    ) : (
                                        "00.00"
                                    )}
                                </>
                            )}
                        </div>
                    )}
                </div>
                <div className="activity-column">
                    <div className="activity-with-image">
                        <div className="activity-name">
                            Total Cost
                        </div>
                        <img src={NotepadIcon} alt="notepad-iccon" />
                    </div>
                    <div className="activity-count">
                        {projectLoading ? (
                            <div className="outter-load-table">
                                <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                />
                            </div>
                        ) : (
                            props.project?.client_name?.preferred_currency ? (
                                totalCost ?
                                    new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: preferredCurrency || "USD",
                                    }).format(totalCost)
                                    :
                                    new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: preferredCurrency || "USD",
                                    }).format("0.00")
                            ) : (
                                totalCost ? totalCost.toFixed(2) : '0.00'
                            )
                        )}
                    </div>
                </div>
                <div className="activity-column">
                    <div className="activity-with-image">
                        <div className="activity-name">
                            ROI
                        </div>
                        <img src={DollarReverse} alt="time-iccon" />
                    </div>
                    <div className="activity-count">
                        {/* 23% */}
                        {(props.isLoadingProject) ? (
                            <div className="outter-load-table">
                                <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                />
                            </div>
                        ) : (
                            // Roi(props.project?.budget ? props.project?.budget : 0, totalCost ? totalCost : 0)
                            Roi(totalBudget ? totalBudget : 0, totalCost ? totalCost : 0)
                        )}
                    </div>
                </div>

            </div>
        </div>
    )
};

export default ClientActivities; 