import React, { useState, useEffect } from 'react';
import "./ProjectOverview.css";
import { ThreeDots } from "react-loader-spinner";
import { DateRangePicker } from "rsuite";
import { useSelector, useDispatch } from "react-redux";
import { AES, enc } from 'crypto-js';
import { Link, useParams } from 'react-router-dom';
import moment from "moment";
import TimeIccon from "../../../../images/icons/clock.svg";
import DollarIccon from "../../../../images/icons/billable.svg";
import CloseDollarIccon from "../../../../images/icons/non-billable.svg";
import PurseMmoney from "../../../../images/icons/purses-money.svg";
import NotepadIcon from "../../../../images/icons/notepad.svg";
import DollarReverse from "../../../../images/icons/dollar-reverse.svg";
import { setClientDashboardDate } from '../../../../features/client/clientSlice';


const ProjectOverview = (props) => {
    const dispatch = useDispatch();
    const { forex } = useSelector((state) => state.forex);
    const settings = JSON.parse(localStorage.getItem("settings"));

    const [selectedRange, setSelectedRange] = useState([]);
    const token = useSelector((state) => state.auth.token);
    const dashboradDate = useSelector((state) => state.client.dashboardDateRange);
    const { projectAdded } = useSelector((state) => state.project);

    const { appSettings } = useSelector((state) => state.appSettings);
    const startDate = props.dateRange && props.dateRange[0];
    const endDate = props.dateRange && props.dateRange[1];
    // const { data: totalBudget = [], isLoading: totalBudgetLoading, refetch: refetchTotalBudget, isError: totalBudgetFetchError } = useTotalBudgetByProjectsAndClients(props.projectIds, props.clientIds, startDate, endDate, token, forex, settings.currency);
    // const { data: trackedHours = [], isLoading: trackedHoursLoading, refetch: refetchTrackedlHours, isError: trackedHoursFetchError } = useTrackedProjectAndClientHours(props.projectIds, props.clientIds, startDate, endDate, token);

    useEffect(() => {
        // refetchTotalCost();
        // refetchTrackedlHours();
        // refetchTotalBudget();
    }, [props.clientIds, props.dateRange, dashboradDate, projectAdded]);

    const handleDateRangeChange = async (value) => {
        if (value === null) {
            var newDate = [
                moment().tz(global.config.getTimeZone()).startOf('year').format('YYYY/MM/DD HH:mm'),
                moment().tz(global.config.getTimeZone()).endOf('year').format('YYYY/MM/DD HH:mm')
            ];
            setSelectedRange(newDate);
        } else {
            setSelectedRange(value);
            await dispatch(setClientDashboardDate(value));
        }
    };

    const changeDurationFormat = (sec) => {
        let second = sec;
        const hours = Math.floor(second / 3600);
        const minutes = Math.floor((second % 3600) / 60);
        const seconds = second % 60;
        const formattedDuration = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        return formattedDuration;
    }

    const Roi = (Cbudget, cCost) => {
        const budget = Cbudget;
        const cost = cCost;
        if (cost === 0 && budget === 0) {
            return "0%";
        } else if (cost === 0 && budget !== 0) {
            return "N/A";
        } else {
            const rev = budget - cost;
            const Rvalue = (rev / cost) * 100;
            return isNaN(Rvalue) ? "0%" : Rvalue.toFixed(2) + "%";
        }
    }

    return (
        <div className="client-activities-outer">
            <div className="client-activity-columns">
                <div className="activity-column">
                    <div className="activity-with-image">
                        <div className="activity-name">
                            Total Hours
                        </div>
                        <img src={TimeIccon} alt="time-iccon" />
                    </div>
                    <div className="activity-count">
                        {props.totalCostLoading ? (
                            <div className="outter-load-table">
                                <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                />
                            </div>
                        ) : (
                            <span>{props.totalDuration ? changeDurationFormat(props.totalDuration) : '00:00:00'}</span>
                        )}
                    </div>
                </div>
                <div className="activity-column">
                    <div className="activity-with-image">
                        <div className="activity-name">
                            Total Billable
                        </div>
                        <img src={DollarIccon} alt="dollar-iccon" />
                    </div>
                    <div className="activity-count">
                        {props.totalCostLoading ? (
                            <div className="outter-load-table">
                                <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                />
                            </div>
                        ) : (
                            <span>{props.calculateTotalDurationBillable ? changeDurationFormat(props.calculateTotalDurationBillable) : '00:00:00'}</span>
                        )}
                    </div>
                </div>
                <div className="activity-column">
                    <div className="activity-with-image">
                        <div className="activity-name">
                            Total Non-Billable
                        </div>
                        <img src={CloseDollarIccon} alt="close-dollar-iccon" />
                    </div>
                    <div className="activity-count">
                        {props.totalCostLoading ? (
                            <div className="outter-load-table">
                                <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                />
                            </div>
                        ) : (
                            <span>{props.calculateTotalDurationNonBillable ? changeDurationFormat(props.calculateTotalDurationNonBillable) : '00:00:00'}</span>
                        )}
                    </div>
                </div>
                <div className="activity-column">
                    <div className="activity-with-image">
                        <div className="activity-name">
                            Total Budget
                        </div>
                        <img src={PurseMmoney} alt="purses-of-money" />
                    </div>
                    <div className="activity-count">
                        {props.totalCostLoading ? (
                            <div className="outter-load-table">
                                <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                />
                            </div>
                        ) : (
                            // totalBudget ? totalBudget : '0.00'
                            settings.currency ? (
                                props.totalBudget ?
                                    new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: settings.currency,
                                    }).format(props.totalBudget)
                                    :
                                    new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: settings?.currency,
                                    }).format("0.00")
                            ) : (
                                props.totalBudget ?
                                    new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: 'USD',
                                    }).format(props.totalBudget) :
                                    new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: 'USD',
                                    }).format('0.00')
                            )
                        )}
                    </div>
                </div>
                <div className="activity-column">
                    <div className="activity-with-image">
                        <div className="activity-name">
                            Total Cost
                        </div>
                        <img src={NotepadIcon} alt="notepad-iccon" />
                    </div>
                    <div className="activity-count">
                        {props.totalCostLoading ? (
                            <div className="outter-load-table">
                                <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                />
                            </div>
                        ) : (
                            // totalCost ? totalCost : '0.00'
                            settings.currency ? (
                                props.totalCost ?
                                    new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: settings.currency,
                                    }).format(props.totalCost)
                                    :
                                    new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: settings?.currency,
                                    }).format("0.00")
                            ) : (
                                props.totalCost ? props.totalCost : '0.00'
                            )
                        )}
                    </div>
                </div>
                <div className="activity-column">
                    <div className="activity-with-image">
                        <div className="activity-name">
                            ROI
                        </div>
                        <img src={DollarReverse} alt="time-iccon" />
                    </div>
                    <div className="activity-count">

                        {props.totalCostLoading ? (
                            <div className="outter-load-table">
                                <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                />
                            </div>
                        ) : (
                            Roi(props.totalBudget ? parseFloat(props.totalBudget) : 0, props.totalCost ? parseFloat(props.totalCost) : 0)
                        )}
                    </div>
                </div>

            </div>
        </div>
    )
};

export default ProjectOverview; 