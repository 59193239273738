import React, { PureComponent, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { ThreeDots } from "react-loader-spinner";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceLine, Legend, ResponsiveContainer, LabelList } from 'recharts';
import { processedBudgetedTrackedByProject } from './Services/reportTimeWorked';

const data = [
  { name: 'Rent', budgeted: 144, tracked: 124 },
  { name: 'Utilities', budgeted: 155, tracked: 139 },
  { name: 'Groceries', budgeted: 113, tracked: 130 },
  { name: 'Transport', budgeted: 143, tracked: 150 },
  { name: 'Entertainment', budgeted: 146, tracked: 127 },
  { name: 'Healthcare', budgeted: 155, tracked: 140 },
  { name: 'Miscellaneous', budgeted: 132, tracked: 118 },
];

const BudgetedTracked = ({ trackReport, trackReportLoading, trackReportError }) => {
  const reportingCliTimeWorked = useSelector((state) => state.reportingCliTimeWorked);

  const processedData = useMemo(() => {
    return processedBudgetedTrackedByProject(
      trackReport,
      reportingCliTimeWorked,
    );
  }, [trackReport, reportingCliTimeWorked,]);

  if (trackReportLoading) return <div className="outter-load-table">
    <ThreeDots
      height="80"
      width="80"
      radius="9"
      color="#6479f9"
      ariaLabel="three-dots-loading"
      wrapperStyle={{}}
      wrapperClassName=""
      visible={true}
    />
  </div>;
  if (trackReportError) return <div>Error loading data.</div>;

  const calculateXTicks = () => {
    if (!processedData.length) return [];
    const maxDataValue = Math.max(...processedData.map(item => Math.max(item.budgeted, item.tracked)));
    if (maxDataValue === 0) return [0];
    // const tickInterval = 10; // Set the interval between ticks
    const tickInterval = Math.ceil(maxDataValue / 10);
    const numOfTicks = Math.ceil(maxDataValue / tickInterval);
    const ticks = [];

    for (let i = 0; i <= numOfTicks + 4; i++) { // Adjust the number of ticks
      ticks.push(i * tickInterval);
    }

    return ticks;
  }

  const xAxisTicks = calculateXTicks();

  const changeDurationFormat = (sec) => {
    const hours = Math.floor(sec / 3600);
    const minutes = Math.floor((sec % 3600) / 60);
    const seconds = sec % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const calculateChartHeight = (dataLength) => {
    const rowHeight = 50; // Adjust this value to set the height per bar
    const baseHeight = 100; // Add some base height for padding and labels
    return dataLength * rowHeight + baseHeight;
  };

  const calculateLeftMargin = (data) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = '12px Arial';

    const longestWordLength = data.reduce((maxLength, item) => {
      const firstWord = item.name.split(' ')[0];
      const wordWidth = context.measureText(firstWord).width;
      return Math.max(maxLength, wordWidth);
    }, 0);

    return Math.max(-20, longestWordLength - 150);
  };

  const leftMargin = calculateLeftMargin(processedData);

  return (
    <div className='graph-project-per-hour' style={{ width: '100%', height: Math.max(542, calculateChartHeight(processedData.length)), }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          layout="vertical"
          data={processedData}
          barSize={13}
          margin={{
            top: 20,
            right: 42,
            left: leftMargin,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" horizontal={false} vertical={true} />
          <XAxis
            type="number"
            domain={[0, 'dataMax + 10']}
            tickFormatter={(tick) => changeDurationFormat(tick)}
            ticks={xAxisTicks}
          />
          <YAxis
            type="category"
            dataKey="name"
            width={150}
            tickMargin={10}
          />
          <Tooltip formatter={(value) => changeDurationFormat(value)} />
          <Legend />
          <ReferenceLine x={0} stroke="#D3D3D3" />
          <Bar dataKey="budgeted" name="Budgeted hours" fill="#87ABE2">
            <LabelList dataKey="budgeted" position="right" formatter={(value) => value !== 0 ? changeDurationFormat(value) : null} />
          </Bar>
          <Bar dataKey="tracked" name="Tracked Hours" fill="#C7B6F6">
            <LabelList dataKey="tracked" position="right" formatter={(value) => value !== 0 ? changeDurationFormat(value) : null} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default BudgetedTracked;
