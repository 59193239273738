import axios from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';

const API_BASE_URL = '/api';
// Fetch time-off requests
export const useProjectList = (token) => {
    return useQuery('projectList', async () => {
        const response = await axios.get(`${API_BASE_URL}/project-details`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    });
};

export const useTrackedProjectAndClientHours = (projectIds, clientIds, startDate, endDate, token) => {
    return useQuery(['trackedProjectAndClientHours', projectIds, clientIds, startDate, endDate,], async () => {
        try {
            const requestData = { projectIds, clientIds };
            if (startDate && endDate) {
                requestData.startDate = startDate;
                requestData.endDate = endDate;
            }
            const response = await axios.post('/api/time-track/total-duration-by-projects-and-clients', requestData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            global.config.slackMessage(error.toString());
            throw new Error('Error fetching total duration by projects and clients');
        }
    });
};

export const useTotalBudgetByProjectsAndClients = (projectIds, clientIds, startDate, endDate, token, forex, currency) => {
    return useQuery(['totalBudgetByProjectsAndClients', projectIds, clientIds, startDate, endDate, forex, currency], async () => {
        try {
            const requestData = { projectIds, clientIds, forex, currency };
            if (startDate && endDate) {
                requestData.startDate = startDate;
                requestData.endDate = endDate;
            }
            const response = await axios.post('/api/time-track/total-budget-by-projects-and-clients', requestData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data.totalBudget;
        } catch (error) {
            global.config.slackMessage(error.toString());
            throw new Error('Error fetching total budget');
        }
    });
};

export const useTotalCostByProjectsAndClients = (projectIds, clientIds, token, projectStartDate, projectEndDate, startDate, endDate, forex, currency) => {

    return useQuery(['totalCostByProjectsAndClients', projectIds, clientIds, projectStartDate, projectEndDate, startDate, endDate, forex, currency], async () => {
        try {
            const requestData = { projectIds, clientIds, forex, currency };
            if (projectStartDate && projectEndDate) {
                requestData.projectStartDate = projectStartDate;
                requestData.projectEndDate = projectEndDate;
            }
            if (startDate && endDate) {
                requestData.startDate = startDate;
                requestData.endDate = endDate;
            }

            const response = await axios.post('/api/time-track/total-cost-by-projects-and-clients', requestData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return response.data.totalCost;
        } catch (error) {
            global.config.slackMessage(error.toString());
            throw new Error('Error fetching total cost');
        }
    });
};

// export const useTotalCostByProjectsAndClientsTest = (projectIds, clientIds, token, projectStartDate, projectEndDate, startDate, endDate, forex, currency) => {
//     return useQuery(
//         ['totalCostByProjectsAndClientstest', projectIds, clientIds, projectStartDate, projectEndDate, startDate, endDate, forex, currency],
//         async () => {
//             try {
//                 const requestData = { projectIds, clientIds, forex, currency };
//                 if (projectStartDate && projectEndDate) {
//                     requestData.projectStartDate = projectStartDate;
//                     requestData.projectEndDate = projectEndDate;
//                 }
//                 if (startDate && endDate) {
//                     requestData.startDate = startDate;
//                     requestData.endDate = endDate;
//                 }

//                 const response = await axios.post('/api/time-track/total-cost-by-projects-and-clients-test', requestData, {
//                     headers: {
//                         Authorization: `Bearer ${token}`,
//                     },
//                 });

//                 return response.data;
//             } catch (error) {
//                 global.config.slackMessage(error.toString());
//                 throw new Error('Error fetching total cost');
//             }
//         },
//         {
//             staleTime: 5 * 60 * 1000, // 5 minutes, adjust based on your needs
//             cacheTime: 30 * 60 * 1000, // 30 minutes, adjust based on your needs
//             refetchOnWindowFocus: false, // Disables refetch on window focus
//             enabled: !!projectIds.length && !!token, // Only fetch if these values are available
//         }
//     );
// };

export const useTotalCostByProjectsAndClientsTest = (token, forex, currency) => {
    return useQuery(
        ['totalCostByProjectsAndClientstest', token, forex, currency],
        async () => {
            try {
                const requestData = { forex, currency };

                const response = await axios.post('/api/time-track/total-cost-by-projects-and-clients-test', requestData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                return response.data;
            } catch (error) {
                global.config.slackMessage(error.toString());
                throw new Error('Error fetching total cost');
            }
        },
        {
            staleTime: 5 * 60 * 1000, // 5 minutes
            cacheTime: 30 * 60 * 1000, // 30 minutes
            refetchOnWindowFocus: false, // Disable refetch on window focus
            enabled: !!token, // Fetch only if token is available
        }
    );
};


export const useTotalCostByProjectsAndClientsBudget = (projectIds, clientIds, token, projectStartDate, projectEndDate, startDate, endDate, forex, currency) => {
    return useQuery(
        ['totalCostByProjectsAndClientsBudget', projectIds, clientIds, projectStartDate, projectEndDate, startDate, endDate, forex, currency],
        async () => {
            try {
                const requestData = { projectIds, clientIds, forex, currency };
                if (projectStartDate && projectEndDate) {
                    requestData.projectStartDate = projectStartDate;
                    requestData.projectEndDate = projectEndDate;
                }
                if (startDate && endDate) {
                    requestData.startDate = startDate;
                    requestData.endDate = endDate;
                }

                const response = await axios.post('/api/time-track/total-cost-by-projects-and-clients-total-budget', requestData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                return response.data;
            } catch (error) {
                global.config.slackMessage(error.toString());
                throw new Error('Error fetching total cost');
            }
        },
        {
            staleTime: 5 * 60 * 1000, // 5 minutes, adjust based on your needs
            cacheTime: 30 * 60 * 1000, // 30 minutes, adjust based on your needs
            refetchOnWindowFocus: false, // Disables refetch on window focus
            enabled: !!projectIds.length && !!token, // Only fetch if these values are available
        }
    );
};


export const useTrackedClientHours = (clientId, token) => {
    return useQuery(['trackedClientHours', clientId], async () => {
        try {
            const response = await axios.post('/api/time-track/total-duration-by-client', { clientId }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            global.config.slackMessage(error.toString());
            throw new Error('Error fetching total duration');
        }
    });
};

export const useTotalCostByClient = (clientId, token, startDate, endDate, forex, currency) => {
    return useQuery(['totalCostByClient', clientId, startDate, endDate, forex, currency], async () => {
        try {
            const response = await axios.post('/api/time-track/total-cost-by-client', { clientId, startDate, endDate, forex, currency }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data.totalCost;
        } catch (error) {
            global.config.slackMessage(error.toString());
            throw new Error('Error fetching total cost');
        }
    });
};

export const useTotalCostByClientReport = (clientId, token, startDate, endDate, forex, currency) => {
    return useQuery(['totalCostByClientReport', clientId, startDate, endDate, forex, currency], async () => {
        try {
            const response = await axios.post('/api/time-track/total-cost-by-client/report', { clientId, startDate, endDate, forex, currency }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data.totalCost;
        } catch (error) {
            global.config.slackMessage(error.toString());
            throw new Error('Error fetching total cost');
        }
    });
};

export const useTotalBudgetByClient = (clientId, token) => {
    return useQuery(['totalBudgetByClient', clientId], async () => {
        try {
            const response = await axios.post('/api/time-track/total-budget-by-client', { clientId }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data.totalClientBudget;
        } catch (error) {
            global.config.slackMessage(error.toString());
            throw new Error('Error fetching total cost');
        }
    });
};

export const useTotalInvoiceAmountByClient = (clientId, token) => {
    return useQuery(['totalInvoiceAmountByClient', clientId], async () => {
        try {
            const response = await axios.post('/api/time-track/total-invoice-amount-by-client', { clientId }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return response.data.totalInvoiceAmount;
        } catch (error) {
            global.config.slackMessage(error.toString());
            throw new Error('Error fetching total cost');
        }
    });
};

export const useTotalCostByProject = (projectId, token, forex, currency) => {
    return useQuery(['totalCostByProject', projectId], async () => {
        try {
            const response = await axios.post('/api/time-track/total-cost-by-project', { projectId, forex, currency }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data.totalCost;
        } catch (error) {
            global.config.slackMessage(error.toString());
            throw new Error('Error fetching total cost');
        }
    });
};

export const useTotalCostByProjectReport = (projectId, token, forex, currency) => {
    return useQuery(['totalCostByProjectReport', projectId], async () => {
        try {
            const response = await axios.post('/api/time-track/total-cost-by-project/report', { projectId, forex, currency }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data.totalCost;
        } catch (error) {
            global.config.slackMessage(error.toString());
            throw new Error('Error fetching total cost');
        }
    });
};

export const useTotalBudgetByProject = (projectId, token) => {
    return useQuery(['totalBudgetByProject', projectId], async () => {
        try {
            const response = await axios.post('/api/time-track/total-budget-by-project', { projectId }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data.totalProjectBudget;
        } catch (error) {
            global.config.slackMessage(error.toString());
            throw new Error('Error fetching total cost');
        }
    });
};

export const useTrackedProjectHours = (projectId, token) => {
    return useQuery(['trackedProjectHours', projectId], async () => {
        try {
            const response = await axios.post('/api/time-track/total-duration-by-project', { projectId }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data.totalDuration;
        } catch (error) {
            global.config.slackMessage(error.toString());
            throw new Error('Error fetching total duration');
        }
    });
};

export const useProjectDetails = (token, id) => {
    return useQuery('projectDetails', async () => {
        const response = await axios.get(`${API_BASE_URL}/project-details/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    });
};

export const useSprints = (token, projectId) => {
    return useQuery('sprintDetails', async () => {
        const response = await axios.get(`${API_BASE_URL}/sprintProject-details/${projectId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    });
};

export const useTotalCostBySprint = (sprintId, token, forex, currency, projectId) => {
    return useQuery(['totalCostBySprint', sprintId], async () => {
        try {
            const response = await axios.post('/api/time-track/total-cost-by-sprint', { sprintId, forex, currency, projectId }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data.totalCost;
        } catch (error) {
            global.config.slackMessage(error.toString());
            throw new Error('Error fetching total cost');
        }
    });
};
