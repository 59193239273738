import axios from "axios";
import "./MasterTenantDetailsEdit.css";
import { toast } from "react-toastify";
import Tele from "../../../images/icons/tele.svg";
import { ThreeDots } from "react-loader-spinner";
import Countries from "../../../constants/countries";
import { Link, useNavigate } from "react-router-dom";
import EmailIcon from "../../../images/icons/email.svg";
import Tick from "../../../images/icons/tick-arrow.svg";
import location from "../../../images/icons/location.svg";
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useRef, useState } from "react";
import PhoneInput from 'react-phone-number-input';
import PassportlIcon from "../../../images/icons/passport.svg";
import PostalCode from "../../../images/icons/postal-code.svg";
import PicEdit from "../../../images/icons/profile-pic-edit.svg";
import profile_image from "../../../images/user/Default_pfp.png";
import Favicon from "../../../images/logo/wx-logo.svg";
import { logout, reset } from "../../../features/auth/authSlice";
import UserIcon from "../../../images/icons/single-user-icon.svg";
import { reset as invoiceReset } from "../../../features/invoice/invoiceSlice.js";
import { setNotification } from "../../../features/notification/notificationSlice.js";
import { reset as headerReset } from "../../../features/headerText/headerTextSlice.js";
import { setAppSettings } from "../../../features/appSettings/appSettingsSlice.js";
import { reset as appSettingsReset } from "../../../features/appSettings/appSettingsSlice.js";
import { useMasterTenantView } from "./useMasterTenantView.jsx";

const MasterTenantDetailsEdit = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const fileInputRef = useRef(null);
    const favIconFileInputRef = useRef(null);
    const [role, setRole] = useState("");
    const [commercialName, setCommercialName] = useState("");
    const [legalEntityName, setLegalEntityName] = useState("");
    const [companyLogo, setCompanyLogo] = useState(null);
    const [favIcon, setFavIcon] = useState(null);
    const [companyId, setCompanyId] = useState("");
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [mobileNo, setMobileNo] = useState("");
    const [postCode, setPostCode] = useState("");
    const [address, setAddress] = useState("");
    const [uploadedImageUrl, setUploadedImageUrl] = useState("");
    const [uploadedFaviconUrl, setUploadedFaviconUrl] = useState("");
    const [countryDropdownOpen, setCountryDropdownOpen] = useState(false);
    const [countrySearchTerm, setCountrySearchTerm] = useState('');
    const [stateDropdownOpen, setStateDropdownOpen] = useState(false);
    const [stateSearchTerm, setStateSearchTerm] = useState('');
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [countriesList, setCountriesList] = useState([]);
    const user = useSelector((state) => state.masterAuth.masterUser)
    const { appSettings } = useSelector((state) => state.appSettings);
    const countryDropdownRef = useRef(null);
    const stateDropdownRef = useRef(null);
    let token = null;
    if (user) {
        if (user && user.token) {
            token = user.token;
        }
    }

    const { data: ownerDetailView = [], isLoading, isError: ownerDetailViewError } = useMasterTenantView(props.encodedSubDomain, token);

    useEffect(() => {
        if (ownerDetailViewError) {
            if (ownerDetailViewError.response?.data?.status === "deleted") {
                // Handle the case where the user is deleted
                localStorage.removeItem("user");
                dispatch(logout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/");
                return;
            }
            // Handle other errors
            alert(`Error fetching users`);
        }
        const sortedCountries = Countries.slice().sort((a, b) => a.country.localeCompare(b.country));
        setCountriesList(sortedCountries);

        const handleKeyDown = (event) => {
            if (event.key === "Escape") {
                document.activeElement.blur();
            }
        };
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [props.encodedSubDomain]);

    useEffect(() => {
        if (!isLoading && ownerDetailView) {
            setCommercialName(ownerDetailView.commercial_name);
            setLegalEntityName(ownerDetailView.legal_entity_name);
            setCompanyId(ownerDetailView.company_id);
            setRole(ownerDetailView.role);
            setCountry(ownerDetailView.country);
            setState(ownerDetailView.state);
            setCity(ownerDetailView.city);
            setMobileNo(ownerDetailView.mobile_no);
            setPostCode(ownerDetailView.pincode);
            setAddress(ownerDetailView.address);
        }
    }, [isLoading, ownerDetailView]);

    const handleCompanyLogoChange = () => {
        // Open file input dialog
        fileInputRef.current.click();
    };

    const handleFileInputChange = (event) => {
        setCompanyLogo(event.target.files[0]);
        const file = event.target.files[0];
        const fileReader = new FileReader();
        fileReader.onload = () => {
            setUploadedImageUrl(fileReader.result);
        };
        if (file) {
            fileReader.readAsDataURL(file);
        }
    };

    const handleFavIconChange = () => {
        // Open file input dialog
        favIconFileInputRef.current.click();
    };

    const handleFavIconInputChange = (event) => {
        setFavIcon(event.target.files[0]);
        const file = event.target.files[0];
        const fileReader = new FileReader();
        fileReader.onload = () => {
            setUploadedFaviconUrl(fileReader.result);
        };
        if (file) {
            fileReader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const mobileNoRegex = /^[+\d]+$/;
        if (!mobileNoRegex.test(mobileNo)) {
            toast.error("It's not a valid phone number", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        const fileExtension = favIcon
            ? favIcon.name.split(".").pop()
            : "";
        const uniqueSuffix = Date.now() + "-" + Math.round(Math.random() * 1e9);
        const favIcon_fileName = favIcon
            ? `${favIcon.name
                .split(".")
                .shift()}_${uniqueSuffix}.${fileExtension}`
            : null;

        const requestData = {
            commercial_name: commercialName,
            legal_entity_name: legalEntityName,
            mobile_no: mobileNo,
            company_id: companyId,
            role,
            address,
            pincode: postCode,
            country,
            state,
            city,
            encodedSubDomain: props.encodedSubDomain,
        };
        if (favIcon) {
            requestData.fav_icon = favIcon_fileName;
        }
        const formData = new FormData();
        if (companyLogo) {
            formData.append("company_logo", companyLogo);
        }

        for (let key in requestData) {
            formData.append(key, requestData[key]);
        }

        try {
            setIsSubmitLoading(true);
            const response = await axios.put(`/api/update/master/tenant/${ownerDetailView?._id}`, formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
            if (favIcon) {
                const uploadFormData = new FormData();
                uploadFormData.append("fav_icon", favIcon, favIcon_fileName);

                try {
                    await axios.post("/api/uploads/uploadFavIcon", uploadFormData);
                } catch (uploadError) {
                    console.log(uploadError);
                    global.config.slackMessage(uploadError.toString());
                    if (uploadError.response?.data?.status === "deleted") {
                        localStorage.removeItem("user");
                        dispatch(logout());
                        dispatch(reset());
                        dispatch(invoiceReset());
                        dispatch(headerReset());
                        dispatch(appSettingsReset());
                        toast.error('This user is deleted!', {
                            position: "bottom-right",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        navigate("/");
                        return;
                    }
                    toast.error('Something went wrong with the fav icon upload!', {
                        position: "bottom-right",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            }
            const data = response.data;
            if (data.status === "error") {
                alert("Something went wrong");
            } else {
                global.config.activityLog(
                    window.location.href,
                    "User",
                    `User ${commercialName} Updated successfully.`
                );
                toast.success("User details updated successfully!!!", {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                props.onCancel();
            }
            // Store low-quality version of profile image on the server
        } catch (error) {
            console.log(error);
            global.config.slackMessage(error.toString());
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("user");
                dispatch(logout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/");
                return;
            }
            alert("Something went wrong123");
            global.config.activityLog(
                window.location.href,
                "User",
                `User (${commercialName} ${legalEntityName}) updated successfully`
            );
        } finally {
            setIsSubmitLoading(false);
        }
    };

    const handleCountryChange = (event) => {
        setCountry(event.target.value);
        setState("");
        setCity("");
        setCountryDropdownOpen(false);
    };

    const handleCountrySearchInputChange = (e) => {
        setCountrySearchTerm(e.target.value);
    };

    const filteredCountries = countriesList.filter((c) =>
        c.country.toLowerCase().includes(countrySearchTerm.toLowerCase())
    );

    const handleToggleCountryDropdown = () => {
        setCountrySearchTerm('');
        setCountryDropdownOpen(!countryDropdownOpen);
    };

    const handleStateSearchInputChange = (e) => {
        setStateSearchTerm(e.target.value);
    };

    const selectedCountry = countriesList.find((c) => c.country === country);
    const filteredStates = selectedCountry?.states?.filter((s) =>
        s.state.toLowerCase().includes(stateSearchTerm.toLowerCase())
    );

    const handleToggleStateDropdown = () => {
        setStateSearchTerm('');
        setStateDropdownOpen(!stateDropdownOpen);
    };

    const handleClickOutsideDropdown = (event) => {
        const isClickInsideCountryDropdown = countryDropdownRef.current && countryDropdownRef.current.contains(event.target);
        const isClickInsideStateDropdown = stateDropdownRef.current && stateDropdownRef.current.contains(event.target);
        if (!isClickInsideCountryDropdown) {
            setCountryDropdownOpen(false);
        }
        if (!isClickInsideStateDropdown) {
            setStateDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutsideDropdown);
        return () => {
            document.removeEventListener('click', handleClickOutsideDropdown);
        };
    }, []);

    const handleStateChange = (event) => {
        setState(event.target.value);
        setCity("");
    };

    const handleCityChange = (event) => {
        setCity(event.target.value);
    };

    const handleCancel = () => {
        props.onCancel();
    };
    return (
        <div className="profile-details-edit">
            <form onSubmit={handleSubmit}>
                <div className="profile-photo-outer edit">
                    <div className="profile-photo">
                        <div className="profile-photo-inner">
                            <div className="profile-photo-sec">
                                {isLoading ? (
                                    <div>
                                        <ThreeDots
                                            height="18"
                                            width="20"
                                            radius="9"
                                            color="#6479f9"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    </div>
                                ) : (
                                    uploadedImageUrl ? (
                                        <div className="profile-image">
                                            <img src={uploadedImageUrl} alt="Uploaded Profile " />
                                        </div>
                                    ) : (
                                        <div className="profile-image">
                                            {ownerDetailView.company_logo !== "null" && ownerDetailView.company_logo !== null ? (
                                                <img
                                                    src={`/uploads/orgiOwnerCompyLog/${ownerDetailView.company_logo}`}
                                                    alt="User Profile "
                                                    onError={(e) => {
                                                        e.target.onerror = null;
                                                        e.target.src = '/uploads/orgiOwnerCompyLog/user_default.png';
                                                    }}
                                                />
                                            ) : (
                                                <img src={profile_image} alt="User Profile " />
                                            )}
                                        </div>
                                    )
                                )}
                                {isLoading ? (
                                    <ThreeDots
                                        height="18"
                                        width="20"
                                        radius="9"
                                        color="#6479f9"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                    />
                                ) : (
                                    <div className="profile-pic-edit">
                                        <Link className="pic-edit" onClick={handleCompanyLogoChange}>
                                            <img src={PicEdit} alt="edit" />
                                        </Link>
                                    </div>
                                )}
                            </div>
                            <div className="profile-name">
                                {isLoading ? (
                                    <div>
                                        <ThreeDots
                                            height="18"
                                            width="20"
                                            radius="9"
                                            color="#6479f9"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    </div>
                                ) : (
                                    <div className="profile-name-sec">
                                        {ownerDetailView.commercial_name ? (
                                            <>
                                                {commercialName ? commercialName : ownerDetailView.commercial_name}{" "}
                                            </>
                                        ) : (
                                            <>
                                                {legalEntityName ? legalEntityName : ownerDetailView.legal_entity_name}
                                            </>
                                        )}
                                    </div>
                                )}
                                {isLoading ? (
                                    <div>
                                        <ThreeDots
                                            height="18"
                                            width="20"
                                            radius="9"
                                            color="#6479f9"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    </div>
                                ) : (
                                    <>
                                        {role ? (
                                            <div className="profile-type">
                                                {role}
                                            </div>
                                        ) : (
                                            <>
                                                {ownerDetailView.role_id && (
                                                    <div className="profile-type">
                                                        {ownerDetailView.role_id.user_type}
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                                <div className="profile-status">
                                    <div className="profile-status">
                                        {isLoading ? (
                                            <div>
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            </div>
                                        ) : (
                                            <>
                                                {ownerDetailView.ownerMasterDetail.status ? (
                                                    <div className="active" style={{ cursor: "default" }}>
                                                        {global.config.locate(appSettings && appSettings.language, 'Active')}
                                                    </div>
                                                ) : (
                                                    <div className="inactive" style={{ cursor: "default" }}>
                                                        {global.config.locate(appSettings && appSettings.language, 'Inactive')}
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="profile-photo profile-faviocn">
                        <div className="profile-photo-inner profile-photo-favicon">
                            <div className="favicon-text">Favicon</div>
                            <div className="profile-photo-sec">
                                {isLoading ? (
                                    <div>
                                        <ThreeDots
                                            height="18"
                                            width="20"
                                            radius="9"
                                            color="#6479f9"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    </div>
                                ) : (
                                    uploadedFaviconUrl ? (
                                        <div className="profile-image">
                                            <img src={uploadedFaviconUrl} alt="Uploaded Profile " />
                                        </div>
                                    ) : (
                                        <div className="profile-image">
                                            {ownerDetailView.favicon !== "null" && ownerDetailView.fav_icon !== null ? (
                                                <img
                                                    src={`/uploads/orgiFavIcon/${ownerDetailView.fav_icon}`}
                                                    alt="User Profile "
                                                    onError={(e) => {
                                                        e.target.onerror = null;
                                                        e.target.src = '/uploads/orgiFavIcon/fav_icon_default.png';
                                                    }}
                                                />
                                            ) : (
                                                <img src={Favicon} alt="User Profile " />
                                            )}
                                        </div>
                                    )
                                )}
                                {isLoading ? (
                                    <ThreeDots
                                        height="18"
                                        width="20"
                                        radius="9"
                                        color="#6479f9"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                    />
                                ) : (
                                    <div className="profile-pic-edit">
                                        <Link className="pic-edit" onClick={handleFavIconChange}>
                                            <img src={PicEdit} alt="edit" />
                                        </Link>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    ref={fileInputRef}
                    onChange={handleFileInputChange}
                />
                <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    ref={favIconFileInputRef}
                    onChange={handleFavIconInputChange}
                />

                <div className="information-outer-section">
                    <div className="information-inner">
                        <div className="information">
                            <div className="information-title">
                                User Information
                            </div>
                            <div className="information-outer">
                                <div className="information-left">
                                    <div className="inner-detail-outer">
                                        <div className="inner-detail">
                                            <span class="icon"><img src={UserIcon} alt="user icon" /></span>
                                            <div className="inner-detail-inner">
                                                <label for="fname">{global.config.locate(appSettings && appSettings.language, 'Commercial_Name')}</label>
                                            </div>
                                        </div>
                                        {isLoading ? (
                                            <ThreeDots
                                                height="18"
                                                width="20"
                                                radius="9"
                                                color="#6479f9"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                        ) : (
                                            <div className="inner-detail">
                                                <span class="icon"></span>
                                                <div className="inner-detail-inner">
                                                    <input type="text" id="fname" value={commercialName} onChange={(e) => setCommercialName(e.target.value)} required />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="inner-detail-outer">
                                        <div className="inner-detail">
                                            <span class="icon"><img src={UserIcon} alt="user icon" /></span>
                                            <div className="inner-detail-inner">
                                                <label for="mname">{global.config.locate(appSettings && appSettings.language, 'Legal_Entity_Name')}</label>
                                            </div>
                                        </div>
                                        {isLoading ? (
                                            <ThreeDots
                                                height="18"
                                                width="20"
                                                radius="9"
                                                color="#6479f9"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                        ) : (
                                            <div className="inner-detail">
                                                <span class="icon"></span>
                                                <div className="inner-detail-inner">
                                                    <input type="text" id="mname" value={legalEntityName} onChange={(e) => setLegalEntityName(e.target.value)} />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="information-right">
                                    <div className="inner-detail-outer">
                                        <div className="inner-detail">
                                            <span class="icon"><img src={EmailIcon} alt="email icon" /></span>
                                            <div className="inner-detail-inner">
                                                <label for="email">{global.config.locate(appSettings && appSettings.language, 'Email')}</label>
                                            </div>
                                        </div>
                                        {isLoading ? (
                                            <ThreeDots
                                                height="18"
                                                width="20"
                                                radius="9"
                                                color="#6479f9"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                        ) : (
                                            <div className="inner-detail">
                                                <span class="icon"></span>
                                                <div className="inner-detail-inner">
                                                    <input type="email" id="email" value={ownerDetailView.email} required readOnly />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="inner-detail-outer">
                                        <div className="inner-detail">
                                            <span class="icon"><img src={PassportlIcon} alt="passport icon" /></span>
                                            <div className="inner-detail-inner">
                                                <label for="id_number">{global.config.locate(appSettings && appSettings.language, 'Company_ID_number')}</label>
                                            </div>
                                        </div>
                                        {isLoading ? (
                                            <ThreeDots
                                                height="18"
                                                width="20"
                                                radius="9"
                                                color="#6479f9"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                        ) : (
                                            <div className="inner-detail">
                                                <span class="icon"></span>
                                                <div className="inner-detail-inner">
                                                    <input type="text" id="id_number" value={companyId} onChange={(e) => setCompanyId(e.target.value)} required />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="information-inner">
                        <div className="information">
                            <div className="information-title">
                                {global.config.locate(appSettings && appSettings.language, 'Address')}
                            </div>
                            <div className="information-outer">
                                <div className="information-left">
                                    <div className="inner-detail-outer">
                                        <div className="inner-detail">
                                            <span className="icon">
                                                <img src={location} alt="Location Icon" />
                                            </span>
                                            <div className="inner-detail-inner">
                                                <label>{global.config.locate(appSettings && appSettings.language, 'Country')}</label>
                                            </div>
                                        </div>
                                        <div className="inner-detail">
                                            <span className="icon"></span>
                                            {isLoading ? (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            ) : (
                                                <div className="inner-detail-inner">

                                                    <div
                                                        className={`custom-dropdown-user-new-outer custom-dropdown-select ${countryDropdownOpen ? 'open' : ''
                                                            }`}
                                                        ref={countryDropdownRef}
                                                    >
                                                        <div
                                                            className="selected-option custom-dropdown-user-new"
                                                            onClick={handleToggleCountryDropdown}
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            {country ? countriesList.find((c) => c.country === country)?.country : 'Select Country'}
                                                        </div>
                                                        {countryDropdownOpen && (
                                                            <div className="custom-dropdown-new">
                                                                <input
                                                                    type="text"
                                                                    className="search-bar"
                                                                    placeholder={global.config.locate(appSettings && appSettings.language, 'Search')}
                                                                    value={countrySearchTerm}
                                                                    onChange={handleCountrySearchInputChange}
                                                                />
                                                                <ul className="country-list">
                                                                    {filteredCountries.length > 0 &&
                                                                        filteredCountries.map((c) => (
                                                                            <li
                                                                                key={c.country}
                                                                                className={country === c.country ? 'selected-item' : ''}
                                                                                onClick={() => {
                                                                                    setCountry(c.country);
                                                                                    setState("");
                                                                                    setCountryDropdownOpen(false);
                                                                                }}
                                                                            >
                                                                                {c.country}
                                                                            </li>
                                                                        ))}
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {filteredStates?.length > 0 && country && (
                                        <div className="inner-detail-outer">
                                            <div className="inner-detail">
                                                <span className="icon">
                                                    <img src={location} alt="Location Icon" />
                                                </span>
                                                <div className="inner-detail-inner">
                                                    <label>{global.config.locate(appSettings && appSettings.language, 'State')}</label>
                                                </div>
                                            </div>
                                            <div className="inner-detail">
                                                <span className="icon"></span>
                                                {isLoading ? (
                                                    <ThreeDots
                                                        height="18"
                                                        width="20"
                                                        radius="9"
                                                        color="#6479f9"
                                                        ariaLabel="three-dots-loading"
                                                        wrapperStyle={{}}
                                                        wrapperClassName=""
                                                        visible={true}
                                                    />
                                                ) : (
                                                    <div className="inner-detail-inner">

                                                        <div
                                                            className={`custom-dropdown-user-new-outer custom-dropdown-select ${stateDropdownOpen ? 'open' : ''
                                                                }`}
                                                            ref={stateDropdownRef}
                                                        >
                                                            <div
                                                                className="selected-option custom-dropdown-user-new"
                                                                onClick={handleToggleStateDropdown}
                                                                style={{ cursor: 'pointer' }}
                                                            >
                                                                {state ? selectedCountry?.states?.find((s) => s.state === state)?.state : 'Select State'}
                                                            </div>
                                                            {stateDropdownOpen && (
                                                                <div className="custom-dropdown-new">
                                                                    <input
                                                                        type="text"
                                                                        className="search-bar"
                                                                        placeholder={global.config.locate(appSettings && appSettings.language, 'Search')}
                                                                        value={stateSearchTerm}
                                                                        onChange={handleStateSearchInputChange}
                                                                    />
                                                                    <ul className="state-list">
                                                                        {filteredStates.length > 0 &&
                                                                            filteredStates.map((s) => (
                                                                                <li
                                                                                    key={s.state}
                                                                                    className={state === s.state ? 'selected-item' : ''}
                                                                                    onClick={() => {
                                                                                        setState(s.state);
                                                                                        setStateDropdownOpen(false);
                                                                                    }}
                                                                                >
                                                                                    {s.state}
                                                                                </li>
                                                                            ))}
                                                                    </ul>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    <div className="inner-detail-outer">
                                        <div className="inner-detail">
                                            <span className="icon">
                                                <img src={location} alt="Location Icon" />
                                            </span>
                                            <div className="inner-detail-inner">
                                                <label>{global.config.locate(appSettings && appSettings.language, 'City')}</label>
                                            </div>
                                        </div>
                                        <div className="inner-detail">
                                            <span className="icon"></span>
                                            {isLoading ? (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            ) : (
                                                <div className="inner-detail-inner">

                                                    <input type="text" id="address" value={city} onChange={(e) => setCity(e.target.value)} required />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="inner-detail-outer">
                                        <div className="inner-detail">
                                            <span class="icon"><img src={location} alt="type" /></span>
                                            <div className="inner-detail-inner">
                                                <label for="address">{global.config.locate(appSettings && appSettings.language, 'Address')}</label>
                                            </div>
                                        </div>
                                        <div className="inner-detail">
                                            <span class="icon"></span>
                                            {isLoading ? (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            ) : (
                                                <div className="inner-detail-inner">
                                                    <input type="text" id="address" value={address} onChange={(e) => setAddress(e.target.value)} rows="4" required />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="information-right">
                                    <div className="inner-detail-outer">
                                        <div className="inner-detail">
                                            <span class="icon"><img src={PostalCode} alt="type" /></span>
                                            <div className="inner-detail-inner">
                                                <label for="postal_code">{global.config.locate(appSettings && appSettings.language, 'Postal_Code')}</label>
                                            </div>
                                        </div>
                                        <div className="inner-detail">
                                            <span class="icon"></span>
                                            {isLoading ? (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            ) : (
                                                <div className="inner-detail-inner">
                                                    <input type="text" id="postal_code" value={postCode} onChange={(e) => setPostCode(e.target.value)} required />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="inner-detail-outer">
                                        <div className="inner-detail">
                                            <span class="icon"><img src={Tele} alt="type" /></span>
                                            <div className="inner-detail-inner">
                                                <label for="number">{global.config.locate(appSettings && appSettings.language, 'Mobile_No')}</label>
                                            </div>
                                        </div>
                                        <div className="inner-detail">
                                            <span class="icon"></span>
                                            {isLoading ? (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            ) : (
                                                <div className="inner-detail-inner">
                                                    <PhoneInput
                                                        placeholder="Enter phone number"
                                                        value={mobileNo}
                                                        onChange={setMobileNo}
                                                        required
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="save-button-sec">
                    <a href="#" className="cancel-btn" onClick={handleCancel} disabled={isSubmitLoading} >{global.config.locate(appSettings && appSettings.language, 'Cancel')}</a>
                    <button type="submit" className="save-btn" disabled={isSubmitLoading}>
                        {isSubmitLoading ?
                            <div className="outter-load-table">
                                <ThreeDots
                                    height="38"
                                    width="40"
                                    radius="9"
                                    color="white"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                />
                            </div> :
                            <>
                                <img src={Tick} alt="arrow" /> {global.config.locate(appSettings && appSettings.language, 'Save_changes')}
                            </>
                        }
                    </button>
                </div>
            </form>
        </div>
    );
};

export default MasterTenantDetailsEdit;