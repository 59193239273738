import React from 'react';
import { useSelector } from "react-redux";
import { AES } from 'crypto-js';
import { Link } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';
import "./VendorOverviewListingRecenInvoices.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useFetchVendorInvoices } from '../VendorInvoice/useVendorInvoice';
import { dateVenInvConvert } from '../../../helpers/dateHelper';
import invStatusConsts from "../../../constants/invStatusConsts";

export function detailCTA(rowData) {
  const encryptedId = AES.encrypt(rowData._id, 'encryptionKey').toString();
  const encryptedCliId = AES.encrypt(rowData.ven_inv_for._id, 'encryptionKey').toString();
  const invId = encodeURIComponent(encryptedId).replace(/\//g, '%2F');
  const encodedId = encodeURIComponent(encryptedCliId).replace(/\//g, '%2F');

  return (
    <Link to={`../invoices/view/${invId}/${encodedId}`} className="cta-link">
      Details
    </Link>
  );
}

const formatCurrency = (value) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
}

const RecentInvoices = (props) => {

  let token = null;
  const { user } = useSelector((state) => state.auth);
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }

  const { data: vendorInvoiceList = [], isLoading, refetch: refetchVendorInvoiceList, isError } = useFetchVendorInvoices(props.id, token);

  const renderStatus = (rowData) => {
    // Determine the class based on the payment_status value
    let statusClass;
    let statusName;
    if (rowData.ven_inv_status === invStatusConsts.Approved) {
      statusClass = "manual";
      statusName = "Approved";
    } else if (rowData.ven_inv_status === invStatusConsts.Not_Reviewed) {
      statusClass = "pending";
      statusName = "Not Reviewed";
    } else if (rowData.ven_inv_status === invStatusConsts.Rejected) {
      statusClass = "overdue";
      statusName = "Rejected";
    } else if (rowData.ven_inv_payment_status === invStatusConsts.Cancelled) {
      statusClass = "overdue";
      statusName = "Cancelled";
    }
    return (
      <span className={`payment-status ${statusClass}`}>{statusName}</span>
    );
  };

  const renderAmount = (rowData) => {
    var totalAmount = rowData.ven_inv_total_amount.toLocaleString("en-US", {
      style: "currency",
      currency: rowData.ven_inv_currency,
    });

    return <span> {totalAmount}</span>;
  };

  return (
    <div className="listing-recent-invoice-inner">
      <div className="table-head-outer">
        <div className="table-head">
          Recent Invoices
        </div>
        <div className="view-button">
          <Link to={`/vendor/invoices/${encodeURIComponent(AES.encrypt(props.id, 'encryptionKey').toString().replace(/\//g, '%2F'))}`} className={window.location.pathname.startsWith('/vendor/invoices') ? 'active' : ''}>
            View All
          </Link>
        </div>
      </div>
      {isLoading ? (
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#405BFF"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      ) : (
        <DataTable
          value={vendorInvoiceList.slice(0, 5)}
          removableSort
          tableStyle={{ minWidth: "50rem", width: "100%", textAlign: "left" }}
          paginator={false}
          paginatorClassName="recent-invoices-pagination"
          rows={5}
          emptyMessage="No invoices found under this vendor"
        >
          <Column
            field="ven_inv_number"
            header="Invoice ID"
            sortable
            style={{ width: "25.8%" }}
          ></Column>
          <Column
            field="ven_inv_due_date"
            body={dateVenInvConvert}
            header="Due Date"
            sortable
            style={{ width: "26.4%" }}
          ></Column>
          <Column
            field="ven_inv_status"
            header="Status"
            body={renderStatus}
            sortable
            style={{ width: "27.8%" }}
          ></Column>
          <Column
            field="ven_inv_total_amount"
            header="Amount"
            body={renderAmount}
            sortable
            style={{ width: "14.3%%" }}
            className="bold-td"
          ></Column>
          <Column
            field="_id"
            body={detailCTA}
            style={{ width: "" }}
          ></Column>
        </DataTable>
      )}
    </div>
  );

};
export default RecentInvoices;