import React, { useState } from 'react';

const EditPlan = () => {
  // State variables
  const [name, setName] = useState('Sample Plan');
  const [monthlyPricing, setMonthlyPricing] = useState(10);
  const [yearlyPricing, setYearlyPricing] = useState(100);
  const [features, setFeatures] = useState(['Feature 1', 'Feature 2']);

  // Handlers
  const handleNameChange = (e) => setName(e.target.value);
  const handleMonthlyPricingChange = (e) => setMonthlyPricing(e.target.value);
  const handleYearlyPricingChange = (e) => setYearlyPricing(e.target.value);

  const handleFeatureChange = (e, index) => {
    const updatedFeatures = [...features];
    updatedFeatures[index] = e.target.value;
    setFeatures(updatedFeatures);
  };

  const handleAddFeature = (e) => {
    e.preventDefault();
    setFeatures([...features, '']);
  };

  const handleDeleteFeature = (index) => {
    const updatedFeatures = features.filter((_, i) => i !== index);
    setFeatures(updatedFeatures);
  };

  const handleSave = (e) => {
    e.preventDefault();
    console.log('Plan Saved:', {
      name,
      monthlyPricing,
      yearlyPricing,
      features,
    });
    alert('Plan saved successfully!');
  };

  return (
    <div className='plan-edit-outer'>
      <div className='plan-edit-top'>
          <div className='top-left-edit'>
            Freelancers Subscription
          </div>
          <div className='top-right-edit'>
            <button>
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                  <path d="M1 11.5L11.5 1" stroke="#282828"/>
                  <path d="M1 1L11.5 11.5" stroke="#282828"/>
                </svg>
            </span>
            Cancel</button>
          </div>
      </div>
      <div className='plan-edit-inner'>
      <form>
        <div className='plan-edit-inner-top'>
        <div className='plan-edit-inner-top-left'>
        <label>
          Name:
          <input type="text" value={name} onChange={handleNameChange} />
        </label>
        </div>
        <div className='plan-edit-inner-top-right'>
        <label>
          Monthly Pricing:
          <input
            type="number"
            value={monthlyPricing}
            onChange={handleMonthlyPricingChange}
          />
        </label>
        
        <label>
          Yearly Pricing:
          <input
            type="number"
            value={yearlyPricing}
            onChange={handleYearlyPricingChange}
          />
        </label>
        </div>
        </div>
        <div className='feature-edit-subscription'>
        <div className='feature-edit-title'>Plan features</div>
        <ul>
          {features.map((feature, index) => (
            <li key={index}>
              <input
                type="text"
                value={feature}
                onChange={(e) => handleFeatureChange(e, index)}
              />
              <button
                type="button"
                onClick={() => handleDeleteFeature(index)}
                style={{ marginLeft: '10px', color: 'red' }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" fill="none">
                  <path d="M16.9577 19.6445L17.4363 4.37763H19.5772V3.85209H14.0109V2.45942C14.0109 1.69739 13.4316 1.09302 12.7011 1.09302H7.966C7.23557 1.09302 6.65628 1.69739 6.65628 2.45942V3.85209H1.08997V4.37763H3.23085L3.70941 19.6445C3.73459 20.354 4.2887 20.9058 4.96875 20.9058H15.6732C16.3784 20.9058 16.9325 20.354 16.9577 19.6445ZM7.16001 2.45942C7.16001 1.98643 7.51263 1.61856 7.966 1.61856H12.7011C13.1545 1.61856 13.5071 1.98643 13.5071 2.45942V3.85209H7.16001V2.45942ZM4.21314 19.6182L3.73459 4.37763H16.9325L16.454 19.6182C16.4288 20.0387 16.1014 20.3803 15.6984 20.3803H4.96875C4.56576 20.3803 4.23833 20.0387 4.21314 19.6182Z" fill="#405BFF" stroke="#282828" stroke-width="0.5"/>
                  <path d="M15.6736 21H4.96874C4.23877 21 3.64643 20.4064 3.61944 19.6486L3.14359 4.4723H1V3.75907H6.56631V2.46024C6.56631 1.64144 7.18114 1 7.96598 1H12.7011C13.486 1 14.1008 1.64144 14.1008 2.46024V3.75907H19.6671V4.4723H17.5231L17.0504 19.5515H17.0508L17.0472 19.6486C17.0202 20.4064 16.4166 21 15.6732 21H15.6736ZM1.17991 4.28414H3.3181L3.79935 19.6416C3.82273 20.2976 4.33637 20.8118 4.96874 20.8118H15.6736C16.3267 20.8118 16.8376 20.3149 16.8673 19.6538V19.6416L17.349 4.28414H19.4872V3.9463H13.9209V2.45978C13.9209 1.74608 13.3852 1.18722 12.7011 1.18722H7.96598C7.28189 1.18722 6.74622 1.74608 6.74622 2.45978V3.9463H1.17991V4.28414ZM4.96874 20.4745C4.52347 20.4745 4.15196 20.101 4.12363 19.6242V19.6214L3.64193 4.28367H17.0256L16.5439 19.6214C16.5151 20.101 16.1441 20.474 15.6984 20.474H4.96874V20.4745ZM4.30308 19.6139C4.32647 19.9912 4.61837 20.2868 4.96874 20.2868H15.6984C16.0492 20.2868 16.3415 19.9907 16.364 19.6125L16.8394 4.47137H3.82723L4.30263 19.6134L4.30308 19.6139ZM13.5971 3.9463H7.07005V2.45978C7.07005 1.93565 7.46359 1.52507 7.96598 1.52507H12.7011C13.2035 1.52507 13.5971 1.93565 13.5971 2.45978V3.9463ZM7.24995 3.7586H13.4172V2.45978C13.4172 2.04075 13.1028 1.71276 12.7011 1.71276H7.96598C7.56434 1.71276 7.24995 2.04075 7.24995 2.45978V3.7586Z" fill="#405BFF" stroke="#282828" stroke-width="0.5"/>
                  <path d="M10.0811 8.3717H10.5848V16.2285H10.0811V8.3717Z" fill="#405BFF" stroke="#282828" stroke-width="0.5"/>
                  <path d="M10.6747 16.3223H9.99109V8.27783H10.6747V16.3223ZM10.171 16.1346H10.4948V8.46552H10.171V16.1346Z" fill="#405BFF" stroke="#282828" stroke-width="0.5"/>
                  <path d="M7.05884 8.3717H7.56258V16.2285H7.05884V8.3717Z" fill="#405BFF" stroke="#282828" stroke-width="0.5"/>
                  <path d="M7.65252 16.3223H6.96887V8.27783H7.65252V16.3223ZM7.14878 16.1346H7.47261V8.46552H7.14878V16.1346Z" fill="#405BFF" stroke="#282828" stroke-width="0.5"/>
                  <path d="M13.1046 8.3717H13.6084V16.2285H13.1046V8.3717Z" fill="#405BFF" stroke="#282828" stroke-width="0.5"/>
                  <path d="M13.6983 16.3223H13.0146V8.27783H13.6983V16.3223ZM13.1946 16.1346H13.5184V8.46552H13.1946V16.1346Z" fill="#405BFF" stroke="#282828" stroke-width="0.5"/>
                </svg>
              </button>
            </li>
          ))}
        </ul>
        <button className="add-feature" onClick={handleAddFeature}>
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
              <path d="M6.75 0V13.5" stroke="#282828"/>
              <path d="M13.5 6.75L0 6.75" stroke="#282828"/>
            </svg>
          </span>
          Add Item
        </button>
        </div>
      </form>
      </div>
      <div className="plan-edit-inner-bottom">
        <button onClick={handleSave} className="edit-delete">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" fill="none">
                <path d="M16.9577 19.6445L17.4363 4.37763H19.5772V3.85209H14.0109V2.45942C14.0109 1.69739 13.4316 1.09302 12.7011 1.09302H7.966C7.23557 1.09302 6.65628 1.69739 6.65628 2.45942V3.85209H1.08997V4.37763H3.23085L3.70941 19.6445C3.73459 20.354 4.2887 20.9058 4.96875 20.9058H15.6732C16.3784 20.9058 16.9325 20.354 16.9577 19.6445ZM7.16001 2.45942C7.16001 1.98643 7.51263 1.61856 7.966 1.61856H12.7011C13.1545 1.61856 13.5071 1.98643 13.5071 2.45942V3.85209H7.16001V2.45942ZM4.21314 19.6182L3.73459 4.37763H16.9325L16.454 19.6182C16.4288 20.0387 16.1014 20.3803 15.6984 20.3803H4.96875C4.56576 20.3803 4.23833 20.0387 4.21314 19.6182Z" fill="#282828" stroke="#282828" stroke-width="0.5"/>
                <path d="M15.6736 21H4.96874C4.23877 21 3.64643 20.4064 3.61944 19.6486L3.14359 4.4723H1V3.75907H6.56631V2.46024C6.56631 1.64144 7.18114 1 7.96598 1H12.7011C13.486 1 14.1008 1.64144 14.1008 2.46024V3.75907H19.6671V4.4723H17.5231L17.0504 19.5515H17.0508L17.0472 19.6486C17.0202 20.4064 16.4166 21 15.6732 21H15.6736ZM1.17991 4.28414H3.3181L3.79935 19.6416C3.82273 20.2976 4.33637 20.8118 4.96874 20.8118H15.6736C16.3267 20.8118 16.8376 20.3149 16.8673 19.6538V19.6416L17.349 4.28414H19.4872V3.9463H13.9209V2.45978C13.9209 1.74608 13.3852 1.18722 12.7011 1.18722H7.96598C7.28189 1.18722 6.74622 1.74608 6.74622 2.45978V3.9463H1.17991V4.28414ZM4.96874 20.4745C4.52347 20.4745 4.15196 20.101 4.12363 19.6242V19.6214L3.64193 4.28367H17.0256L16.5439 19.6214C16.5151 20.101 16.1441 20.474 15.6984 20.474H4.96874V20.4745ZM4.30308 19.6139C4.32647 19.9912 4.61837 20.2868 4.96874 20.2868H15.6984C16.0492 20.2868 16.3415 19.9907 16.364 19.6125L16.8394 4.47137H3.82723L4.30263 19.6134L4.30308 19.6139ZM13.5971 3.9463H7.07005V2.45978C7.07005 1.93565 7.46359 1.52507 7.96598 1.52507H12.7011C13.2035 1.52507 13.5971 1.93565 13.5971 2.45978V3.9463ZM7.24995 3.7586H13.4172V2.45978C13.4172 2.04075 13.1028 1.71276 12.7011 1.71276H7.96598C7.56434 1.71276 7.24995 2.04075 7.24995 2.45978V3.7586Z" fill="#282828" stroke="#282828" stroke-width="0.5"/>
                <path d="M10.0811 8.37158H10.5848V16.2284H10.0811V8.37158Z" fill="#282828" stroke="#282828" stroke-width="0.5"/>
                <path d="M10.6747 16.3223H9.99109V8.27783H10.6747V16.3223ZM10.171 16.1346H10.4948V8.46552H10.171V16.1346Z" fill="#282828" stroke="#282828" stroke-width="0.5"/>
                <path d="M7.05884 8.37158H7.56258V16.2284H7.05884V8.37158Z" fill="#282828" stroke="#282828" stroke-width="0.5"/>
                <path d="M7.65252 16.3223H6.96887V8.27783H7.65252V16.3223ZM7.14878 16.1346H7.47261V8.46552H7.14878V16.1346Z" fill="#282828" stroke="#282828" stroke-width="0.5"/>
                <path d="M13.1046 8.37158H13.6084V16.2284H13.1046V8.37158Z" fill="#282828" stroke="#282828" stroke-width="0.5"/>
                <path d="M13.6983 16.3223H13.0146V8.27783H13.6983V16.3223ZM13.1946 16.1346H13.5184V8.46552H13.1946V16.1346Z" fill="#282828" stroke="#282828" stroke-width="0.5"/>
              </svg>
            </span>
            Delete Plan
        </button>
        <button onClick={handleSave} className="edit-save">Save Changes</button>
      </div>
    </div>
  );
};

export default EditPlan;
