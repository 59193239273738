import axios from "axios";
import "./ClientPayment.css";
import React, { useState, useRef, useEffect } from "react";
import Modal from "react-modal";
import validator from 'validator';
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ThreeDots } from "react-loader-spinner";
import { Parser } from 'html-to-react';
import PhoneInput from 'react-phone-number-input';
import Currencies from "../../../constants/currenciesList.js";
import editImage from "../../../images/singleinvoice/edit.svg";
import Tick from "../../../images/singleinvoice/tick-green.svg";
import Close from "../../../images/icons/close.svg";
import Delete from "../../../images/icons/delete.svg";
import crssrImage from "../../../images/icons/close.svg";
import PlusIcon from "../../../images/icons/plus-icon.svg";
import userTypeConsts from "../../../constants/userTypeConsts";
import { useFetchClientPayment, useFetchCompanyProfiles, useFetchWiseProfiles } from "./elements/useProfiles";
import { logout, reset } from "../../../features/auth/authSlice";
import { reset as invoiceReset } from "../../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../features/appSettings/appSettingsSlice.js";
import { setNotification } from "../../../features/notification/notificationSlice.js";
import BankAccount from "../BankAccount/BankAccount.jsx";


const ClientPayment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState("");
  const [newClientPaymentModal, setNewClientPaymentModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankNameEdit, setBankNameEdit] = useState("");
  const [bankNameView, setBankNameView] = useState("");
  const [bankNameIsValid, setBankNameIsValid] = useState(false);
  const [bankNameEditIsValid, setBankNameEditIsValid] = useState(false);
  const bankNameRef = useRef(null);
  const bankNameEditRef = useRef(null);
  const [wxCompany, setWxCompany] = useState("");
  const [wxCompanyEdit, setWxCompanyEdit] = useState("");
  const [wxCompanyView, setWxCompanyView] = useState("");
  const [wxCompanyIsValid, setWxCompanyIsValid] = useState(false);
  const [wxCompanyEditIsValid, setWxCompanyEditIsValid] = useState(false);
  const wxCompanyRef = useRef(null);
  const wxCompanyEditRef = useRef(null);
  // const [accountNumber, setAccountNumber] = useState("");
  // const [accountNumberEdit, setAccountNumberEdit] = useState("");
  // const [accountNumberView, setAccountNumberView] = useState("");
  // const [accountNumberIsValid, setAccountNumberIsValid] = useState(false);
  // const [accountNumberEditIsValid, setAccountNumberEditIsValid] = useState(false);
  // const accountNumberRef = useRef(null);
  // const accountNumberEditRef = useRef(null);
  const [currenciesList, setCurrenciesList] = useState([]);
  const [currencyCodeSearchTerm, setCurrencyCodeSearchTerm] = useState('');
  const [currencyCodeDropdownOpen, setCurrencyCodeDropdownOpen] = useState(false);
  const [currencyCodeEditDropdownOpen, setCurrencyCodeEditDropdownOpen] = useState(false);
  const currencyDropdownRef = useRef(null);
  const currencyEditDropdownRef = useRef(null);
  const [currencyCode, setCurrencyCode] = useState("");
  const [currencyCodeEdit, setCurrencyCodeEdit] = useState("");
  const [currencyCodeView, setCurrencyCodeView] = useState("");
  const [currencyCodeIsValid, setCurrencyCodeIsValid] = useState(false);
  const currencyCodeRef = useRef(null);
  const currencyCodeEditRef = useRef(null);
  const [bankDetails, setBankDetails] = useState("");
  const [bankDetailsEdit, setBankDetailsEdit] = useState("");
  const [bankDetailsView, setBankDetailsView] = useState("");
  const [bankDetailsIsValid, setBankDetailsIsValid] = useState(false);
  const [bankDetailsEditIsValid, setBankDetailsEditIsValid] = useState(false);
  const bankDetailsRef = useRef(null);
  const bankDetailsEditRef = useRef(null);
  const [wxCompanyDropdownOpen, setWxCompanyDropdownOpen] = useState(false);
  const [wxCompanyEditDropdownOpen, setWxCompanyEditDropdownOpen] = useState(false);
  const wxCompanyDropdownRef = useRef(null);
  const wxCompanyEditDropdownRef = useRef(null);
  const [isProfileDeleteModalOpen, setIsProfileDeleteModalOpen] = useState(false);
  const [clientPaymentEditModalOpen, setClientPaymentEditModalOpen] = useState(false);
  const [isClientPaymentEdit, setIsClientPaymentEdit] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [wiseModalIsOpen, setWiseModalIsOpen] = useState(false);
  const [isWiseProfileLoading, setIsWiseProfileLoading] = useState(false);
  const [loadingProfiles, setLoadingProfiles] = useState({});
  const token = useSelector(
    (state) => state.auth.token
  );
  const appId = useSelector(
    (state) => state.appSettings.appSettings.appId
  );
  const { user } = useSelector((state) => state.auth);
  const { appSettings } = useSelector((state) => state.appSettings);
  const [perPage, setPerPage] = useState(10);
  const [showingDropdownOpen, setShowingDropdownOpen] = useState(false);
  const showingDropdownRef = useRef(null);

  const { data: profiles = [], isLoading: loadingCompProfiles, refetch: refetchProfiles } = useFetchCompanyProfiles(token);
  const { data: clientPayment = [], isLoading: loadingClientPayment, refetch: refetchClientPayment } = useFetchClientPayment(token);

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideShowingDropdown = showingDropdownRef.current && showingDropdownRef.current.contains(event.target);
    const isClickInsideBankAccountDropdown = wxCompanyDropdownRef.current && wxCompanyDropdownRef.current.contains(event.target);
    const isClickInsideBankAccountEditDropdown = wxCompanyEditDropdownRef.current && wxCompanyEditDropdownRef.current.contains(event.target);
    const isClickInsideCurrencyDropdown = currencyDropdownRef.current && currencyDropdownRef.current.contains(event.target);
    const isClickInsideCurrencyEditDropdown = currencyEditDropdownRef.current && currencyEditDropdownRef.current.contains(event.target);
    if (!isClickInsideShowingDropdown) {
      setShowingDropdownOpen(false);
    }
    if (!isClickInsideBankAccountDropdown) {
      setWxCompanyDropdownOpen(false);
    }
    if (!isClickInsideBankAccountEditDropdown) {
      setWxCompanyEditDropdownOpen(false);
    }
    if (!isClickInsideCurrencyDropdown) {
      setCurrencyCodeDropdownOpen(false);
    }
    if (!isClickInsideCurrencyEditDropdown) {
      setCurrencyCodeEditDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideDropdown);
    return () => {
      document.removeEventListener('click', handleClickOutsideDropdown);
    };
  }, []);

  const handleFilterChange = (e) => {
    setFilter(e.target.value)
  };

  const filteredData = clientPayment.filter((item) => {
    const searchTerm = filter.trim()?.toLowerCase();
    if (!loadingClientPayment) {
      const hasMatch =
        item.wx_company?.wx_company.toLowerCase().includes(searchTerm) ||
        item.bankName?.toLowerCase().includes(searchTerm) ||
        // item.accountNumber?.toLowerCase().includes(searchTerm) ||
        item.bankCurrencyCode?.toLowerCase().includes(searchTerm) ||
        item.bankDetails?.trim()?.toLowerCase().includes(searchTerm);
      return hasMatch;
    }
  });

  const handleOpenCreateModal = () => {
    refetchProfiles();
    setNewClientPaymentModal(true);
  };

  const handleToggleWxCompanyDropdown = () => {
    setWxCompanyDropdownOpen((prevOpen) => !prevOpen);
  };

  const handleToggleWxCompanyEditDropdown = () => {
    setWxCompanyEditDropdownOpen((prevOpen) => !prevOpen);
  };

  const handleWxCompanySelect = (profile) => {
    setWxCompany(profile._id);
    setWxCompanyDropdownOpen(false);
  };

  const handleWxCompanyEditSelect = (profile) => {
    setWxCompanyEdit(profile._id);
    setWxCompanyEditDropdownOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setBankNameIsValid(false);
    setWxCompanyIsValid(false);
    // setAccountNumberIsValid(false);
    setCurrencyCodeIsValid(false);
    setBankDetailsIsValid(false);
    if (
      bankName === "" ||
      wxCompany === "" ||
      // accountNumber === "" ||
      currencyCode === "" ||
      bankDetails === ""
    ) {
      if (wxCompany === "") {
        setWxCompanyIsValid(true);
        wxCompanyRef?.current?.focus();
        toast.error("Company name cannot be empty!", { position: "bottom-right", autoClose: 2000 });
        return;
      }
      if (bankName === "") {
        setBankNameIsValid(true);
        bankNameRef?.current?.focus();
        toast.error("Bank name cannot be empty!", { position: "bottom-right", autoClose: 2000 });
        return;
      }
      // if (accountNumber === "") {
      //   setAccountNumberIsValid(true);
      //   accountNumberRef?.current?.focus();
      //   toast.error("Account number cannot be empty!", { position: "bottom-right", autoClose: 2000 });
      //   return;
      // }
      if (currencyCode === "") {
        setCurrencyCodeIsValid(true);
        currencyCodeRef?.current?.focus();
        toast.error("Currency cannot be empty!", { position: "bottom-right", autoClose: 2000 });
        return;
      }
      if (bankDetails === "") {
        setBankDetailsIsValid(true);
        bankDetailsRef?.current?.focus();
        toast.error("Bank details cannot be empty!", { position: "bottom-right", autoClose: 2000 });
        return;
      }

      toast.error("Please fill all required fields!", { position: "bottom-right", autoClose: 2000 });
      return;
    }

    const requestData = {
      bankName: bankName,
      wxComapny: wxCompany,
      // accountNumber: accountNumber,
      bankCurrencyCode: currencyCode,
      bankDetails: bankDetails,
      appId: appId,
    };

    try {
      setIsSubmitLoading(true);
      await axios.post(`/api/app-owner-bank/add`, requestData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      toast.success("Profile created successfully", { position: "bottom-right", autoClose: 2000 });
      dispatch(setNotification(Date.now()));
      refetchClientPayment();
      handleCloseCreateModal();
    } catch (error) {
      console.error(error);
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        toast.error("This user is deleted!", { position: "bottom-right", autoClose: 1500 });
        navigate("/");
        return;
      }
      toast.error("Something went wrong while updating the profile!", { position: "bottom-right", autoClose: 2000 });
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const handleCloseCreateModal = () => {
    setSelectedId("");
    setWxCompany("");
    setBankName("");
    // setAccountNumber("");
    setBankDetails("");
    setCurrencyCode("");
    setNewClientPaymentModal(false);
  };

  const handleDetailClick = async (rowData) => {
    setSelectedId(rowData._id);
    if (rowData?.wxComapny) {
      setWxCompanyEdit(rowData.wxComapny?._id);
      setWxCompanyView(rowData.wxComapny?._id);
    }
    if (rowData.bankName) {
      setBankNameEdit(rowData.bankName);
      setBankNameView(rowData.bankName);
    }
    // if (rowData.accountNumber) {
    //   setAccountNumberEdit(rowData.accountNumber);
    //   setAccountNumberView(rowData.accountNumber);
    // }
    if (rowData.bankCurrencyCode) {
      setCurrencyCodeEdit(rowData.bankCurrencyCode);
      setCurrencyCodeView(rowData.bankCurrencyCode);
    }
    if (rowData.bankDetails) {
      setBankDetailsEdit(rowData.bankDetails);
      setBankDetailsView(rowData.bankDetails);
    }
    setClientPaymentEditModalOpen(true);
  }

  const handleCloseDetailsModal = () => {
    setSelectedId("");
    setBankNameEdit("");
    setBankNameView("");
    // setAccountNumberEdit("");
    // setAccountNumberView("");
    setWxCompanyEdit("");
    setWxCompanyView("");
    setCurrencyCodeEdit("");
    setCurrencyCodeView("");
    setBankDetailsEdit("");
    setBankDetailsView("");
    setIsClientPaymentEdit(false);
    setClientPaymentEditModalOpen(false);
    setWxCompanyEditIsValid(false);
    setBankNameEditIsValid(false);
    // setAccountNumberEditIsValid(false);
    setCurrencyCodeIsValid(false);
  };

  const handleSetClientPaymentEdit = () => {
    setIsClientPaymentEdit(true);
  };

  useEffect(() => {
    async function checkWiseSettings() {
      try {
        setIsWiseProfileLoading(true);
        const response = await axios.get("/api/check/wise/settings/exists", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.data.exists) {
          setWiseModalIsOpen(true);
        }
        return response.data.exists;
      } catch (error) {
        console.log("Error checking Wise settings:", error);
        return false;
      } finally {
        setIsWiseProfileLoading(false);
      }
    }
    const sortedCurrencies = Currencies.sourceCurrencies[0].targetCurrencies.slice().sort((a, b) => a.currencyCode.localeCompare(b.currencyCode));
    setCurrenciesList(sortedCurrencies);
    refetchClientPayment();
    refetchProfiles();
    checkWiseSettings();
  }, []);

  const closeWiseModal = () => {
    setWiseModalIsOpen(false);
    if (user.userType === userTypeConsts.SUPER_ADMIN || user.userType === userTypeConsts.ADMIN) {
      localStorage.setItem('activeTab', '1');
      navigate("/user/account/settings");
    } else {
      navigate("/user/dashboard");
    }
  };

  const goToWisePage = () => {
    setWiseModalIsOpen(false);
    if (user.userType === userTypeConsts.SUPER_ADMIN || user.userType === userTypeConsts.ADMIN) {
      localStorage.setItem('activeTab', '1');
      navigate("/user/account/settings");
    } else {
      navigate("/user/dashboard");
    }
  };

  const handleToggleCurrencyDropdown = () => {
    setCurrencyCodeDropdownOpen((prevOpen) => !prevOpen);
  };

  const handleToggleCurrencyEditDropdown = () => {
    setCurrencyCodeEditDropdownOpen((prevOpen) => !prevOpen);
  };

  const handleCurrencySearchInputChange = (e) => {
    setCurrencyCodeSearchTerm(e.target.value);
  };

  const filteredCurrency = currenciesList.filter((currency) => {
    const currencyName = currency.currencyCode
      && `${currency.currencyCode} (${currency.currencyName})`;
    return currencyName?.toLowerCase().includes(currencyCodeSearchTerm?.toLowerCase());
  });

  const handleClientPaymentEditSubmit = async (event) => {
    event.preventDefault();
    setBankNameEditIsValid(false);
    setWxCompanyEditIsValid(false);
    // setAccountNumberEditIsValid(false);
    setCurrencyCodeIsValid(false);
    setBankDetailsEditIsValid(false);
    if (
      bankNameEdit === "" ||
      wxCompanyEdit === "" ||
      // accountNumberEdit === "" ||
      currencyCodeEdit === "" ||
      bankDetailsEdit === ""
    ) {
      if (wxCompanyEdit === "") {
        setWxCompanyEditIsValid(true);
        wxCompanyEditRef?.current?.focus();
        toast.error("Company name cannot be empty!", { position: "bottom-right", autoClose: 2000 });
        return;
      }
      if (bankNameEdit === "") {
        setBankNameEditIsValid(true);
        bankNameEditRef?.current?.focus();
        toast.error("Bank name cannot be empty!", { position: "bottom-right", autoClose: 2000 });
        return;
      }
      // if (accountNumberEdit === "") {
      //   setAccountNumberEditIsValid(true);
      //   accountNumberEditRef?.current?.focus();
      //   toast.error("Account number cannot be empty!", { position: "bottom-right", autoClose: 2000 });
      //   return;
      // }
      if (bankDetailsEdit === "") {
        setBankDetailsEditIsValid(true);
        bankDetailsEditRef?.current?.focus();
        toast.error("Bank details cannot be empty!", { position: "bottom-right", autoClose: 2000 });
        return;
      }
      if (currencyCodeEdit === "") {
        setCurrencyCodeIsValid(true);
        currencyCodeEditRef?.current?.focus();
        toast.error("Currency cannot be empty!", { position: "bottom-right", autoClose: 2000 });
        return;
      }
      toast.error("Please fill all required fields!", { position: "bottom-right", autoClose: 2000 });
      return;
    }

    const requestData = {
      bankName: bankNameEdit,
      wxComapny: wxCompanyEdit,
      // accountNumber: accountNumberEdit,
      bankCurrencyCode: currencyCodeEdit,
      bankDetails: bankDetailsEdit,
    };

    try {
      setIsSubmitLoading(true);
      await axios.put(`/api/app-owner-bank/update/${selectedId}`, requestData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success("Bank updated successfully", { position: "bottom-right", autoClose: 2000 });

      dispatch(setNotification(Date.now()));
      refetchClientPayment();
      handleCloseDetailsModal();
    } catch (error) {
      console.error(error);
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        toast.error("This user is deleted!", { position: "bottom-right", autoClose: 1500 });
        navigate("/");
        return;
      }
      toast.error("Something went wrong while updating the profile!", { position: "bottom-right", autoClose: 2000 });
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const handleResetProfileEdit = () => {
    setIsClientPaymentEdit(false);
  };

  const renderDetailButton = (rowData) => {
    return (
      <button
        className="cta-link account-payment"
        onClick={() => handleDetailClick(rowData)}
      >
        {loadingClientPayment ?
          <div className="outter-load-table">
            <ThreeDots
              height="18"
              width="20"
              radius="9"
              color="white"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div> : global.config.locate(appSettings && appSettings.language, 'Details')}
      </button>
    );
  };

  const handleProfileDelete = () => {
    setIsProfileDeleteModalOpen(true);
  };

  const handleConfirmClientPaymentDelete = async () => {
    try {
      setIsSubmitLoading(true);
      const response = await axios.get(`/api/app-owner-bank/delete/${selectedId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      toast.success("Bank data removed successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch(setNotification(Date.now()));
      refetchClientPayment();
      handleCloseDetailsModal();
      global.config.activityLog(window.location.href, 'Bank', `Bank profile (${selectedId}) deleted successfully`);
      // profileRefetch();
    } catch (error) {
      console.error(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error('This user is deleted!', {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      toast.error("Something went wrong", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsSubmitLoading(false);
      handleCancelProfileDelete();
    }
  };

  const handleCancelProfileDelete = () => {
    setSelectedId("");
    setIsProfileDeleteModalOpen(false);
  };

  const totalResults = filteredData.length;

  const StatusChangeActive = async (rowData) => {
    try {
      setLoadingProfiles((prevLoadingProfiles) => ({
        ...prevLoadingProfiles,
        [rowData._id]: true,
      }));
      await axios.get(`/api/app-owner-bank/status-change/${rowData._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success(`${rowData.bankName}'s account has been deactivated successfully!`, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      global.config.activityLog(
        window.location.href,
        "Client payment",
        `${rowData.bankName}'s account has been deactivated successfully!`
      );
      dispatch(setNotification(Date.now()));
      refetchClientPayment();
    } catch (error) {
      console.log("Something went wrong while deactivating: ", error);
      toast.error(`Something went wrong while deactivating ${rowData.bankName}'s account`, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      global.config.activityLog(
        window.location.href,
        "User",
        `Something went wrong while deactivating ${rowData.bankName}'s account!`
      );
      console.log(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error('This user is deleted!', {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
    } finally {
      setLoadingProfiles((prevLoadingProfiles) => ({
        ...prevLoadingProfiles,
        [rowData._id]: false,
      }));
    }
  };

  const StatusChangeInactive = async (rowData) => {
    try {
      setLoadingProfiles((prevLoadingProfiles) => ({
        ...prevLoadingProfiles,
        [rowData._id]: true,
      }));
      await axios.get(`/api/app-owner-bank/status-change/${rowData._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success(`${rowData.bankName}'s account has been activated successfully!`, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch(setNotification(Date.now()));
      global.config.activityLog(
        window.location.href,
        "Client payment",
        `${rowData._id}'s account has been activated successfully!`
      );
      refetchClientPayment();
    } catch (error) {
      console.log("Something went wrong while activating user: ", error);
      global.config.slackMessage(error.toString());
      toast.error("Something went wrong in changing user status!", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      global.config.activityLog(
        window.location.href,
        "User",
        `Something went wrong while activating ${rowData.bankName}'s account!`
      );
      console.log(error);
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error('This user is deleted!', {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
    } finally {
      setLoadingProfiles((prevLoadingProfiles) => ({
        ...prevLoadingProfiles,
        [rowData._id]: false,
      }));
      refetchClientPayment();
    }
  };

  const renderStatus = (rowData) => {
    const { _id, status } = rowData;
    const isLoading = loadingProfiles[_id] || false;

    return (
      <span
        className={`payment-status ${status ? "active" : "inactive"}`}
        onClick={(e) => {
          e.preventDefault();
          if (!isLoading) {
            if (status) {
              StatusChangeActive(rowData);
            } else {
              StatusChangeInactive(rowData);
            }
          }
        }}
        style={{ cursor: 'pointer' }}
      >
        {isLoading ?
          <div className="outter-load-table">
            <ThreeDots
              height="18"
              width="20"
              radius="9"
              color="#6479f9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div> : (
            status ? global.config.locate((appSettings && appSettings.language), 'Active') : global.config.locate((appSettings && appSettings.language), 'Inactive')
          )}
      </span>
    );
  };

  return (
    <div className="user-outer-overview add-bank-account bank-account-new-type">
      <div className="invoice-top">
      <div className="invoice-top-inner-left">
        <BankAccount />
        <div className="search-sec">
          <div class="search-container">
            <input
              type="text"
              onChange={handleFilterChange}
              value={filter}
              class="search-input"
              placeholder='Search by name'
            />
          </div>
        </div>
        </div>
        <div className="button-sec-invoice">
          <Link onClick={handleOpenCreateModal}>
            <span className="icon-invoice">
              <img src={PlusIcon} alt="Add" />
            </span>
            New Bank Account
          </Link>
        </div>
      </div>
      <Modal
        className="delete-modal"
        isOpen={wiseModalIsOpen}
        onRequestClose={closeWiseModal}
        contentLabel="No Wise Account Modal"
        style={{
          content: {
            height: "41%",
            width: "30%",
            position: "fixed",
            top: "40%",
            left: "50%",
            transform: "translate(-17.5%, 0%)",
            parent: document.querySelector(".admin-outer.time.tracker"),
          },
        }}
      >
        <div className="delete-modal-content">
          <div className="outter-modal-main">
            <a className="close-image-clr1" onClick={closeWiseModal}>
              <img src={crssrImage} alt="Close Icon" />{" "}
            </a>
          </div>
          <h2>No Wise Account Registered</h2>
          <p>
            There is no Wise account registered for this account. Please add a Wise account to proceed further.
          </p>
          {isWiseProfileLoading ? (
            <div className="outter-load-table">
              <ThreeDots
                height="38"
                width="40"
                radius="9"
                color="#6479f9"
                ariaLabel="three-dots-loading"
                visible={true}
              />
            </div>
          ) : (
            (user.userType === userTypeConsts.SUPER_ADMIN || user.userType === userTypeConsts.ADMIN) ? (
              <div className="delete-modal-buttons">
                <button className="buttons-approved" onClick={goToWisePage}>
                  Add Wise Account
                </button>
                <button onClick={closeWiseModal}>Close</button>
              </div>
            ) : (
              <div style={{ color: "red", textAlign: "center", marginTop: "20px" }}>
                Please contact admin to create a bank account
              </div>
            )
          )}
        </div>
      </Modal>
      <div className="showing-count">
        <div className="filter-inner showing-result">
          <label>{global.config.locate(appSettings && appSettings.language, 'Showing')}</label>
          <div ref={showingDropdownRef} className="select-modal-mainM custom-dropdown-user-new-outer">
            <div className="selected-option custom-dropdown-user-new" onClick={() => setShowingDropdownOpen(!showingDropdownOpen)}>
              {totalResults < 10 ? totalResults.toString() : perPage.toString()}
            </div>
            {showingDropdownOpen && (
              <div className="custom-dropdown-new">
                <ul className="showing-options-list">
                  {totalResults >= 10 && <li className={perPage === 10 ? 'selected-item' : ''} onClick={() => { setPerPage(10); setShowingDropdownOpen(false); }}>10</li>}
                  {totalResults >= 20 && <li className={perPage === 20 ? 'selected-item' : ''} onClick={() => { setPerPage(20); setShowingDropdownOpen(false); }}>20</li>}
                  {totalResults >= 30 && <li className={perPage === 30 ? 'selected-item' : ''} onClick={() => { setPerPage(30); setShowingDropdownOpen(false); }}>30</li>}
                  {totalResults >= 40 && <li className={perPage === 40 ? 'selected-item' : ''} onClick={() => { setPerPage(40); setShowingDropdownOpen(false); }}>40</li>}
                  {totalResults >= 10 && <li className={totalResults >= 10 && perPage === totalResults ? 'selected-item' : ''} onClick={() => { setPerPage(totalResults); setShowingDropdownOpen(false); }}>ALL</li>}
                  {/* Add more conditions as needed */}
                  {totalResults < 10 ? (
                    <li className={totalResults < 10 ? 'selected-item' : ''} onClick={() => { setPerPage(totalResults); setShowingDropdownOpen(false); }}>{totalResults}</li>
                  ) : null}
                </ul>
              </div>
            )}
          </div>
          <label>{global.config.locate(appSettings && appSettings.language, 'of')} {totalResults} {global.config.locate(appSettings && appSettings.language, 'Results')}</label>
        </div>
      </div>

      <div className="card-outer1">
        <div className="card-outer card-outer-account">
          <div className="card-outer-user">
            <Modal
              className="client-modal project-modal"
              isOpen={newClientPaymentModal}
              onRequestClose={!isSubmitLoading ? handleCloseCreateModal : undefined}
              contentLabel="Create Profile"
              style={{
                content: {
                  height: "41%",
                  width: "30%",
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-40%, -50%)",
                  parent: document.querySelector(".admin-outer.time.tracker"),
                },
              }}
            >
              <div className="modal timeoff-modal">
                <div className="modal-close-invite outter-modal-main">
                  <a className="" onClick={handleCloseCreateModal} disabled={isSubmitLoading}>
                    <img src={crssrImage} alt="Edit Icon" />{" "}
                  </a>
                </div>
                <div className="modal-client-head">
                  <h2 className="">Create Client Payment Details</h2>
                </div>
                <div className="form-field-client-outer profile-details-edit">
                  <form onSubmit={handleSubmit}>

                    <div className="form-field-client">
                      <label htmlFor="bankName" className="form-label">
                        Bank Name
                      </label>
                      <input
                        type="text"
                        id="bankName"
                        name="bankName"
                        className="form-input"
                        value={bankName}
                        onChange={(e) => setBankName(e.target.value)}
                        ref={bankNameRef}
                        required
                      />
                      {bankNameIsValid && (
                        <span className="text-red-500 text-xs italic">
                          Bank Name Field Cannot be empty
                        </span>
                      )}
                    </div>

                    <div className="form-field-client">
                      <label htmlFor="company" className="form-label">
                        Company Name
                      </label>
                      <div ref={wxCompanyDropdownRef} className="select-modal-mainM custom-dropdown-user-new-outer">
                        <span
                          id="bankAccount"
                          onClick={handleToggleWxCompanyDropdown}
                          className="custom-dropdown-user-new"
                          ref={wxCompanyRef}
                        >
                          {wxCompany ? (
                            profiles?.find((wxComp) => wxComp._id === wxCompany)?.wx_company
                          ) : 'Select Company'}
                        </span>
                        {wxCompanyDropdownOpen && (
                          <div className="custom-dropdown-new">
                            <ul className="bank-account-list">
                              {profiles.length > 0 &&
                                profiles.map((profile) => (
                                  <li
                                    key={profile._id}
                                    className={wxCompany === profile._id ? 'selected-item' : ''}
                                    onClick={() => {
                                      handleWxCompanySelect(profile);
                                      setWxCompanyDropdownOpen(false);
                                    }}
                                  >
                                    {profile.wx_company}
                                  </li>
                                ))}
                            </ul>
                          </div>
                        )}
                      </div>
                      {wxCompanyIsValid && (
                        <span className="text-red-500 text-xs italic">
                          Company needs to be selected
                        </span>
                      )}
                    </div>

                    {/* <div className="form-field-client">
                      <label htmlFor="accountNumber" className="form-label">
                        Account Number
                      </label>
                      <input
                        type="text"
                        id="accountNumber"
                        name="accountNumber"
                        className="form-input"
                        value={accountNumber}
                        onChange={(e) => setAccountNumber(e.target.value)}
                        ref={accountNumberRef}
                        required
                      />
                      {accountNumberIsValid && (
                        <span className="text-red-500 text-xs italic">
                          Account number Field Cannot be empty
                        </span>
                      )}
                    </div> */}

                    <div className="form-field-client">
                      <label htmlFor="country" className="form-label">
                        Currency
                      </label>
                      <div ref={currencyDropdownRef} className="select-modal-mainM custom-dropdown-user-new-outer">
                        <span
                          id="country"
                          onClick={handleToggleCurrencyDropdown}
                          className="custom-dropdown-user-new"
                          ref={currencyCodeRef}
                        >
                          {currencyCode ? currencyCode : 'Select Currency'}
                        </span>
                        {currencyCodeDropdownOpen && (
                          <div className="custom-dropdown-new">
                            <input
                              type="text"
                              className="search-bar"
                              placeholder={global.config.locate(appSettings && appSettings.language, 'Search')}
                              value={currencyCodeSearchTerm}
                              onChange={handleCurrencySearchInputChange}
                            />
                            <ul className="country-list">
                              {filteredCurrency.length > 0 &&
                                filteredCurrency.map((c) => (
                                  <li
                                    key={c.currencyCode}
                                    className={currencyCode === c.currencyCode ? 'selected-item' : ''}
                                    onClick={() => {
                                      setCurrencyCode(c.currencyCode);
                                      setCurrencyCodeDropdownOpen(false);
                                    }}
                                  >
                                    {`${c.currencyCode} (${c.currencyName})`}
                                  </li>
                                ))}
                            </ul>
                          </div>
                        )}
                      </div>
                      {currencyCodeIsValid && (
                        <span className="text-red-500 text-xs italic">
                          Country needs to be selected
                        </span>
                      )}
                    </div>

                    <div className="form-field-client">
                      <label htmlFor="bankDetails" className="form-label">
                        Bank Details
                      </label>
                      <textarea
                        id="bankDetails"
                        name="bankDetails"
                        className="form-input"
                        value={bankDetails}
                        ref={bankDetailsRef}
                        onChange={(e) => setBankDetails(e.target.value)}
                        style={{ height: '150px' }}
                      />
                      {bankDetailsIsValid && (
                        <span className="text-red-500 text-xs italic">
                          Bank Details Cannot be empty
                        </span>
                      )}
                    </div>

                    <button type="submit" className="submit-client" disabled={isSubmitLoading}>
                      {isSubmitLoading ?
                        <div className="outter-load-table">
                          <ThreeDots
                            height="18"
                            width="20"
                            radius="9"
                            color="white"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </div> : 'Create Profile'}
                    </button>
                  </form>
                </div>
              </div>
            </Modal>
          </div>
          <div className="modal-invite-main-outter modal-add-bank-account">
            <Modal
              className="client-modal project-modal"
              isOpen={clientPaymentEditModalOpen}
              onRequestClose={handleCloseDetailsModal}
              contentLabel="View and Edit Profile"
              style={{
                content: {
                  height: "41%",
                  width: "30%",
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  parent: document.querySelector(".admin-outer.time.tracker"),
                },
              }}
            >
              <div className="modal">
                <div className="modal-close-invite outter-modal-main">
                  <a className="" onClick={handleCloseDetailsModal}>
                    <img src={crssrImage} alt="Edit Icon" />{" "}
                  </a>
                </div>
                <div className="project-detail-modal">
                  <div className="modal-client-head ">
                    {isClientPaymentEdit ? (
                      <h2 className="">Edit Bank Details</h2>
                    ) : (
                      <h2 className="">Bank Details</h2>
                    )}
                  </div>
                  <div className="form-field-client-outer">
                    <form>
                      {isClientPaymentEdit || bankNameView ? (
                        <div className="form-field-client">
                          <label htmlFor="bankNameEdit" className="form-label">
                            Bank Name
                          </label>
                          {isClientPaymentEdit ? (
                            <>
                              <input
                                type="text"
                                id="bankNameEdit"
                                name="bankNameEdit"
                                className="form-input"
                                value={bankNameEdit}
                                onChange={(e) => setBankNameEdit(e.target.value)}
                                ref={bankNameEditRef}
                                required
                              />
                              {bankNameEditIsValid && (
                                <span className="text-red-500 text-xs italic">
                                  Bank Name Field Cannot be empty
                                </span>
                              )}
                            </>
                          ) : (
                            <p className="form-textarea">
                              {bankNameView}
                            </p>
                          )}
                        </div>
                      ) : null}

                      {isClientPaymentEdit || wxCompanyView ? (
                        <div className="form-field-client">
                          <label htmlFor="wxCompanyEdit" className="form-label">
                            Company Name
                          </label>
                          {isClientPaymentEdit ? (
                            <>
                              <div ref={wxCompanyEditDropdownRef} className="select-modal-mainM custom-dropdown-user-new-outer">
                                <span
                                  id="bankAccount"
                                  onClick={handleToggleWxCompanyEditDropdown}
                                  className="custom-dropdown-user-new"
                                  ref={wxCompanyEditRef}
                                >
                                  {wxCompanyEdit ? (
                                    profiles?.find((wxComp) => wxComp._id === wxCompanyEdit)?.wx_company
                                  ) : 'Select Company'}
                                </span>
                                {wxCompanyEditDropdownOpen && (
                                  <div className="custom-dropdown-new">
                                    <ul className="bank-account-list">
                                      {profiles.length > 0 &&
                                        profiles.map((profile) => (
                                          <li
                                            key={profile._id}
                                            className={wxCompanyEdit === profile._id ? 'selected-item' : ''}
                                            onClick={() => {
                                              handleWxCompanyEditSelect(profile);
                                            }}
                                          >
                                            {profile.wx_company}
                                          </li>
                                        ))}
                                    </ul>
                                  </div>
                                )}
                              </div>
                              {wxCompanyEditIsValid && (
                                <span className="text-red-500 text-xs italic">
                                  Company Name Field Cannot be empty
                                </span>
                              )}
                            </>
                          ) : (
                            <p className="form-textarea">
                              {wxCompanyView ? (
                                profiles?.find((wxComp) => wxComp._id === wxCompanyView)?.wx_company
                              ) : null}
                            </p>
                          )}
                        </div>
                      ) : null}

                      {/* {isClientPaymentEdit || accountNumberView ? (
                        <div className="form-field-client">
                          <label htmlFor="accountNumberEdit" className="form-label">
                            Account Number
                          </label>
                          {isClientPaymentEdit ? (
                            <>
                              <input
                                type="text"
                                id="accountNumberEdit"
                                name="accountNumberEdit"
                                className="form-input"
                                value={accountNumberEdit}
                                onChange={(e) => setAccountNumberEdit(e.target.value)}
                                ref={accountNumberEditRef}
                                required
                              />
                              {accountNumberEditIsValid && (
                                <span className="text-red-500 text-xs italic">
                                  Account number Field Cannot be empty
                                </span>
                              )}
                            </>
                          ) : (
                            <p className="form-textarea">
                              {accountNumberView}
                            </p>
                          )}
                        </div>
                      ) : null} */}

                      <div className="form-field-client">
                        {isClientPaymentEdit || currencyCodeView ? (
                          <label htmlFor="currencyCodeEdit" className="form-label">
                            Currency
                          </label>
                        ) : null}
                        {isClientPaymentEdit ? (
                          <>
                            <div ref={currencyEditDropdownRef} className="select-modal-mainM custom-dropdown-user-new-outer">
                              <span
                                id="currencyCodeEdit"
                                onClick={handleToggleCurrencyEditDropdown}
                                className="custom-dropdown-user-new"
                                ref={currencyCodeEditRef}
                              >
                                {currencyCodeEdit ? currencyCodeEdit : 'Select Currency'}
                              </span>
                              {currencyCodeEditDropdownOpen && (
                                <div className="custom-dropdown-new">
                                  <input
                                    type="text"
                                    className="search-bar"
                                    placeholder={global.config.locate(appSettings && appSettings.language, 'Search')}
                                    value={currencyCodeSearchTerm}
                                    onChange={handleCurrencySearchInputChange}
                                  />
                                  <ul className="currency-list">
                                    {filteredCurrency.length > 0 &&
                                      filteredCurrency.map((c) => (
                                        <li
                                          key={c.currencyCode}
                                          className={currencyCodeEdit === c.currencyCode ? 'selected-item' : ''}
                                          onClick={() => {
                                            setCurrencyCodeEdit(c.currencyCode);
                                            setCurrencyCodeEditDropdownOpen(false);
                                          }}
                                        >
                                          {`${c.currencyCode} (${c.currencyName})`}
                                        </li>
                                      ))}
                                  </ul>
                                </div>
                              )}
                            </div>

                            {currencyCodeIsValid && (
                              <span className="text-red-500 text-xs italic">
                                Country needs to be selected
                              </span>
                            )}
                          </>
                        ) : (
                          <p className="form-input">
                            {currencyCodeView ? currencyCodeView : null}
                          </p>
                        )}
                      </div>

                      {isClientPaymentEdit || bankDetailsView ? (
                        <div className="form-field-client">
                          <label htmlFor="bankDetailsEdit" className="form-label">
                            Bank Details
                          </label>
                          {isClientPaymentEdit ? (
                            <>
                              <textarea
                                id="bankDetailsEdit"
                                name="bankDetailsEdit"
                                className="form-textarea"
                                style={{ height: '150px' }}
                                value={bankDetailsEdit}
                                ref={bankDetailsEditRef}
                                onChange={(e) => setBankDetailsEdit(e.target.value)}
                              />
                              {bankDetailsEditIsValid && (
                                <span className="text-red-500 text-xs italic">
                                  Bank Details Cannot be empty
                                </span>
                              )}
                            </>
                          ) : (
                            <p className="form-textarea">
                              <pre style={{ fontFamily: 'inherit', whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                                {Parser().parse(bankDetailsView ? bankDetailsView : (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ))}
                              </pre></p>
                          )}
                        </div>
                      ) : null}

                      {isClientPaymentEdit ? (
                        <>
                          {isSubmitLoading ?
                            (
                              <div className="outter-load-table">
                                <ThreeDots
                                  height="38"
                                  width="40"
                                  radius="9"
                                  color="#6479f9"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              </div>
                            ) : (
                              <div className="sprint-btn-outer">
                                <button
                                  type="button"
                                  onClick={handleResetProfileEdit}
                                  className="submit-client cancel-sprint"
                                >
                                  Cancel
                                </button>
                                <button
                                  type="button"
                                  onClick={handleClientPaymentEditSubmit}
                                  className="submit-client save-sprint"
                                >
                                  Save Changes
                                </button>
                              </div>
                            )}
                        </>
                      ) : (
                        <div className="sprint-btn-outer">
                          <button
                            type="button"
                            className="submit-client cancel-sprint"
                            onClick={handleProfileDelete}
                            disabled={isSubmitLoading}
                          >
                            <img src={Delete} alt="Delete" />  Delete
                          </button>
                          <Modal
                            className="delete-modal"
                            isOpen={isProfileDeleteModalOpen}
                            onRequestClose={handleCancelProfileDelete}
                            contentLabel="Confirm Delete"
                            style={{
                              content: {
                                height: "41%",
                                width: "30%",
                                position: "fixed",
                                top: "36%",
                                left: "50%",
                                transform: "translate(-19.75%, 0%)",
                                parent: document.querySelector(".admin-outer.time.tracker"),
                              },
                            }}
                          >
                            <div className="delete-modal-content">
                              <h2>Confirm Delete</h2>
                              <p>
                                Are you sure you want to delete this Bank Account?
                              </p>
                              <div className="delete-modal-buttons">
                                <button className="buttons-rejected" onClick={handleConfirmClientPaymentDelete} disabled={isSubmitLoading}>
                                  {isSubmitLoading ?
                                    <div className="outter-load-table">
                                      <ThreeDots
                                        height="18"
                                        width="20"
                                        radius="9"
                                        color="white"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                      />
                                    </div> : "Delete"}
                                </button>
                                <button onClick={handleCancelProfileDelete} disabled={isSubmitLoading}>
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </Modal>
                          <button
                            type="button"
                            onClick={handleSetClientPaymentEdit}
                            className="submit-client save-sprint"
                          >
                            <img alt="" src={editImage} />{" "}
                            Edit Profile
                          </button>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
          <div className="card">
            {loadingClientPayment === true ? (
              <div className="outter-load-table">
                <ThreeDots
                  height="80"
                  width="80"
                  radius="9"
                  color="#405BFF"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
            ) : (
              <DataTable
                value={filteredData}
                removableSort
                tableStyle={{
                  minWidth: "50rem",
                  width: "100%",
                  textAlign: "left",
                }}
                paginator={filteredData.length > perPage}
                paginatorClassName="recent-invoices-pagination"
                rows={perPage}
              >
                <Column
                  field="bankName"
                  header="Bank name"
                  sortable
                  style={{ paddingRight: "44px", maxWidth: "297px" }}
                ></Column>
                <Column
                  field="bankCurrencyCode"
                  header="Currency"
                  sortable
                  style={{ paddingRight: "20px" }}
                ></Column>
                <Column
                  field="wxComapny.wx_company"
                  header="Comapny"
                  sortable
                  style={{ paddingRight: "20px" }}
                ></Column>
                {/* <Column
                  field="accountNumber"
                  header="Account number"
                  sortable
                  style={{ paddingRight: "44px", maxWidth: "297px" }}
                ></Column> */}
                <Column
                  header={global.config.locate(appSettings && appSettings.language, 'Status')}
                  body={renderStatus}
                  sortable
                  style={{ paddingRight: "44px" }}
                ></Column>
                <Column
                  body={renderDetailButton}
                  style={{ width: "76px", textAlign: "center" }}
                ></Column>
              </DataTable>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientPayment;