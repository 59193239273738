import axios from "axios";
import Modal from "react-modal";
import * as XLSX from "xlsx";
import "./VendorsOverview.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ThreeDots } from 'react-loader-spinner';
import React, { useEffect, useRef, useState } from "react";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Countries from "../../constants/countries.js";
import { useSelector, useDispatch } from 'react-redux';
import Plus from "../../images/icons/plus-black.svg";
import PlusIcon from "../../images/icons/plus-icon.svg";
import InviteIcon from "../../images/icons/invite.svg";
import crssrImage from "../../images/singleinvoice/cross-red.svg";
import dletImage from "../../images/singleinvoice/delete.svg";
import addicon from "../../images/icons/addicon.svg";
import DownloadImage from "../../images/icons/download-icon.svg";
import EyeIcon from "../../images/icons/eye-icon.svg";
import EyeCrossed from "../../images/icons/eye-crossed.svg";
import { setNotification } from "../../features/notification/notificationSlice.js";
import { setHeaderText } from "../../features/headerText/headerTextSlice.js";
import { logout, reset } from "../../features/auth/authSlice.js";
import { reset as invoiceReset } from "../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../features/appSettings/appSettingsSlice.js";
import { useUserById } from "../TimeOff/timeOffApi.jsx";
import VendorsOverviewListing from "./VendorsOverviewListing";
import { useFetchVendorDetails } from "../../pages/Vendors/VendorDetailsLayout/useVendorDetails";
import moment from "moment";

const VendorOverviewSection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [vendorName, setVendorName] = useState("");
  const [legalEntityName, setLegalEntityName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [vatNumber, setVatNumber] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [userSearchTerm, setUserSearchTerm] = useState('');
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [isValidUrl, setIsValidUrl] = useState(false);
  const [companyLogo, setCompanyLogo] = useState(null);
  const [companyLogoName, setCompanyLogoName] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [updateVendorList, setUpdateVendorList] = useState(false);
  const [countrySearchTerm, setCountrySearchTerm] = useState('');
  const [countryDropdownOpen, setCountryDropdownOpen] = useState(false);
  const [countriesList, setCountriesList] = useState([]);
  const [filter, setFilter] = useState("");
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [isVenInviteModalOpen, setIsVenInviteModalOpen] = useState(false);
  const [venInvEmail, setVenInvEmail] = useState("");
  const [venInvEmails, setVenInvEmails] = useState([]);
  const [alreadySentVenInvEmailsList, setAlreadySentVenInvEmailsList] = useState([]);
  const [isResendVenInviteModalOpen, setIsResendVenInviteModalOpen] = useState(false);
  const [isVenInvMailLoading, setIsVenInvMailLoading] = useState(false);
  const [isBulkImportModalOpen, setIsBulkImportModalOpen] = useState(false);
  const [isBulkImportLoading, setIsBulkImportLoading] = useState(false);
  const [bulkImportFile, setBulkImportFile] = useState(null);
  const [bulkImportFileName, setBulkImportFileName] = useState("");
  const statusDropdownRef = useRef(null);
  const countryDropdownRef = useRef(null);
  const [sortDropdownOpen, setSortDropdownOpen] = useState(false);
  const [selectedSortOption, setSelectedSortOption] = useState(null);
  const [checkedOption, setCheckedOption] = useState(null);
  const [passwordMatchError, setPasswordMatchError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const passwordConfirmationRef = useRef(null);
  const passwordRef = useRef(null);
  const sortDropdownRef = useRef(null);

  let token = null;
  const { user } = useSelector((state) => state.auth);
  const { appSettings } = useSelector((state) => state.appSettings);
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }
  const { data: userIds = [], isLoading: isLoadingUser } = useUserById(token);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setVendorName("");
    setLegalEntityName("");
    setEmail("");
    setPassword("");
    setVatNumber("");
    setMobileNo("");
    setUserSearchTerm('');
    setCompanyWebsite("");
    setCompanyLogo(null);
    setCompanyLogoName("");
    setCountry("");
    setCity("");
    setAddress("");
    setPincode("");
    setAdditionalInfo("");
  };

  const handleLogoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCompanyLogo(e.target.files[0])
      setCompanyLogoName(file.name)
    } else {
      setCompanyLogo(null)
      setCompanyLogoName("")
    }
  };


  useEffect(() => {
    dispatch(setHeaderText("Vendor Overview"));
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        document.activeElement.blur();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const sortedCountries = Countries.slice().sort((a, b) => a.country.localeCompare(b.country));
    setCountriesList(sortedCountries);
  }, []);

  const handleToggleCountryDropdown = () => {
    setCountrySearchTerm('');
    setCountryDropdownOpen((prevOpen) => !prevOpen);
  };

  const handleCountrySearchInputChange = (e) => {
    setCountrySearchTerm(e.target.value);
  };

  const filteredCountries = countriesList.filter((country) => {
    return country.country.toLowerCase().includes(countrySearchTerm.toLowerCase());
  });

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideCountryDropdown = countryDropdownRef.current && countryDropdownRef.current.contains(event.target);
    const isClickInsideSortDropdown = sortDropdownRef.current && sortDropdownRef.current.contains(event.target);
    const isClickInsideStatusDropdown = statusDropdownRef.current && statusDropdownRef.current.contains(event.target);

    if (!isClickInsideCountryDropdown) {
      setCountryDropdownOpen(false);
    }
    if (!isClickInsideSortDropdown) {
      setSortDropdownOpen(false);
    }
    if (!isClickInsideStatusDropdown) {
      setStatusDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideDropdown);
    return () => {
      document.removeEventListener('click', handleClickOutsideDropdown);
    };
  }, []);

  const toggleStatusDropdown = () => {
    setStatusDropdownOpen(!statusDropdownOpen);
  };
  const selectStatusOption = (selectedStatus) => {
    setSelectedStatus(selectedStatus);
    setStatusDropdownOpen(false);
  };

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (sortDropdownRef.current && !sortDropdownRef.current.contains(event.target)) {
  //       setSortDropdownOpen(false);
  //     }
  //   };

  //   document.addEventListener('mousedown', handleClickOutside);

  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, []);

  const handleVenInviteSubmit = async (e) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmails = venInvEmails.every((obj) => (!obj.venInvEmail || emailRegex.test(obj.venInvEmail)));
    if (!isValidEmails) {
      toast.error("Please enter valid email addresses!!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      handleCloseVenInviteModal();
      return;
    }
    var allVenInvEmails = venInvEmails;
    if (venInvEmail !== "") {
      allVenInvEmails = [...venInvEmails, { venInvEmail: venInvEmail, id: Date.now() }];
    }
    if (!venInvEmail) {
      toast.error("Email cannot be empty!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (allVenInvEmails.some(obj => !obj.venInvEmail)) {
      toast.error("Email cannot be empty!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    try {
      setIsVenInvMailLoading(true);
      const response = await fetch("/api/vendor-invite", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          venInvEmails: allVenInvEmails.map((obj) => obj.venInvEmail),
          invite_status: "0",
        }),
      });
      const data = await response.json();
      setVenInvEmails([]);
      setVenInvEmail("");
      setIsVenInviteModalOpen(false);
      if (response.ok) {
        if (data.alreadySentVenInvEmails.length > 0 || data.alreadyRegisteredVenInvEmails.length > 0) {
          const alreadySentVenInvEmails = data.alreadySentVenInvEmails.join(", ");
          const alreadyRegisteredVenInvEmails = data.alreadyRegisteredVenInvEmails.join(", ");
          const successSentVenInvEmails = data.successSentVenInvEmails.join(", ");
          if (alreadySentVenInvEmails) {

            setAlreadySentVenInvEmailsList(data.alreadySentVenInvEmails);
            const sentVenInvEmailsList = data.alreadySentVenInvEmails.map((entry) => entry).join(", ");
            handleOpenResendVenInviteModal();
            toast.warning(`Invitation already sent to ${sentVenInvEmailsList}`, {
              position: "bottom-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          if (alreadyRegisteredVenInvEmails) {
            toast.warning(`User with email ${alreadyRegisteredVenInvEmails} is already registered`, {
              position: "bottom-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          if (successSentVenInvEmails) {
            dispatch(setNotification(Date.now()));
            toast.success(`Invitation successfully sent to ${successSentVenInvEmails}`, {
              position: "bottom-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            global.config.activityLog(
              window.location.href,
              "User Invite",
              `Invitation successfully sent to ${successSentVenInvEmails}`
            );
          }
        } else {
          const successSentVenInvEmails = data.successSentVenInvEmails.join(", ");
          dispatch(setNotification(Date.now()));
          toast.success("Mail sent successfully!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          global.config.activityLog(
            window.location.href,
            "User Invite",
            `Invitation successfully sent to ${successSentVenInvEmails}`
          );
        }
      } else {
        toast.error("Failed to send mail.", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      alert("Something went wrong");
    } finally {
      setIsVenInvMailLoading(false);
      handleCloseVenInviteModal();
    }
  };

  const handleResendVenInvEmails = async () => {
    try {
      setIsVenInvMailLoading(true);
      const response = await fetch("/api/vendor/resend-invitations", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          venInvEmails: alreadySentVenInvEmailsList, // List of emails to resend
        }),
      });

      if (response.ok) {
        // Handle success
        // Close the resend modal
        setIsResendVenInviteModalOpen(false);
        // Show a success message
        toast.success("Invitations resent successfully!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        global.config.activityLog(
          window.location.href,
          "User Invite",
          `Invitation successfully resent to ${alreadySentVenInvEmailsList}`
        );
      } else {
        // Handle failure
        // Show an error message
        toast.error("Failed to resend invitations.", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log(error);
      // Handle network or server error
      // Show an error message
      global.config.slackMessage(error.toString());
      toast.error("Failed to resend invitations.", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsVenInvMailLoading(false);
      handleCloseResendVenInviteModal();
    }
  };

  const handleAddVenInvEmail = (e) => {
    e.preventDefault();
    // if (email !== "") {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidVenInvEmails = emailRegex.test(venInvEmail);
    if (venInvEmail && !isValidVenInvEmails) {
      toast.error("Please enter valid email addresses!!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    // if (email) {
    const newVenInvEmailEntry = { venInvEmail: venInvEmail, id: Date.now() };
    setVenInvEmails([...venInvEmails, newVenInvEmailEntry]);
    // }
    setVenInvEmail("");
    // }
  };

  const handleEditVenInvEmail = (id, field, value) => {
    // Find the index of the email entry with the given id
    const index = venInvEmails.findIndex((obj) => obj.id === id);
    if (index !== -1) {
      // Create a copy of the emails array to avoid mutating state directly
      const updatedVenInvEmails = [...venInvEmails];
      // Update the specified field of the email entry
      updatedVenInvEmails[index][field] = value;
      // Update the state with the new array
      setVenInvEmails(updatedVenInvEmails);
    }
  };

  const handleRemoveVenInvEmail = (id) => {
    const filteredVenInvEmails = venInvEmails.filter((obj) => obj.id !== id);
    setVenInvEmails(filteredVenInvEmails);
  };

  const handleRemoveLatestVenInvEmail = () => {
    const updatedVenInvEmails = [...venInvEmails];
    const lastVenInvEmail = updatedVenInvEmails.pop(); // Remove the last element
    setVenInvEmails(updatedVenInvEmails);
    // Set state variables with the latest values
    setVenInvEmail(lastVenInvEmail.venInvEmail);
  };

  // Function to remove an email from the list
  const handleRemoveResendVenInvEmail = (venInvEmailToRemove) => {
    const updatedVenInvEmails = alreadySentVenInvEmailsList.filter((venInvEmail) => venInvEmail !== venInvEmailToRemove);
    setAlreadySentVenInvEmailsList(updatedVenInvEmails);
  };

  const handleOpenResendVenInviteModal = () => {
    // Set the list of already sent emails here
    setIsResendVenInviteModalOpen(true);
  };

  const handleCloseResendVenInviteModal = () => {
    setIsResendVenInviteModalOpen(false);
    setAlreadySentVenInvEmailsList([]);
  };

  const handleOpenVenInviteModal = () => {
    setIsVenInviteModalOpen(true);
  };

  const handleCloseVenInviteModal = () => {
    setIsVenInviteModalOpen(false);
    setVenInvEmails([]);
    setVenInvEmail("");
  };

  const handleUpload = async (event) => {
    event.preventDefault();
    setIsBulkImportLoading(true);
    if (!bulkImportFile) {
      toast.error("Please select a file.", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setIsBulkImportLoading(true);
      return;
    }

    const maxFileSize = 5 * 1024 * 1024;
    if (bulkImportFile.size > maxFileSize) {
      toast.error("File size exceeds the 5 MB limit.", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setIsBulkImportLoading(false);
      return;
    }

    const fileExtension = bulkImportFile.name.split(".").pop();
    if (fileExtension !== "xlsx") {
      toast.error("Only xlsx files are supported.", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setIsBulkImportLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("file", bulkImportFile);

    try {
      const response = await axios.post("/api/bulk/import/vendor", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.status === "requiredValidationError") {
        toast.error(response.data.message, {
          position: "bottom-right",
          autoClose: 5000, // Adjust as needed
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        if (response.data.existingVendorEmails && response.data.existingVendorEmails.length > 0) {
          toast.error(`The following emails are already registered: ${response.data.existingVendorEmails.join(", ")}`, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          if (response.data.filteredVendors && response.data.filteredVendors.length > 0) {
            toast.success(`Inserted ${response.data.filteredVendors.length} records`, {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        } else {
          toast.success("File uploaded successfully!", {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        handleBulkImportCloseModal();
      }
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      toast.error("Error uploading file. Please try again.", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } finally {
      setIsBulkImportLoading(false);
      if (updateVendorList === false) {
        setUpdateVendorList(true);
      } else {
        setUpdateVendorList(false);
      }
    }
  };

  const handleBulkImportOpenModal = () => {
    setIsBulkImportModalOpen(true);
  };

  const handleBulkImportCloseModal = () => {
    setBulkImportFile(null)
    setBulkImportFileName("")
    setIsBulkImportModalOpen(false);
  };

  const handleBulkImportUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setBulkImportFile(e.target.files[0])
      setBulkImportFileName(file.name)
    } else {
      setBulkImportFile(null)
      setBulkImportFileName("")
    }
  };

  const toggleSortDropdown = () => {
    setSortDropdownOpen(!sortDropdownOpen);
  };

  const selectSortOption = (selectedOption) => {
    setSelectedSortOption(selectedOption);
    setCheckedOption(selectedOption); // Set the checked option
    setSortDropdownOpen(false);
    // Don't close the dropdown here
    // Add sorting logic here based on the selectedOption
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsValidUrl(false);
    setPasswordMatchError("");
    setPasswordError("");
    const pattern = /^(ftp|http|https):\/\/[^ "]+$/;
    if (
      vendorName === ""
    ) {
      toast.error("Please fill all required fields!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (!pattern.test(companyWebsite)) {
      toast.error("It's not a valid URL.", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsValidUrl(true);
      setIsSubmitLoading(false);
      return;
    }
    if (password !== passwordConfirmation) {
      setPasswordMatchError("Passwords do not match");
      passwordConfirmationRef.current.focus();
      setIsSubmitLoading(false);
      return;
    }
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$%\^&\*\(\)_\+\-\.\/:;<=>?@[\\\]^_`{|}~])[A-Za-z\d!@#\$%\^&\*\(\)_\+\-\.\/:;<=>?@[\\\]^_`{|}~]{8,}$/;
    if (!passwordRegex.test(password)) {
      setPasswordError("Invalid password format");
      toast.error(
        "Password must be at least 8 charachters long and include atleast one uppercase letter ,one lowercase letter,one digit and one special character from the set !@#$%^&*()_+,-/:;<=>?@_'[\]{|}~", {
        position: "bottom-right",
        autoClose: 3800,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      passwordRef.current.focus();
      setIsSubmitLoading(false);
      return;
    }
    const requestData = {
      vendor_name: vendorName,
      vendor_legalentity_name: legalEntityName,
      email,
      password,
      vat_number: vatNumber,
      mobile_no: mobileNo,
      address,
      city,
      country,
      pincode,
      company_website: companyWebsite,
      additional_info: additionalInfo,
    };
    // Create a new FormData object
    const formData = new FormData();
    formData.append("company_logo", companyLogo);

    // Append the other request data to the form data
    for (let key in requestData) {
      formData.append(key, requestData[key]);
    }
    try {
      setIsSubmitLoading(true);
      const response = await axios.post("/api/vendor-details/create", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response.data;
      if (data.status === "exists") {
        toast.error("Vendor already exists", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (data.status === "error") {
        toast.error("Something went wrong", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.success("Vendor registered successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        handleCloseModal();
        global.config.activityLog(window.location.href, 'Vendor', `Vendor (${vendorName || legalEntityName}) Registered Successfully`);
      }
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error('This user is deleted!', {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      alert("Something went wrong");
    } finally {
      setIsSubmitLoading(false);
      if (updateVendorList === false) {
        setUpdateVendorList(true);
      } else {
        setUpdateVendorList(false);
      }
    }
  };

  const { data: vendorProfiles = [], isLoading, refetch: refetchVendorProfiles } = useFetchVendorDetails(token);

  const sortData = (data, selectedSortOption) => {
    switch (selectedSortOption) {
      case 'By name: A - Z':
        return data.slice().sort((a, b) => a.vendor_name.localeCompare(b.vendor_name));
      case 'By name: Z - A':
        return data.slice().sort((a, b) => b.vendor_name.localeCompare(a.vendor_name));
      case 'Date: old to new':
        return data.slice().sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
      case 'Date: new to old':
        return data.slice().sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      default:
        return data;
    }
  };

  const sortedData = sortData(vendorProfiles, selectedSortOption);

  const filteredData = sortedData.filter((item) => {
    const searchTerm = filter.trim().toLowerCase();
    const hasMatch =
      item.vendor_name.toLowerCase().includes(searchTerm) &&
      (selectedStatus === "Active" ? item.is_active : true) &&
      (selectedStatus === "Inactive" ? !item.is_active : true);
    return hasMatch;
  });

  const exportToExcel = () => {
    if (filteredData.length === 0) {
      toast.error("No data to export.", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    try {
      const now = new Date();
      const formattedDate =
        now.getFullYear() +
        "-" +
        (now.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        now.getDate().toString().padStart(2, "0");
      const formattedTime =
        now.getHours().toString().padStart(2, "0") +
        ":" +
        now.getMinutes().toString().padStart(2, "0") +
        ":" +
        now.getSeconds().toString().padStart(2, "0");

      const modifiedLog = filteredData.map((item, index) => ({
        SlNo: index + 1,
        "Commercial Name": item.vendor_name,
        "Legal Entity Name ": item.vendor_legalentity_name || "",
        "Email": item.email || "",
        "Company Url": item.company_website || "",
        "Mobile No": item.mobile_no || "",
        "Address": item.address || "",
        "Country": item.country || "",
        // "State": item.state || "",
        "City": item.city || "",
        "Pincode": item.pincode || "",
        "Company VAT Number": item.vat_number || "",
        "VAT Applicable Percentage ": item.vat_percentage || "",
        "Billing Contact Name": item.billing_contact_name || "",
        "Billing Contact Position": item.billing_contact_position || "",
        "Billing Contact Email": item.billing_contact_email || "",
        "Preferred Currency": item.preferred_currency || "USD",
        "Company": item?.vendor_wx_comapny?.wx_company || "",
        // "Billing Mobile No": item.billing_mobile_no || "",
        "POC User Name": item.poc_user_name || "",
        "POC Email": item.poc_email || "",
        "POC User Position": item.poc_user_position || "",
        "Additional Info": item.additional_info || "",
        "Is Active": item.is_active ? "Yes" : "No",
        "Created At": moment(item.created_at).tz(global.config.getTimeZone()).format("DD/MM/YYYY, hh:mm:ss A"),
      }));

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(modifiedLog);
      const colWidths = [
        { wpx: 25 }, { wpx: 100 }, { wpx: 150 }, { wpx: 100 }, { wpx: 150 },
        { wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 150 },
        { wpx: 100 }, { wpx: 150 }, { wpx: 150 }, { wpx: 150 }, { wpx: 100 },
        { wpx: 100 }, { wpx: 100 }, { wpx: 150 }, { wpx: 100 }, { wpx: 100 },
        { wpx: 75 }, { wpx: 75 }, { wpx: 75 }, { wpx: 150 }
      ];
      worksheet["!cols"] = colWidths;

      // Add alignment and font styling for headers
      const firstRow = XLSX.utils.decode_range(worksheet["!ref"]).s.r;
      for (let i = 0; i < colWidths.length; i++) {
        const cell = XLSX.utils.encode_cell({ r: firstRow, c: i });
        const style = worksheet[cell]?.s || {};
        style.alignment = style.alignment || {};
        style.alignment.horizontal = "center";
        style.alignment.vertical = "center";
        style.font = { size: 14, bold: true };
        worksheet[cell] = { ...worksheet[cell], s: style };
      }

      // Set font size for the data rows
      const range = XLSX.utils.decode_range(worksheet["!ref"]);
      for (let row = range.s.r + 1; row <= range.e.r; row++) {
        for (let col = range.s.c; col <= range.e.c; col++) {
          const cell = XLSX.utils.encode_cell({ r: row, c: col });
          const style = worksheet[cell]?.s || {};
          style.font = { size: 12 };
          worksheet[cell] = { ...worksheet[cell], s: style };
        }
      }

      const fileName = `Vendors_${formattedDate}_${formattedTime}.xlsx`;
      worksheet["!autofilter"] = { ref: range };
      XLSX.utils.book_append_sheet(workbook, worksheet, "Vendors Log");
      XLSX.writeFile(workbook, fileName);
    } catch (error) {
      console.error(error);
      global.config.slackMessage(error.toString());
      global.config.activityLog(window.location.href, "Vendors Log", "Error occurred while exporting to Excel");
      alert("Error occurred while exporting to Excel.");
    }
  };


  return (
    <div className="time-client-outer client-top-overview-outer">
      <div className="client-top-overview-name-btn">
        <div className="title-client-all">Select a vendor</div>
        <div className="outer-client-overview-sec">
          <div className="invite-client-outer invite-client-outer-client-overview">
            <a className="btn-invite-client" onClick={handleOpenVenInviteModal}>
              <span className="icon-invite">
                <img src={InviteIcon} alt="invite" />
              </span>
              Invite Vendor
            </a>

            <Modal
              isOpen={isVenInviteModalOpen}
              onRequestClose={!isVenInvMailLoading ? handleCloseVenInviteModal : undefined}
              contentLabel="Invite a User"
              className="user-invite-modal-main-outer"
              style={{
                content: {
                  height: "41%",
                  width: "30%",
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-25%, -50%)",
                },
              }}
            >
              <div className="modal">
                <div className="modal-top-section-user-invite">
                  <div className="modal-invite-head">
                    <h2 className="">Invite a new Vendor</h2>
                  </div>
                  <div className="modal-close-invite outter-modal-main outter-modal-main-user">
                    <a className="" onClick={handleCloseVenInviteModal} disabled={isVenInvMailLoading}>
                      <img src={crssrImage} alt="Edit Icon" />{" "}
                    </a>
                  </div>

                </div>

                <form onSubmit={handleVenInviteSubmit}>
                  <div>
                    {venInvEmails.map((obj) => (
                      <div key={obj.id} className="the-modal-inuser">

                        <div className="user-add-outer-innner">
                          <div className="invite-user">
                            <label htmlFor="grid-password" className="">
                              Email Address
                            </label>
                            <input
                              type="email"
                              id={`email-${obj.id}`}
                              className=""
                              name="email"
                              required
                              placeholder="Enter Email ID"
                              value={obj.venInvEmail}
                              onChange={(e) => handleEditVenInvEmail(obj.id, 'venInvEmail', e.target.value)}
                            />
                          </div>
                          <div
                            className="invite-user-delete"
                            onClick={() => handleRemoveVenInvEmail(obj.id)}
                          >
                            <img src={dletImage} alt="delete-icon" />{" "}
                          </div>
                        </div>

                      </div>
                    ))}
                    <div className="the-modal-inuser">
                      <div className="user-add-outer-innner">
                        <div className="invite-user">
                          <label htmlFor="grid-password" className="">
                            Email Address
                          </label>
                          <input
                            type="email"
                            name="email"
                            className=""
                            placeholder="Enter Email ID"
                            value={venInvEmail}
                            onChange={(e) => setVenInvEmail(e.target.value)}
                          />
                        </div>
                        {venInvEmails.length > 0 && (
                          <div className="invite-user-delete" onClick={handleRemoveLatestVenInvEmail}>
                            <img src={dletImage} alt="delete-icon" />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="add-email-sec-modal" onClick={handleAddVenInvEmail} style={{ cursor: "pointer" }}>
                      <img src={addicon} alt="Edit Icon" /><div className="add-icon-main-text">Add new email</div>
                    </div>
                  </div>
                  <div className="invite-mail-popup-btn" >
                    <button type="submit" className="" disabled={isVenInvMailLoading}>
                      {isVenInvMailLoading ?
                        <div className="outter-load-table">
                          <ThreeDots
                            height="18"
                            width="20"
                            radius="9"
                            color="white"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </div> : "Send Invite"}
                    </button>
                  </div>
                </form>
              </div>
            </Modal>

            <Modal
              isOpen={isResendVenInviteModalOpen}
              onRequestClose={!isVenInvMailLoading ? handleCloseResendVenInviteModal : undefined}
              contentLabel="Resend Invitations"
              style={{
                content: {
                  height: "41%",
                  width: "30%",
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-25%, -50%)",
                },
              }}
            >
              <div className="modal">
                <div className="modal-close-invite outter-modal-main">
                  <a
                    className=""
                    onVenck={handleCloseResendVenInviteModal}
                    disabled={isVenInvMailLoading}
                  >
                    <img src={crssrImage} alt="Edit Icon" />{" "}
                  </a>
                </div>
                <div className="modal-invite-head">
                  <h2 className="">Resend Invitations</h2>
                </div>
                {/* List the already sent emails here */}
                <div>
                  <div className="the-modal-inuser">
                    <label htmlFor="grid-password" className="">
                      Email
                    </label>
                    {alreadySentVenInvEmailsList.map((email) => (
                      <div key={email} className="the-modal-inusers">

                        <div className="resend-invitation">
                          <input
                            type="email"
                            className=""
                            name="email"
                            required
                            placeholder="Enter Email ID"
                            value={email}
                            disabled
                          />
                          {alreadySentVenInvEmailsList.length > 1 && (
                            <button className="del-resend" onClick={() => handleRemoveResendVenInvEmail(email)}>
                              <img src={dletImage} alt="delete-icon" />{" "}
                            </button>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="sprint-btn-outer resend-invoice">
                  <button
                    type="button"
                    onVenck={handleCloseResendVenInviteModal}
                    disabled={isVenInvMailLoading}
                    className="submit-client cancel-sprint"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="submit-client save-sprint"
                    disabled={isVenInvMailLoading}
                    onClick={handleResendVenInvEmails}
                  >
                    {isVenInvMailLoading ?
                      <div className="outter-load-table">
                        <ThreeDots
                          height="18"
                          width="20"
                          radius="9"
                          color="white"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div> : "Resend Invitations"}
                  </button>
                </div>
              </div>
            </Modal>

            {/* <a className="btn-invite-client" onClick={handleBulkImportOpenModal}>
              <span className="icon-invoice">
                <img src={DownloadImage} alt="Export" />
              </span>
              Bulk Import
            </a>

            <Modal
              isOpen={isBulkImportModalOpen}
              onRequestClose={!isBulkImportLoading ? handleBulkImportCloseModal : undefined}
              contentLabel="Invite a User"
              className="bulk-import-popup"
              style={{
                content: {
                  height: "50%",
                  width: "50%",
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-17.5%, -46%)",
                },
              }}
            >
              <div className="modal">
                <div className="modal-top-section-user-invite">
                  <div className="modal-invite-head">
                    <h2 className="">Bulk Import</h2>
                  </div>
                  <div className="modal-close-invite outter-modal-main outter-modal-main-user">
                    <a className="" onClick={handleBulkImportCloseModal} disabled={isBulkImportLoading}>
                      <img src={crssrImage} alt="Close Bulk Import" />{" "}
                    </a>
                  </div>
                </div>

                <form>
                  <div className="relative w-full mb-3" >
                    <div className="file-input-wrapper">
                      <input
                        type="text"
                        className="form-input form-input-file"
                        value={bulkImportFileName}
                        readOnly
                      />
                      <input
                        type="file"
                        id="expFileAttchEdit"
                        name="expFileAttchEdit"
                        className="form-input hidden-input"
                        onChange={handleBulkImportUpload}
                      // required
                      />
                      <label
                        htmlFor="expFileAttch"
                        className="file-input-label"
                      >
                        <img src={Plus} alt="Browse" />Browse
                      </label>
                    </div>
                    <div className="text-sm bulk-text">
                      Demo File: {"    "}
                      <a
                        href="https://docs.google.com/spreadsheets/d/1u5GFHWbJkYVFn0k2X0myPw-nZUinKhcLzCsePbpuVD4/edit?usp=sharing"
                        className="form-textarea"
                        target="_blank"
                      >
                        Import_demo.xlsx
                      </a>
                    </div>
                  </div>

                  <div className="bulk-btn-section sprint-btn-outer">
                    <button
                      className="submit-client cancel-sprint"
                      type="button"
                      onClick={handleBulkImportCloseModal}
                      disabled={isBulkImportLoading}
                    >
                      Close
                    </button>
                    <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={handleUpload}
                      disabled={isBulkImportLoading}
                    >
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </Modal> */}

          </div>


          <div className="client-new-btn">


            <a className="btn-invite-client bulk-export" onClick={exportToExcel}>
              <span className="icon-invoice">
                <img src={DownloadImage} alt="Export" />
              </span>
              Export Data
            </a>


            {/* <a className="btn-invite-client bulk-import" onClick={handleBulkImportOpenModal}>
              <span className="icon-invoice">
                <img src={DownloadImage} alt="Export" />
              </span>
              Bulk Import
            </a> */}

            <Modal
              isOpen={isBulkImportModalOpen}
              onRequestClose={!isBulkImportLoading ? handleBulkImportCloseModal : undefined}
              contentLabel="Invite a User"
              className="bulk-import-popup"
              style={{
                content: {
                  height: "50%",
                  width: "50%",
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-17.5%, -46%)",
                },
              }}
            >
              <div className="modal">
                <div className="modal-top-section-user-invite">
                  <div className="modal-invite-head">
                    <h2 className="">Bulk Import</h2>
                  </div>
                  <div className="modal-close-invite outter-modal-main outter-modal-main-user">
                    <a className="" onClick={handleBulkImportCloseModal} disabled={isBulkImportLoading}>
                      <img src={crssrImage} alt="Close Bulk Import" />{" "}
                    </a>
                  </div>
                </div>

                <form>
                  <div className="relative w-full mb-3" >
                    <div className="file-input-wrapper">
                      <input
                        type="text"
                        className="form-input form-input-file"
                        value={bulkImportFileName}
                        readOnly
                      />
                      <input
                        type="file"
                        id="expFileAttchEdit"
                        name="expFileAttchEdit"
                        className="form-input hidden-input"
                        onChange={handleBulkImportUpload}
                      // required
                      />
                      <label
                        htmlFor="expFileAttch"
                        className="file-input-label"
                      >
                        <img src={Plus} alt="Browse" />Browse
                      </label>
                    </div>
                    <div className="text-sm bulk-text">
                      Demo File: {"    "}
                      <a
                        href="https://docs.google.com/spreadsheets/d/1u5GFHWbJkYVFn0k2X0myPw-nZUinKhcLzCsePbpuVD4/edit?usp=sharing"
                        className="form-textarea"
                        target="_blank"
                      >
                        Import_demo.xlsx
                      </a>
                    </div>
                  </div>

                  <div className="bulk-btn-section sprint-btn-outer">
                    <button
                      className="submit-client cancel-sprint"
                      type="button"
                      onClick={handleBulkImportCloseModal}
                      disabled={isBulkImportLoading}
                    >
                      Close
                    </button>
                    <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={handleUpload}
                      disabled={isBulkImportLoading}
                    >
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </Modal>
          </div>

          <div className="client-new-btn">
            <a className="new-client" onClick={handleOpenModal}>
              <span className="icon-invoice">
                <img src={PlusIcon} alt="New Vendor" />
              </span>
              New Vendor
            </a>

            <Modal
              className="client-modal"
              isOpen={isModalOpen}
              onRequestClose={handleCloseModal}
              contentLabel="New Vendor Account"
              style={{
                content: {
                  height: "41%",
                  width: "30%",
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  parent: document.querySelector(".admin-outer.time.tracker"),
                },
              }}
            >
              <div className="modal">
                <div className="modal-close-invite outter-modal-main">
                  <a className="" onClick={handleCloseModal}>
                    <img src={crssrImage} alt="Edit Icon" />{" "}
                  </a>
                </div>
                <div className="modal-client-head">
                  <h2 className="">New Vendor Account</h2>
                </div>
                <div className="form-field-client-outer profile-details-edit">
                  <form onSubmit={handleSubmit}>
                    <div className="form-field-client">
                      <label htmlFor="vendorName" className="form-label">
                        Commercial Name
                      </label>
                      <input
                        type="text"
                        id="vendorName"
                        name="vendorName"
                        className="form-input"
                        value={vendorName}
                        onChange={(e) => setVendorName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-field-client">
                      <label htmlFor="legalEntityName" className="form-label">
                        Legal Entity Name
                      </label>
                      <input
                        type="text"
                        id="legalEntityName"
                        name="legalEntityName"
                        className="form-input"
                        value={legalEntityName}
                        onChange={(e) => setLegalEntityName(e.target.value)}
                      />
                    </div>
                    <div className="form-field-client">
                      <label htmlFor="emailAddress" className="form-label">
                        Email
                      </label>
                      <input
                        autoComplete="off"
                        type="email"
                        id="vendorEmailAddress"
                        name="vendorEmailAddress"
                        className="form-input"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-field-client">
                      <label htmlFor="password" className="form-label">
                        Password
                      </label>
                      <div className="inner-detail-inner pass-eye">
                        <input
                          type={showPassword ? "text" : "password"}
                          autoComplete="off"
                          id="vendorPassword"
                          name="vendorPassword"
                          className="form-input"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                        <img
                          src={showPassword ? EyeCrossed : EyeIcon}
                          className="password-toggle-icon"
                          alt="Toggle Password"
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      </div>
                      {passwordError && (
                        <span>{passwordError}</span>
                      )}
                    </div>
                    <div className="form-field-client">
                      <label htmlFor="confpassword" className="form-label">
                        Confirm Password
                      </label>
                      <input
                        type="password"
                        id="confpassword"
                        name="confpassword"
                        className="form-input"
                        value={passwordConfirmation}
                        onChange={(e) => setPasswordConfirmation(e.target.value)}
                        autoComplete="off"
                        required
                      />
                      {passwordMatchError && (
                        <span style={{ color: "red" }}> {passwordMatchError} </span>
                      )}
                    </div>
                    <div className="form-field-client">
                      <label htmlFor="vatNumber" className="form-label">
                        Tax ID number
                      </label>
                      <input
                        type="text"
                        id="vatNumber"
                        name="vatNumber"
                        className="form-input"
                        value={vatNumber}
                        onChange={(e) => setVatNumber(e.target.value)}
                      />
                    </div>
                    <div className="form-field-client">
                      <label htmlFor="companyLogo" className="form-label">
                        {global.config.locate(appSettings && appSettings.language, 'Company Logo')}
                      </label>
                      <div className="file-input-wrapper">
                        <input
                          type="text"
                          className="form-input form-input-file"
                          value={companyLogoName}
                          readOnly
                        />
                        <input
                          type="file"
                          id="companyLogo"
                          name="companyLogo"
                          className="form-input hidden-input"
                          accept="image/*"
                          onChange={handleLogoUpload}
                          required
                        />
                        <label
                          htmlFor="companyLogo"
                          className="file-input-label"
                        >
                          <img src={Plus} alt="Browse" />Browse
                        </label>
                      </div>
                    </div>
                    <div className="form-field-client">
                      <label htmlFor="address" className="form-label">
                        {global.config.locate(appSettings && appSettings.language, 'Address')}
                      </label>
                      <input
                        type="text"
                        id="address"
                        name="address"
                        className="form-input"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                    <div className="form-field-client">
                      <label htmlFor="city" className="form-label">
                        {global.config.locate(appSettings && appSettings.language, 'City')}
                      </label>
                      <input
                        type="text"
                        id="city"
                        name="city"
                        className="form-input"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                    </div>
                    <div className="form-field-client">
                      <label htmlFor="country" className="form-label">
                        {global.config.locate(appSettings && appSettings.language, 'Country')}
                      </label>
                      <div ref={countryDropdownRef} className="select-modal-mainM custom-dropdown-user-new-outer">
                        <span
                          id="country"
                          onClick={handleToggleCountryDropdown}
                          className="custom-dropdown-user-new"
                        >
                          {country ? country : (appSettings && appSettings.language ? global.config.locate(appSettings.language, 'Select Country') : null)}
                        </span>
                        {countryDropdownOpen && (
                          <div className="custom-dropdown-new">
                            <input
                              type="text"
                              className="search-bar"
                              placeholder={global.config.locate(appSettings && appSettings.language, 'Search')}
                              value={countrySearchTerm}
                              onChange={handleCountrySearchInputChange}
                            />
                            <ul className="country-list">
                              {filteredCountries.length > 0 &&
                                filteredCountries.map((c) => (
                                  <li
                                    key={c.country}
                                    className={country === c.country ? 'selected-item' : ''}
                                    onClick={() => {
                                      setCountry(c.country);
                                      setCountryDropdownOpen(false);
                                    }}
                                  >
                                    {c.country}
                                  </li>
                                ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-field-client">
                      <label htmlFor="pincode" className="form-label">
                        Postal Code
                      </label>
                      <input
                        type="text"
                        id="pincode"
                        name="pincode"
                        className="form-input"
                        value={pincode}
                        onChange={(e) => setPincode(e.target.value)}
                      />
                    </div>
                    <div className="inner-detail-inner form-field-client">
                      <label htmlFor="mobileNo" className="form-label">
                        Phone No.
                      </label>
                      <PhoneInput
                        placeholder="Enter phone number"
                        value={mobileNo}
                        onChange={setMobileNo}
                      />
                    </div>
                    <div className="form-field-client">
                      <label htmlFor="companyWebsite" className="form-label">
                        {global.config.locate(appSettings && appSettings.language, 'Company Website')}
                      </label>
                      <input
                        type="text"
                        id="companyWebsite"
                        name="companyWebsite"
                        className="form-input"
                        value={companyWebsite}
                        onChange={(e) => setCompanyWebsite(e.target.value)}
                      />
                      {isValidUrl && (
                        <span className="text-red-500 text-xs italic">
                          Please enter a valid URL!!
                        </span>
                      )}
                    </div>
                    <div className="form-field-client">
                      <label
                        htmlFor="additionalInformation"
                        className="form-label"
                      >
                        {global.config.locate(appSettings && appSettings.language, 'Additional Information')}
                      </label>
                      <textarea
                        id="additionalInformation"
                        name="additionalInformation"
                        className="form-textarea"
                        rows="4"
                        value={additionalInfo}
                        onChange={(e) => setAdditionalInfo(e.target.value)}
                      ></textarea>
                    </div>
                    <button type="submit" className="submit-client" disabled={isSubmitLoading}>
                      {isSubmitLoading ?
                        <div className="outter-load-table">
                          <ThreeDots
                            height="18"
                            width="20"
                            radius="9"
                            color="white"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </div> : 'Create Vendor'}
                    </button>
                  </form>
                </div>
              </div>
            </Modal>

          </div>
        </div>
      </div>

      <div className="top-filter-client">
        <div className="top-filter-client-inner">
          <div className="top-filter-client-inner-left">
            <div className="top-filter-client-status">
              <label className="top-filter-client-status-label">Status</label>
              <div className="custom-dropdown">
                <div ref={statusDropdownRef} className={`custom-dropdown-user-new-outer custom-dropdown-select ${statusDropdownOpen ? 'open' : ''}`}>
                  <div className="selected-option custom-dropdown-user-new" onClick={toggleStatusDropdown}>
                    {selectedStatus ? global.config.locate(appSettings && appSettings.language, selectedStatus) : (appSettings && appSettings.language ? global.config.locate(appSettings.language, 'ALL') : null)}
                  </div>
                  {statusDropdownOpen && (
                    <div className="custom-dropdown-new">
                      <ul className="type-list">
                        <li onClick={() => selectStatusOption(null)}>All</li>
                        <li onClick={() => selectStatusOption('Active')}>{global.config.locate(appSettings && appSettings.language, 'Active')}</li>
                        <li onClick={() => selectStatusOption('Inactive')}>{global.config.locate(appSettings && appSettings.language, 'Inactive')}</li>
                        {/* Add more Status options as needed */}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="title-client-all-mobile">Select a vendor</div>
            <div className="top-filter-client-search">
              <div className="search-sec">
                <div className="search-container">
                  <input type="text" className="search-input" placeholder='Search by name' onChange={(e) => setFilter(e.target.value)} value={filter} />
                </div>
              </div>
            </div>
          </div>
          <div className="top-filter-client-sort">
            <div className="custom-dropdown">
              <div ref={sortDropdownRef} className={`custom-dropdown-user-new-outer custom-dropdown-select ${sortDropdownOpen ? 'open' : ''}`}>
                <div className="selected-option custom-dropdown-user-new" onClick={toggleSortDropdown}>
                <div className="center-sort">
                  {selectedSortOption ? global.config.locate(appSettings && appSettings.language, selectedSortOption) : (appSettings && appSettings.language ? global.config.locate(appSettings.language, 'Sort By') : null)}
                  </div>
                </div>
                {sortDropdownOpen && (
                  <div className="custom-dropdown-new">
                    <ul className="type-list">
                      <li className={checkedOption === 'By name: A - Z' ? 'selected' : ''} onClick={() => selectSortOption('By name: A - Z')}>
                        <span className={`checkbox ${checkedOption === 'By name: A - Z' ? 'checked' : ''}`}></span>
                        {global.config.locate(appSettings && appSettings.language, 'By name: A - Z')}
                      </li>
                      <li className={checkedOption === 'By name: Z - A' ? 'selected' : ''} onClick={() => selectSortOption('By name: Z - A')}>
                        <span className={`checkbox ${checkedOption === 'By name: Z - A' ? 'checked' : ''}`}></span>
                        {global.config.locate(appSettings && appSettings.language, 'By name: Z - A')}
                      </li>
                      <li className={checkedOption === 'Date: old to new' ? 'selected' : ''} onClick={() => selectSortOption('Date: old to new')}>
                        <span className={`checkbox ${checkedOption === 'Date: old to new' ? 'checked' : ''}`}></span>
                        {global.config.locate(appSettings && appSettings.language, 'Date: old to new')}
                      </li>
                      <li className={checkedOption === 'Date: new to old' ? 'selected' : ''} onClick={() => selectSortOption('Date: new to old')}>
                        <span className={`checkbox ${checkedOption === 'Date: new to old' ? 'checked' : ''}`}></span>
                        {global.config.locate(appSettings && appSettings.language, 'Date: new to old')}
                      </li>
                      {/* Add more sorting options as needed */}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="client-ls-sec">
        <VendorsOverviewListing vendorProfiles={vendorProfiles} isLoading={isLoading} refetchVendorProfiles={refetchVendorProfiles} filter={filter} updateVendorList={updateVendorList} selectedSortOption={selectedSortOption} selectedStatus={selectedStatus} />
      </div>

    </div>
  );
};

export default VendorOverviewSection;