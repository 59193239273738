import axios from 'axios';
import { useQuery, useMutation } from 'react-query';
import { useMemo } from 'react';


export function useFetchProjectDetails(token) {
  const queryKey = useMemo(() => 'projectDetails', []);

  const fetchFunction = useMemo(
    () => async () => {
      const response = await axios.get('/api/project-details', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    },
    [token]
  );

  return useQuery(queryKey, fetchFunction, {
    enabled: !!token, // Only fetch if token is available
    staleTime: 1000 * 60 * 2, // Data is fresh for 5 minutes
  });
}

// ['projectDetailsOptimized', projectIds, startDate, endDate, page, limit]
export function useFetchProjectDetailsOptimized(token, selectedProjects, startDate, endDate, page, limit) {
  return useQuery('projectDetailsOptimized', async () => {
    const response = await axios.post(`/api/project-details-optimized`,
      {
        page: page,
        limit: limit,
        selectedProjects, 
        startDate, 
        endDate,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    return response.data;
  });
}

export function useFetchProjectPrintOptimized(token, selectedProjects, startDate, endDate) {
  return useQuery('projectPrintOptimized', async () => {
    const response = await axios.post(`/api/project/export/optimized`,
      {
        selectedProjects, 
        startDate, 
        endDate,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    return response.data;
  });
}


export function useFetchProjectManager(token) {
  const queryKey = useMemo(() => 'projectManagers', []);

  const fetchFunction = useMemo(() => async () => {
    const response = await axios.get('/api/user/user-project-manager', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }, [token]);

  return useQuery(queryKey, fetchFunction, {
    enabled: !!token, // Only fetch if the token is available
    staleTime: 1000 * 60 * 2, // Data is considered fresh for 2 minutes
    refetchInterval: 1000 * 60 * 2, // Automatically refetch every 2 minutes
  });
}

export function useFetchClientProjectManager(token, clientId) {
  const queryKey = useMemo(() => ['clientProjectManagers', clientId], [clientId]);

  const fetchFunction = useMemo(() => async () => {
    const response = await axios.get(`/api/client/get-project-managers/${clientId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.project_managers;
  }, [token, clientId]);

  return useQuery(queryKey, fetchFunction, {
    enabled: !!token && !!clientId,
  });
}

export function useFetchClientDetails(token) {
  const queryKey = useMemo(() => 'clientDetails', []);

  const fetchFunction = useMemo(() => async () => {
    const response = await axios.get('/api/client-details', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }, [token]);

  return useQuery(queryKey, fetchFunction, {
    enabled: !!token,
  });
}

export function useFetchSprintProjectDetails(token, projectId) {
  const queryKey = useMemo(() => ['sprintProjectDetails', projectId], [projectId]);

  const fetchFunction = useMemo(() => async () => {
    const response = await axios.get(`/api/sprintProject-details/${projectId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }, [token, projectId]);

  return useQuery(queryKey, fetchFunction, {
    enabled: !!token && !!projectId,
  });
}

export function useDeleteProjectDetails(token, selectedId) {
  const mutation = useMutation(async () => {
    const response = await axios.get(`/api/project-details/delete/${selectedId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  });

  return mutation;
}

export function useUpdateProjectDetails(token, selectedId, requestData) {
  const mutation = useMutation(async () => {
    const response = await axios.put(`/api/project-details/update/${selectedId}`, requestData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  });

  return mutation;
}

export function useDeleteSprintDetails(token, selectedSprintId) {
  const mutation = useMutation(async () => {
    const response = await axios.get(`/api/sprint-details/delete/${selectedSprintId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  });

  return mutation;
}

export function useUpdateSprintDetails(token, selectedSprintId, requestData) {
  const mutation = useMutation(async () => {
    const response = await axios.put(`/api/sprint-details/update/${selectedSprintId}`, requestData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  });

  return mutation;
}