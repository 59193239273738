import axios from "axios";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState, useRef } from "react";
import ticno from "../../../../../images/team-member-tab/table-no.svg";
import ticyes from "../../../../../images/team-member-tab/table-yes.svg";
import editimgblue from "../../../../../images/team-member-tab/edit-btn-blue.svg";
import { setNotification } from "../../../../../features/notification/notificationSlice.js";
import { setProjectAdded } from "../../../../../features/projects/projectSlice.js";
import { useUpdateProjectCost, useUserProjectCost } from "../../../../../components/TimeTracker/TimeTrackerTeam/TimeTrackerTeamMembers/teamMemberApi.jsx";

export default function ProjectCost(props) {
  const dispatch = useDispatch();
  const [cost, setCost] = useState(0);
  const billableRateEditRef = useRef(null);
  // const [selectedId, setSelectedId] = useState("");
  const [editableRow, setEditableRow] = useState(-1);
  const { user } = useSelector((state) => state.auth);
  const token = user.token;
  // const [billableRateEdit, setBillableRateEdit] = useState("");
  const [loading, setLoading] = useState(true);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const updateBillableRateMutation = useUpdateProjectCost(token);
  const { projectAdded } = useSelector((state) => state.project);
  const notifyUpdate = useSelector((state) => state.notification.notificationState)
  // const [sortedUserProfiles, setSortedUserProfiles] = useState([]);
  // const { data: userProjectCost = [], isLoading: loading, refetch: refetchTotalCost, isError } = useUserProjectCost(props.projectId, props.item._id, token);

  useEffect(() => {
    const getData = async () => {
      try {
        // setLoading(true);
        const response = await axios.post(
          "/api/project-user-costs",
          { projectId: props.projectId, userId: props.item._id },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCost(response.data.amount);
      } catch (error) {
        console.error("Error fetching data:", error);
        global.config.slackMessage(error.toString());
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, [props.item._id, props.projectId, projectAdded, notifyUpdate, token]);

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    if (!cost) {
      toast.error("Billable cost cannot be empty!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      billableRateEditRef.current.focus();
      return;
    }

    try {
      setIsUpdateLoading(true);
      const response = await updateBillableRateMutation.mutateAsync({
        projectId: props.projectId,
        userId: props.item._id,
        amount: cost,
      });
      const data = response.data;

      if (data.status === "error") {
        toast.error("Something went wrong", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.success("Billable rate updated successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // global.config.activityLog(window.location.href, 'Leave Type', `Leave Type updated successfully`);
        setEditableRow(-1);
        dispatch(setNotification(Date.now()));
        dispatch(setProjectAdded());
        // setSortedUserProfiles((prevState) => {
        //   const updatedInvoiceView = prevState.map((item) => {
        //     if (item._id === selectedId) {
        //       return { ...item, billable_cost: billableRateEdit };
        //     }
        //     return item;
        //   });
        //   return updatedInvoiceView;
        // });
      }
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        return;
      }
      alert("Something went wrong");
    } finally {
      setIsUpdateLoading(false);
    }
  };

  const cancelChanges = () => {
    setEditableRow(-1);
  };

  const handleEdit = (item, index) => {
    setEditableRow(index);
    // setSelectedId(item._id);
    // setBillableRateEdit(item.billable_cost);
  };

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  return (
    <>
      <div className="edit-value-changebtn-tic">
        {editableRow === props.item._id ? (
          <input
            type="number"
            value={cost}
            onChange={(e) => setCost(e.target.value)}
            ref={billableRateEditRef}
            onWheel={numberInputOnWheelPreventChange}
          />
        ) : (
          loading ? (
            <ThreeDots
              height="18"
              width="20"
              radius="9"
              color="#6479f9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          ) : (
            // `${cost} (${props.item.payment_currency})`
            `${cost ?
              new Intl.NumberFormat('en-US', { style: 'currency', currency: props.clientPreferredCurrency }).format(cost)
              :
              new Intl.NumberFormat('en-US', { style: 'currency', currency: props.clientPreferredCurrency }).format(0.00)
            }`
          )
        )}
        {editableRow === props.item._id ? (
          <>
            <div className="hello-boom-open">
              {isUpdateLoading ? (
                <ThreeDots
                  height="18"
                  width="20"
                  radius="9"
                  color="#6479f9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              ) : (
                <>
                  <button className="sep-pop-btn" onClick={handleUpdateSubmit}>
                    <img src={ticyes} alt="yes" />
                  </button>
                  <button className="sep-pop-btn" onClick={() => cancelChanges()}>
                    <img src={ticno} alt="no" />
                  </button>
                </>
              )}
            </div>
          </>
        ) : (
          <button
            className="main-edit-btn-parent"
            onClick={() => handleEdit(props.item, props.item._id)}
          >
            <img src={editimgblue} alt="edit" />
            Edit
          </button>
        )}
      </div>
    </>
  );
}
