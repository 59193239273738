import "./profileSetting.css";
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setHeaderText } from "../../../features/headerText/headerTextSlice";
import UserProfile from "../../../components/User/UserDetails/UserProfile/UserProfile";
import UserAccount from '../../../components/User/UserDetails/UserAccount/UserAccount';
import UserSecurity from '../../../components/User/UserDetails/UserSecurity/UserSecurity';
import UserNotification from '../../../components/User/UserDetails/UserNotification/UserNotification';
import Subscription from '../../../pages/subscription/subscriptionOverview';
import UserNotificationCenter from "../../../components/User/UserDetails/UserNotificationCenter/UserNotificationCenter";
import userCategory from "../../../constants/userCategory";
import VendorUserProfile from "../../../components/Vendors/VendorUserProfile/VendorUserProfile";
import ClientUserProfile from "../../../components/Clients/ClientUserProfile/ClientUserProfile";

function ProfileSettings() {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const user = useSelector(
    (state) => state.auth.user
  );
  const id = user._id;
  const venId = user.venId;
  const cliId = user.cliId;
  const handleTabClick = (index) => {
    setActiveTab(index);
    localStorage.setItem('activeTab', String(index));
  };
  useEffect(() => {
    const storedActiveTab = localStorage.getItem('activeTab');
    if (storedActiveTab) {
      setActiveTab(Number(storedActiveTab));
    }
    dispatch(setHeaderText("Profile Settings"));
  }, [id, dispatch]);

  return (
    <div>
      <div className='tab-outer-tab-account'>
        <ul className="tabs">
          <li
            className={activeTab === 0 ? 'active-tab' : ''}
            onClick={() => handleTabClick(0)}
          >
            Profile
          </li>
          {(user && (user.userCategory !== userCategory.Client)) && (
            <li
              className={activeTab === 1 ? 'active-tab' : ''}
              onClick={() => handleTabClick(1)}
            >
              Bank Accounts
            </li>
          )}

          <li
            className={activeTab === 2 ? 'active-tab' : ''}
            onClick={() => handleTabClick(2)}
          >
            Security
          </li>

          <li
            className={activeTab === 3 ? 'active-tab' : ''}
            onClick={() => handleTabClick(3)}
          >
            Preferences
          </li>

          <li
            className={activeTab === 5 ? 'active-tab' : ''}
            onClick={() => handleTabClick(5)}
          >
            Subscription
          </li>

          {(user && (user.userCategory !== userCategory.Client)) && (
            <li
              className={activeTab === 4 ? 'active-tab' : ''}
              onClick={() => handleTabClick(4)}
            >
              Notification Center
            </li>
          )}

        </ul>

        <div className="tabs-content account-settings-tab">
          <div className='company-profile' style={{ display: activeTab === 0 ? 'block' : 'none' }}>
            {user && user.userCategory === userCategory.Vendor ? (
              <VendorUserProfile id={venId} />
            ) : user && user.userCategory === userCategory.Client ? (
              <ClientUserProfile id={cliId} />
            ) : (
              <UserProfile id={id} />
            )}
          </div>
          {(user && (user.userCategory !== userCategory.Client)) && (
            <div style={{ display: activeTab === 1 ? 'block' : 'none' }}>
              <UserAccount id={id} />
            </div>
          )}
          <div className="user-security" style={{ display: activeTab === 2 ? 'block' : 'none' }}>
            <UserSecurity id={id} />
          </div>
          <div className="prferences" style={{ display: activeTab === 3 ? 'block' : 'none' }}>
            <UserNotification id={id} />
          </div>
          <div className="subscription" style={{ display: activeTab === 5 ? 'block' : 'none' }}>
            <Subscription id={id} />
          </div>
          {(user && (user.userCategory !== userCategory.Client)) && (
            <div className="prferences" style={{ display: activeTab === 4 ? 'block' : 'none' }}>
              <UserNotificationCenter id={id} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default ProfileSettings;