import axios from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';

const API_BASE_URL = '/api';
// Fetch time-off requests
export const useTimeOffRequests = (token) => {
    return useQuery('timeOffRequests', async () => {
        const response = await axios.get(`${API_BASE_URL}/time-off-request/view`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    });
};

export const useTimeOffRequestsOptimized = ({
    token,
    currentPage,
    limit,
    startDate,
    endDate,
    users,
    status,
    filter,
}) => {
    return useQuery(
        [
            'timeOffRequestsOptimized',
            { currentPage, limit, startDate, endDate, users, status, filter, },
        ],
        async () => {
            const response = await axios.post(
                `${API_BASE_URL}/time-off-request/view-optimize`,
                {
                    page: currentPage,
                    limit,
                    startDate,
                    endDate,
                    users,
                    status,
                    filter,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            return response.data;
        },
        {
            keepPreviousData: true, // Retain previous data while fetching new data
            staleTime: 1000 * 60,   // Data is considered fresh for 1 minute
        }
    );
};


export const getTimeOff = async (token, currentPage, limit, startDate, endDate, users, status, filter) => {


    try {

        const response = await axios.post(
            `${API_BASE_URL}/time-off-request/view-optimize`,
            {
                page: currentPage,
                limit,
                startDate,
                endDate,
                users,
                status,
                filter,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response.data;

    } catch (error) {


        console.log(error);

    }


}

// Fetch user by ID
export const useUserById = (token) => {
    return useQuery('userById', async () => {
        const response = await axios.get(`${API_BASE_URL}/team`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const sortedTeams = response.data.sort((a, b) => {
            const nameA = a.first_name ? `${a.first_name} ${a.middle_name ? a.middle_name : ''} ${a.last_name}` : `${a.company_first_name ? a.company_first_name + (a.company_last_name && a.company_last_name) : a.company_name}`;
            const nameB = b.first_name ? `${b.first_name} ${b.middle_name ? b.middle_name : ''} ${b.last_name}` : `${b.company_first_name ? b.company_first_name + (b.company_last_name && b.company_last_name) : b.company_name}`;
            return nameA.localeCompare(nameB);
        });

        return sortedTeams;
    });
};

export const useActiveUser = (token) => {
    return useQuery('activeUser', async () => {
        const response = await axios.get(`${API_BASE_URL}/active/team`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const sortedTeams = response.data.sort((a, b) => {
            const nameA = a.first_name ? `${a.first_name} ${a.middle_name ? a.middle_name : ''} ${a.last_name}` : `${a.company_first_name ? a.company_first_name + (a.company_last_name && a.company_last_name) : a.company_name}`;
            const nameB = b.first_name ? `${b.first_name} ${b.middle_name ? b.middle_name : ''} ${b.last_name}` : `${b.company_first_name ? b.company_first_name + (b.company_last_name && b.company_last_name) : b.company_name}`;
            return nameA.localeCompare(nameB);
        });

        return sortedTeams;
    });
};

// Fetch leave types
export const useFetchLeaveTypes = (token) => {
    return useQuery('leaveTypes', async () => {
        const response = await axios.get(`${API_BASE_URL}/leave-type`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    });
};

// Approve time-off request
export const useApproveTimeOffRequest = (token) => {
    const queryClient = useQueryClient(); // Get the queryClient
    return useMutation(
        (selectedId) =>
            axios.get(`/api/time-off-request/approve/${selectedId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
        {
            onSuccess: () => {
                // You can perform any actions needed after the request is successfully cancelled.
                // For example, refetch the time-off requests.
                queryClient.invalidateQueries('timeOffRequests');
            },
        }
    );
};

// Reject a time-off request
export const useRejectTimeOffRequest = (token) => {
    const queryClient = useQueryClient(); // Get the queryClient
    return useMutation(
        (selectedId) =>
            axios.get(`/api/time-off-request/reject/${selectedId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
        {
            onSuccess: () => {
                // You can perform any actions needed after the request is successfully rejected.
                // For example, refetch the time-off requests.
                queryClient.invalidateQueries('timeOffRequests');
            },
        }
    );
};

// Cancel a time-off request
export const useCancelTimeOffRequest = (token) => {
    const queryClient = useQueryClient(); // Get the queryClient
    return useMutation(
        (selectedId) =>
            axios.get(`/api/time-off-request/cancel/${selectedId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
        {
            onSuccess: () => {
                // You can perform any actions needed after the request is successfully cancelled.
                // For example, refetch the time-off requests.
                queryClient.invalidateQueries('timeOffRequests');
            },
        }
    );
};

// Delete a time-off request
export const useDeleteTimeOffRequest = (token) => {
    const queryClient = useQueryClient(); // Get the queryClient
    return useMutation(
        (selectedId) =>
            axios.get(`/api/time-off-request/delete/${selectedId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
        {
            onSuccess: () => {
                // You can perform any actions needed after the request is successfully deleted.
                // For example, refetch the time-off requests.
                queryClient.invalidateQueries('timeOffRequests');
            },
        }
    );
};

// Create a time-off request
export const useCreateTimeOffRequest = (token) => {
    const queryClient = useQueryClient(); // Get the queryClient
    return useMutation(
        (requestData) =>
            axios.post('/api/time-off-request/create', requestData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
        {
            onSuccess: () => {
                // You can perform any actions needed after the request is successfully created.
                // For example, refetch the time-off requests.
                queryClient.invalidateQueries('timeOffRequests');
            },
        }
    );
}; 