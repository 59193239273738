import React, { useState } from 'react';
import { registerUser } from "../../../../src/services/emailRegister/emailRegisterServices.js";
import { toast } from "react-toastify";
import { AES, enc } from 'crypto-js';
import { ThreeDots } from "react-loader-spinner";
import { Link, useNavigate, useParams } from "react-router-dom";
import EyeIcon from "../../../images/icons/eye-icon.svg";
import EyeCrossed from "../../../images/icons/eye-crossed.svg";
import LoginImageMobile from "../../../images/login/lg-img-mob.png";
import LoginImage from "../../../images/login/lg-img.png";
import Logo from "../../../images/logo/to-logo.svg";

const TenantInviteRegister = () => {
    const { email } = useParams();
    const deEmail = decodeURIComponent(
        atob(email.replace(/\-/g, "+").replace(/_/g, "/"))
    );

    const navigate = useNavigate();
    const [companyName, setCompanyName] = useState("");
    const [location, setlocation] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [subDomain, setSubDomain] = useState("");
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const domainName = window.location.hostname;
    const currentPort = window.location.port;

    const submitHandler = async () => {
        if (password !== confirmPassword) {
            alert("Password and confirm password do not match");
            return;
        }

        setLoading(true); // Start loading
        try {
            const result = await registerUser(deEmail, companyName, password, companyName, false);
            if (result.status === "success") {
                if (result.data.subDomain) {
                    if (currentPort === "3000") {
                        window.location.href = `http://${result.data.subDomain}.${domainName}:3000`;
                    } else {
                        window.location.href = `https://${result.data.subDomain}.${domainName}`;
                    }

                }
                toast.success(result.data.message || "App owner registered successfully.", {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else if (result.status === "fail") {
                toast.error(result.message || "Something went wrong.", {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else if (result.status === "failed") {
                toast.error(result.message || "Company name or email already exists.", {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setLoading(false); // End loading
        }
    };

    return (
        <div>
            <div className="top-logo-mobile">
                <a href="/"><img src={Logo} alt="Talent On" /></a>
            </div>
            <div className="home-register-outer">
                <div className="lg-lft">
                    <img src={LoginImage} alt="desktop" className="desktop-lg-img" />
                    <img
                        src={LoginImageMobile}
                        alt="mobile"
                        className="mobile-lg-img"
                    />
                </div>
                <div className="home-register-form-section">
                    <div className="top-logo">
                        <a href="/"><img src={Logo} alt="Talent On" /></a>
                    </div>
                    <div className="register-head">
                        Please enter your details to register
                    </div>
                    <div className="input-outer">
                        <label>Email</label>
                        <input type="email" value={deEmail} disabled />
                        <br />
                    </div>
                    <div className="input-outer">
                        <label>Company Name</label>
                        <input
                            type="text"
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                        />
                        <br />
                    </div>
                    <div className="input-outer">
                        <label>Password</label>
                        <div className="password-input-wrapper">
                            <input
                                type={showPassword ? "text" : "password"}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <img
                                src={showPassword ? EyeCrossed : EyeIcon}
                                className="password-toggle-icon"
                                alt="Toggle Password"
                                onClick={() => setShowPassword(!showPassword)}
                            />
                        </div>
                        <br />
                    </div>
                    <div className="input-outer">
                        <label>Confirm Password</label>
                        <div className="password-input-wrapper 123">
                            <input
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                        </div>
                        <br />
                    </div>
                    <div className="create-acnt-button">
                        <button onClick={submitHandler}>
                            {loading ?
                                <div className="outter-load-table">
                                    <ThreeDots
                                        height="20"
                                        width="40"
                                        radius="4"
                                        color="white"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                    />
                                </div> : "Create Account"
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TenantInviteRegister;