import "./App.css";
import "./assets/custom.css";
import { Helmet } from "react-helmet";
import Admin from "./Layouts/Admin";
import Users from "./pages/user/user.jsx";
import Users2 from "./pages/user/user2.jsx";
import UsersTest from "./pages/user/user-test.jsx";
import TwoFA from "./pages/auth/TwoFA";
import { useSelector } from "react-redux";
import Login from "./pages/auth/Login.jsx";
import Page404 from "./pages/404page/404page";
import TimeOff from "./pages/timeOff/timeOff";
import TimeOffs from "./components/TimeOff/TimeOffOverview";
import { ToastContainer } from "react-toastify";
import Invoices from "./pages/invoices/invoices.jsx";
import Dashboard from "./pages/dashboard/dashboard.jsx";
import ResetPassword from "../src/pages/auth/ResetPass";
import ForgetPassword from "./pages/auth/ForgetPassword";
import OnboardCompanyDetails from "./pages/onboarding/OnboardingCompanyDetails.jsx";
import NewBank from "./components/User/Bank/newBank.jsx";
import TimeTracker from "./pages/timeTracker/timeTracker";
import ActivityLog from "./pages/activityLog/activityLog";
import InvoiceSingle from "./pages/invoices/invoiceSingle";
import Timer from "./components/TimeTracker/Timer/Timer.jsx";
import UserProfile from "./pages/user/userDetails/userDeatils.jsx";
import InviteRegister from "./pages/user/invite/inviteRegister.jsx";
import InvoiceCreateAuto from "./pages/invoices/invoiceCreateAuto";
import TimeTrackerLayout from "./pages/timeTracker/timeTracker.jsx";
// import AppSettingsLayout from "./pages/appSettings/appCategory.jsx";
import InvoiceEditManualPage from "./pages/invoices/invoiceEditManual";
import InvoiceCreateManual from "./pages/invoices/invoiceCreateManual";
import UserRegister from "./components/User/UserRegister/UserRegister";
import Company from "./components/AccountSettings/Companies/Company.jsx";
import ProfileSettings from "./pages/user/profileSettings/profileSettings.jsx";
import { useQuery } from '@tanstack/react-query';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import TimeTrackerTag from "./components/TimeTracker/TimeTrackerTag/TimeTrackerTag.jsx";
import PaymentOption from "./pages/accountSettings/paymentOptions/paymentOption.jsx";
import AccountSettings from "./pages/accountSettings/accountSettings/accountSettings";
import TimeTrackerTeam from "./components/TimeTracker/TimeTrackerTeam/TimeTrackerTeam.jsx";
import TimeTrackerClient from "./components/TimeTracker/TimeTrackerClient/TimeTrackerClient.jsx";
import TimeTrackerProject from "./components/TimeTracker/TimeTrackerProject/TimeTrackerProject.jsx";
import TimeTrackerOverview from "./components/TimeTracker/TimeTrackerOverview/TimeTrackerOverview.jsx";
import TimeTrackerTeamMember from "./components/TimeTracker/TimeTrackerTeam/TimeTrackerTeamMembers/TimeTrackerTeamMembers.jsx";
import TimeTrackerTeamTypeOfLeave from "./components/TimeTracker/TimeTrackerTeam/TimeTrackerTeamTypeOfLeave/TimeTrackerTeamTypeOfLeave.jsx";
import TimeTrackerTeamDepartment from "./components/TimeTracker/TimeTrackerTeam/TimeTrackerTeamDepartment/TimeTrackerTeamDepartment.jsx";
// import ExpenseCategory from "./components/AppSettings/expCategory.jsx";
import ClientOverview from "./pages/Clients/ClientOverview.jsx";
import ClientOverviewDetails from "./pages/Clients/ClientDetails";
import ClientProfileSection from "./pages/Clients/Details/ClientDetailProfile.jsx";
import ClientDetailLayout from "./pages/Clients/ClientDetailsLayout/ClientDetailsLayout.jsx";
// import { logout, reset } from "./features/auth/authSlice";
import VendorOverview from "./pages/Vendors/VendorOverview.jsx";
import VendorOverviewDetails from "./pages/Vendors/VendorDetails";
import VendorProfileSection from "./pages/Vendors/Details/VendorDetailProfile.jsx";
import VendorProfileViewSection from "./pages/Vendors/Details/VendorProfileView.jsx";
import VendorDetailLayout from "./pages/Vendors/VendorDetailsLayout/VendorDetailsLayout.jsx";

import { useDispatch } from "react-redux";
import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import TimeZoneModal from "./components/Modal/SetDefaultModal.jsx";
import Project from "./pages/projects/Project.jsx";
import ProjectDetails from "./pages/projects/details/Details.jsx";
import Document from "./pages/documents/Document.jsx";
import Setup from "./pages/Clients/SetUp/Setup.jsx";
import { setForex } from "../src/features/forex/forexSlice.js";



import TestPage from "./components/Test/Test.jsx";
import axios from "axios";
import ClientInvoiceSection from "./pages/Clients/Details/ClientDetailInvoices.jsx";
import ClientInvoiceCreate from "./components/Clients/ClientInvoice/ClientInvoiceCreate/ClientInvoiceCreate.jsx";
import ClientInvoiceSingle from "./components/Clients/ClientInvoice/ClientInvoicesSingle/ClientInvoiceSingle.jsx";
import ClientInvoiceEdit from "./components/Clients/ClientInvoice/ClientInvoiceEdit/ClientInvoiceEdit.jsx";
import ClientInviteRegister from "./pages/Clients/ClientInvite/ClientInviteRegister.jsx";
import ClientInvoice from "./pages/Clients/ClientInvoice/ClientInvoice.jsx";
import ClientsProjects from "./pages/Clients/ClientProject/ClientsProjects.jsx";
// import ClientsProjects2 from "./pages/Clients/ClientProject/ClientsProjects2.jsx";
import Equipment from "./pages/equipment/equipment.jsx";
import ExpenseOverview from "./pages/expense/expenseOverview.jsx";
import CreateExpense from "./pages/expense/expenseCreate.jsx";

import VendorInvoiceSection from "./pages/Vendors/Details/VendorDetailInvoices.jsx";
import VendorInvoiceCreate from "./components/Vendors/VendorInvoice/VendorInvoiceCreate/VendorInvoiceCreate.jsx";
import VendorInvoiceSingle from "./components/Vendors/VendorInvoice/VendorInvoicesSingle/VendorInvoiceSingle.jsx";
import VendorInvoiceEdit from "./components/Vendors/VendorInvoice/VendorInvoiceEdit/VendorInvoiceEdit.jsx";
import VendorInviteRegister from "./pages/Vendors/VendorInvite/VendorInviteRegister.jsx";
import VendorInvoice from "./pages/Vendors/VendorInvoice/VendorInvoice.jsx";

import ReportingOverview from "./pages/Reporting/ReportingOverview.jsx";
import ReportingClients from "./pages/Reporting/ReportingClients.jsx";
import ReportingFinancial from "./pages/Reporting/ReportingFinancial.jsx";
import ReportingFinancialExpense from "./components/Reporting/ReportingFinancial/ReportingExpense";
import ReportingFinancialRevenue from "./components/Reporting/ReportingFinancial/ReportingRevenue.jsx";
import ReportingFinancialOverview from "./components/Reporting/ReportingFinancial/ReportingFinancialOverview.jsx";
import ReportingPersonnel from "./pages/Reporting/ReportPersonnel.jsx";
import ReportingServices from "./pages/Reporting/ReportingServices.jsx";
import UserEquipment from "./pages/equipment/userEquipment.jsx";
import EquipmentCategory from "./pages/equipment/equipmentCategory/eqpCategory.jsx";
import ExpCategoryLayout from "./pages/expense/expCategory/expCategoryLayout.jsx";
import TypeOfLeave from "./components/TimeOff/element/typeOfLeave.jsx";
import ReportingReimbursement from "./components/Reporting/ReportingPersonal/ReportingReimbursement.jsx";
import ReportingTimeWorked from "./components/Reporting/ReportingPersonal/ReportingTimeWorked.jsx";
import ReportingTimeOffRequest from "./components/Reporting/ReportingPersonal/ReportingTimeOffRequest.jsx";
import OnboardingLanding from "./pages/onboarding/landingPage/LangingPage.jsx";
import OnboardingEmailVerification from "./pages/onboarding/landingPage/EmailVerification.jsx";
import OnBoardRegisteration from "./pages/onboarding/registeration/OnboardingCompanyDetails.jsx";
import VendorDashboard from "./pages/Vendors/VendorDashboard.jsx";
import VendorBankSection from "./pages/Vendors/Details/VendorDetailBank.jsx";
import ReportingClientOverview from "./components/Reporting/ClientReport/ReportingClientOverview.jsx";
import ReportingClientTimeWorked from "./components/Reporting/ClientReport/ReportingClientTimeWorked.jsx";
import Home from "./pages/home/home.jsx";
import ClientDashboard from "./pages/Clients/ClientDashboard.jsx";
import MyTeamTabModule from "./pages/user/myteam.jsx";
import OtpVerification from "./pages/home/emailLogin/otpVerification.jsx";
import AppRegister from "./pages/home/emailLogin/register.jsx";
import MasterLogin from "./pages/masterAuth/MasterLogin.jsx";
import MasterUserLayout from "./Layouts/MasterUserLayout.js";
import MasterDashboard from "./pages/master/dashboard/dashboard.jsx";
import Tenants from "./pages/master/tenants/TenantOverview.jsx";
import TenantInviteRegister from "./pages/master/tenants/TenantInviteRegister.jsx";
import CheckEmail from "./pages/auth/CheckEmail.jsx";
import CheckSubDomain from "./pages/auth/CheckSubDomain.jsx";
import AuthOptions from "./pages/auth/AuthOptions.jsx";
import GoogleLoad from "./pages/home/googleLogin/GoogleLoad.jsx";
import GoogleLoginTeams from "./pages/home/emailLogin/googleLoginTeams.jsx";
import GoogleLoginTeamsVerify from "./pages/home/emailLogin/googleLoginTeamsVerify.jsx"
import MasterForgetPassword from "./pages/masterAuth/MasterForgetPassword.jsx";
import MasterResetPass from "./pages/masterAuth/MasterResetPass.jsx";
import MasterTwoFA from "./pages/masterAuth/MasterTwoFA.jsx";
import MasterUsers from "./pages/master/users/MasterUserOverview.jsx";
import MasterUserDeatils from "./pages/master/users/MasterUserDetails/MasterUserDeatils.jsx";
import MasterInviteRegisteration from "./pages/master/users/invite/MasterInviteRegisteration.jsx";
import MasterProfileSettings from "./pages/master/profileSettings/masterProfileSettings.jsx";
import MasterUserRegister from "./components/Master/MasterUsers/MasterUserRegister/MasterUserRegister.jsx";
import MasterTenantDetails from "./components/Master/MasterTenants/MasterTenantDetails.jsx";
import MasterTenantRegister from "./components/Master/MasterTenants/MasterTenantRegister.jsx";
import MasterSubscriptionOvervirew from "./pages/master/subscription/SubscriptionOverview";
import MasterSubscriptionEdit from "./pages/master/subscription/SubscriptionEdit";
import MasterSubscriptionAdd from "./pages/master/subscription/SubscriptionAdd";
import Expense from "./pages/expense/expense.jsx";
import EquipmentLayout from "./pages/equipment/equipmentLayout.jsx";
import SubdomainGuard from './components/SubdomainGuard';
import Page404Christmas from "./pages/404page/404PageChirstmas.jsx";

function App() {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { masterUser } = useSelector((state) => state.masterAuth);
  const { forex } = useSelector((state) => state.forex);
  const { appSettings } = useSelector((state) => state.appSettings);
  const [showTimeZoneModal, setShowTimeZoneModal] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [alertState, setAlertState] = useState(true);
  const [alertFrom, setAlertFrom] = useState(null);
  const [timeZones, setTimeZones] = useState([]);
  var zoneChangeConfirm = false;
  zoneChangeConfirm = localStorage.getItem("zoneChangeConfirm");


  const Live = false;
  const faviconFileName = appSettings && appSettings.favIcon;
  const role = user ? user.userType : null;
  const userCat = user ? user.userCategory : null;
  const SUPER_ADMIN = 1;
  const ADMIN = 2;
  const USER = 3;
  const BUSSINESS = 4;
  const PROJECT_MANAGER = 6;
  const TEAM_MANAGER = 7;
  const INDIVIDUAL = 1;
  const BUSSI = 2;
  const VENDOR = 3;
  const CLIENT = 4;
  const auth = user ? true : false;
  const masterAuth = masterUser ? true : false;

  const Redirect = () => {
    if (!auth) {
      return <Navigate to="/" replace={true} />;
    }
    return userCat === VENDOR ? (
      <Navigate to="/vendor/dashboard" replace={true} />
    ) : userCat === CLIENT ? (
      <Navigate to="/client/dashboard" replace={true} />
    ) : (
      <Navigate to="/user/dashboard" replace={true} />
    );
  };

  const MasterUserRedirect = () => {
    if (!masterAuth) {
      return <Navigate to="/master-user/login" replace={true} />;
    }
    return (
      <Navigate to="/master-user/dashboard" replace={true} />
    );
  };

  const CustomToast = ({ closeToast }) => (
    <div>
      <div>Please Choose Preferences (Time Zone , Language and Currency).</div>
      <a href="/user/profile/settings">Profile Settings</a>
    </div>
  );

  const getForex = async (currency) => {
    const response = await axios.post(
      "/api/forex/get",
      { base_currency: "USD" },
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );

    if (response.status === 200) {
      return response.data ? response.data.json : null;
    } else {
      console.error(`Request failed with status code: ${response.status}`);
      // handle error
    }
  };

  useEffect(() => {
    const ForexUpdate = async () => {
      const forex = await getForex("USD");
      await dispatch(setForex(forex));
    };

    if (user) {
      ForexUpdate();
    }

    const updateOnlineStatus = () => {
      setIsOnline(navigator.onLine);
      if (navigator.onLine === false) {
        toast.warning("Network connection lost!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {

        toast.success("Network connection re-established.", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

      }
    };
    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);
    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);

  useEffect(() => {
    const getDomainWithoutSubdomain = (url) => {
      const parsedUrl = new URL(url);
      const { protocol, hostname } = parsedUrl;

      // Split the hostname by dots
      const domainParts = hostname.split('.');

      // Remove "ama" if it exists at the beginning
      if (domainParts[0].toLowerCase() === "ama") {
        domainParts.shift(); // Remove the first part ("ama")
      }

      // Rebuild the base domain
      const baseDomain = `${protocol}//${domainParts.join('.')}`;
      return baseDomain;
    };

    const validateSubdomain = async () => {

      try {
        const response = await axios.get(`/api/sub/domains`);
        const subdomains = response.data;
        let subdomain = null;

        const currentUrl = window.location;
        let baseDomain = `${currentUrl.protocol}//${currentUrl.hostname.split('.')[1]}`;


        const hostParts = currentUrl.hostname.split('.');

        if (Live === false) {

          if (hostParts.length === 4) {

            const domainParts = currentUrl.hostname.split('.');
            domainParts.shift();
            baseDomain = `${currentUrl.protocol}//${domainParts.join('.')}`;
            subdomain = hostParts[0];
            if (subdomain && !subdomains.includes(subdomain)) {



              window.location.replace(`${currentUrl}/login`);
            } else {

              window.location.replace(`https://app.talenton.io/`);

            }

          } else if (hostParts.length === 3) {

            window.location.replace(`https://app.talenton.io/`);

          } else {

            window.location.replace(`https://app.talenton.io/`);

          }
        } else {
          if (hostParts.length === 3) {
            const domainParts = currentUrl.hostname.split('.');
            domainParts.shift();
            //let baseDomain = `${currentUrl.protocol}//${domainParts.join('.')}`;
            subdomain = hostParts[0];
            if (subdomain && !subdomains.includes(subdomain)) {
              window.location.replace(`${currentUrl}/login`);
            } else {
              window.location.replace(`https://talenton.app/`);
            }
          } else if (hostParts.length === 2) {
            window.location.replace(`https://talenton.app/`);
          } else {
            window.location.replace(`https://talenton.app/`);
          }
        }


        if (subdomain && !subdomains.includes(subdomain)) {
          // window.location.replace(`${baseDomain}/auth`);
        }
      } catch (error) {
        console.error('Error validating subdomain:', error);
      }
    };

    const redirectToSubdomainLogin = () => {
      const { protocol, hostname } = window.location;
      const domainParts = hostname.split('.');
      // Check if there is a subdomain present (i.e., more than 2 parts in hostname)
      if (domainParts.length === 3 && Live === false) {
        const subdomain = domainParts[0];
        const baseDomain = domainParts.slice(1).join('.'); // Join remaining parts to get the base domain
        // Redirect to the subdomain login page
        window.location.href = `${protocol}//${subdomain}.${baseDomain}/login`;
      }
      return null;
    };
    //  validateSubdomain();
  }, [Navigate]);

  const moment = require("moment-timezone");
  const checkTimeZone = async () => {
    const currentTimeZone = moment.tz.guess();
    const browseTimeZone =
      Intl.DateTimeFormat("en-US").resolvedOptions().timeZone;

    if (user) {
      try {
        const response = await axios.get(`/api/get/user-settings/${user._id}`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        if (response.data !== null) {
          if (response.data.timeZone !== null) {
            if (response.data.timeZone !== currentTimeZone) {
              if (zoneChangeConfirm === null) {
                setTimeZones({
                  browser: browseTimeZone,
                  appSettings: response.data.timeZone,
                });
                setAlertFrom("zoneChanged");
                setShowTimeZoneModal(true);
              }
            }
          }
        }
      } catch (error) {
        global.config.slackMessage(error.toString());
        console.log(error);
      }
    }
  };

  // const checkForceLogout = async () => {
  //   if (user) {
  //     try {
  //       const response = await axios.get(`/api/get/user-settings/${user._id}`, {
  //         headers: {
  //           Authorization: `Bearer ${user.token}`,
  //         },
  //       });

  //       if (response.data === null) {
  //         // });
  //         setShowTimeZoneModal(true);
  //       }

  //       if (
  //         response &&
  //         response.data &&
  //         (response.data.currency === null || response.data.timeZone === null)
  //       ) {
  //         setShowTimeZoneModal(true);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //       global.config.slackMessage(error.toString());
  //     }
  //   }

  //   let forceEmail = user && user ? user.email : null;

  //   if (forceEmail) {
  //     const force = await axios.get(`/api/get/force-logout`);
  //     if (force.data && force.data.forceStatus === true) {
  //       const logCheck = await axios.post(`/api/post/force-user-exist`, {
  //         email: user && user.email ? user.email : null,
  //       });

  //       if (logCheck && logCheck.data === null) {
  //         if (user && user.email) {
  //           await axios.post(`/api/post/force-user`, { email: user.email });
  //         }
  //         await dispatch(logout());

  //         return <Navigate to="/" replace={true} />;
  //       } else {
  //         return true;
  //       }
  //     } else {
  //       return true;
  //     }
  //   }
  // };

  // automatic check case
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     // Call your function here
  //     if (user) {
  //       checkForceLogout();
  //       checkTimeZone();
  //     }
  //   }, 5000);
  //   return () => clearInterval(intervalId);
  // }, []);

  // automatic check case end

  //checkForceLogout();

  const checkTocken = () => {
    const localUser = localStorage.getItem("user");
    const parsedUser = JSON.parse(localUser);
    if (parsedUser.token === user.token) {
      return true;
    } else {
      return false;
    }
  };

  const checkMasterTocken = () => {
    const localMasterUser = localStorage.getItem("masterUser");
    const parsedMasterUser = JSON.parse(localMasterUser);
    if (parsedMasterUser.token === masterUser?.token) {
      return true;
    } else {
      return false;
    }
  };

  const locationEnable = async () => {
    try {
      const permission = await navigator.permissions.query({
        name: "geolocation",
      });
      if (permission.state === "granted") {
        navigator.geolocation.getCurrentPosition((position) => { });
      }
    } catch (err) {
      console.log("Error:", err);
      global.config.slackMessage(err.toString());
    }
  };
  locationEnable();
  console.warn = () => { };

  // reload page after long inactive time

  const inactivityTime = 3600000; // 1 hours in milliseconds
  const timeoutId = useRef(null);

  // Function to reload the page
  const reloadPage = () => {
    window.location.reload();
  };

  // Function to reset the timeout
  const resetTimeout = () => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    timeoutId.current = setTimeout(reloadPage, inactivityTime);
  };

  // Set up event listeners
  useEffect(() => {
    const events = ['mousemove', 'mousedown', 'keypress', 'touchstart'];

    events.forEach((event) => {
      window.addEventListener(event, resetTimeout);
    });

    // Initialize the timeout
    resetTimeout();

    // Cleanup event listeners on component unmount
    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, resetTimeout);
      });

      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, []);

  // refresh page


  const checkSubdomain = () => {
    const subdomain = window.location.hostname.split('.')[0];
    const domain = window.location.hostname;



  }

  return (
    <div className="App">
      <>
        <Helmet>
          <meta charset="utf-8" />
          <meta name="robots" content="noindex" />
          {appSettings &&
            appSettings.favIcon &&
            appSettings.favIcon !== "null" &&
            appSettings.favIcon !== null ? (
            <link rel="icon" href={`/uploads/orgiFavIcon/${faviconFileName}`} />
          ) : (
            <link rel="icon" href="#" />
          )}

          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="theme-color" content="#000000" />
          {appSettings &&
            appSettings.seoTitle &&
            appSettings.seoTitle !== "null" &&
            appSettings.seoTitle !== null ? (
            <meta name="title" content={appSettings.seoTitle} />
          ) : (
            <meta name="title" content="TalentOn" />
          )}
          {appSettings &&
            appSettings.metaDescription &&
            appSettings.metaDescription !== "null" &&
            appSettings.metaDescription !== null ? (
            <meta name="description" content={appSettings.metaDescription} />
          ) : (
            <meta name="description" content="TalentOn" />
          )}

          {appSettings &&
            appSettings.favIcon &&
            appSettings.favIcon !== "null" &&
            appSettings.favIcon !== null ? (
            <link
              rel="apple-touch-icon"
              href={`/uploads/orgiFavIcon/${faviconFileName}`}
            />
          ) : (
            <link rel="apple-touch-icon" href="#" />
          )}

          <link rel="manifest" href="../public/manifest.json" />
          <link rel="stylesheet" href="https://use.typekit.net/eel7ejk.css" />
          {/* <link rel='stylesheet' href='https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/3.3.7/css/bootstrap.min.css' /> */}
          <link rel='stylesheet' href='https://fonts.googleapis.com/css?family=Arvo' />
          
          <title>Talenton</title>
        </Helmet>
        <Router>
          <Routes>
            <Route path="on-boarding" element={<OnboardingLanding />} />
            <Route path="on-boarding/email-verification" element={<OnboardingEmailVerification />} />
            <Route path="onboard/invite/register/:encodedAppId/:usertype/:email" element={!auth ? <OnBoardRegisteration /> : Redirect()} />

            {/* <Route path="/" element={!auth ? <AuthOptions /> : Redirect()} /> */}
            <Route path="/" element={!auth ? <SubdomainGuard> <CheckEmail /> </SubdomainGuard> : Redirect()} />
            <Route path="auth" element={!auth ? <Home /> : Redirect()} />
            <Route path="/login" element={(!auth && checkSubdomain()) ? <SubdomainGuard> <Login /> </SubdomainGuard> : Redirect()} />

            <Route path="auth/myteam/:subdomain/:email" element={!auth ? <SubdomainGuard><CheckSubDomain /></SubdomainGuard> : Redirect()} />
            <Route path="forget-password" element={!auth ? <SubdomainGuard><ForgetPassword /></SubdomainGuard> : Redirect()} />
            <Route path="passwords/reset/:token/:email" element={!auth ? <ResetPassword /> : Redirect()} />
            <Route path="2fa" element={!auth ? <TwoFA /> : Redirect()} />
            <Route path="invite/register/:encodedAppId/:usertype/:email" element={!auth ? <InviteRegister /> : Redirect()} />

            <Route path="client/invite/register/:encodedAppId/:cliInvEmail" element={!auth ? <ClientInviteRegister /> : Redirect()} />
            <Route path="vendor/invite/register/:encodedAppId/:venInvEmail" element={!auth ? <VendorInviteRegister /> : Redirect()} />

            <Route path="onboarding/company-details" element={auth && checkTocken() ? <OnboardCompanyDetails /> : Redirect()} />

            <Route path="/master-user/login" element={!masterAuth ? <MasterLogin /> : MasterUserRedirect()} />
            <Route path="/master-user/forget-password" element={!masterAuth ? <MasterForgetPassword /> : MasterUserRedirect()} />
            <Route path="/master-user/passwords/reset/:token/:email" element={!masterAuth ? <MasterResetPass /> : MasterUserRedirect()} />
            <Route path="/master-user/2fa" element={!masterAuth ? <MasterTwoFA /> : MasterUserRedirect()} />
            <Route path="/master/user/invite/register/:usertype/:email" element={!masterAuth ? <MasterInviteRegisteration /> : MasterUserRedirect()} />

            <Route path="/master-user" element={masterAuth && checkMasterTocken() ? (<MasterUserLayout />) : (MasterUserRedirect())}>
              <Route path="dashboard" element={masterAuth && checkMasterTocken() ? <MasterDashboard /> : MasterUserRedirect()} />
              {/* <Route path="dashboard" element={masterAuth && checkMasterTocken() ? <Tenants /> : MasterUserRedirect()} /> */}
              <Route path="tenants/list" element={masterAuth && checkMasterTocken() ? <Tenants /> : MasterUserRedirect()} />
              <Route path="tenants/register" element={masterAuth && checkMasterTocken() ? <MasterTenantRegister /> : MasterUserRedirect()} />
              <Route path="subscriptions" element={masterAuth && checkMasterTocken() ? <MasterSubscriptionOvervirew /> : MasterUserRedirect()} />
              <Route path="subscriptions/edit" element={masterAuth && checkMasterTocken() ? <MasterSubscriptionEdit /> : MasterUserRedirect()} />
              <Route path="subscriptions/add" element={masterAuth && checkMasterTocken() ? <MasterSubscriptionAdd /> : MasterUserRedirect()} />
              <Route path="tenant/profile/:encodedSubDomain" element={(masterAuth && checkMasterTocken()) ? (<MasterTenantDetails />) : (MasterUserRedirect())} />
              <Route path="list" element={masterAuth && checkMasterTocken() ? <MasterUsers /> : MasterUserRedirect()} />
              <Route path="register" element={masterAuth && checkMasterTocken() ? (<MasterUserRegister />) : (Redirect())} />
              <Route path="profile/:encryptedId" element={(masterAuth && checkMasterTocken()) ? (<MasterUserDeatils />) : (MasterUserRedirect())} />
              <Route path="profile/settings" element={masterAuth && checkMasterTocken() ? <MasterProfileSettings /> : Redirect()} />
            </Route>
            <Route path="/user" element={auth && checkTocken() ? (<Admin />) : (Redirect())}>
              <Route path="dashboard" element={auth && checkTocken() && (userCat === INDIVIDUAL || userCat === BUSSI) ? <Dashboard /> : Redirect()} />
              <Route path="account/settings" element={auth && checkTocken() && (role === ADMIN || role === SUPER_ADMIN) ? (<AccountSettings />) : (Redirect())} />
              <Route path="invoices" element={auth && checkTocken() && (userCat === INDIVIDUAL || userCat === BUSSI) ? <Invoices /> : Redirect()} />
              <Route path="myteam" element={auth && checkTocken() && (role === ADMIN || role === SUPER_ADMIN) && (userCat === INDIVIDUAL || userCat === BUSSI) ? (<MyTeamTabModule />) : (Redirect())} >
                <Route path="list" element={auth && checkTocken() && (role === ADMIN || role === SUPER_ADMIN) ? (<Users />) : (Redirect())} />
                <Route path="list2" element={auth && checkTocken() && (role === ADMIN || role === SUPER_ADMIN) ? (<Users2 />) : (Redirect())} />
                <Route path="department" element={auth && checkTocken() && (role === ADMIN || role === SUPER_ADMIN) ? (<TimeTrackerTeamDepartment />) : (Redirect())} />
              </Route>
              {/* test */}
              <Route path="list-test" element={auth && checkTocken() && (role === ADMIN || role === SUPER_ADMIN) ? (<UsersTest />) : (Redirect())} />
              {/* test */}
              <Route exact path="register" element={auth && checkTocken() && (role === ADMIN || role === SUPER_ADMIN) ? (<UserRegister />) : (Redirect())} />
              <Route path="invoice/view/:id" element={auth && checkTocken() && (userCat === INDIVIDUAL || userCat === BUSSI) ? <InvoiceSingle /> : Redirect()} />
              <Route path="invoice/manual/create" element={auth && checkTocken() && (userCat === INDIVIDUAL || userCat === BUSSI) ? <InvoiceCreateManual /> : Redirect()} />
              <Route path="invoice/auto/create" element={auth && checkTocken() && (userCat === INDIVIDUAL || userCat === BUSSI) ? <InvoiceCreateAuto /> : Redirect()} />
              <Route path="invoice/edit/:encryptedId" element={auth && checkTocken() && (userCat === INDIVIDUAL || userCat === BUSSI) ? <InvoiceEditManualPage /> : Redirect()} />
              <Route path="profile/:encryptedId" element={auth && checkTocken() && (role === ADMIN || role === SUPER_ADMIN) ? (<UserProfile />) : (Redirect())} />
              <Route path="profile/settings" element={auth && checkTocken() ? <ProfileSettings /> : Redirect()} />
              <Route path="accont-settings/payment-options" element={auth && checkTocken() && (role === ADMIN || role === SUPER_ADMIN) ? (<PaymentOption />) : (Redirect())} />
              <Route path="bank">
                <Route path="new" element={auth && checkTocken() ? <NewBank /> : Redirect()} />
              </Route>
              <Route path="time-tracker" element={auth && checkTocken() && (userCat === INDIVIDUAL || userCat === BUSSI) ? <TimeTracker /> : Redirect()} />
              <Route path="time-tracker" element={auth && checkTocken() && (userCat === INDIVIDUAL || userCat === BUSSI) ? (<TimeTrackerLayout />) : (Redirect())} >
                <Route path="overview" element={auth && checkTocken() && checkTocken() ? (<TimeTrackerOverview />) : (Redirect())} />
                <Route path="timer"
                  element={auth && checkTocken() &&
                    (role === USER || role === BUSSINESS || role === PROJECT_MANAGER || role === TEAM_MANAGER || role === ADMIN || role === SUPER_ADMIN)
                    ?
                    (<Timer />) : (Redirect())}
                />
                <Route path="team" element={auth && checkTocken() && (role === ADMIN || role === SUPER_ADMIN) ? (<TimeTrackerTeam />) : (Redirect())} >
                  <Route path="members" element={auth && checkTocken() && (role === ADMIN || role === SUPER_ADMIN) ? (<TimeTrackerTeamMember />) : (Redirect())} />
                  <Route path="department" element={auth && checkTocken() && (role === ADMIN || role === SUPER_ADMIN) ? (<TimeTrackerTeamDepartment />) : (Redirect())} />
                  <Route path="type-of-leave" element={auth && checkTocken() && (role === ADMIN || role === SUPER_ADMIN) ? (<TimeTrackerTeamTypeOfLeave />) : (Redirect())} />
                </Route>
                <Route path="client" element={auth && checkTocken() && (role === ADMIN || role === SUPER_ADMIN) ? (<TimeTrackerClient />) : (Redirect())} />
                <Route path="projects" element={auth && checkTocken() && (role === ADMIN || role === SUPER_ADMIN) ? (<TimeTrackerProject />) : (Redirect())} />
                <Route path="tag" element={auth && checkTocken() && (role === ADMIN || role === SUPER_ADMIN) ? (<TimeTrackerTag />) : (Redirect())} />
              </Route>
              <Route path="activity-log/list" element={auth && checkTocken() && (role === ADMIN || role === SUPER_ADMIN) ? (<ActivityLog />) : (Redirect())} />
              <Route path="time-off" element={auth && checkTocken() && (userCat === INDIVIDUAL || userCat === BUSSI) ? (<TimeOff />) : (Redirect())} >
                <Route path="overview" element={auth && checkTocken() && (userCat === INDIVIDUAL || userCat === BUSSI) ? <TimeOffs /> : (Redirect())} />
                <Route path="leave-type" element={auth && checkTocken() && (role === ADMIN || role === SUPER_ADMIN) ? (<TypeOfLeave />) : (Redirect())} />
              </Route>
              <Route path="expense" element={auth && checkTocken() && (role === ADMIN || role === SUPER_ADMIN || role === PROJECT_MANAGER) ? (<Expense />) : (Redirect())} >
                <Route path="overview" element={auth && checkTocken() && (role === ADMIN || role === SUPER_ADMIN || role === PROJECT_MANAGER) ? (<ExpenseOverview />) : (Redirect())} />
                <Route path="expense-category" element={auth && checkTocken() && (role === ADMIN || role === SUPER_ADMIN || role === PROJECT_MANAGER) ? (<ExpCategoryLayout />) : (Redirect())} />
              </Route>
              <Route path="expense/create" element={auth && checkTocken() && (role === ADMIN || role === SUPER_ADMIN) ? (<CreateExpense />) : (Redirect())} />
              <Route path="equipment" element={auth && checkTocken() && (role === ADMIN || role === SUPER_ADMIN) ? (<EquipmentLayout />) : (Redirect())} >
                <Route path="overview" element={auth && checkTocken() && (role === ADMIN || role === SUPER_ADMIN) ? (<Equipment />) : (Redirect())} />
                <Route path="equipment/category" element={auth && checkTocken() && (role === ADMIN || role === SUPER_ADMIN) ? (<EquipmentCategory />) : (Redirect())} />
              </Route>
              <Route path="users/equipment" element={auth && checkTocken() && role !== ADMIN && role !== SUPER_ADMIN ? (<UserEquipment />) : (Redirect())} />
              <Route path="app/company" element={auth && checkTocken() ? <Company /> : Redirect()} />
              {/* <Route path="app-settings" element={(auth && checkTocken() && (role === ADMIN || role === SUPER_ADMIN)) ? <AppSettingsLayout /> : Redirect()} > */}
              {/* <Route path="expense-category" element={((auth && checkTocken()) && checkTocken() && (role === ADMIN || role === SUPER_ADMIN)) ? <ExpenseCategory /> : Redirect()} /> */}
              {/* </Route> */}
            </Route>

            {/* Client section */}
            <Route path="/client" element={auth && checkTocken() && (((role === ADMIN || role === SUPER_ADMIN || role === PROJECT_MANAGER) && (userCat === INDIVIDUAL || userCat === BUSSI)) || userCat === CLIENT) ? (<Admin />) : (Redirect())} >
              <Route path="dashboard" element={auth && checkTocken() && (userCat === CLIENT) ? <ClientDashboard /> : Redirect()} />
              <Route path="overview" element={auth && checkTocken() ? <ClientOverview /> : Redirect()} />
              <Route path="details/:encryptedId" element={auth && checkTocken() && (role === ADMIN || role === SUPER_ADMIN || role === PROJECT_MANAGER) ? (<ClientOverviewDetails />) : (Redirect())} />
              <Route path="profile" element={auth && checkTocken() && ((role === ADMIN || role === SUPER_ADMIN || role === PROJECT_MANAGER) && (userCat === INDIVIDUAL || userCat === BUSSI)) ? (<ClientDetailLayout />) : (Redirect())} >
                <Route path=":encryptedId" element={auth && checkTocken() ? (<ClientProfileSection />) : (Redirect())} />
                <Route path="invoices/:encryptedId" element={auth && checkTocken() ? (<ClientInvoiceSection />) : (Redirect())} />
              </Route>
              <Route path="invoices" element={auth && checkTocken() ? (<ClientDetailLayout />) : (Redirect())} >
                <Route path=":encryptedId" element={auth && checkTocken() ? (<ClientInvoiceSection />) : (Redirect())} />
                <Route path="create/:encryptedId" element={auth && checkTocken() && ((role === ADMIN || role === SUPER_ADMIN || role === PROJECT_MANAGER) && (userCat === INDIVIDUAL || userCat === BUSSI)) ? <ClientInvoiceCreate /> : Redirect()} />
                <Route path="view/:inv/:encryptedId" element={auth && checkTocken() ? <ClientInvoiceSingle /> : Redirect()} />
                <Route path="edit/:inv/:encryptedId" element={auth && checkTocken() && ((role === ADMIN || role === SUPER_ADMIN || role === PROJECT_MANAGER) && (userCat === INDIVIDUAL || userCat === BUSSI)) ? <ClientInvoiceEdit /> : Redirect()} />
              </Route>
              <Route path="projects" element={auth && checkTocken() && (userCat === INDIVIDUAL || userCat === BUSSI) ? (<ClientDetailLayout />) : (Redirect())} >
                <Route path=":encryptedId" element={auth && checkTocken() ? <Project /> : Redirect()} />
                <Route path="details/:projectId/:encryptedId" element={auth && checkTocken() ? <ProjectDetails /> : Redirect()} />
              </Route>
              <Route path="documents" element={auth && checkTocken() && (userCat === INDIVIDUAL || userCat === BUSSI) ? (<ClientDetailLayout />) : (Redirect())} >
                <Route path=":encryptedId" element={auth && checkTocken() ? <Document /> : Redirect()} />
              </Route>
              <Route path="setup" element={auth && checkTocken() && (userCat === INDIVIDUAL || userCat === BUSSI) ? (<ClientDetailLayout />) : (Redirect())} >
                <Route path=":encryptedId" element={auth && checkTocken() ? <Setup /> : Redirect()} />
              </Route>
              <Route path="invoice" element={auth && checkTocken() ? <ClientInvoice /> : Redirect()} />
              <Route path="project" element={auth && checkTocken() ? <ClientsProjects /> : Redirect()} />
              {/* <Route path="project2" element={auth && checkTocken() ? <ClientsProjects2 /> : Redirect()} /> */}
              {/* <Route path="/" element={((auth && checkTocken()) && (userCat === INDIVIDUAL || userCat === BUSSI)) ? <ClientDetailLayout /> : Redirect()} >
                <Route path="invoice/:encryptedId" element={(auth && checkTocken()) ? <ClientInvoiceSection /> : Redirect()} />
                <Route path="invoice/create/:encryptedId" element={(auth && checkTocken()) ? <ClientInvoiceCreate /> : Redirect()} />
              </Route> */}
            </Route>
            {/* Client section */}

            {/* Vendor section */}
            <Route path="/vendor" element={auth && checkTocken() && (role === ADMIN || role === SUPER_ADMIN || userCat === VENDOR) ? (<Admin />) : (Redirect())}>
              <Route path="dashboard" element={auth && checkTocken() && (userCat === VENDOR) ? <VendorDashboard /> : Redirect()} />
              <Route path="overview" element={auth && checkTocken() ? <VendorOverview /> : Redirect()} />
              <Route path="details/:encryptedId" element={auth && checkTocken() && (role === ADMIN || role === SUPER_ADMIN) ? (<VendorOverviewDetails />) : (Redirect())} />
              <Route path="profile" element={auth && checkTocken() && (userCat === INDIVIDUAL || userCat === BUSSI) ? (<VendorDetailLayout />) : (Redirect())}>
                <Route path="view/:encryptedId" element={auth && checkTocken() ? (<VendorProfileViewSection />) : (Redirect())} />
                <Route path=":encryptedId" element={auth && checkTocken() ? (<VendorProfileSection />) : (Redirect())} />
                <Route path="invoices/:encryptedId" element={auth && checkTocken() ? (<VendorInvoiceSection />) : (Redirect())} />
              </Route>
              <Route path="invoices" element={auth && checkTocken() ? (<VendorDetailLayout />) : (Redirect())} >
                <Route path=":encryptedId" element={auth && checkTocken() ? (<VendorInvoiceSection />) : (Redirect())} />
                <Route path="create/:encryptedId" element={auth && checkTocken() ? <VendorInvoiceCreate /> : Redirect()} />
                <Route path="view/:inv/:encryptedId" element={auth && checkTocken() ? <VendorInvoiceSingle /> : Redirect()} />
                <Route path="edit/:inv/:encryptedId" element={auth && checkTocken() ? <VendorInvoiceEdit /> : Redirect()} />
              </Route>
              <Route path="banks" element={auth && checkTocken() ? (<VendorDetailLayout />) : (Redirect())} >
                <Route path=":encryptedId" element={auth && checkTocken() ? (<VendorBankSection />) : (Redirect())} />
              </Route>
              <Route path="invoice" element={auth && checkTocken() ? <VendorInvoice /> : Redirect()} />
              {/* <Route path="/" element={((auth && checkTocken()) && (userCat === INDIVIDUAL || userCat === BUSSI)) ? <VendorDetailLayout /> : Redirect()} >
                <Route path="invoice/:encryptedId" element={(auth && checkTocken()) ? <VendorInvoiceSection /> : Redirect()} />
                <Route path="invoice/create/:encryptedId" element={(auth && checkTocken()) ? <VendorInvoiceCreate /> : Redirect()} />
              </Route> */}
            </Route>
            {/* Vendor section */}

            {/* Report section */}
            <Route path="/report" element={auth && checkTocken() ? <Admin /> : Redirect()} >
              <Route path="overview" element={auth && checkTocken() ? <ReportingOverview /> : Redirect()} />
              <Route path="clients" element={auth && checkTocken() ? <ReportingClients /> : Redirect()} >
                <Route path="overview" element={auth && checkTocken() ? (<ReportingClientOverview />) : (Redirect())} />
                <Route path="timeworked" element={auth && checkTocken() ? (<ReportingClientTimeWorked />) : (Redirect())} />
              </Route>
              <Route path="financial" element={auth && checkTocken() ? <ReportingFinancial /> : Redirect()}  >
                <Route path="expense" element={auth && checkTocken() ? (<ReportingFinancialExpense />) : (Redirect())} />
                <Route path="revenue" element={auth && checkTocken() ? (<ReportingFinancialRevenue />) : (Redirect())} />
                <Route path="overview" element={auth && checkTocken() ? (<ReportingFinancialOverview />) : (Redirect())} />
              </Route>
              <Route path="personnel" element={auth && checkTocken() ? <ReportingPersonnel /> : Redirect()} >
                <Route path="timeoffrequest" element={auth && checkTocken() ? (<ReportingTimeOffRequest />) : (Redirect())} />
                <Route path="reimbursement" element={auth && checkTocken() ? (<ReportingReimbursement />) : (Redirect())} />
                <Route path="timeworked" element={auth && checkTocken() ? (<ReportingTimeWorked />) : (Redirect())} />
              </Route>
              <Route path="services" element={auth && checkTocken() ? <ReportingServices /> : Redirect()} />
            </Route>
            {/* Report section */}

            <Route path="test" element={<TestPage />} />
            <Route path="home" element={<Home />} />
            <Route path="*" element={<Page404Christmas />} />

            {/* app registration */}
            <Route path="app/otp/verification/:email" element={<OtpVerification />} />
            <Route path="app/register/:email/:otp" element={<AppRegister />} />
            <Route path="app/register/:email" element={<TenantInviteRegister />} />
            <Route path="app/google/auth/:id" element={<GoogleLoad />} />
            <Route path="app/google/auth/team/:encodedEmail" element={<GoogleLoginTeams />} />
            <Route path="app/google/auth/team/verify/:token" element={<GoogleLoginTeamsVerify />} />
            {/* end app registration */}

          </Routes>
          {showTimeZoneModal === true && (
            <TimeZoneModal
              state={alertState}
              from={alertFrom}
              data={timeZones}
            />
          )}
        </Router>
        <ToastContainer />
      </>
    </div>
  );
}

export default App;
