import React, { useState, useMemo } from 'react';
import download from '../../images/icons/download-gray.svg';
import UpArrowImage from "../../images/icons/up-down-arrow.svg";
import DownArrowImage from "../../images/icons/up-down-arrow.svg";

const SubscriptionOverview = () => {
  const dummyData = [
    {
      invoiceId: 'INV-00092',
      plan: 'Individual',
      billingDate: 'April 01, 2023',
      amount: '$100.00',
      paymentStatus: 'Paid',
    },
    {
      invoiceId: 'INV-00093',
      plan: 'Individual',
      billingDate: 'April 02, 2023',
      amount: '$200.00',
      paymentStatus: 'Pending',
    },
    {
      invoiceId: 'INV-00094',
      plan: 'Individual',
      billingDate: 'April 03, 2023',
      amount: '$500.00',
      paymentStatus: 'Paid',
    },
  ];

  const [sort, setSort] = useState({
    column: '',
    direction: '',
  });

  const sortedData = useMemo(() => {
    if (!sort.column) return dummyData;
    const sorted = [...dummyData];
    sorted.sort((a, b) => {
      if (sort.direction === 'asc') {
        return a[sort.column] < b[sort.column] ? -1 : 1;
      } else {
        return a[sort.column] > b[sort.column] ? -1 : 1;
      }
    });
    return sorted;
  }, [sort.column, sort.direction, dummyData]);

  const handleSort = (column) => {
    if (sort.column === column) {
      setSort({ column, direction: sort.direction === 'asc' ? 'desc' : 'asc' });
    } else {
      setSort({ column, direction: 'asc' });
    }
  };

  const getSortIcon = (column) => {
    if (sort.column === column) {
      return (
        <span>
          {sort.direction === 'asc' ? (
            <img src={UpArrowImage} alt="ascending" style={{ width: 12, height: 12 }} />
          ) : (
            <img src={DownArrowImage} alt="descending" style={{ width: 12, height: 12 }} />
          )}
        </span>
      );
    }
    return <img src={UpArrowImage} alt="sort" style={{ width: 12, height: 12 }} />;
  };

  return (
    <div className="card-outer1">
      <div className="time-table">
        <table style={{ minWidth: "50rem", width: "100%", textAlign: "left" }}>
          <thead>
            <tr>
              <th onClick={() => handleSort('invoiceId')}>
                Invoice ID
                {getSortIcon('invoiceId')}
              </th>
              <th onClick={() => handleSort('plan')}>
                Plan
                {getSortIcon('plan')}
              </th>
              <th onClick={() => handleSort('billingDate')}>
                Billing Date
                {getSortIcon('billingDate')}
              </th>
              <th onClick={() => handleSort('amount')}>
                Amount
                {getSortIcon('amount')}
              </th>
              <th onClick={() => handleSort('paymentStatus')}>
                Payment Status
                {getSortIcon('paymentStatus')}
              </th>
              <th className="subscription-download-icon"></th>
            </tr>
          </thead>
          <tbody>
            {sortedData.length === 0 ? (
              <tr>
                <td colSpan="6" style={{ textAlign: "center" }}>
                  No Data Available
                </td>
              </tr>
            ) : (
              sortedData.map((row, index) => (
                <tr key={index}>
                  <td>{row.invoiceId}</td>
                  <td>{row.plan}</td>
                  <td>{row.billingDate}</td>
                  <td>{row.amount}</td>
                  <td>
                    <span
                      className={
                        row.paymentStatus === "Paid"
                          ? "payment-status manual"
                          : "payment-status pending"
                      }
                    >
                      {row.paymentStatus}
                    </span>
                  </td>
                  <td className="subscription-download-icon">
                    <button type="button">
                      <img src={download} alt="download" />
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SubscriptionOverview;