import React, { useState } from 'react';

const SubscriptionPlans = () => {
  const [tab, setTab] = useState('billedMonthly');

  const handleTabClick = (tab) => {
    setTab(tab);
  };

  return (
    <div className="subscription-plans">
        <div className="subscription-plans-heading">
            <h3>Talenton Subscriptions</h3>
            <p>Choose the subscription plan that best suits your needs.</p>
        </div>
      <div className='subscription-plans-inner'>
        <div className="tabs">
            <button className={`tab-button ${tab === 'billedMonthly' ? 'active' : ''}`} onClick={() => handleTabClick('billedMonthly')}>Billed Monthly</button>
            <button className={`tab-button ${tab === 'billedAnnually' ? 'active' : ''}`} onClick={() => handleTabClick('billedAnnually')}>Billed Annually</button>
        </div>
        <div className="plans">
            {tab === 'billedMonthly' && (
            <div className="plan-box">
                <div className='top-plan-box'>
                    <h4>Plan 1</h4>
                    <span className="plan-price">$49.99</span>
                    <p>per user/month, billed annually</p>
                </div>
                <div className='top-plan-feature'>
                <p>For Freelancers</p>
                <ul>
                    <li>Lorem Ipsum</li>
                    <li>Lorem Ipsum</li>
                    <li>Lorem Ipsum</li>
                    <li>Lorem Ipsum</li>
                </ul>
                <button className="get-started-btn">Get Started</button>
                </div>
            </div>
            )}
            {tab === 'billedMonthly' && (
            <div className="plan-box">
                <div className='top-plan-box'>
                    <h4>Plan 2</h4>
                    <span className="plan-price">$99.99</span>
                    <p>per user/month, billed annually</p>
                </div>
                <div className='top-plan-feature'>
                <p>For Small Teams</p>
                <ul>
                    <li>Lorem Ipsum</li>
                    <li>Lorem Ipsum</li>
                    <li>Lorem Ipsum</li>
                    <li>Lorem Ipsum</li>
                </ul>
                <button className="get-started-btn">Get Started</button>
                </div>
            </div>
            )}
            {tab === 'billedMonthly' && (
            <div className="plan-box">
                <div className='top-plan-box'>
                    <h4>Plan 3</h4>
                    <span className="plan-price">$199.99</span>
                    <p>per user/month, billed annually</p>
                </div>
                <div className='top-plan-feature'>
                <p>For Large Teams</p>
                <ul>
                    <li>Lorem Ipsum</li>
                    <li>Lorem Ipsum</li>
                    <li>Lorem Ipsum</li>
                    <li>Lorem Ipsum</li>
                </ul>
                <button className="get-started-btn">Get Started</button>
                </div>
            </div>
            )}
            {tab === 'billedAnnually' && (
            <div className="plan-box">
                <div className='top-plan-box'>
                    <h4>Plan 1</h4>
                    <span className="plan-price">$499.99</span>
                    <p>per user/month, billed annually</p>
                </div>
                <div className='top-plan-feature'>
                <p>For Freelancers</p>
                <ul>
                    <li>Lorem Ipsum</li>
                    <li>Lorem Ipsum</li>
                    <li>Lorem Ipsum</li>
                    <li>Lorem Ipsum</li>
                </ul>
                <button className="get-started-btn">Get Started</button>
                </div>
            </div>
            )}
            {tab === 'billedAnnually' && (
            <div className="plan-box">
                <div className='top-plan-box'>  
                    <h4>Plan 2</h4>
                    <span className="plan-price">$999.99</span>
                    <p>per user/month, billed annually</p>
                </div>
                <div className='top-plan-feature'>
                <p>For Small Teams</p>
                <ul>
                    <li>Lorem Ipsum</li>
                    <li>Lorem Ipsum</li>
                    <li>Lorem Ipsum</li>
                    <li>Lorem Ipsum</li>
                </ul>
                <button className="get-started-btn">Get Started</button>
                </div>
            </div>
            )}
            {tab === 'billedAnnually' && (
            <div className="plan-box">
                <div className='top-plan-box'>
                    <h4>Plan 3</h4>
                    <span className="plan-price">$1999.99</span>
                    <p>per user/month, billed annually</p>
                </div>
                <div className='top-plan-feature'>
                    <p>For Large Teams</p>
                    <ul>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                    </ul>
                    <button className="get-started-btn">Get Started</button>
                </div>
            </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPlans;