import './TwoFA.css';
import axios from 'axios';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import Logo from '../../images/logo/to-logo.svg';
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useState, useRef } from 'react';
import crssrImage from "../../images/icons/close-black.svg";
import { login, loginTemp as LT } from '../../features/auth/authSlice';

export default function TwoFA() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [qrcode, setQrcode] = useState(null);
  const [showQr, setShowQr] = useState(false);
  const [is2faVerify, setIs2faVerify] = useState(false);
  const [codeError, setCodeError] = useState(null);
  const { loginTemp } = useSelector((state) => state.auth);
  const { appSettings } = useSelector((state) => state.appSettings);
  const codeInputs = useRef([]);
  const [code, setCode] = useState(['', '', '', '', '', '']);
  const [isLoadPage , setIsLoadPage] = useState(true);

  useEffect(() => {

    setIsLoadPage(true);
    if (localStorage.getItem("2faSkip")==="true") {
      dispatch(login(loginTemp));
      navigate('/user/dashboard');
    }

    if(!loginTemp){
      navigate('/');
    }

    const twoFactorEnabledAt = localStorage.getItem("twoFactorEnabledAt");
   

    function checkTwoFactorStatus() {
      if (twoFactorEnabledAt && localStorage.getItem("2faSkip")!=="true") {
        const oneWeekInMs = 7 * 24 * 60 * 60 * 1000; // One week in milliseconds
        const enabledAtDate = new Date(twoFactorEnabledAt);
        const now = new Date();
    
        // Check if the time difference is greater than one week
        if (now - enabledAtDate > oneWeekInMs) {
          // More than one week has passed, so prompt for 2FA
          //setIs2faVerify(true)
        } else {
          dispatch(login(loginTemp));
          navigate('/user/dashboard');
          // Optionally, continue without requiring 2FA
        }
      }
    }

    checkTwoFactorStatus();
    setIsLoadPage(false);
  },[]);

  useEffect(() => {

    const fetchQr = async () => {
      try {
        const response = await axios.post('/api/user/generateQRCode', {
          userId: loginTemp._id,
        });
        setQrcode(response.data);
      } catch (error) {
        console.error(error);
       // global.config.slackMessage(error.toString());
      }
    };

    fetchQr();
  }, [loginTemp, navigate]);

  const skipTwoFA = () => {
    dispatch(LT('sasa'));
    dispatch(login(loginTemp));
    localStorage.setItem('2faSkip',true);
    navigate('/user/dashboard');
  };

  const enableTwoFA = async () => {
    try {
      await axios.post('/api/user/enableTwofa', {
        userId: loginTemp._id,
        secret: qrcode.secret.base32,
      });
      setIs2faVerify(true);
    } catch (error) {
      console.error(error);
      global.config.slackMessage(error.toString());
    }
  };

  const verifyEnableTwoFA = async () => {
    const isCodeEmpty = code.some((digit) => digit === '');
    if (isCodeEmpty) {
      toast.error("You need to enter a code to enable 2FA", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    try {
      const response = await axios.post('/api/user/verify/enable/twofa', {
        userId: loginTemp._id,
        otpCode: code.join(''),
        secret: qrcode.secret.base32,
      });
      if (response.data === true) {
        localStorage.removeItem("2faSkip");
        localStorage.setItem('twoFactorEnabled', 'true');
        localStorage.setItem('twoFactorEnabledAt', Date.now());
        dispatch(login(loginTemp));
        navigate('/user/dashboard');
        closeVerify2faModal();
      } else {
        setCodeError('Invalid Code. Please Try again.');
      }
    } catch (error) {
      setCodeError('Invalid Code. Please Try again.');
      global.config.slackMessage(error.toString());
      console.error(error);
    }
  };

  const closeVerify2faModal = () => {
    setCodeError(null);
    setCode(['', '', '', '', '', '']);
    setIs2faVerify(false);
  };

  const verifyTwoFA = async () => {
    try {
      const response = await axios.post('/api/user/verify-twofa', {
        otpCode: code.join(''),
        secret: loginTemp.twoFa_secret,
      });

      if (response.data === true) {
        localStorage.setItem('twoFactorEnabled', 'true');
        localStorage.setItem('twoFactorEnabledAt', Date.now());
        dispatch(login(loginTemp));
        navigate('/user/dashboard');
      } else {
        setCodeError('Invalid Code. Please Try again.');
      }
    } catch (error) {
      global.config.slackMessage(error.toString());
      console.error(error);
    }
  };

  const showQrCode = () => {
    setShowQr(true);
  };

  const handleCodeChange = (index, value) => {
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);

    // Move to the next input field
    if (value !== '' && index < code.length - 1) {
      codeInputs.current[index + 1].focus();
    }
  };

  const handleCodeKeyDown = (index, event) => {
    if (event.key === 'Backspace' && index > 0 && code[index] === '') {
      // Move to the previous input field when backspace is pressed and the current field is empty
      codeInputs.current[index - 1].focus();
    }
  };

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur()

    // Prevent the page/container scrolling
    e.stopPropagation()

    setTimeout(() => {
      e.target.focus()
    }, 0)
  }

  return (
    <>
      <div className="outer-twofactor">
        <div className="logo">
          <img src={Logo} alt="TalentOn" />
        </div>
        {(!showQr && (loginTemp && loginTemp.is_2fa_enabled === false)) && (isLoadPage === false) && localStorage.getItem("2faSkip")!=="true" && (
          <div className="choosing-outer">
            <div className="choose-inner">
              <div className="content-two-factor">
                <h3>Consider adding a second method for two-factor authentication</h3>
                <p>
                  Having two methods helps make sure you can still access your account if your first method is ever
                  unavailable.
                </p>
              </div>
              <div className="two-factor-btns">
                <button className="add-method" onClick={showQrCode}>
                  Add second method
                </button>
                {(appSettings.twoFa!==true) && (
                <button className="skip-btn" onClick={skipTwoFA}>
                  Skip for now
                </button>)}
              </div>
            </div>
          </div>
        )}

        {(loginTemp && loginTemp.is_2fa_enabled === true) && (isLoadPage === false) && (
          <div className="twoFAContainer">
            <>
              <div className='qr-code-title'>
                Enter 6-digit code
              </div>
              <div className='qr-code-des'>
                Enter the code from your two-factor authentication app
              </div>
              <div className="code-input-container">
                {code.map((digit, index) => (
                  <input
                    key={index}
                    ref={(ref) => (codeInputs.current[index] = ref)}
                    type="number"
                    className="code-input"
                    value={digit}
                    onChange={(e) => handleCodeChange(index, e.target.value)}
                    onKeyDown={(e) => handleCodeKeyDown(index, e)}
                    maxLength={1}
                    onWheel={numberInputOnWheelPreventChange}
                  />
                ))}
              </div>
              {codeError && <p className="error" style={{ color: 'red' }}>{codeError}</p>}
              <div className='qr-code-btn'>
                <button type="submit" onClick={verifyTwoFA}>Verify</button>
              </div>
            </>
          </div>
        )}

        {(showQr && !is2faVerify && loginTemp.is_2fa_enabled === false) && (isLoadPage === false) && (
          <div className="twoFAContainer">
            <>
              <div className='qr-title'>
                <h2>Register Talenton</h2>
              </div>
              <div className='qr-des'>
                <p>Scan this QR code with a Google Authenticator app.</p>
              </div>
              <div className='qr-image-sec'>
                <img className="qrCodeImage" src={qrcode.data} alt="QR Code" />
              </div>
              <div className='qr-btn'>
                <a className="linkButton" onClick={enableTwoFA}>Enable</a>
              </div>
              <div className='skip-btn'>
                <a className="linkButton" onClick={skipTwoFA}><img src={crssrImage} alt="Edit Icon" /></a>
              </div>
              <div className='qr-bottom-sec'>
                <p>Once you connect your Talenton account to the security or authentication app, you’ll get a one-time verification code whenever you need it.</p>
              </div>
            </>
          </div>
        )}

        {(is2faVerify && loginTemp && loginTemp.is_2fa_enabled === false) && (isLoadPage === false) && (
          <div className="twoFAContainer">
            <>
              <div className='skip-btn'>
                <a className="linkButton" onClick={closeVerify2faModal}><img src={crssrImage} alt="Edit Icon" /></a>
              </div>
              <div className='qr-code-title'>
                Enter 6-digit code
              </div>
              <div className='qr-code-des'>
                Enter the code from your two-factor authentication app to enable 2FA
              </div>
              <div className="code-input-container">
                {code.map((digit, index) => (
                  <input
                    key={index}
                    ref={(ref) => (codeInputs.current[index] = ref)}
                    type="number"
                    className="code-input"
                    value={digit}
                    onChange={(e) => handleCodeChange(index, e.target.value)}
                    onKeyDown={(e) => handleCodeKeyDown(index, e)}
                    maxLength={1}
                    onWheel={numberInputOnWheelPreventChange}
                  />
                ))}
                {codeError && <p className="error two-fa-error" style={{ color: 'red' }}>{codeError}</p>}
              </div>
              <div className='qr-code-btn'>
                <button type="submit" onClick={verifyEnableTwoFA}>Verify</button>
              </div>
            </>
          </div>
        )}
      </div>
    </>
  );
}