import axios from "axios";
import { useQuery } from "react-query";


export async function fetchInvoices(startDate, endDate, token) {
    try {

        let cancelToken;
        if (cancelToken) {
            cancelToken.cancel();
        }
        cancelToken = axios.CancelToken.source();

        const response = await axios.post(
            "/api/invoices",
            { startDate, endDate },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        return response.data;
    } catch (e) {
        console.log('Error', e);
        throw e;  // Rethrow the error to handle it in useQuery
    }
}

export async function fetchInvoices2({
    startDate,
    endDate,
    token,
    invNumber = '',
    invTypes = [],
    invCreatedDates = [],
    invDueDates = [],
    invStatuses = [],
    invPaymentStatuses = [],
    userIds = [],
    page = 1,
    limit = 10,
    sortByUserName = null,
}) {
    try {
        const cancelTokenSource = axios.CancelToken.source();

        const response = await axios.post(
            "/api/invoices-optimized",
            {
                startDate,
                endDate,
                invNumber,
                invTypes,
                invCreatedDates,
                invDueDates,
                invStatuses,
                invPaymentStatuses,
                userIds,
                page,
                limit,
                sortByUserName,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                cancelToken: cancelTokenSource.token,
            }
        );

        return response.data;
    } catch (error) {
        console.error("Error fetching invoices:", error.message);
        if (axios.isCancel(error)) {
            console.log("Request canceled:", error.message);
        } else {
            throw error;
        }
    }
}

export function useFetchInvoices({
    startDate,
    endDate,
    token,
    invNumber,
    invTypes,
    invCreatedDates,
    invDueDates,
    invStatuses,
    invPaymentStatuses,
    userIds,
    page,
    limit,
    sortByUserName,
  }) {
    return useQuery(
      [
        "invoices",
        startDate,
        endDate,
        invNumber,
        invTypes,
        invCreatedDates,
        invDueDates,
        invStatuses,
        invPaymentStatuses,
        userIds,
        page,
        limit,
        sortByUserName,
      ],
      () =>
        fetchInvoices2({
          startDate,
          endDate,
          token,
          invNumber,
          invTypes,
          invCreatedDates,
          invDueDates,
          invStatuses,
          invPaymentStatuses,
          userIds,
          page,
          limit,
          sortByUserName,
        }),
      {
        staleTime: 5 * 60 * 1000, // Data is fresh for 5 minutes
        cacheTime: 10 * 60 * 1000, // Data remains in cache for 10 minutes
        keepPreviousData: true, // Keep old data during refetch
        onError: (error) => {
          console.error("Error fetching invoices:", error);
        },
      }
    );
  }  