
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';

function createAndLogUUID() {
        const uniqueId = uuidv4();
        return uniqueId;
}

export async function sendRegisterEmail(email, subDomain) {

        try {
                const uuid = createAndLogUUID();
                const response = await axios.post(
                        `/api/app/register/email`,
                        {
                                email: email,
                                uuid: uuid,
                                subDomain
                        },
                        {
                                headers: {
                                        // Authorization: `Bearer ${token}`,
                                },
                        }
                );

                return response.data;

        } catch (error) {


                console.log(error);
        }
}


export async function otpVerification(email, otp) {

        try {

                const response = await axios.post(
                        `/api/app/otp/verification`,
                        {
                                email: email,
                                otp: otp,
                        },
                        {
                                headers: {
                                        // Authorization: `Bearer ${token}`,
                                },
                        }
                );

                return response.data;

        } catch (error) {


                console.log(error);
        }
}


export async function registerUser(email, companyName, password, database, google) {
        try {
                const response = await axios.post(
                        `/api/app/register`,
                        {
                                email: email,
                                companyName: companyName,
                                password: password,
                                database: database,
                                google: google,
                        },
                        {
                                headers: {
                                        // Authorization: `Bearer ${token}`,
                                },
                        }
                );
                return response.data;
        } catch (error) {
                console.log(error);
        }
}


export async function checkGoogleUser(token) {
        try {
                const response = await axios.post(
                        `/api/goole-auth/verification`,
                        {
                                token: token
                        },
                        {
                                headers: {
                                        // Authorization: `Bearer ${token}`,
                                },
                        }
                );
                return response.data;
        } catch (error) {
                console.log(error);
        }
}