// components/SubdomainGuard.js
import React, { useState, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { IsValidSubdomain } from '../utils/subdomainUtils';
import { toast } from "react-toastify";

const SubdomainGuard = ({ children }) => {
  const navigate = useNavigate();
  const [isValid, setIsValid] = useState(null); // null initially to represent the loading state

  useEffect(() => {
    const checkSubdomain = async () => {
      const isValidSubdomain = await IsValidSubdomain();
      setIsValid(isValidSubdomain);
    };

    checkSubdomain();
  }, []);

  if (isValid === null) {
    // Render a loading indicator or nothing while loading
    // return <div>Loading...</div>;
  }

  if (isValid === false) {
    toast.error("Invalid subdomain. Please try again!!", {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  
    setTimeout(() => {
      window.location.href = 'https://app.talenton.io';
    }, 3000); // 3-second delay
  
    return null; // Render nothing while redirecting
  }

  // If valid, render the wrapped component(s)
  return children;
};

export default SubdomainGuard;