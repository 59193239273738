import React from 'react';

const CurrentPlan = () => {
  return (
    <div className="current-plan">
        <div className='current-plan-inner'>
            <div className='current-plan-inner-top'>
                <h3>Your current plan: For Freelancers</h3>
                <p>Lorem ipsum dolor sit amet consecteur.</p>
            </div>
            <div className='current-plan-inner-bottom'>
                <div className='current-plan-price'>
                    $99.99/month
                </div>
                <div className='upgrade-plan-btn'>
                    <button>Upgrade Plan</button>
                </div>
            </div>
        </div>      
    </div>
  );
};

export default CurrentPlan;