import axios from 'axios';
import moment from 'moment';
import { useMemo } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from 'react-query';

export function useFetchTenantsCount(token, startDate, endDate) {
    return useQuery(
        ['tenantsCount', startDate, endDate],
        async () => {
            const response = await axios.post(
                '/api/get/tenants/count',
                { startDate, endDate },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            return response.data.count;
        },
        {
            enabled: !!token && !!startDate && !!endDate,
        }
    );
}

export function useFetchActiveTenantsCount(token, startDate, endDate) {
    return useQuery(
        ['activeTenantsCount', startDate, endDate],
        async () => {
            const response = await axios.post(
                '/api/get/active/tenants/count',
                { startDate, endDate },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            
            return response.data.count;
        },
        {
            enabled: !!token && !!startDate && !!endDate,
        }
    );
}

export function useFetchInactiveTenantsCount(token, startDate, endDate) {
    return useQuery(
        ['inactiveTenantsCount', startDate, endDate],
        async () => {
            const response = await axios.post(
                '/api/get/inactive/tenants/count',
                { startDate, endDate },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            return response.data.count;
        },
        {
            enabled: !!token && !!startDate && !!endDate,
        }
    );
}

export function useFetchRecentTenants(token, startDate, endDate) {
    return useQuery(['allTenants', startDate, endDate],
        async () => {
            const response = await axios.post(
                '/api/tenants/recent',
                { startDate, endDate },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            
            return response.data;
        },
        {
            enabled: !!token && !!startDate && !!endDate,
        }
    );
}

export function useForexCurrency(currencies) {
    return useQuery('forex', async () => {
        // const response = await axios.get('/api/dashboard/invoice-currency', {
        //     headers: {
        //         Authorization: `Bearer ${token}`,
        //     },
        // });
        currencies.map((item) => {

        });
        return currencies;
    });
}