

export const calculateTotalCost = (trackedTime) => {
        let totalSumCost = 0;
        trackedTime && trackedTime.map(items => {
                // const totalCost = (parseFloat(items.convertedCost) + parseFloat(items.convertedOverheadCost)) * (parseInt(items.duration) / 3600);
                totalSumCost += parseFloat(items.convertedCost)
        });
        return totalSumCost;
};

export const calculateTotalBudget = (trackedTime) => {
        let totalBudget = 0;
        const processedProjects = new Set();
        trackedTime && trackedTime.forEach((item) => {
                if (item.project && !processedProjects.has(item.project)) {
                        if (item.projectBudgetConverted) {
                                totalBudget += parseFloat(item.projectBudgetConverted);
                        }
                        processedProjects.add(item.project._id);
                }
        });
        return totalBudget;
};

export const calculateTotalDuration = (trackedTime) => {
        let totalDuaration = 0;
        trackedTime && trackedTime.map(items => {
                totalDuaration += parseInt(items.duration)
        });
        return totalDuaration;
};

export const calculateTotalDurationBillable = (trackedTime) => {
        let totalDuaration = 0;
        trackedTime && trackedTime.map(items => {
                if (items.project.is_billable === true) {
                        totalDuaration += parseInt(items.duration);
                }
        });
        return totalDuaration;
};

export const calculateTotalDurationNonBillable = (trackedTime) => {
        let totalDuaration = 0;
        trackedTime && trackedTime.map(items => {
                if (items.project.is_billable === false) {
                        totalDuaration += parseInt(items.duration);
                }
        });
        return totalDuaration;
};

