import "./Login.css";
import axios from "axios";
import moment from "moment";
import Cookies from 'js-cookie';
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import Logo from "../../images/logo/to-logo.svg";
import React, { useState, useEffect } from "react";
import EyeIcon from "../../images/icons/eye-icon.svg";
import { useSelector, useDispatch } from "react-redux";
import LoginImage from "../../images/login/lg-img.png";
import { loginTemp } from "../../features/auth/authSlice";
import Spinner from "../../components/Animations/Spinner";
import EyeCrossed from "../../images/icons/eye-crossed.svg";
import LoginImageMobile from "../../images/login/lg-img-mob.png";
import { login, loginTemp as LT } from '../../features/auth/authSlice';
import { setDashboardCurrencyRx } from "../../features/invoice/invoiceSlice";
import { setAppSettings } from "../../features/appSettings/appSettingsSlice.js";
import { setForex } from "../../features/forex/forexSlice.js";
import Modal from 'react-modal';


const SUPER_ADMIN = 1;
const ADMIN = 2;
const USER = 3;
// BUSINESS: 4,
const COLLABERATOR = 5;
const PROJECT_MANAGER = 6;
const TEAM_MANAGER = 7;


function Login() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ email: "", password: "", });
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State for controlling password visibility
  const { email, password } = formData;
  const [errorMessage, setErrorMessage] = useState(null);
  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(true);
  const handleCancelDelete = () => {
    setIsDeleteModalOpen(false);
  };

  const onChange = (e) => {
    setErrorMessage(null)
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const fetchForex = async () => {

    var settingsData = await axios.post(`/api/forex/get`, { base_currency: "USD" }, {
      headers: {

      }
    });
    await dispatch(setForex(settingsData.data.json));
    return settingsData.data.json;

  }

  const onSubmit = async (e) => {
    e.preventDefault();

    let favIcon_fileName;
    let app_owner_name;
    let app_owner_id;


    setLoading(true);
    if (email && password) {

      if (rememberMe) {
        Cookies.set('email', email, { expires: 30 }); // Store username in a cookie for 30 days
        Cookies.set('password', password, { expires: 30 }); // Store password in a cookie for 30 days
      }

      try {

        const res = await fetch("/api/user/login", {
          method: "POST",
          headers: { "Content-Type": "application/json", },
          body: JSON.stringify({ email, password }),
        });

        const data = await res.json();

        if (data.token) {

          const dispatchLoginTemp = await dispatch(loginTemp(data));
          const forexFetch = await fetchForex();

          var userSettingsData = await axios.get(`/api/get/user-settings/${data._id}`, {
            headers: {
              Authorization: `Bearer ${data.token}`,
            }
          });

          var favIconData = await axios.get(`/api/view/owner/by/appid/${data.appId}`, {
            headers: {
              Authorization: `Bearer ${data.token}`,
            },
          });


          // update favorite icon

          if (favIconData && favIconData.data) {
            favIcon_fileName = favIconData?.data?.fav_icon || "fav_icon_default.jpeg";
            app_owner_name = favIconData.data.commercial_name;
            app_owner_id = favIconData.data._id;
          } else {
            favIcon_fileName = "fav_icon_default.jpeg";
            app_owner_name = "TalenTon";
            app_owner_id = favIconData.data._id;
          }

          // update favorite icon


          var settingsData = await axios.get(`/api/get/app-settings/${data.appId}`, {
            headers: {
              Authorization: `Bearer ${data.token}`,
            }
          });



          // dispatch settings data to redux

          if (settingsData.data !== null && (data.userType !== SUPER_ADMIN && data.userType !== ADMIN)) {

            settingsData.data.currency = userSettingsData?.data?.currency ? userSettingsData?.data?.currency : "USD";
            settingsData.data.timeZone = userSettingsData?.data?.timeZone ? userSettingsData?.data?.timeZone : null;
            settingsData.data.timeFormat = userSettingsData?.data?.timeFormat ? userSettingsData?.data?.timeFormat : 12;
          }
         // const companyLogo = settingsData.data.appOwnerDetails.company_logo;
          await dispatch(setAppSettings({ ...settingsData.data, favIcon: favIcon_fileName, app_ownername: app_owner_name, app_ownerid: app_owner_id }));

          // dispatch settings data to redux




          // update dashboard currency

          const response = await axios.get("/api/dashboard/invoice-currency", {
            headers: {
              Authorization: `Bearer ${data.token}`,
            }
          });


          if (response.data.length > 0) {
            localStorage.setItem('DashboardCurrency', JSON.stringify(response.data[0]));
            dispatch(setDashboardCurrencyRx(response.data[0]));
          }

          // update dashboard currency



          let already2fa = localStorage.getItem('twoFactorEnabled');
          let twoFactorEnabled = true;
          let twoFactorEnabledAt = moment.tz(global.config.getTimeZone()).format();




          if (settingsData && settingsData.data.twoFa === true) {

            twoFactorEnabled = localStorage.getItem('twoFactorEnabled');
            twoFactorEnabledAt = localStorage.getItem('twoFactorEnabledAt');
            
          } else {
           

            twoFactorEnabled = true;
            twoFactorEnabledAt = moment.tz(global.config.getTimeZone()).format();

          }




          if (twoFactorEnabled && twoFactorEnabledAt) {

            if (twoFactorEnabled && already2fa !== null && Date.now() - twoFactorEnabledAt < 30 * 24 * 60 * 60 * 1000) {
              // 2FA is enabled and it's within the 30-day timeframe
              dispatch(login(data));
              navigate('/user/dashboard');
            } else {
              // Redirect user to enable 2FA
              navigate("/2fa");
            }
            
          } else {

            if (userSettingsData.data.twoFa === false) {
              // 2FA is enabled and it's within the 30-day timeframe
              dispatch(login(data));
              navigate('/user/dashboard');

            } else {

            
              navigate("/2fa");
              return;

            }

          }
        } else {
          setErrorMessage(data);
          toast.error(data.message, {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        setLoading(false);
      } catch (err) {
        console.error(err.message);
        global.config.slackMessage(err.toString());
        toast.error(err.message, {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      }
    } else {
      setErrorMessage({ message: "", field: "both" });
      toast.error("Please enter username/password.", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoading(false);
    }
  };

  useEffect(() => {

    const element = document.getElementById('userback_button_container');

    if (element) {
      element.style.display = 'none';
    }
    const storedEmail = Cookies.get('email');
    const storedPassword = Cookies.get('password');
    if (storedEmail && storedPassword) {
      setFormData({ email: storedEmail, password: storedPassword })
      setRememberMe(true);
    }

    const token = localStorage.getItem("user");
    if (token) {
      navigate("user/dashboard");
    }
    if (isError) {
      toast.error(message);
    }
    if (isSuccess && user) {
      navigate("user/dashboard");
    }
  }, [user, isError, isSuccess, message, navigate]);

  if (isLoading) {
    return <Spinner />;
  }

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  return (
    <div>
      <div className="login-outer">
        <div className="login-inner">
          <div className="top-logo-mobile">
            <a href="/"><img src={Logo} alt="Talent On" /></a>
          </div>
          <div className="lg-lft">
            <img src={LoginImage} alt="desktop" className="desktop-lg-img" />
            <img src={LoginImageMobile} alt="mobile" className="mobile-lg-img" />
          </div>
          <div className="lg-rgt">
            <div className="top-logo">
              <a href="/"><img src={Logo} alt="Talent On" /></a>
            </div>
            <div className="login-form-sec">
              <form onSubmit={onSubmit}>
                <h3>Hi! Please enter your details.</h3>
                <div className="form-outer email">
                  <label>Email</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="email@gmail.com"
                    value={email}
                    onChange={onChange}
                    className={errorMessage && (errorMessage.field === 'username' || errorMessage.field === "both") && "error"}
                  />
                </div>
                <div className="form-outer password">
                  <label>Password</label>
                  <div className="password-input-wrapper">
                    <input
                      type={showPassword ? "text" : "password"} // Use the showPassword state to toggle between text and password type
                      id="password"
                      name="password"
                      placeholder="Password"
                      value={password}
                      onChange={onChange}
                      className={errorMessage && (errorMessage.field === 'password' || errorMessage.field === "both") && "error"}
                    />
                    <img
                      src={showPassword ? EyeCrossed : EyeIcon} // Use the custom eye icon image based on the showPassword state
                      className="password-toggle-icon"
                      alt="Toggle Password"
                      onClick={() => setShowPassword(!showPassword)} // Toggle showPassword state on eye icon click
                    />
                  </div>
                </div>
                <div className="remb-for">
                  <div className="remember-me">
                    <input type="checkbox" checked={rememberMe} onChange={handleRememberMeChange} />Remember me
                  </div>
                  <div className="forget-password">
                    <Link to="./forget-password">I forgot my password</Link>
                  </div>
                </div>
                <button className="login-button" disabled={loading}>
                  {loading ?
                    <div className="outter-load-table">
                      <ThreeDots
                        height="38"
                        width="40"
                        radius="9"
                        color="white"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div> : "Log In"
                  }
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Login.propTypes = {};
export default Login;