import axios from "axios";
import { AES, enc } from 'crypto-js';
import "./InviteRegister.css";
import Modal from "react-modal";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { toast } from "react-toastify";
import { useDispatch } from 'react-redux';
import { DatePicker } from "rsuite";
import 'react-phone-number-input/style.css';
import "react-toastify/dist/ReactToastify.css";
import { ThreeDots } from "react-loader-spinner";
import PhoneInput from 'react-phone-number-input';
import Type from "../../../images/icons/type.svg";
import Cost from "../../../images/icons/cost.svg";
import Tele from "../../../images/icons/tele.svg";
import { RotatingLines } from 'react-loader-spinner';
import Countries from "../../../constants/countries";
import userTypeName from "../../../constants/userType";
import EmailIcon from "../../../images/icons/email.svg";
import PassLock from "../../../images/icons/pass_lock.svg";
import Tick from "../../../images/icons/tick-arrow.svg";
import Company from "../../../images/icons/company.svg";
import Payment from "../../../images/icons/payment.svg";
import EyeIcon from "../../../images/icons/eye-icon.svg";
import location from "../../../images/icons/location.svg";
import JobTitle from "../../../images/icons/job-title.svg";
import Currencies from "../../../constants/currenciesList";
import React, { useEffect, useRef, useState, createRef } from "react";
import typeOfContract from "../../../constants/typeOfContract";
import PostalCode from "../../../images/icons/postal-code.svg";
import PassportlIcon from "../../../images/icons/passport.svg";
import birthIcon from "../../../images/icons/dat-of-birth.svg";
import EyeCrossed from "../../../images/icons/eye-crossed.svg";
import userCategoryName from "../../../constants/userCategory";
import { Link, useNavigate, useParams } from "react-router-dom";
import { logout, reset } from "../../../features/auth/authSlice";
import profile_image from "../../../images/user/Default_pfp.png";
import PicEdit from "../../../images/icons/profile-pic-edit.svg";
import UserIcon from "../../../images/icons/single-user-icon.svg";
import TypeContact from "../../../images/icons/type-of-contact.svg";
import { reset as invoiceReset } from "../../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../features/appSettings/appSettingsSlice.js";

export default function InviteRegisteration() {
  const dispatch = useDispatch();
  const { email, usertype, encodedAppId } = useParams();
  const fileInputRef = useRef(null);
  const [userCategory, setUserCategory] = useState(userCategoryName.Individual);
  const [userType, setUserType] = useState(usertype);
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyFirstName, setCompanyFirstName] = useState("");
  const [companyLastName, setCompanyLastName] = useState("");
  const [wxComapny, setWxComapny] = useState("");
  const [dept, setDept] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [contract, setContract] = useState("");
  const [currency, setCurrency] = useState("");
  const [cost, setCost] = useState("");
  const [overheadCost, setOverheadCost] = useState("");
  const [vat, setVat] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [profileImage, setProfileImage] = useState(null);
  const [profileImageName, setProfileImageName] = useState(null);
  const [dob, setDob] = useState("");
  const [passport, setPassport] = useState("");
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [vatId, setVatId] = useState("");
  const [registrationDocument, setRegistrationDocument] = useState(null);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [postCode, setPostCode] = useState("");
  const [address, setAddress] = useState("");
  const [uploadedImageUrl, setUploadedImageUrl] = useState("");
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [fileSelectIsLoading, setFileSelectIsLoading] = useState(false);
  const [countrySearchTerm, setCountrySearchTerm] = useState('');
  const [countryDropdownOpen, setCountryDropdownOpen] = useState(false);
  const [stateSearchTerm, setStateSearchTerm] = useState('');
  const [stateDropdownOpen, setStateDropdownOpen] = useState(false);
  const cropperRef = createRef();
  const [roleId, setRoleId] = useState([]);
  const [wXCompany, setWXCompany] = useState([]);
  const [userDept, setUserDept] = useState([]);
  const [typeContract, setTypeContract] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [passwordMatchError, setPasswordMatchError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const passwordConfirmationRef = useRef(null);
  const passwordRef = useRef(null);
  const countryDropdownRef = useRef(null);
  const stateDropdownRef = useRef(null);
  const decodedEmail = decodeURIComponent(
    atob(email.replace(/\-/g, "+").replace(/_/g, "/"))
  );
  const decodedAppId = decodeURIComponent(encodedAppId.replace(/%2F/g, '/'));
  const decryptedBytes = AES.decrypt(decodedAppId, 'encryptionKey');
  const appId = decryptedBytes.toString(enc.Utf8);
  const url = new URL(window.location.href);
  const subdomain = url.hostname.split('.')[0];
  const capitalizedSubdomain = subdomain.charAt(0).toUpperCase() + subdomain.slice(1);
  const navigate = useNavigate();
  useEffect(() => {
    const verifyResetPassLink = async () => {
      const { email } = { email: decodedEmail };
      try {
        const response = await axios.post("/api/user/check/user-invite/validity", { email });
        if (response.data.status === "emailAlreadyRegistered") {
          // Reset link expired, redirect to forget-password page with alert message
          toast.error("This email is already registered.!!!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate("/");
        }
        if (response.data.status === "linkExpired") {
          // Reset link expired, redirect to forget-password page with alert message
          toast.error("Sorry!.Invite link expired", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate("/");
        }
      } catch (error) {
        console.error(error);
        global.config.slackMessage(error.toString());
        toast.error("Something went wrong while verifying the reset link");
      }
    };
    verifyResetPassLink();

    async function fetchRoleId() {
      try {
        const response = await axios.get("/api/user-type");
        setRoleId(
          response.data.filter(
            (role) =>
              role._id !== userTypeName.Collaborator &&
              role._id !== userTypeName.Business
          )
        );
      } catch (error) {
        console.log(error);
        global.config.slackMessage(error.toString());
        if (error.response?.data?.status === "deleted") {
          localStorage.removeItem("user");
          dispatch(logout());
          dispatch(reset());
          dispatch(invoiceReset());
          dispatch(headerReset());
          dispatch(appSettingsReset());
          toast.error('This user is deleted!', {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate("/");
          return;
        }
        alert("Something went wrong");
      }
    }
    fetchRoleId();

    async function fetchWXCompany() {
      try {
        const response = await axios.get("/api/get-wx-comapies");
        setWXCompany(response.data);
      } catch (error) {
        console.log(error);
        global.config.slackMessage(error.toString());
        if (error.response?.data?.status === "deleted") {
          localStorage.removeItem("user");
          dispatch(logout());
          dispatch(reset());
          dispatch(invoiceReset());
          dispatch(headerReset());
          dispatch(appSettingsReset());
          toast.error('This user is deleted!', {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate("/");
          return;
        }
        alert("Something went wrong");
      }
    }

    fetchWXCompany();

    async function fetchUserDept() {
      try {
        const response = await axios.get("/api/user-department");
        setUserDept(response.data);
      } catch (error) {
        console.log(error);
        global.config.slackMessage(error.toString());
        if (error.response?.data?.status === "deleted") {
          localStorage.removeItem("user");
          dispatch(logout());
          dispatch(reset());
          dispatch(invoiceReset());
          dispatch(headerReset());
          dispatch(appSettingsReset());
          toast.error('This user is deleted!', {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate("/");
          return;
        }
        alert("Something went wrong");
      }
    }
    fetchUserDept();

    async function fetchTypeContract() {
      try {
        const response = await axios.get("/api/contract-type");
        setTypeContract(response.data);
      } catch (error) {
        console.log(error);
        global.config.slackMessage(error.toString());
        if (error.response?.data?.status === "deleted") {
          localStorage.removeItem("user");
          dispatch(logout());
          dispatch(reset());
          dispatch(invoiceReset());
          dispatch(headerReset());
          dispatch(appSettingsReset());
          toast.error('This user is deleted!', {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate("/");
          return;
        }
        alert("Something went wrong");
      }
    }
    fetchTypeContract();

    setCurrencyList(Currencies.sourceCurrencies[0].targetCurrencies);
    const sortedCountries = Countries.slice().sort((a, b) => a.country.localeCompare(b.country));
    setCountriesList(sortedCountries);

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        document.activeElement.blur();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitLoading(true);
    const mobileNoRegex = /^[+\d]+$/;
    if (!mobileNoRegex.test(mobileNo)) {
      toast.error("It's not a valid phone number", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsSubmitLoading(false);
      return;
    }
    if (password !== passwordConfirmation) {
      setPasswordMatchError("Passwords do not match");
      toast.error("Passwords do not match", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      passwordConfirmationRef.current.focus();
      setIsSubmitLoading(false);
      return;
    }
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$%\^&\*\(\)_\+\-\.\/:;<=>?@[\\\]^_`{|}~])[A-Za-z\d!@#\$%\^&\*\(\)_\+\-\.\/:;<=>?@[\\\]^_`{|}~]{8,}$/;
    if (!passwordRegex.test(password)) {
      setPasswordError("Invalid password format");
      toast.error("Password must be at least 8 charachters long and include atleast one uppercase letter ,one lowercase letter,one digit and one special character from the set !@#$%^&*()_+,-/:;<=>?@_'[\]{|}~", {
        position: "bottom-right",
        autoClose: 3800,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      passwordRef.current.focus();
      setIsSubmitLoading(false);
      return;
    }

    const fileExtension = profileImageName
      ? profileImageName.name.split(".").pop()
      : "";
    const uniqueSuffix = Date.now() + "-" + Math.round(Math.random() * 1e9);
    const profile_img_fileName = profileImageName
      ? `${profileImageName.name
        .split(".")
        .shift()}_${uniqueSuffix}.${fileExtension}`
      : null;
    const requestData = {

      first_name: [userCategoryName.Business, userCategoryName.Vendor, userCategoryName.Client].includes(userCategory) ? "" : firstName,
      middle_name: [userCategoryName.Business, userCategoryName.Vendor, userCategoryName.Client].includes(userCategory) ? "" : middleName,
      last_name: [userCategoryName.Business, userCategoryName.Vendor, userCategoryName.Client].includes(userCategory) ? "" : lastName,
      user_wx_comapny: "65125c1d4845bcfc83c5bf13",
      email: decodedEmail,
      mobile_no: mobileNo,
      passport_no: passport,
      password,
      user_category: userCategory,
      role_id: userType,
      department: "6466ffa5efdb9ea1084d88c7",
      typeOfContract: "64670fbe6cb18be88f7cc3bc",
      job_title: "",
      payment_currency: "USD",
      cost: 0,
      overHeadCostType: "65dd73c74c4587f420433d5d",
      overhead_cost: 0,
      vat: 0,
      address,
      pincode: postCode,
      dob,
      country,
      state,
      city,
      company_name: [userCategoryName.Business, userCategoryName.Vendor, userCategoryName.Client].includes(userCategory) ? companyName : "",
      company_first_name: [userCategoryName.Business, userCategoryName.Vendor, userCategoryName.Client].includes(userCategory) ? companyFirstName : "",
      company_last_name: [userCategoryName.Business, userCategoryName.Vendor, userCategoryName.Client].includes(userCategory) ? companyLastName : "",
      vat_number: [userCategoryName.Business, userCategoryName.Vendor, userCategoryName.Client].includes(userCategory) ? vatId : "",
      register_number: [userCategoryName.Business, userCategoryName.Vendor, userCategoryName.Client].includes(userCategory) ? registrationNumber : "",
      profile_image: profile_img_fileName,
      appId,
      registrationByInvite: true,
    };

    if (!requestData.appId) {
      toast.error("Something went wrong with the appId", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsSubmitLoading(false);
      return;
    }

    // Create a new FormData object
    const formData = new FormData();
    // Append the profile image to the form data
    formData.append("company_document", registrationDocument);

    // Append the other request data to the form data
    for (let key in requestData) {
      formData.append(key, requestData[key]);
    }

    try {
      const response = await axios.post("/api/user", formData);
      const data = response.data;
      if (data.status === "exists") {
        toast.warning("User with this email id already exists", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (data.status === "error") {
        console.log(data);
        toast.error("Something went wrong1", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.success("Your account is registered successfully. Please check your email", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        navigate(`/`);
      }

      if (profileImage) {
        const resizedImage = await resizeImage(profileImage, 500);
        const base64Image = resizedImage.split(',')[1];
        const blob = await fetch(`data:image/png;base64,${base64Image}`).then(res => res.blob());

        const formData = new FormData();
        formData.append("profile_image", blob, profile_img_fileName);

        try {
          const response = await axios.post("/api/uploads/profileImage", formData);
        } catch (error) {
          console.log(error);
          global.config.slackMessage(error.toString());
          alert("Something went wrong");
        }
      }
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error('This user is deleted!', {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      alert("Something went wrong");
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const handleProfilePicChange = () => {
    setFileSelectIsLoading(true);
    // Open file input dialog
    fileInputRef.current.click();
  };

  const handleFileInputCancel = () => {
    setFileSelectIsLoading(false);
  };

  useEffect(() => {
    const inputElement = fileInputRef.current;

    const handleChange = () => {
      handleFileInputChange({ target: { files: inputElement.files } });
    };

    const handleCancel = () => {
      handleFileInputCancel();
    };

    inputElement.addEventListener('change', handleChange);
    inputElement.addEventListener('cancel', handleCancel);

    return () => {
      inputElement.removeEventListener('change', handleChange);
      inputElement.removeEventListener('cancel', handleCancel);
    };
  }, []);

  const handleFileInputChange = (event) => {
    setFileSelectIsLoading(false);
    const file = event.target.files[0];
    setProfileImageName(event.target.files[0])
    if (file) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        setImage(fileReader.result);
        setModalIsOpen(true);
      };
      fileReader.readAsDataURL(file);
    }
  };

  const closeModal = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setFileSelectIsLoading(false);
    setModalIsOpen(false);
  };

  const handleCountrySearchInputChange = (e) => {
    setCountrySearchTerm(e.target.value);
  };

  const filteredCountries = countriesList.filter((country) => {
    return country.country.toLowerCase().includes(countrySearchTerm.toLowerCase());
  });

  const handleStateSearchInputChange = (e) => {
    setStateSearchTerm(e.target.value);
  };

  const selectedCountry = countriesList.find((c) => c.country === country);
  const filteredStates = selectedCountry?.states?.filter((stateObj) =>
    stateObj.state.toLowerCase().includes(stateSearchTerm.toLowerCase())
  );

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideCountryDropdown = countryDropdownRef.current && countryDropdownRef.current.contains(event.target);
    const isClickInsideStateDropdown = stateDropdownRef.current && stateDropdownRef.current.contains(event.target);
    if (!isClickInsideCountryDropdown) {
      setCountryDropdownOpen(false);
    }
    if (!isClickInsideStateDropdown) {
      setStateDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideDropdown);
    return () => {
      document.removeEventListener('click', handleClickOutsideDropdown);
    };
  }, []);

  const handleCropClick = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      const croppedImage = (cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
      setUploadedImageUrl(croppedImage);
      setProfileImage(croppedImage);
    }
    closeModal();
  };

  // Function to resize the image
  const resizeImage = async (base64Image, maxWidth) => {
    return new Promise((resolve) => {
      const image = new Image();
      image.src = base64Image;

      image.onload = () => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        const aspectRatio = image.width / image.height;
        const newHeight = maxWidth / aspectRatio;

        canvas.width = maxWidth;
        canvas.height = newHeight;

        context.drawImage(image, 0, 0, maxWidth, newHeight);

        const resizedImage = canvas.toDataURL('image/jpeg'); // Change to 'image/png' if needed
        resolve(resizedImage);
      };
    });
  };

  const allowedExtensions = ["pdf", "doc", "docx", "msword", "xls", "xlsx", "csv"];
  const handleRegistrationDocChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
      if (allowedExtensions.includes(fileExtension)) {
        setRegistrationDocument(selectedFile);
      } else {
        toast.error("Invalid file format. Allowed formats are pdf, doc, docx, msword, xls, xlsx, csv.!!", {
          position: "bottom-right",
          autoClose: 3500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // Optionally, you can clear the input
        event.target.value = null;
      }
    }
  };
  const countryData = countriesList.find((c) => c.country === country);
  const states = countryData?.states || [];
  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur()

    // Prevent the page/container scrolling
    e.stopPropagation()

    setTimeout(() => {
      e.target.focus()
    }, 0)
  }




  return (
    <div className="inviter-register-outer">
      <div className="container">
        <div>
          <div className='tab-outer'>
            <ul className="tabs">
              <li className='active-tab'>
                Registration
              </li>
            </ul>
            <div className="tabs-content">
              <div className="profile-details-edit profile-details-edit-register" >
                <form onSubmit={handleSubmit}>
                  <div className="profile-photo-outer">
                    <div className="profile-photo">
                      <div className="profile-photo-inner">
                        <div className="profile-photo-sec">
                          {uploadedImageUrl ? (
                            <div className="profile-image">
                              <img src={uploadedImageUrl} alt="Uploaded Profile" />
                            </div>
                          ) : (
                            <div className="profile-image">
                              <img src={profile_image} alt="User Profile" />
                            </div>
                          )}

                          <div className="profile-pic-edit">
                            <a href="#" className="pic-edit" onClick={!fileSelectIsLoading && handleProfilePicChange}>
                              {fileSelectIsLoading ? (
                                <RotatingLines
                                  strokeColor="grey"
                                  strokeWidth="5"
                                  animationDuration="0.75"
                                  width="18"
                                  visible={true}
                                />
                              ) : (
                                <img src={PicEdit} alt="edit" />
                              )}
                            </a>
                          </div>
                        </div>
                        <div className="profile-name">

                          <div className="profile-name-sec">
                            {firstName ? (
                              <>
                                {firstName ? firstName : "First Name"}{" "}
                                {middleName ? middleName : ""}{" "}
                                {lastName ? lastName : ""}{" "}
                              </>
                            ) : (
                              <>
                                {companyName ? companyName : "Name"}
                              </>
                            )}
                          </div>

                          {userType ? (
                            userType === userTypeName.Super_Admin ? (
                              <div className="profile-type">
                                Super Admin
                              </div>
                            ) : userType === userTypeName.Admin ? (
                              <div className="profile-type">
                                Admin
                              </div>
                            ) : userType === userTypeName.User ? (
                              <div className="profile-type">
                                User
                              </div>
                            ) : null
                          ) : (
                            <div className="profile-type">
                              ROLE
                            </div>
                          )}

                        </div>
                      </div>
                    </div>
                  </div>

                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    ref={fileInputRef}
                  />
                  <Modal
                    isOpen={modalIsOpen}
                    contentLabel="Image Crop Modal"
                    style={{
                      content: {
                        height: "500%",
                        width: "1000%",
                        position: "fixed",
                        top: "20%",
                        left: "50%",
                        transform: "translate(-17.5%, 0%)",
                        parent: document.querySelector(".admin-outer.time.tracker"),
                      },
                    }}
                  >
                    <div>
                      {image && (
                        <Cropper
                          ref={cropperRef}
                          style={{ height: 400, width: "100%" }}
                          zoomTo={0}
                          initialAspectRatio={1}
                          preview=".img-preview"
                          src={image}
                          viewMode={1}
                          minCropBoxHeight={10}
                          minCropBoxWidth={10}
                          background={false}
                          responsive={true}
                          autoCropArea={100}
                          checkOrientation={false}
                          guides={true}
                        />
                      )}
                    </div>
                    <div>
                      <div className="crop-btn">
                        <button onClick={handleCropClick}>Crop and Upload</button>
                        <button className="delete-client" onClick={closeModal}>Cancel</button>
                      </div>
                    </div>
                  </Modal>
                  <div className="information-outer-section">

                    <div className="information-inner">
                      <div className="information">
                        <div className="information-title">
                          Personal Information
                        </div>
                        <div className="information-outer">
                          <div className="information-left">
                            <div className="inner-detail-outer">
                              <div className="inner-detail">
                                <span class="icon"><img src={Type} alt="type" /></span>
                                <div className="inner-detail-inner">
                                  <label>User Category *</label>
                                </div>
                              </div>
                              <div className="inner-detail">
                                <span class="icon"></span>
                                <div className="inner-detail-inner detail-radio">
                                  {Object.keys(userCategoryName)
                                    .filter((key) => key !== 'Vendor' && key !== 'Client')
                                    .map((key) => (
                                      <div key={userCategoryName[key]} className="radio-option radio-option1">
                                        <input
                                          type="radio"
                                          id={userCategoryName[key]}
                                          value={userCategoryName[key]}
                                          checked={userCategory === userCategoryName[key]}
                                          onChange={(e) => setUserCategory(userCategoryName[key])}
                                          required
                                        />
                                        <label htmlFor={userCategoryName[key]}>{key}</label>
                                      </div>
                                    ))}
                                  <div className="tooltip-message">
                                    You can only view this board
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="inner-detail-outer" style={{ display: 'none' }}>
                              <div className="inner-detail">
                                <span class="icon"><img src={Type} alt="type" /></span>
                                <div className="inner-detail-inner">
                                  <label>Type of Account *</label>
                                </div>
                              </div>
                              <div className="inner-detail">
                                <span class="icon"></span>
                                <div className="inner-detail-inner">
                                  <select
                                    type="select"
                                    value={userType}
                                    onChange={(e) => setUserType(e.target.value)}
                                    required
                                    disabled
                                    placeholder="Name"
                                  >
                                    <option value="">Select Role</option>
                                    {roleId.map((role) => (
                                      <option key={role._id} value={role._id}>
                                        {role.user_type}
                                      </option>
                                    ))}
                                  </select>
                                  <div className="tooltip-message">
                                    You can only view this board
                                  </div>
                                </div>
                              </div>
                            </div>

                            {userCategory === "" ||
                              userCategory === userCategoryName.Individual ? (
                              <div>
                                <div className="inner-detail-outer">
                                  <div className="inner-detail">
                                    <span class="icon"><img src={UserIcon} alt="user icon" /></span>
                                    <div className="inner-detail-inner">
                                      <label for="fname">First Name *</label>
                                    </div>
                                  </div>
                                  <div className="inner-detail">
                                    <span class="icon"></span>
                                    <div className="inner-detail-inner">
                                      <input
                                        type="text"
                                        placeholder="Enter First name"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        readOnly={userType === ""}
                                        required
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="inner-detail-outer">
                                  <div className="inner-detail">
                                    <span class="icon"><img src={UserIcon} alt="user icon" /></span>
                                    <div className="inner-detail-inner">
                                      <label for="mname">Middle Name</label>
                                    </div>
                                  </div>
                                  <div className="inner-detail">
                                    <span class="icon"></span>
                                    <div className="inner-detail-inner">
                                      <input
                                        type="text"
                                        placeholder="Enter Middle name"
                                        value={middleName}
                                        readOnly={userType === ""}
                                        onChange={(e) => setMiddleName(e.target.value)}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="inner-detail-outer">
                                  <div className="inner-detail">
                                    <span class="icon"><img src={UserIcon} alt="user icon" /></span>
                                    <div className="inner-detail-inner">
                                      <label for="lname">Last Name *</label>
                                    </div>
                                  </div>
                                  <div className="inner-detail">
                                    <span class="icon"></span>
                                    <div className="inner-detail-inner">
                                      <input
                                        type="text"
                                        placeholder="Enter Last name"
                                        value={lastName}
                                        readOnly={userType === ""}
                                        onChange={(e) => setLastName(e.target.value)}
                                        required
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}

                            {(userCategory === userCategoryName.Business ||
                              userCategory === userCategoryName.Vendor ||
                              userCategory === userCategoryName.Client) && (
                                <div>
                                  <div className="inner-detail-outer">
                                    <div className="inner-detail">
                                      <span class="icon"><img src={UserIcon} alt="user icon" /></span>
                                      <div className="inner-detail-inner">
                                        <label for="compname">Company Name *</label>
                                      </div>
                                    </div>
                                    <div className="inner-detail">
                                      <span class="icon"></span>
                                      <div className="inner-detail-inner">
                                        <input
                                          type="text"
                                          placeholder="Company name"
                                          value={companyName}
                                          onChange={(e) => setCompanyName(e.target.value)}
                                          readOnly={userType === ""}
                                          required
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="inner-detail-outer">
                                    <div className="inner-detail">
                                      <span class="icon"><img src={UserIcon} alt="user icon" /></span>
                                      <div className="inner-detail-inner">
                                        <label for="compfirstname">First Name *</label>
                                      </div>
                                    </div>
                                    <div className="inner-detail">
                                      <span class="icon"></span>
                                      <div className="inner-detail-inner">
                                        <input
                                          type="text"
                                          placeholder="First Name"
                                          value={companyFirstName}
                                          onChange={(e) => setCompanyFirstName(e.target.value)}
                                          readOnly={userType === ""}
                                          required
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="inner-detail-outer">
                                    <div className="inner-detail">
                                      <span class="icon"><img src={UserIcon} alt="user icon" /></span>
                                      <div className="inner-detail-inner">
                                        <label for="complastname">Last Name</label>
                                      </div>
                                    </div>
                                    <div className="inner-detail">
                                      <span class="icon"></span>
                                      <div className="inner-detail-inner">
                                        <input
                                          type="text"
                                          placeholder="Last name"
                                          value={companyLastName}
                                          onChange={(e) => setCompanyLastName(e.target.value)}
                                          readOnly={userType === ""}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="inner-detail-outer">
                                    <div className="inner-detail">
                                      <span class="icon"><img src={UserIcon} alt="user icon" /></span>
                                      <div className="inner-detail-inner">
                                        <label for="regnum">Registration Number *</label>
                                      </div>
                                    </div>
                                    <div className="inner-detail">
                                      <span class="icon"></span>
                                      <div className="inner-detail-inner">
                                        <input
                                          type="text"
                                          placeholder="Registration number"
                                          value={registrationNumber}
                                          onChange={(e) =>
                                            setRegistrationNumber(e.target.value)
                                          }
                                          readOnly={userType === ""}
                                          required
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                          </div>
                          <div className="information-right">
                            <div className="inner-detail-outer">
                              <div className="inner-detail">
                                <span class="icon"><img src={EmailIcon} alt="user icon" /></span>
                                <div className="inner-detail-inner">
                                  <label for="email">Email</label>
                                </div>
                              </div>
                              <div className="inner-detail">
                                <span class="icon"></span>
                                <div className="inner-detail-inner">
                                  <input
                                    type="email"
                                    placeholder="Email"
                                    value={decodedEmail}
                                    readOnly
                                    required
                                  />
                                </div>
                              </div>
                            </div>


                            <div className="inner-detail-outer">
                              <div className="inner-detail">
                                <span class="icon"><img src={PassLock} alt="user icon" /></span>
                                <div className="inner-detail-inner">
                                  <label for="password">Password *</label>
                                </div>
                              </div>
                              <div className="inner-detail">
                                <span class="icon"></span>
                                <div className="inner-detail-inner pass-eye">
                                  <input
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    ref={passwordRef}
                                    readOnly={userType === ""}
                                    required
                                  />
                                  <img
                                    src={showPassword ? EyeCrossed : EyeIcon}
                                    className="password-toggle-icon"
                                    alt="Toggle Password"
                                    onClick={() => setShowPassword(!showPassword)}
                                  />
                                </div>
                                {passwordError && (
                                  <span>{passwordError}</span>
                                )}
                              </div>
                            </div>

                            <div className="inner-detail-outer">
                              <div className="inner-detail">
                                <span class="icon"><img src={PassLock} alt="user icon" /></span>
                                <div className="inner-detail-inner">
                                  <label for="confpassword">Confirm Password *</label>
                                </div>
                              </div>
                              <div className="inner-detail">
                                <span class="icon"></span>
                                <div className="inner-detail-inner">
                                  <input
                                    type="password"
                                    placeholder="Confirm Password"
                                    value={passwordConfirmation}
                                    onChange={(e) => setPasswordConfirmation(e.target.value)}
                                    ref={passwordConfirmationRef}
                                    readOnly={userType === ""}
                                    required
                                  />
                                </div>
                                {passwordMatchError && (
                                  <span> {passwordMatchError} </span>
                                )}
                              </div>
                            </div>
                            {/* {userCategory === "" ||
                              userCategory === userCategoryName.Individual ? ( */}
                            <div>
                              <div className="inner-detail-outer">
                                <div className="inner-detail">
                                  <span class="icon"><img src={birthIcon} alt="user icon" /></span>
                                  <div className="inner-detail-inner">
                                    <label>Date of birth *</label>
                                  </div>
                                </div>
                                <div className="inner-detail">
                                  <span class="icon"></span>
                                  <div className="inner-detail-inner">
                                    <div className="date-picker-outer date-picker-outer-all">
                                      <div className="custom-picker-icon custom-picker-icon-all">
                                        <svg
                                          width="1em"
                                          height="1em"
                                          viewBox="0 0 14 14"
                                          fill="currentColor"
                                          aria-hidden="true"
                                          focusable="false"
                                          class="rs-picker-toggle-caret rs-icon"
                                          aria-label="calendar"
                                          data-category="legacy"
                                        >
                                          <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                                        </svg>
                                      </div>
                                      <div className="custoom-icon-calender custoom-icon-calender-all">
                                        <DatePicker
                                          oneTap
                                          className={userType ? 'datePickerReadOnly' : ''}
                                          selected={dob ? new Date(dob) : null}
                                          onChange={(date) => setDob(date)}
                                          // readOnly={userType === ""}
                                          required
                                          format="dd/MM/yyyy"
                                        />
                                      </div>
                                    </div>


                                  </div>
                                </div>
                              </div>

                              <div className="inner-detail-outer">
                                <div className="inner-detail">
                                  <span class="icon"><img src={PassportlIcon} alt="user icon" /></span>
                                  <div className="inner-detail-inner">
                                    <label for="id_number">Passport or ID Number *</label>
                                  </div>
                                </div>
                                <div className="inner-detail">
                                  <span class="icon"></span>
                                  <div className="inner-detail-inner">
                                    <input type="text" id="id_number" value={passport} onChange={(e) => setPassport(e.target.value)} readOnly={userType === ""} required />
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* ) : ( */}
                            {userCategory === "" ||
                              userCategory === userCategoryName.Business && (
                                <div>
                                  <div className="inner-detail-outer">
                                    <div className="inner-detail">
                                      <span class="icon"><img src={UserIcon} alt="user icon" /></span>
                                      <div className="inner-detail-inner">
                                        <label for="vatid">Vat Id *</label>
                                      </div>
                                    </div>
                                    <div className="inner-detail">
                                      <span class="icon"></span>
                                      <div className="inner-detail-inner">
                                        <input
                                          type="text"
                                          placeholder="VAT ID"
                                          value={vatId}
                                          onChange={(e) => setVatId(e.target.value)}
                                          readOnly={userType === ""}
                                          required
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="inner-detail-outer">
                                    <div className="inner-detail">
                                      <span class="icon"><img src={TypeContact} alt="user icon" /></span>
                                      <div className="inner-detail-inner">
                                        <label for="compregdoc">Company Registration Document *</label>
                                      </div>
                                    </div>
                                    <div className="inner-detail">
                                      <span class="icon"></span>
                                      <div className="inner-detail-inner">
                                        <input
                                          type="file"
                                          onChange={handleRegistrationDocChange}
                                          readOnly={userType === ""}
                                          required
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="information-inner" style={{ display: 'none' }}>
                      <div className="information">
                        <div className="information-title">
                          Account Information
                        </div>
                        <div className="information-outer">
                          <div className="information-left">
                            <div className="inner-detail-outer">
                              <div className="inner-detail">
                                <span class="icon"><img src={Type} alt="user icon" /></span>
                                <div className="inner-detail-inner">
                                  <label>Department *</label>
                                </div>
                              </div>
                              <div className="inner-detail">
                                <span class="icon"></span>
                                <div className="inner-detail-inner">
                                  <select
                                    type="select"
                                    value={dept}
                                    onChange={(e) => setDept(e.target.value)}
                                    disabled={userType === ""}
                                  // required
                                  >
                                    <option value="">Select Dept</option>
                                    {userDept
                                      .slice()
                                      .sort((a, b) => a.user_department.toLowerCase().localeCompare(b.user_department.toLowerCase()))
                                      .map((dept) => (
                                        <option key={dept._id} value={dept._id}>
                                          {dept.user_department}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              </div>
                            </div>

                            <div className="inner-detail-outer">
                              <div className="inner-detail">
                                <span class="icon"><img src={JobTitle} alt="user icon" /></span>
                                <div className="inner-detail-inner">
                                  <label>Job Title *</label>
                                </div>
                              </div>
                              <div className="inner-detail">
                                <span class="icon"></span>
                                <div className="inner-detail-inner">
                                  <input
                                    type="text"
                                    placeholder="Enter Job Title"
                                    value={jobTitle}
                                    onChange={(e) => setJobTitle(e.target.value)}
                                    readOnly={userType === ""}
                                  // required
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="inner-detail-outer">
                              <div className="inner-detail">
                                <span class="icon"><img src={Company} alt="user icon" /></span>
                                <div className="inner-detail-inner">
                                  <label>{`${capitalizedSubdomain} Company *`}</label>
                                </div>
                              </div>
                              <div className="inner-detail">
                                <span class="icon"></span>
                                <div className="inner-detail-inner">
                                  <select
                                    type="select"
                                    value={wxComapny}
                                    onChange={(e) => setWxComapny(e.target.value)}
                                    // required
                                    placeholder="Name"
                                  >
                                    <option value="">{`Select ${capitalizedSubdomain} Company`}</option>
                                    {wXCompany.map((comp) => (
                                      <option key={comp._id} value={comp._id}>
                                        {comp.wx_company}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="information-right">
                            <div className="inner-detail-outer">
                              <div className="inner-detail">
                                <span class="icon"><img src={TypeContact} alt="user icon" /></span>
                                <div className="inner-detail-inner">
                                  <label>Type of contract *</label>
                                </div>
                              </div>
                              <div className="inner-detail">
                                <span class="icon"></span>
                                <div className="inner-detail-inner">
                                  <select
                                    type="select"
                                    value={contract}
                                    onChange={(e) => setContract(e.target.value)}
                                    disabled={userType === ""}
                                  // required
                                  >
                                    <option value="">Select Type of Contract</option>
                                    {typeContract.map((contract) => (
                                      <option key={contract._id} value={contract._id}>
                                        {contract.typeOfContract}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="inner-detail-outer">
                              <div className="inner-detail">
                                <span class="icon"><img src={Payment} alt="user icon" /></span>
                                <div className="inner-detail-inner">
                                  <label>Payment Currency *</label>
                                </div>
                              </div>
                              <div className="inner-detail">
                                <span class="icon"></span>
                                <div className="inner-detail-inner">
                                  <select
                                    type="select"
                                    value={currency}
                                    onChange={(e) => setCurrency(e.target.value)}
                                    disabled={userType === ""}
                                  // required
                                  >
                                    <option value="">Select Currency</option>
                                    {currencyList
                                      .sort((a, b) =>
                                        a.currencyCode.localeCompare(b.currencyCode)
                                      )
                                      .map((curncy) => (
                                        <option
                                          key={Math.random().toString().replace("0.", "")}
                                          value={curncy.currencyCode}
                                        >
                                          {curncy.currencyCode}({curncy.currencyName})
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              </div>
                            </div>

                            <div className="inner-detail-outer">
                              <div className="inner-detail">
                                <span class="icon"><img src={Cost} alt="user icon" /></span>
                                <div className="inner-detail-inner">
                                  {contract === typeOfContract.Hourly_Rate ? (
                                    <label>
                                      Compensation per Hour {currency && `(${currency})`} *
                                    </label>
                                  ) : (
                                    <label>
                                      Monthly Compensation {currency && `(${currency})`} *
                                    </label>
                                  )}
                                </div>
                              </div>
                              <div className="inner-detail">
                                <span class="icon"></span>
                                <div className="inner-detail-inner">
                                  <input
                                    type="number"
                                    placeholder="Enter Cost"
                                    value={cost}
                                    onChange={(e) => setCost(e.target.value)}
                                    readOnly={userType === ""}
                                    onWheel={numberInputOnWheelPreventChange}
                                  // required
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="inner-detail-outer">
                              <div className="inner-detail">
                                <span class="icon"><img src={TypeContact} alt="user icon" /></span>
                                <div className="inner-detail-inner">
                                  <label>VAT (%)</label>
                                </div>
                              </div>
                              <div className="inner-detail">
                                <span class="icon"></span>
                                <div className="inner-detail-inner">
                                  <input
                                    type="number"
                                    placeholder="Enter VAT"
                                    value={vat}
                                    onChange={(e) => setVat(e.target.value)}
                                    readOnly={userType === ""}
                                    onWheel={numberInputOnWheelPreventChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="information-inner">
                      <div className="information">
                        <div className="information-title">
                          Address
                        </div>
                        <div className="information-outer">
                          <div className="information-left">
                            <div className="inner-detail-outer">
                              <div className="inner-detail">
                                <span className="icon">
                                  <img src={location} alt="Location Icon" />
                                </span>
                                <div className="inner-detail-inner">
                                  <label>Country *</label>
                                </div>
                              </div>
                              <div className="inner-detail">
                                <span className="icon"></span>
                                <div className="inner-detail-inner">

                                  <div className={`custom-dropdown-user-new-outer custom-dropdown-select ${countryDropdownOpen ? 'open' : ''}`} ref={countryDropdownRef}>
                                    <div className="selected-option custom-dropdown-user-new" onClick={userType ? () => setCountryDropdownOpen(!countryDropdownOpen) : null} style={{ cursor: userType ? 'pointer' : 'no-drop' }}>
                                      {country ? countriesList.find((c) => c.country === country)?.country : 'Select Country'}
                                    </div>
                                    {userType && countryDropdownOpen && (
                                      <div className="custom-dropdown-new">
                                        <input
                                          type="text"
                                          className="search-bar"
                                          placeholder="Search..."
                                          value={countrySearchTerm}
                                          onChange={handleCountrySearchInputChange}
                                        />
                                        <ul className="country-list">
                                          {filteredCountries.length > 0 &&
                                            filteredCountries.map((c) => (
                                              <li
                                                key={c.country}
                                                className={country === c.country ? 'selected-item' : ''}
                                                onClick={() => {
                                                  setCountry(c.country);
                                                  setCountryDropdownOpen(false);
                                                }}
                                              >
                                                {c.country}
                                              </li>
                                            ))}
                                        </ul>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            {states.length > 0 && (
                              <div className="inner-detail-outer">
                                <div className="inner-detail">
                                  <span className="icon">
                                    <img src={location} alt="Location Icon" />
                                  </span>
                                  <div className="inner-detail-inner">
                                    <label>State *</label>
                                  </div>
                                </div>
                                <div className="inner-detail">
                                  <span className="icon"></span>
                                  <div className="inner-detail-inner">

                                    <div className={`custom-dropdown-user-new-outer custom-dropdown-select ${stateDropdownOpen ? 'open' : ''}`} ref={stateDropdownRef}>
                                      <div className="selected-option custom-dropdown-user-new" onClick={userType ? () => setStateDropdownOpen(!stateDropdownOpen) : null} style={{ cursor: userType ? 'pointer' : 'no-drop' }}>
                                        {state ? states.find((s) => s.state === state)?.state : 'Select State'}
                                      </div>
                                      {userType && stateDropdownOpen && (
                                        <div className="custom-dropdown-new">
                                          <input
                                            type="text"
                                            className="search-bar"
                                            placeholder="Search..."
                                            value={stateSearchTerm}
                                            onChange={handleStateSearchInputChange}
                                          />
                                          <ul className="state-list">
                                            {filteredStates.length > 0 &&
                                              filteredStates.map((s) => (
                                                <li
                                                  key={s.state}
                                                  className={state === s.state ? 'selected-item' : ''}
                                                  onClick={() => {
                                                    setState(s.state);
                                                    setStateDropdownOpen(false);
                                                  }}
                                                >
                                                  {s.state}
                                                </li>
                                              ))}
                                          </ul>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className="inner-detail-outer">
                              <div className="inner-detail">
                                <span className="icon">
                                  <img src={location} alt="Location Icon" />
                                </span>
                                <div className="inner-detail-inner">
                                  <label>City *</label>
                                </div>
                              </div>
                              <div className="inner-detail">
                                <span className="icon"></span>
                                <div className="inner-detail-inner">
                                  <input type="text" id="city" value={city} onChange={(e) => setCity(e.target.value)} required />
                                </div>
                              </div>
                            </div>

                            <div className="inner-detail-outer">
                              <div className="inner-detail">
                                <span class="icon"><img src={location} alt="user icon" /></span>
                                <div className="inner-detail-inner">
                                  <label for="address">Address *</label>
                                </div>
                              </div>
                              <div className="inner-detail">
                                <span class="icon"></span>
                                <div className="inner-detail-inner">
                                  <input
                                    type="text"
                                    placeholder="Address"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                    readOnly={userType === ""}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="information-right">
                            <div className="inner-detail-outer">
                              <div className="inner-detail">
                                <span class="icon"><img src={PostalCode} alt="user icon" /></span>
                                <div className="inner-detail-inner">
                                  <label for="postal_code">Postal Code </label>
                                </div>
                              </div>
                              <div className="inner-detail">
                                <span class="icon"></span>
                                <div className="inner-detail-inner">
                                  <input
                                    type="text"
                                    placeholder="Post Code"
                                    value={postCode}
                                    onChange={(e) => setPostCode(e.target.value)}
                                    readOnly={userType === ""}

                                  />
                                </div>
                              </div>
                            </div>

                            <div className="inner-detail-outer">
                              <div className="inner-detail">
                                <span class="icon"><img src={Tele} alt="user icon" /></span>
                                <div className="inner-detail-inner">
                                  <label for="number">Mobile No. *</label>
                                </div>
                              </div>
                              <div className="inner-detail">
                                <span class="icon"></span>
                                <div className="inner-detail-inner">
                                  <PhoneInput
                                    placeholder="Enter phone number"
                                    value={mobileNo}
                                    onChange={setMobileNo}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="save-button-sec">
                    {isSubmitLoading ? (
                      <span className="cancel-btn disabled">Cancel</span>
                    ) : (
                      <Link to="../list" className="cancel-btn">Cancel</Link>
                    )}
                    <button type="submit" className="save-btn" disabled={isSubmitLoading}>
                      {isSubmitLoading ?
                        <div className="outter-load-table">
                          <ThreeDots
                            height="38"
                            width="40"
                            radius="9"
                            color="white"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </div> :
                        <>
                          <img src={Tick} alt="arrow" />Save changes
                        </>
                      }
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div >
        </div >
      </div>
    </div>
  );
}