import { ThreeDots } from 'react-loader-spinner';
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DateConverter from "../../../../helpers/dateFormatChange";
import { useFetchProjectPrintOptimized } from '../../../../components/TimeTracker/TimeTrackerProject/useProjectDetails';

const ClientProjectsPrintTable = (props) => {
  let token = null;
  const { user } = useSelector((state) => state.auth);
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }
  const selectedProjects = props.selectedProjects;
  const startDate = props.startDate;
  const endDate = props.endDate;
  const { data: userPrintProject, refetch: refetchPrintProject, isLoading: userPrintProjectLoading, } = useFetchProjectPrintOptimized(token, selectedProjects, startDate, endDate);
  // useEffect(() => {
  //   props.setLoading(userPrintProjectLoading);
  // }, [userPrintProjectLoading, props]);
  useEffect(() => {
    props.setLoading(userPrintProjectLoading);
    if (!userPrintProjectLoading && userPrintProject) {
      props.onTableRendered();
    }
  }, [userPrintProjectLoading, userPrintProject, props]);
  const renderProjectStatus = (rowData) => {
    // Determine the class based on the payment_status value
    let statusClass;
    let statusName;
    if (rowData.is_billable === true) {
      statusClass = "yes";
      statusName = "Yes";
    } else if (rowData.is_billable === false) {
      statusClass = "no";
      statusName = "No";
    }
    return (
      <span className={`payment-status ${statusClass}`}>{statusName}</span>
    );
  };

  return (
    <div className="outer-time-table project-print-table">
      <div className="time-table" ref={props.componentRef}>
        {userPrintProjectLoading ? (
          <div className="outter-load-table">
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#6479f9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        ) : (
          <DataTable
            value={userPrintProject?.data}
            removableSort
            tableStyle={{
              width: "100%",
              textAlign: "left",
              overflowX: "auto",
              fontSize: "12px",
              tableLayout: "fixed", // Ensures fixed table layout for consistent widths
            }}
          >
            <Column
              field="project_name"
              header="Project"
              style={{
                width: "35%",
                maxWidth: "150px", // Restrict the maximum width
                wordWrap: "break-word",
                whiteSpace: "normal",
                paddingRight: "20px",
              }}
            ></Column>
            <Column
              field="created_at"
              body={(rowData) => (
                <DateConverter date={rowData.created_at} />
              )}
              header="Date Created"
              style={{ width: "25%", paddingRight: "20px", wordWrap: "break-word", whiteSpace: "normal", }}
            ></Column>
            <Column
              field="tracked_hours"
              header="Tracked Hours"
              body={(row) => {
                const timers = row.timers || [];
                const totalDurationInSeconds = timers.reduce(
                  (total, timer) => total + timer.duration,
                  0
                );

                function formatSecondsToHHMMSS(totalSeconds) {
                  const hours = Math.floor(totalSeconds / 3600);
                  const minutes = Math.floor((totalSeconds % 3600) / 60);
                  const seconds = totalSeconds % 60;
                  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
                    2,
                    "0"
                  )}:${String(seconds).padStart(2, "0")}`;
                }

                return totalDurationInSeconds
                  ? formatSecondsToHHMMSS(totalDurationInSeconds)
                  : "00:00:00";
              }}
              style={{ width: "20%", paddingRight: "20px", wordWrap: "break-word", whiteSpace: "normal", }}
            ></Column>
            <Column
              field="budget"
              header="Budget"
              body={(row) => {
                const preferredCurrency = row?.client_name?.preferred_currency || "USD";
                const totalCostInClientCurrency = row.sprints.reduce(
                  (sum, sprint) => sum + (sprint.budget || 0),
                  0
                );

                return totalCostInClientCurrency
                  ? new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: preferredCurrency,
                  }).format(totalCostInClientCurrency)
                  : "0.00";
              }}
              style={{ width: "15%", paddingRight: "20px", wordWrap: "break-word", whiteSpace: "normal", }}
            ></Column>
            <Column
              field="cost"
              header="Cost"
              body={(row) => {
                const preferredCurrency = row?.client_name?.preferred_currency || "USD";
                const totalCostInClientCurrency = row.timers.reduce(
                  (sum, timer) => sum + (timer.totalCost || 0),
                  0
                );

                return totalCostInClientCurrency
                  ? new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: preferredCurrency,
                  }).format(totalCostInClientCurrency)
                  : "0.00";
              }}
              style={{ width: "15%", paddingRight: "20px", wordWrap: "break-word", whiteSpace: "normal", }}
            ></Column>
            <Column
              field="roi"
              header="ROI"
              body={(row) => {
                const totalCostInClientCurrencyBudget = row.sprints.reduce(
                  (sum, sprint) => sum + (sprint.budget || 0),
                  0
                );
                const totalCostInClientCurrencyCost = row.timers.reduce(
                  (sum, timer) => sum + (timer.totalCost || 0),
                  0
                );

                let Rvalue = 0;
                if (totalCostInClientCurrencyCost > 0) {
                  const rev =
                    totalCostInClientCurrencyBudget - totalCostInClientCurrencyCost;
                  Rvalue = (rev / totalCostInClientCurrencyCost) * 100;
                }

                return typeof Rvalue === "number"
                  ? `${Rvalue.toFixed(2)}%`
                  : "0.00%";
              }}
              style={{ width: "15%", paddingRight: "20px", wordWrap: "break-word", whiteSpace: "normal", }}
            ></Column>
            <Column
              field="is_billable"
              header="Billable"
              body={(rowData) => renderProjectStatus(rowData)}
              style={{ width: "10%" }} // Set column width as percentage
            ></Column>
          </DataTable>
        )}
    </div>
    </div >
  );
};

export default ClientProjectsPrintTable;