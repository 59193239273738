import React,{useState} from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { useSelector, useDispatch } from 'react-redux';
import { logout, reset } from "../../../features/auth/authSlice";
import { reset as invoiceReset } from "../../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../features/appSettings/appSettingsSlice.js";

const BankAccountVerification = ({ email,wiseBanks,isLoading,isError }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const emailExists = wiseBanks.some(
    (bank) => bank.details && bank.details.email === email
  );

  if (emailExists) {
   var verificationStatus = 'Yes';
  } else {
    var verificationStatus = 'No';
  }



  // const token = useSelector(
  //   (state) => state.auth.token
  // )
  // const { data: verificationStatus, isLoading, isError } = useQuery(
  //   ['bankAccountVerification', email],
  //   async () => {
  //     const response = await axios.get(`/api/wise/user/banks/${email}`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     if (response.status === 200) {
  //       return 'Yes';
  //     } else {
  //       return 'No';
  //     }
  //   },
  //   {
  //     enabled: !!email, // Only fetch data when email is truthy
  //     onError: (error) => {
  //       if (error.response?.data?.status === 'deleted') {
  //         localStorage.removeItem('user');
  //         dispatch(logout());
  //         dispatch(reset());
  //         dispatch(invoiceReset());
  //         dispatch(headerReset());
  //         dispatch(appSettingsReset());
  //         toast.error('This user is deleted!', {
  //           position: 'bottom-right',
  //           autoClose: 1500,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: 'light',
  //         });
  //         navigate('/');
  //       } else if (error.response && error.response.status === 404) {
  //         // Handle 404 error
  //       } else {
  //         // Handle other errors
  //       }
  //     },
  //   }
  // );

  return (
    <>
      {isLoading ? (
        <ThreeDots
          height="18"
          width="20"
          radius="9"
          color="#6479f9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      ) : isError ? (
        // Handle error state here if needed
        <span>Error</span>
      ) : (
        <span>{verificationStatus}</span>
      )}
    </>
  );
};

export default BankAccountVerification;