import axios from 'axios';
import moment from "moment";
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useSelector, useDispatch } from "react-redux";

export function useGetTeams(token) {
  return useQuery('teams', async () => {
    const response = await axios.get('/api/team', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const sortedTeams = response.data.sort((a, b) => {
      const nameA = a.first_name ? `${a.first_name} ${a.middle_name ? a.middle_name : ''} ${a.last_name}` : `${a.company_first_name ? a.company_first_name + (a.company_last_name && a.company_last_name) : a.company_name}`;
      const nameB = b.first_name ? `${b.first_name} ${b.middle_name ? b.middle_name : ''} ${b.last_name}` : `${b.company_first_name ? b.company_first_name + (b.company_last_name && b.company_last_name) : b.company_name}`;
      return nameA.localeCompare(nameB);
    });
    return sortedTeams;
  });
}

export function useGetActiveTeams(token) {
  return useQuery('activeTeams', async () => {
    const response = await axios.get('/api/active/team', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const sortedTeams = response.data.sort((a, b) => {
      const nameA = a.first_name ? `${a.first_name} ${a.middle_name ? a.middle_name : ''} ${a.last_name}` : `${a.company_first_name ? a.company_first_name + (a.company_last_name && a.company_last_name) : a.company_name}`;
      const nameB = b.first_name ? `${b.first_name} ${b.middle_name ? b.middle_name : ''} ${b.last_name}` : `${b.company_first_name ? b.company_first_name + (b.company_last_name && b.company_last_name) : b.company_name}`;
      return nameA.localeCompare(nameB);
    });
    return sortedTeams;
  });
}

export function useGetClients(token) {
  return useQuery('clients', async () => {
    const response = await axios.get('/api/client-details', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  });
}

export function useGetAllClients(token) {
  return useQuery('allClients', async () => {
    const response = await axios.get('/api/all/client-details', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  });
}

export function useGetVendors(token) {
  return useQuery('vendors', async () => {
    const response = await axios.get('/api/vendor-details', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  });
}

export function useGetProjects(token) {
  return useQuery('projects', async () => {
    const response = await axios.get('/api/project-details', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  });
}

export function useGetAllProjects(token) {
  return useQuery('allProjects', async () => {
    const response = await axios.get('/api/all/project-details', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  });
}

export function useGetDepartments() {
  return useQuery('departments', async () => {
    const response = await axios.get('/api/user-department');
    return response.data;
  });
}

export function useGetTags(token) {
  return useQuery('tags', async () => {
    const response = await axios.get('/api/tag-details', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  });
}

export function useGetSprints(token) {
  return useQuery('sprints', async () => {
    const response = await axios.get('/api/sprint-details', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  });
}

export function useGetTimeTrackerGraphData(token, team, timerData, date, tz) {
  return useQuery(['timeTrackerGraphData', timerData, date], async () => {
    const dddate = date && moment(date).tz(tz).format();
    const response = await axios.post(
      '/api/time-track/chart',
      {
        date: dddate ? dddate : moment().tz(tz).format(),
        team: team,
        client: timerData.client,
        project: timerData.project,
        tag: timerData.tag,
        department: timerData.department,
        tz: tz
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    
    const processedData = response.data.map(item => ({
      ...item,
      exps: parseFloat(item.exps).toFixed(3),
      day: moment(item.date).utc().format("ddd, MMM D")
    }));
    return processedData.reverse();
  });
}

export function useGetTimerData(token, tracker, startDate, endDate) {

  const { data: timerData = [], isLoading, refetch } = useQuery(['timerData', tracker, startDate, endDate], async () => {
    const result = await axios.post(
      '/api/time-track/total-hour',
      {
        team: tracker.team,
        client: null,
        project: null,
        tag: null,
        department: null,
        startDate: new Date(startDate.getTime() + 24 * 60 * 60 * 1000),
        endDate: new Date(endDate.getTime() + 24 * 60 * 60 * 1000),
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return result.data;
  });


  const timerDataa = useSelector((state) => state.timerData.timerDataState);
  const filteredData = useMemo(() => {
    // refetch();
    let filtered = timerData;
    if (tracker.department) {
      filtered = filtered.filter((item) => {
        return tracker.department.includes(item.user.department);
      });
    }

    if (tracker.client) {
      filtered = filtered.filter((item) => {
        if (item.project.client_name != null) {
          return tracker.client.includes(item.project.client_name._id);
        }
      });
    }

    if (tracker.project) {
      filtered = filtered.filter((item) => {
        return tracker.project.includes(item.project._id);
      });
    }

    if (tracker?.tag) {
      filtered = filtered.filter((item) => {
        return tracker?.tag.includes(item.tag?._id);
      });
    }

    if (tracker?.sprint) {
      filtered = filtered.filter((item) => {
        return tracker?.sprint.includes(item.sprint?._id);
      });
    }

    if (tracker.task) {
      filtered = filtered.filter((item) => {
        return item.task.toLowerCase().includes(tracker.task.toLowerCase());
      });
    }

    return filtered;
  }, [timerData, tracker, timerDataa]);

  return {
    data: filteredData,
    isLoading,
    refetch
  };
}