import css from "./User.css";
import Modal from "react-modal";
import axios from "axios";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import { Column } from "primereact/column";
import { ThreeDots } from "react-loader-spinner";
import { DataTable } from "primereact/datatable";
import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import isEqual from "lodash/isEqual";
import addicon from "../../images/icons/addicon.svg";
import BankAccountVerification from "./elements/Bank";
import InviteIcon from "../../images/icons/invite.svg";
import { useUserProfiles, useUserType, useWiseBanks, useTeams, fetchTeams } from "./userProfileQueries";
import { useSelector, useDispatch } from "react-redux";
import UserIcon from "../../images/icons/user-icon.svg";
import { RenderUserCTA } from "../../helpers/invoiceHelpers";
import profile_image from "../../images/user/Default_pfp.png";
import dletImage from "../../images/singleinvoice/delete.svg";
import crssrImage from "../../images/singleinvoice/cross-red.svg";
import Plus from "../../images/icons/plus-black.svg";
import { setHeaderText } from "../../features/headerText/headerTextSlice";
import { setNotification } from "../../features/notification/notificationSlice.js";
import userCategory from "../../constants/userCategory";
import userTypeName from "../../constants/userType";
import DownloadImage from "../../images/icons/download-icon.svg";
import { motion } from "framer-motion";
import moment from "moment-timezone";
import { useContractTypes } from "./UserDetails/UserProfile/useUserView.jsx";
import TruncateFilterFieldView from "../helpers/truncateFilterFieldView.js";
import { RotatingLines } from 'react-loader-spinner';
import { useLocation } from "react-router-dom";

const UseRenderMemberName = ({ rowData }) => {
  const [showFullname, setShowFullname] = useState(false);

  const fullName = rowData.first_name
    ? `${rowData.first_name} ${rowData.last_name || ""}`.trim()
    : rowData.company_first_name
      ? `${rowData.company_first_name} ${rowData.company_last_name || ""}`.trim()
      : `${rowData.company_name} (Company)`;

  const displayName = showFullname
    ? fullName
    : fullName && fullName.length <= 21
      ? fullName
      : fullName && fullName.slice(0, 21) + "...";

  return (
    <div
      className={`member-name${showFullname ? " active" : ""}`}
      // onMouseEnter={toggleShowFullname}
      onMouseEnter={() => {
        if (fullName.length > 21) {
          setShowFullname(true);
        }
      }}
      onMouseLeave={() => setShowFullname(false)}
    >
      {rowData.profile_image !== null && rowData.profile_image !== "null" ? (
        <img
          src={`/uploads/lowProfileImage/${rowData.profile_image}`}
          alt={fullName}
          className="member-image"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "/uploads/lowProfileImage/user_default.png";
          }}
        />
      ) : (
        <img src={profile_image} alt={fullName} className="member-image" />
      )}
      <span>{displayName}</span>
    </div>
  );
};

const Users = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [filter, setFilter] = useState("");
  const [users, setUsers] = useState([]);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [emails, setEmails] = useState([]);
  const [userType, setUserType] = useState("");
  const roleDropdownRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMailLoading, setIsMailLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [roleDropdownOpen, setRoleDropdownOpen] = useState(false);
  const userTypeDropdownRef = useRef(null);
  const [bulkImportFile, setBulkImportFile] = useState(null);
  const [isResendModalOpen, setIsResendModalOpen] = useState(false);
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [userFilter, setUserFilter] = useState("All");
  const [selectAllTeam, setSelectAllTeam] = useState(true);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [isTeamDropdownOpen, setIsTeamDropdownOpen] = useState(false);
  const [isTeamModalDropdownOpen, setIsTeamModalDropdownOpen] = useState(false);
  const [userCategoryFilter, setUserCategoryFilter] = useState("All");
  const [selectAllCategory, setSelectAllCategory] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false);
  const [isCategoryModalDropdownOpen, setIsCategoryModalDropdownOpen] =
    useState(false);
  const [accountTypeFilter, setAccountTypeFilter] = useState("All");
  const [selectAllAccountType, setSelectAllAccountType] = useState(true);
  const [selectedAccountType, setSelectedAccountType] = useState([]);
  const [isAccountTypeDropdownOpen, setIsAccountTypeDropdownOpen] =
    useState(false);
  const [isAccountTypeModalDropdownOpen, setIsAccountTypeModalDropdownOpen] =
    useState(false);
  const [statusModalDropdownOpen, setStatusModalDropdownOpen] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isBulkImportLoading, setIsBulkImportLoading] = useState(false);
  const [userTypeDropdownOpen, setUserTypeDropdownOpen] = useState(false);
  const [bulkImportFileName, setBulkImportFileName] = useState("");
  const [isBulkImportModalOpen, setIsBulkImportModalOpen] = useState(false);
  const [alreadySentEmailsList, setAlreadySentEmailsList] = useState([]);
  const [showingDropdownOpen, setShowingDropdownOpen] = useState(false);
  const teamDropdownRef = useRef(null);
  const teamModalDropdownRef = useRef(null);
  const userCategoryDropdownRef = useRef(null);
  const userCategoryModalDropdownRef = useRef(null);
  const accountTypeDropdownRef = useRef(null);
  const accountTypeModalDropdownRef = useRef(null);
  const statusDropdownRef = useRef(null);
  const statusModalDropdownRef = useRef(null);
  const showingDropdownRef = useRef(null);
  const [paginatedTeams, setPaginatedTeams] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const location = useLocation();

  let token = null;
  const { user } = useSelector((state) => state.auth);
  const { appSettings } = useSelector((state) => state.appSettings);
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }

  useEffect(() => {
    dispatch(setHeaderText("My Team"));
  }, []);

  const [teamRefetchLoading, setTeamRefetchLoading] = useState(false);
  const {
    data: userProfiles = [],
    refetch: refetchUserProfiles,
    isLoading: userProfileLoading,

    isError,
    error,
  } = useUserProfiles(token);

  const { data: roleId = [], isLoading: roleLoading, } = useUserType(token);


  const enabled = selectedTeams.length > 0 && selectedAccountType.length > 0 && selectedCategory.length > 0;
  // const {

  //   data: teams = [],
  //   refetch: refetchTeams,
  //   isLoading: teamsLoading,
  //   isError: teamsError,

  // } = useTeams(
  //   token,
  //   currentPage,
  //   limit1,
  //   selectedTeams,
  //   selectedCategory,
  //   selectedAccountType,
  //   selectedStatus,
  //   { enabled }
  // );

  const [allTeam, setAllTeam] = useState([]);
  const [teamsLoading, setTeamsLoading] = useState(false);
  const [teams, setTeams] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);

  const getAllTeams = async () => {
    try {
      setIsUpdating(true); // Indicates data is being updated
      const allTeam = await fetchTeams(
        token,
        currentPage,
        rowsPerPage,
        selectedTeams,
        selectedCategory,
        selectedAccountType,
        selectedStatus
      );
      
      setTeams(allTeam);          // Update new data
      setPaginatedTeams(allTeam.users);
      setTotal(allTeam.total);

    } catch (error) {
      console.log(error);
    } finally {
      setIsUpdating(false);       // End update state
    }
  };

  const memoizedDependencies = useMemo(() => {
    return {
      selectedTeams,
      selectedAccountType,
      selectedCategory,
      selectedStatus,
      currentPage,
      rowsPerPage,
    };
  }, [selectedTeams, selectedAccountType, selectedCategory, selectedStatus, currentPage, rowsPerPage]);

  useEffect(() => {
    if (
      memoizedDependencies.selectedTeams.length > 0 &&
      memoizedDependencies.selectedAccountType.length > 0 &&
      memoizedDependencies.selectedCategory.length > 0
    ) {
      getAllTeams();
    }
  }, [
    memoizedDependencies.selectedTeams,
    memoizedDependencies.selectedAccountType,
    memoizedDependencies.selectedCategory,
    memoizedDependencies.selectedStatus,
    memoizedDependencies.currentPage,
    memoizedDependencies.rowsPerPage,
  ]);

  useEffect(() => {

    setCurrentPage(1);

  }, [selectedTeams])

  useEffect(() => {
    const fetchAllTeams = async () => {
      try {
        const response = await fetchTeams(token, currentPage, rowsPerPage, selectedTeams, selectedCategory, selectedAccountType, selectedStatus);
        setAllTeam(response);
        setTeamsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchAllTeams();

  }, [paginatedTeams]);




  // test

  useEffect(() => {

   
    setSelectedTeams(userProfiles.map((team) => team._id));


  }, [userProfiles,]);

  useEffect(() => {

  
    setSelectedAccountType(roleId.map((team) => team._id));


  }, [roleId]);

  useEffect(() => {

    console.log("userCategoryList", userCategoryList);
    setSelectedCategory(userCategoryList.map((item) => item.value));


  }, []);

  useEffect(() => {


    setIsUpdating(true);

  }, [userProfileLoading]);

  // test

  // useEffect(() => {

  //   console.log('page changes');
  //  // setSelectedTeams(userProfiles.map((team) => team._id));
  //   console.log('selectedTeams', selectedTeams);
  //   if (selectedTeams.length > 0) {

  //   }

  // }, [location.pathname,selectedTeams]);


  const handleRefetch = async () => {
    try {
      //   setTeamRefetchLoading(true);
      // //  await refetchTeams(); // Triggers refetch
      //   setTeamRefetchLoading(false);
    } catch (error) {
      console.error("Refetch failed:", error);
    }
  };

  const {
    data: typeContract = [],
    isLoading: contractTypesLoading,
    isError: contractTypesError,
  } = useContractTypes();

  const {
    data: wiseBanks = [],
    refetch: refetchWiseBanks,
    isLoading: wiseBanksLoading,
    isError: wiseBanksError,
    error: wiseBanksErrorMessage,
  } = useWiseBanks(token);



  // roleId = roleId.filter(
  //   (role) => !(user.userType === 'ADMIN' && role._id === userTypeName.Super_Admin)
  // );

  // useEffect(() => {
  //   if (userProfiles) {
  //     // setUsers(prevUsers => [...prevUsers, ...userProfiles]);
  //     setUsers(userProfiles);
  //   }
  // }, [userProfiles]);

  const renderUserStatus = (rowData) => {
    // Determine the class based on the payment_ssdstatus value
    let statusClass;
    let statusName;
    if (rowData.is_active === true) {
      statusClass = "active";
      statusName = "Active";
    } else if (rowData.is_active === false) {
      statusClass = "inactive";
      statusName = "Inactive";
    }
    return (
      <span className={`payment-status ${statusClass}`}>{statusName}</span>
    );
  };
  const [emailValidation, setEmailValodation] = useState(false);
  const [selectValidation, setSelectValodation] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmails = emails.every(
      (obj) => !obj.email || emailRegex.test(obj.email)
    );
    if (email === "") {
      setEmailValodation(true);
    } else {
      setEmailValodation(false);
    }
    if (userType === "") {
      setSelectValodation(true);
    } else {
      setSelectValodation(false);
    }
    if (!isValidEmails) {
      toast.error("Please enter valid email addresses!!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      handleCloseModal();
      return;
    }
    var allEmails = emails;
    if (email !== "") {
      allEmails = [
        ...emails,
        { email: email, id: Date.now(), userType: userType },
      ]; // combine all emails including the last added one
    }
    // Validate email and userType fields
    if (!email || !userType) {
      toast.error("Email and user type cannot be empty!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (allEmails.some((obj) => !obj.email || !obj.userType)) {
      toast.error("Email and user type cannot be empty!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    try {
      setIsMailLoading(true);

      const response = await fetch("/api/user-invite", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          emails: allEmails.map((obj) => ({
            email: obj.email,
            userType: obj.userType,
          })),
          invite_status: "0",
        }),
      });

      const data = await response.json();
      setEmails([]);
      setEmail("");
      setIsModalOpen(false);
      if (response.ok) {
        if (
          data.alreadySentEmails.length > 0 ||
          data.alreadyRegisteredEmails.length > 0
        ) {
          const alreadySentEmails = data.alreadySentEmails.join(", ");
          const alreadyRegisteredEmails =
            data.alreadyRegisteredEmails.join(", ");
          const successSentEmails = data.successSentEmails.join(", ");

          if (alreadySentEmails) {
            setAlreadySentEmailsList(data.alreadySentEmails);
            const sentEmailsList = data.alreadySentEmails
              .map((entry) => entry.email)
              .join(", ");
            handleOpenResendModal();
            toast.success(`Invitation already sent to ${sentEmailsList}`, {
              position: "bottom-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          if (alreadyRegisteredEmails) {
            toast.warning(
              `User with email ${alreadyRegisteredEmails} is already registered`,
              {
                position: "bottom-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          }

          if (successSentEmails) {
            dispatch(setNotification(Date.now()));
            toast.success(
              `Invitation successfully sent to ${successSentEmails}`,
              {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
            global.config.activityLog(
              window.location.href,
              "User Invite",
              `Invitation successfully sent to ${successSentEmails}`
            );
          }
        } else {
          const successSentEmails = data.successSentEmails.join(", ");
          dispatch(setNotification(Date.now()));
          toast.success("Mail sent successfully!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          global.config.activityLog(
            window.location.href,
            "User Invite",
            `Invitation successfully sent to ${successSentEmails}`
          );
        }
      } else {
        toast.error("Failed to send mail.", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      alert("Something went wrong");
    } finally {
      setIsMailLoading(false);
      handleCloseModal();
    }
  };

  const handleResendEmails = async () => {
    try {
      setIsMailLoading(true);
      const response = await fetch("/api/resend-invitations", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          emails: alreadySentEmailsList, // List of emails to resend
        }),
      });

      if (response.ok) {
        // Handle success
        // Close the resend modal
        setIsResendModalOpen(false);
        // Show a success message
        toast.success("Invitations resent successfully!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        global.config.activityLog(
          window.location.href,
          "User Invite",
          `Invitation successfully resent to ${alreadySentEmailsList}`
        );
      } else {
        // Handle failure
        // Show an error message
        toast.error("Failed to resend invitations.", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      // Handle network or server error
      // Show an error message
      toast.error("Failed to resend invitations.", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsMailLoading(false);
      handleCloseResendModal();
    }
  };

  const handleAddEmail = (e) => {
    e.preventDefault();
    // if (email !== "") {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmails = emailRegex.test(email);
    if (email && !isValidEmails) {
      toast.error("Please enter valid email addresses!!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    // if (email) {
    const newEmailEntry = { email: email, id: Date.now(), userType: userType };
    setEmails([...emails, newEmailEntry]);
    // }
    setEmail("");
    setUserType("");
    // }
  };

  const handleToggleRoleDropdown = () => {
    setRoleDropdownOpen(!roleDropdownOpen);
  };

  const handleToggleUserTypeDropdown = () => {
    setUserTypeDropdownOpen(!userTypeDropdownOpen);
  };

  const handleUserTypeOptionClick = (selectedValue) => {
    setUserType(selectedValue);
    setSelectValodation(false);
    setUserTypeDropdownOpen(false);
  };

  const handleCheckboxTeamChange = (teamId) => {
    const updatedSelection = [...selectedTeams];
    const index = updatedSelection.indexOf(teamId);

    if (index === -1) {
      // Add to selection
      updatedSelection.push(teamId);
    } else {
      // Remove from selection
      updatedSelection.splice(index, 1);
    }
    setFirst(0);
    setSelectedTeams(updatedSelection);
  };

  const handleSelectAllTeamChange = () => {
    if (!selectAllTeam) {
      setSelectedTeams(userProfiles.map((team) => team._id));
    } else {
      setSelectedTeams([]);
    }
    setSelectAllTeam(!selectAllTeam);
  };

  // useEffect(() => {
  //   setSelectAllTeam(selectedTeams.length === userProfiles.length);
  //   if (selectedTeams.length === userProfiles.length) {
  //     setUserFilter("All");
  //   } else {
  //     setUserFilter(selectedTeams);
  //   }
  // }, [selectedTeams, userProfiles]);

  const toggleTeamDropdown = () => {
    setIsTeamDropdownOpen(!isTeamDropdownOpen);
  };

  const toggleTeamModalDropdown = () => {
    setIsTeamModalDropdownOpen(!isTeamModalDropdownOpen);
  };

  const userCategoryList = Object.entries(userCategory)
    .filter(([name]) => name === "Individual" || name === "Business")
    .map(([name, value]) => ({
      name,
      value,
    }));

  const toggleCategoryDropdown = () => {
    setIsCategoryDropdownOpen(!isCategoryDropdownOpen);
  };

  const toggleCategoryModalDropdown = () => {
    setIsCategoryModalDropdownOpen(!isCategoryModalDropdownOpen);
  };

  const handleCheckboxCategoryChange = (value) => {
    setSelectedCategory((prevSelected) =>
      prevSelected.includes(value)
        ? prevSelected.filter((item) => item !== value)
        : [...prevSelected, value]
    );
  };

  const handleSelectAllCategoryChange = () => {
    setSelectAllCategory(!selectAllCategory);
    if (!selectAllCategory) {
      setSelectedCategory(userCategoryList.map((item) => item.value));
    } else {
      setSelectedCategory([]);
    }
  };

  useEffect(() => {
    setSelectAllCategory(selectedCategory.length === userCategoryList.length);
    if (selectedCategory.length === userCategoryList.length) {
      setUserCategoryFilter("All");
    } else {
      setUserCategoryFilter(selectedTeams);
    }
  }, [selectedCategory, userCategoryList]);

  const filteredRoleId = roleId.filter(
    (role) =>
      !(
        user.role_id === userTypeName.Admin &&
        role._id === userTypeName.Super_Admin
      )
  );

  const toggleAccountTypeDropdown = () => {
    setIsAccountTypeDropdownOpen(!isAccountTypeDropdownOpen);
  };

  const toggleAccountTypeModalDropdown = () => {
    setIsAccountTypeModalDropdownOpen(!isAccountTypeModalDropdownOpen);
  };

  const handleCheckboxAccountTypeChange = (value) => {
    const updatedSelection = [...selectedAccountType];
    const index = updatedSelection.indexOf(value);

    if (index === -1) {
      updatedSelection.push(value);
    } else {
      updatedSelection.splice(index, 1);
    }
    // setCurrentPage(0);
    setSelectedAccountType(updatedSelection);
  };

  const handleSelectAllAccountTypeChange = () => {
    setSelectAllAccountType(!selectAllAccountType);
    if (!selectAllAccountType) {
      setSelectedAccountType(filteredRoleId.map((item) => item._id));
    } else {
      setSelectedAccountType([]);
    }
  };

  // useEffect(() => {
  //   setSelectAllAccountType(
  //     selectedAccountType.length === filteredRoleId.length
  //   );
  //   if (selectedAccountType.length === filteredRoleId.length) {
  //     setAccountTypeFilter("All");
  //   } else {
  //     setAccountTypeFilter(selectedTeams);
  //   }
  // }, [selectedAccountType, filteredRoleId]);

  const toggleStatusDropdown = () => {
    setStatusDropdownOpen(!statusDropdownOpen);
  };

  const toggleStatusModalDropdown = () => {
    setStatusModalDropdownOpen(!statusModalDropdownOpen);
  };

  const selectStatusOption = (selectedStatus) => {
    setSelectedStatus(selectedStatus);
    setStatusDropdownOpen(false);
  };

  const applyFilters = () => {
    setShowFilterModal(false);
  };

  const clearFilters = () => {
    setSelectedTeams(userProfiles.map((team) => team._id));
    setSelectedCategory(userCategoryList.map((item) => item.value));
    setSelectedAccountType(filteredRoleId.map((type) => type._id));
    setShowFilterModal(false);
  };

  const checkAndShowConfirmationModal = () => {
    const isTeamsModified = !isEqual(
      selectedTeams,
      userProfiles.map((team) => team._id)
    );
    const isUserCategoryModified = !isEqual(
      selectedStatus,
      userCategoryList.map((item) => item.value)
    );
    const isAccountTypeModified = !isEqual(
      selectedAccountType,
      filteredRoleId.map((type) => type._id)
    );

    if (isTeamsModified || isUserCategoryModified || isAccountTypeModified) {
      setShowConfirmationModal(true);
    } else {
      setShowFilterModal(false);
    }
  };

  const handleCancel = () => {
    setShowConfirmationModal(false);
  };

  const handleApplyConfirm = () => {
    setShowConfirmationModal(false);
    setShowFilterModal(false);
  };

  const toggleFilterModal = () => {
    setShowConfirmationModal(false);
    setShowFilterModal(!showFilterModal);
  };

  // useEffect(() => {
  //   setSelectedTeams(userProfiles.map((team) => team._id));
  //   setSelectedCategory(userCategoryList.map((item) => item.value));
  //   setSelectedAccountType(filteredRoleId.map((type) => type._id));
  // }, [userProfiles, roleId]);

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideRoleDropdown =
      roleDropdownRef.current && roleDropdownRef.current.contains(event.target);
    const isClickInsideUserTypeDropdown =
      userTypeDropdownRef.current &&
      userTypeDropdownRef.current.contains(event.target);
    const isClickInsideTeamDropdown =
      teamDropdownRef.current && teamDropdownRef.current.contains(event.target);
    const isClickInsideTeamModalDropdown =
      teamModalDropdownRef.current &&
      teamModalDropdownRef.current.contains(event.target);
    const isClickInsideUserCategoryDropdown =
      userCategoryDropdownRef.current &&
      userCategoryDropdownRef.current.contains(event.target);
    const isClickInsideUserCategoryModalDropdown =
      userCategoryModalDropdownRef.current &&
      userCategoryModalDropdownRef.current.contains(event.target);
    const isClickInsideAccountTypeDropdown =
      accountTypeDropdownRef.current &&
      accountTypeDropdownRef.current.contains(event.target);
    const isClickInsideAccountTypeModalDropdown =
      accountTypeModalDropdownRef.current &&
      accountTypeModalDropdownRef.current.contains(event.target);
    const isClickInsideStatusDropdown =
      statusDropdownRef.current &&
      statusDropdownRef.current.contains(event.target);
    const isClickInsideStatusModalDropdown =
      statusModalDropdownRef.current &&
      statusModalDropdownRef.current.contains(event.target);
    const isClickInsideshowingDropdown = showingDropdownRef.current && showingDropdownRef.current.contains(event.target);

    if (!isClickInsideRoleDropdown) {
      setRoleDropdownOpen(false);
    }
    if (!isClickInsideUserTypeDropdown) {
      setUserTypeDropdownOpen(false);
    }
    if (!isClickInsideTeamDropdown) {
      setIsTeamDropdownOpen(false);
    }
    if (!isClickInsideTeamModalDropdown) {
      setIsTeamModalDropdownOpen(false);
    }
    if (!isClickInsideUserCategoryDropdown) {
      setIsCategoryDropdownOpen(false);
    }
    if (!isClickInsideUserCategoryModalDropdown) {
      setIsCategoryModalDropdownOpen(false);
    }
    if (!isClickInsideAccountTypeDropdown) {
      setIsAccountTypeDropdownOpen(false);
    }
    if (!isClickInsideAccountTypeModalDropdown) {
      setIsAccountTypeModalDropdownOpen(false);
    }
    if (!isClickInsideStatusDropdown) {
      setStatusDropdownOpen(false);
    }
    if (!isClickInsideStatusModalDropdown) {
      setStatusModalDropdownOpen(false);
    }
    if (!isClickInsideshowingDropdown) {
      setShowingDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideDropdown);
    return () => {
      document.removeEventListener("click", handleClickOutsideDropdown);
    };
  }, []);

  const handleRowsPerPageChange = (e) => {
    const newRowsPerPage = parseInt(e.target.value);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(total / rowsPerPage);

  const handleEditEmail = (id, field, value) => {
    // Find the index of the email entry with the given id
    const index = emails.findIndex((obj) => obj.id === id);
    if (index !== -1) {
      // Create a copy of the emails array to avoid mutating state directly
      const updatedEmails = [...emails];
      // Update the specified field of the email entry
      updatedEmails[index][field] = value;
      // Update the state with the new array
      setEmails(updatedEmails);
    }
  };

  const handleRemoveEmail = (id) => {
    const filteredEmails = emails.filter((obj) => obj.id !== id);
    setEmails(filteredEmails);
  };

  const handleRemoveLatestEmail = () => {
    const updatedEmails = [...emails];
    const lastEmail = updatedEmails.pop(); // Remove the last element
    setEmails(updatedEmails);

    // Set state variables with the latest values
    setEmail(lastEmail.email);
    setUserType(lastEmail.userType);
  };

  // Function to remove an email from the list
  const handleRemoveResendEmail = (emailToRemove) => {
    const updatedEmails = alreadySentEmailsList.filter(
      (email) => email !== emailToRemove
    );
    setAlreadySentEmailsList(updatedEmails);
  };

  const handleOpenResendModal = () => {
    // Set the list of already sent emails here
    setIsResendModalOpen(true);
  };

  const handleCloseResendModal = () => {
    setIsResendModalOpen(false);
    setAlreadySentEmailsList([]);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEmails([]);
    setEmail("");
    setUserType("");
  };

  const handleUpload = async (event) => {
    event.preventDefault();
    setIsBulkImportLoading(true);
    if (!bulkImportFile) {
      toast.error("Please select a file.", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setIsBulkImportLoading(true);
      return;
    }

    const maxFileSize = 5 * 1024 * 1024;
    if (bulkImportFile.size > maxFileSize) {
      toast.error("File size exceeds the 5 MB limit.", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setIsBulkImportLoading(false);
      return;
    }

    const fileExtension = bulkImportFile.name.split(".").pop();
    if (fileExtension !== "xlsx") {
      toast.error("Only xlsx files are supported.", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setIsBulkImportLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("file", bulkImportFile);

    try {
      const response = await axios.post("/api/import/user", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.status === "requiredValidationError") {
        toast.error(response.data.message, {
          position: "bottom-right",
          autoClose: 5000, // Adjust as needed
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        if (
          response.data.existingUserEmails &&
          response.data.existingUserEmails.length > 0
        ) {
          toast.error(
            `The following emails are already registered: ${response.data.existingUserEmails.join(
              ", "
            )}`,
            {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
          if (
            response.data.filteredUsers &&
            response.data.filteredUsers.length > 0
          ) {
            toast.success(
              `Inserted ${response.data.filteredUsers.length} records`,
              {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              }
            );
          }
        } else {
          toast.success("File uploaded successfully!", {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        refetchUserProfiles();
        handleBulkImportCloseModal();
      }
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      toast.error("Error uploading file. Please try again.", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } finally {
      setIsBulkImportLoading(false); // Reset loading state after upload process completes
    }
  };

  const handleBulkImportOpenModal = () => {
    setIsBulkImportModalOpen(true);
  };

  const handleBulkImportCloseModal = () => {
    setBulkImportFile(null);
    setBulkImportFileName("");
    setIsBulkImportModalOpen(false);
  };

  const handleBulkImportUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setBulkImportFile(e.target.files[0]);
      setBulkImportFileName(file.name);
    } else {
      setBulkImportFile(null);
      setBulkImportFileName("");
    }
  };

  const filteredData = users.filter((item) => {
    const searchTerm = filter.trim().toLowerCase();
    const fullName =
      (item.first_name ||
        (item.company_first_name
          ? `${item.company_first_name} ${item.company_last_name ? item.company_last_name : ""
          }`
          : `${item.company_name} (Company)`)) +
      (item.middle_name && item.middle_name !== "null"
        ? " " + item.middle_name
        : "") +
      " " +
      item.last_name;

    const hasMatch =
      (fullName?.toLowerCase().includes(searchTerm) ||
        item.job_title?.toLowerCase().includes(searchTerm) ||
        item.email?.toLowerCase().includes(searchTerm) ||
        item.user_wx_comapny?.wx_company?.toLowerCase().includes(searchTerm)) &&
      (selectedStatus === "Active" ? item.is_active : true) &&
      (selectedStatus === "Inactive" ? !item.is_active : true);

    const isUserFilterMatched =
      userFilter === "All" || selectedTeams.includes(item._id);
    const isUserCategoryFilterMatched =
      userCategoryFilter === "All" ||
      selectedCategory.includes(item.user_category);
    const isAccountTypeFilterMatched =
      accountTypeFilter === "All" ||
      selectedAccountType.includes(item?.role_id?._id);

    if (user.role_id === userTypeName.Admin) {
      return (
        hasMatch &&
        item?.role_id?._id !== userTypeName.Super_Admin &&
        (item.user_category === userCategory.Individual ||
          item.user_category === userCategory.Business) &&
        isUserFilterMatched &&
        isUserCategoryFilterMatched &&
        isAccountTypeFilterMatched
      );
    }

    return (
      hasMatch &&
      (item.user_category === userCategory.Individual ||
        item.user_category === userCategory.Business) &&
      isUserFilterMatched &&
      isUserCategoryFilterMatched &&
      isAccountTypeFilterMatched
    );
  });

  const componentRef = useRef(null);
  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  const exportToExcel = () => {
    if (filteredData.length === 0) {
      toast.error("No data to export.", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    try {
      const now = new Date();
      const formattedDate =
        now.getFullYear() +
        "-" +
        (now.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        now.getDate().toString().padStart(2, "0");
      const formattedTime =
        now.getHours().toString().padStart(2, "0") +
        ":" +
        now.getMinutes().toString().padStart(2, "0") +
        ":" +
        now.getSeconds().toString().padStart(2, "0");

      const modifiedLog = filteredData.map((item, index) => ({
        SlNo: index + 1,
        "User Name": item.first_name
          ? `${item.first_name} ${item.middle_name && item.middle_name !== "null"
            ? item.middle_name + " "
            : ""
          }${item.last_name}`
          : item.company_first_name
            ? `${item.company_first_name} ${item.company_last_name || ""}`
            : `${item.company_name || ""} (Company)`,
        Email: item.email,
        "Company Name": item.company_name,
        "User Category":
          item.user_category === userCategory.Individual
            ? "Individual"
            : item.user_category === userCategory.Business
              ? "Business"
              : item.user_category === userCategory.Vendor
                ? "Vendor"
                : item.user_category === userCategory.Client
                  ? "Client"
                  : "",
        Company: item?.user_wx_comapny?.wx_company
          ? item.user_wx_comapny.wx_company
          : "",
        Role: item.role_id?.user_type,
        "Job Title": item.job_title,
        Department: item?.department?.user_department,
        "Date of Birth": item?.dob
          ? moment(item.dob)
            .tz(global.config.getTimeZone())
            .format("DD/MM/YYYY")
          : "",
        "Passport Number": item.passport_no,
        "Registration Number": item.register_number,
        Mobile: item.mobile_no,
        Address: item.address,
        Pincode: item.pincode,
        Country: item.country,
        State: item.state,
        City: item.city,
        Currency: item.payment_currency,
        "Type of contract": item.typeOfContract
          ? item.typeOfContract.typeOfContract
          : " ",
        "Monthly Hours": `${item.monthly_hours || 168} hrs`,
        Compensation: item.cost
          ? item.cost.toLocaleString("en-US", {
            style: "currency",
            currency: item.payment_currency || "USD",
          })
          : new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: item.payment_currency || "USD",
          }).format(0),
        "Overhead Cost per Hour": item.overhead_cost
          ? item.overhead_cost.toLocaleString("en-US", {
            style: "currency",
            currency: item.payment_currency || "USD",
          })
          : new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: item.payment_currency || "USD",
          }).format(0),
        "VAT (%)": `${item.vat} %`,
        "Vat Id": item.vat_number ? item.vat_number : " ",
        "Is Client Active": item.is_active ? "Yes" : "No",
        "Created at": moment(parseInt(item.created_at)).format(
          "DD/MM/YYYY, hh:mm:ss A"
        ),
      }));

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(modifiedLog);

      // Calculate column widths based on the maximum length of the data in each column
      const getMaxLength = (arr, field) =>
        Math.max(
          ...arr.map((item) => (item[field] || "").toString().length),
          field.length
        );

      const headers = Object.keys(modifiedLog[0]);
      const colWidths = headers.map((header) => ({
        wch: getMaxLength(modifiedLog, header) + 5, // Add padding to each column
      }));

      worksheet["!cols"] = colWidths;

      // Add alignment and font styling for header
      const firstRow = XLSX.utils.decode_range(worksheet["!ref"]).s.r;
      for (let i = 0; i < headers.length; i++) {
        const cell = XLSX.utils.encode_cell({ r: firstRow, c: i });
        const style = worksheet[cell]?.s || {};
        style.alignment = style.alignment || {};
        style.alignment.horizontal = "center";
        style.alignment.vertical = "center";
        style.font = { size: 14, bold: true };
        worksheet[cell] = { ...worksheet[cell], s: style };
      }

      // Set border for active area and increase font size for data
      const range = XLSX.utils.decode_range(worksheet["!ref"]);
      for (let row = range.s.r + 1; row <= range.e.r; row++) {
        for (let col = range.s.c; col <= range.e.c; col++) {
          const cell = XLSX.utils.encode_cell({ r: row, c: col });
          const style = worksheet[cell]?.s || {};
          style.font = { size: 12 };
          worksheet[cell] = { ...worksheet[cell], s: style };
        }
      }

      const fileName = `Users_${formattedDate}_${formattedTime}.xlsx`;
      worksheet["!autofilter"] = { ref: range };
      XLSX.utils.book_append_sheet(workbook, worksheet, "Clients Log");
      XLSX.writeFile(workbook, fileName);
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      global.config.activityLog(
        window.location.href,
        "Clients Log",
        "Error occurred while exporting to Excel"
      );
      alert("Error occurred while exporting to Excel.");
    }
  };


  // new table

  const [refetchLoading, setRefetchLoading] = useState(false);



  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // useEffect(() => {

  //   if (teams && teams?.users) {
  //     setPaginatedTeams(teams?.users);
  //     setTotal(teams.total);
  //   }

  // }, [teams, location, selectedTeams]);

  // useEffect(() => {

  //   if (selectedTeams.length > 0) {

  //     handleRefetch();

  //   }


  // }, [
  //   selectedTeams,
  //   location.pathname,
  // ]);


  // useEffect(() => {

  //   if (teams && teams?.users) {

  //     setTotal(teams.total);

  //   }

  // }, [teams]);


  // new table

  return (
    <motion.div
      ref={componentRef}
      initial="hidden"
      animate="visible"
      variants={fadeIn}
      transition={{ duration: 0.3 }}
    >
      <div className="user-outer-overview invoice-outer-invoice">
        <div className="invoice-top">
          <div className="new-top-flter">
            <div className="button-sec-invoice">
              <Link to="/user/register">
                <span className="icon-invoice">
                  <img
                    src={UserIcon}
                    alt="Create Invoice"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "/uploads/lowProfileImage/user_default.png";
                    }}
                  />
                </span>
                Create User
              </Link>
              <a className="btn-forpop-initem" onClick={handleOpenModal}>
                <span className="icon-invoice">
                  <img src={InviteIcon} alt="Export" />
                </span>
                Invite Users
              </a>

              <Modal
                isOpen={isModalOpen}
                onRequestClose={!isMailLoading ? handleCloseModal : undefined}
                contentLabel="Invite a User"
                className="user-invite-modal-main-outer"
                style={{
                  content: {
                    height: "41%",
                    width: "30%",
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-25%, -50%)",
                  },
                }}
              >
                <div className="modal">
                  <div className="modal-top-section-user-invite">
                    <div className="modal-invite-head">
                      <h2 className="">Invite a new user</h2>
                    </div>
                    <div className="modal-close-invite outter-modal-main outter-modal-main-user">
                      <a
                        className=""
                        onClick={handleCloseModal}
                        disabled={isMailLoading}
                      >
                        <img src={crssrImage} alt="Edit Icon" />{" "}
                      </a>
                    </div>
                  </div>

                  <form onSubmit={handleSubmit}>
                    <div>
                      {emails.map((obj) => (
                        <div key={obj.id} className="the-modal-inuser">
                          <div className="user-add-outer-innner">
                            <div className="invite-user">
                              <label htmlFor="grid-password" className="">
                                Email Address
                              </label>
                              <input
                                type="email"
                                id={`email-${obj.id}`}
                                className=""
                                name="email"
                                required
                                placeholder="Enter Email ID"
                                value={obj.email}
                                onChange={(e) =>
                                  handleEditEmail(obj.id, "email", e.target.value)
                                }
                              />
                              <label
                                htmlFor="grid-password"
                                className="type-of-acc"
                              >
                                Role
                              </label>

                              <div
                                className={`custom-dropdown-user-new-outer ${roleDropdownOpen ? "open" : ""
                                  }`}
                                ref={roleDropdownRef}
                              >
                                <div
                                  className="selected-option custom-dropdown-user-new"
                                  onClick={handleToggleRoleDropdown}
                                >
                                  {obj.userType
                                    ? roleId.find(
                                      (option) => option._id === obj.userType
                                    )?.user_type
                                    : "Select Role"}
                                </div>
                                {roleDropdownOpen && (
                                  <div className="custom-dropdown-new">
                                    <ul className="role-list">
                                      {roleId.map((option) => (
                                        <li
                                          className={
                                            obj.userType === option._id
                                              ? "selected-item"
                                              : ""
                                          }
                                          key={option._id}
                                          onClick={() => {
                                            handleEditEmail(
                                              obj.id,
                                              "userType",
                                              option._id
                                            );
                                            setRoleDropdownOpen(false);
                                          }}
                                        >
                                          {option.user_type}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div
                              className="invite-user-delete"
                              onClick={() => handleRemoveEmail(obj.id)}
                            >
                              <img src={dletImage} alt="delete-icon" />{" "}
                            </div>
                          </div>
                        </div>
                      ))}
                      <div className="the-modal-inuser">
                        <div className="user-add-outer-innner">
                          <div className="invite-user">
                            <label htmlFor="grid-password" className="">
                              Email Address
                            </label>
                            <input
                              type="email"
                              name="email"
                              className={emailValidation && "error-input"}
                              placeholder="Enter Email ID"
                              value={email}
                              onChange={(e) => {
                                setEmail(e.target.value);
                                setEmailValodation(false);
                              }}
                            />
                            <label
                              htmlFor="grid-password"
                              className="type-of-acc"
                            >
                              Role
                            </label>

                            <div
                              className={`custom-dropdown-user-new-outer ${userTypeDropdownOpen ? "open" : ""
                                }`}
                              ref={userTypeDropdownRef}
                            >
                              <div
                                className={
                                  selectValidation
                                    ? "selected-option custom-dropdown-user-new error-input"
                                    : "selected-option custom-dropdown-user-new"
                                }
                                onClick={handleToggleUserTypeDropdown}
                              >
                                {userType
                                  ? roleId.find(
                                    (option) => option._id === userType
                                  )?.user_type
                                  : "Select Role"}
                              </div>
                              {userTypeDropdownOpen && (
                                <div className="custom-dropdown-new">
                                  <ul className="role-list">
                                    {roleId.map((option) => (
                                      <li
                                        className={
                                          userType === option._id
                                            ? "selected-item"
                                            : ""
                                        }
                                        key={option._id}
                                        onClick={() =>
                                          handleUserTypeOptionClick(option._id)
                                        }
                                      >
                                        {option.user_type}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              )}
                            </div>
                          </div>
                          {emails.length > 0 && (
                            <div
                              className="invite-user-delete"
                              onClick={handleRemoveLatestEmail}
                            >
                              <img src={dletImage} alt="delete-icon" />
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className="add-email-sec-modal"
                        onClick={handleAddEmail}
                        style={{ cursor: "pointer" }}
                      >
                        <img src={addicon} alt="Edit Icon" />
                        <div className="add-icon-main-text">Add new email</div>
                      </div>
                    </div>
                    <div className="invite-mail-popup-btn">
                      <button type="submit" className="" disabled={isMailLoading}>
                        {isMailLoading ? (
                          <div className="outter-load-table">
                            <ThreeDots
                              height="18"
                              width="20"
                              radius="9"
                              color="white"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClassName=""
                              visible={true}
                            />
                          </div>
                        ) : (
                          "Send Invite"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </Modal>

              <Modal
                isOpen={isResendModalOpen}
                onRequestClose={
                  !isMailLoading ? handleCloseResendModal : undefined
                }
                contentLabel="Resend Invitations"
                style={{
                  content: {
                    height: "41%",
                    width: "30%",
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-25%, -50%)",
                  },
                }}
              >
                <div className="modal">
                  <div className="modal-close-invite outter-modal-main">
                    <a
                      className=""
                      onClick={handleCloseResendModal}
                      disabled={isMailLoading}
                    >
                      <img src={crssrImage} alt="Edit Icon" />{" "}
                    </a>
                  </div>
                  <div className="modal-invite-head">
                    <h2 className="">Resend Invitations</h2>
                  </div>
                  {/* List the already sent emails here */}
                  <div>
                    <div className="the-modal-inuser">
                      <label htmlFor="grid-password" className="">
                        Email
                      </label>
                      {alreadySentEmailsList.map((email) => (
                        <div key={email.email} className="the-modal-inusers">
                          <div className="resend-invitation">
                            <input
                              type="email"
                              className=""
                              name="email"
                              required
                              placeholder="Enter Email ID"
                              value={email.email}
                              disabled
                            />
                            <label
                              htmlFor="grid-password"
                              className="type-of-acc"
                            >
                              Role
                            </label>
                            <select value={email.userType} disabled>
                              <option value="">Select Role</option>
                              {roleId.map((role) => (
                                <option key={role._id} value={role._id}>
                                  {role.user_type}
                                </option>
                              ))}
                            </select>
                            {alreadySentEmailsList.length > 1 && (
                              <button
                                className="del-resend"
                                onClick={() => handleRemoveResendEmail(email)}
                              >
                                <img src={dletImage} alt="delete-icon" />{" "}
                              </button>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="sprint-btn-outer resend-invoice">
                    <button
                      type="button"
                      onClick={handleCloseResendModal}
                      disabled={isMailLoading}
                      className="submit-client cancel-sprint"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="submit-client save-sprint"
                      disabled={isMailLoading}
                      onClick={handleResendEmails}
                    >
                      {isMailLoading ? (
                        <div className="outter-load-table">
                          <ThreeDots
                            height="18"
                            width="20"
                            radius="9"
                            color="white"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </div>
                      ) : (
                        "Resend Invitations"
                      )}
                    </button>
                  </div>
                </div>
              </Modal>

              <a
                className="btn-forpop-initem bulk-import"
                onClick={handleBulkImportOpenModal}
              >
                <span className="icon-invoice">
                  <img src={DownloadImage} alt="Export" />
                </span>
                Bulk Import
              </a>

              <a
                className="btn-forpop-initem bulk-export"
                onClick={exportToExcel}
              >
                <span className="icon-invoice">
                  <img src={DownloadImage} alt="Export" />
                </span>
                Export Data
              </a>

              <Modal
                isOpen={isBulkImportModalOpen}
                onRequestClose={
                  !isBulkImportLoading ? handleBulkImportCloseModal : undefined
                }
                contentLabel="Invite a User"
                className="bulk-import-popup"
                style={{
                  content: {
                    height: "50%",
                    width: "50%",
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-17.5%, -46%)",
                  },
                }}
              >
                <div className="modal">
                  <div className="outter-modal-main">
                    <a
                      className="close-image-clr1"
                      onClick={handleBulkImportCloseModal}
                      disabled={isBulkImportLoading}
                    >
                      <img src={crssrImage} alt="Close Bulk Import" />{" "}
                    </a>
                  </div>

                  <div className="outter-modal-main">
                    <p>Bulk Import</p>
                    {/* <div className="modal-top-section-user-invite">
                  <div className="modal-invite-head">
                    <h2 className="">BULK IMPORT</h2>
                  </div>
                  <div className="modal-close-invite outter-modal-main outter-modal-main-user">
                    <a className="" onClick={handleBulkImportCloseModal} disabled={isBulkImportLoading}>
                      <img src={crssrImage} alt="Close Bulk Import" />{" "}
                    </a>
                  </div>
                </div> */}

                    <form>
                      <div className="relative w-full mb-3">
                        <label className="gray-label">Select client list</label>
                        <div className="file-input-wrapper">
                          <input
                            type="text"
                            className="form-input form-input-file"
                            value={bulkImportFileName}
                            readOnly
                          />
                          <input
                            type="file"
                            id="expFileAttchEdit"
                            name="expFileAttchEdit"
                            className="form-input hidden-input"
                            onChange={handleBulkImportUpload}
                          // required
                          />
                          <label
                            htmlFor="expFileAttch"
                            className="file-input-label"
                          >
                            <img src={Plus} alt="Browse" />
                            Browse
                          </label>
                        </div>
                        <div className="text-sm bulk-text">
                          Demo File: {"    "}
                          <a
                            href="https://docs.google.com/spreadsheets/d/1rCCZmtrRoUs2wL-58OzTunfEYZdiVRQnvKYrTYQIhoQ/edit?usp=sharing"
                            className="form-textarea"
                            target="_blank"
                          >
                            Import_demo.xlsx
                          </a>
                        </div>
                      </div>

                      <div className="bulk-btn-section sprint-btn-outer">
                        <button
                          className="submit-client cancel-sprint"
                          type="button"
                          onClick={handleBulkImportCloseModal}
                          disabled={isBulkImportLoading}
                        >
                          Close
                        </button>
                        <button
                          className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="button"
                          onClick={handleUpload}
                          disabled={isBulkImportLoading}
                        >
                          Send
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </Modal>
            </div>
            
            <div className="search-sec search-sec-user-overview">
              <div class="search-container">
                <input
                  type="text"
                  onChange={(e) => setFilter(e.target.value)}
                  value={filter}
                  class="search-input"
                  placeholder="Search"
                />
              </div>
            </div>
          </div>
          <div className="invoice-filter">
            <div className="invoice-filter-inner-section no-flter-all">
              <div className="filter-inner invoice-status">
                <label for="user">User Name</label>
                <div className="custom-dropdown1" ref={teamDropdownRef}>
                  <div className="drop-down-inner">
                    <div className="selected-options1" onClick={toggleTeamDropdown} >
                      {selectedTeams.length === 0 ? (
                        <div className="placeholder">Select</div>
                      ) : (
                        <>
                          <div
                            className="selected-option1"
                            key={selectedTeams[0]}
                          >
                            {selectedTeams.length === userProfiles.length ? (
                              `All`
                            ) : (
                              <>
                                <TruncateFilterFieldView
                                  textContent={
                                    userProfiles.find((item) => item._id === selectedTeams[0])?.first_name
                                      ? `${userProfiles.find((item) => item._id === selectedTeams[0]).first_name} ${userProfiles.find((item) => item._id === selectedTeams[0]).middle_name
                                        ? userProfiles.find((item) => item._id === selectedTeams[0]).middle_name : ""} ${userProfiles.find((item) => item._id === selectedTeams[0]).last_name
                                          ? userProfiles.find((item) => item._id === selectedTeams[0]).last_name : ""}`
                                      : userProfiles.find((item) => item._id === selectedTeams[0]).company_first_name ?
                                        `${userProfiles.find((item) => item._id === selectedTeams[0]).company_first_name} ${userProfiles.find((item) => item._id === selectedTeams[0]).company_last_name ?
                                          userProfiles.find((item) => item._id === selectedTeams[0]).company_last_name : ""}`
                                        : `${userProfiles.find((item) => item._id === selectedTeams[0]).company_name} (Company)`
                                  }
                                />
                                {selectedTeams.length > 1 &&
                                  selectedTeams.length !== userProfiles.length && (
                                    <div className="selected-option-count">
                                      {selectedTeams.length - 1}+
                                    </div>
                                  )}
                              </>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                    {isTeamDropdownOpen && (
                      <div className="dropdown-list1">
                        {userProfiles.length > 0 && (
                          <>
                            <label className="checkbox-label1" key="selectAll" >
                              <input type="checkbox" checked={selectAllTeam} onChange={handleSelectAllTeamChange} />
                              All Teams
                            </label>
                            {userProfiles
                              .slice()
                              .sort((a, b) => {
                                // Define the name to sort by (either individual or company)
                                const nameA = a.first_name
                                  ? `${a.first_name} ${a.middle_name || ''} ${a.last_name || ''}`.trim()
                                  : a.company_first_name
                                    ? `${a.company_first_name} ${a.company_last_name || ''}`.trim()
                                    : a.company_name || '';

                                const nameB = b.first_name
                                  ? `${b.first_name} ${b.middle_name || ''} ${b.last_name || ''}`.trim()
                                  : b.company_first_name
                                    ? `${b.company_first_name} ${b.company_last_name || ''}`.trim()
                                    : b.company_name || '';

                                // Sort alphabetically
                                return nameA.localeCompare(nameB);
                              })
                              .map((team) => (
                                <label className="checkbox-label1" key={team._id}>
                                  <input
                                    type="checkbox"
                                    checked={selectedTeams.includes(team._id)}
                                    onChange={() => handleCheckboxTeamChange(team._id)}
                                  />
                                  {team.first_name
                                    ? `${team.first_name} ${team.middle_name ? team.middle_name : ""} ${team.last_name}`
                                    : team.company_first_name
                                      ? `${team.company_first_name} ${team.company_last_name ? team.company_last_name : ""}`
                                      : `${team.company_name} (Company)`}
                                </label>
                              ))}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="filter-inner user-category">
                <label htmlFor="userCategory">User Category</label>
                <div className="custom-dropdown1" ref={userCategoryDropdownRef}>
                  <div className="drop-down-inner">
                    <div className="selected-options1" onClick={toggleCategoryDropdown}>
                      {selectedCategory.length === 0 ? (
                        <div className="placeholder">Select</div>
                      ) : (
                        <>
                          <div className="selected-option1" key={selectedCategory[0]}>
                            {selectedCategory.length === userCategoryList.length ? (
                              "All"
                            ) : (
                              <>
                                <TruncateFilterFieldView
                                  textContent={
                                    userCategoryList.find(
                                      (item) => item.value === selectedCategory[0]
                                    )?.name
                                  }
                                />
                                {selectedCategory.length > 1 &&
                                  selectedCategory.length !== userCategoryList.length && (
                                    <div className="selected-option-count">
                                      {selectedCategory.length - 1}+
                                    </div>
                                  )}
                              </>
                            )}
                          </div>
                        </>
                      )}
                    </div>

                    {isCategoryDropdownOpen && (
                      <div className="dropdown-list1">
                        {userCategoryList.length > 0 && (
                          <>
                            <label className="checkbox-label1" key="selectAll">
                              <input
                                type="checkbox"
                                checked={selectAllCategory}
                                onChange={handleSelectAllCategoryChange}
                              />
                              All
                            </label>
                            {userCategoryList
                              .sort((a, b) => a.name.localeCompare(b.name)) // Sort by category name alphabetically
                              .map((category) => (
                                <label className="checkbox-label1" key={category.value}>
                                  <input
                                    type="checkbox"
                                    checked={selectedCategory.includes(category.value)}
                                    onChange={() => handleCheckboxCategoryChange(category.value)}
                                  />
                                  {category.name}
                                </label>
                              ))}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="filter-inner account-type">
                <label htmlFor="accountType">Type of Account</label>
                <div className="custom-dropdown1" ref={accountTypeDropdownRef}>
                  <div className="drop-down-inner">
                    <div className="selected-options1" onClick={toggleAccountTypeDropdown}>
                      {selectedAccountType.length === 0 ? (
                        <div className="placeholder">Select</div>
                      ) : (
                        <>
                          <div className="selected-option1" key={selectedAccountType[0]}>
                            {selectedAccountType.length === filteredRoleId.length ? (
                              "All"
                            ) : (
                              <>
                                <TruncateFilterFieldView
                                  textContent={
                                    filteredRoleId.find(
                                      (item) => item._id === selectedAccountType[0]
                                    )?.user_type
                                  }
                                />
                                {selectedAccountType.length > 1 &&
                                  selectedAccountType.length !== filteredRoleId.length && (
                                    <div className="selected-option-count">
                                      {selectedAccountType.length - 1}+
                                    </div>
                                  )}
                              </>
                            )}
                          </div>
                        </>
                      )}
                    </div>

                    {isAccountTypeDropdownOpen && (
                      <div className="dropdown-list1">
                        {filteredRoleId.length > 0 && (
                          <>
                            <label className="checkbox-label1" key="selectAll">
                              <input
                                type="checkbox"
                                checked={selectAllAccountType}
                                onChange={handleSelectAllAccountTypeChange}
                              />
                              All
                            </label>
                            {filteredRoleId
                              .sort((a, b) => (a.user_type || '').localeCompare(b.user_type || '')) // Sort by user_type alphabetically
                              .map((accountType) => (
                                <label className="checkbox-label1" key={accountType._id}>
                                  <input
                                    type="checkbox"
                                    checked={selectedAccountType.includes(accountType._id)}
                                    onChange={() => handleCheckboxAccountTypeChange(accountType._id)}
                                  />
                                  {accountType.user_type && `${accountType.user_type}`}
                                </label>
                              ))}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="filter-inner invoice-type">
                <label for="invoice-type">Status</label>
                <div className="custom-dropdown1" ref={statusDropdownRef}>
                  <div className="drop-down-inner">
                    <div
                      className="selected-options1"
                      onClick={toggleStatusDropdown}
                    >
                      {selectedStatus ? global.config.locate(appSettings && appSettings.language, selectedStatus) : (appSettings && appSettings.language ? global.config.locate(appSettings.language, 'ALL') : null)}
                    </div>
                    {statusDropdownOpen && (
                      <div className="custom-dropdown-new">
                        <ul className="type-list">
                          <li onClick={() => selectStatusOption(null)}>All</li>
                          <li onClick={() => selectStatusOption('Active')}>{global.config.locate(appSettings && appSettings.language, 'Active')}</li>
                          <li onClick={() => selectStatusOption('Inactive')}>{global.config.locate(appSettings && appSettings.language, 'Inactive')}</li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="filter-invoice-right apply-fliter-right">
              <button onClick={toggleFilterModal}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="17"
                  viewBox="0 0 13 17"
                  fill="none"
                >
                  <path
                    d="M11.7363 8.81108C11.4276 9.81451 10.4925 10.5444 9.38889 10.5444C8.28471 10.5444 7.35016 9.8141 7.04147 8.81108L0.722221 8.81108C0.323349 8.81108 -1.07845e-06 8.48773 -1.06101e-06 8.08886C-1.04357e-06 7.68998 0.323349 7.36663 0.722221 7.36663L7.04144 7.36663C7.35022 6.3632 8.28529 5.6333 9.38889 5.6333C10.4931 5.6333 11.4276 6.36362 11.7363 7.36663L12.2778 7.36663C12.6767 7.36663 13 7.68998 13 8.08886C13 8.48773 12.6766 8.81108 12.2778 8.81108L11.7363 8.81108ZM9.38889 7.07775C8.83162 7.07775 8.37778 7.53123 8.37778 8.08886C8.37778 8.64613 8.83127 9.09997 9.38889 9.09997C9.94616 9.09997 10.4 8.64648 10.4 8.08886C10.4 7.53158 9.94651 7.07775 9.38889 7.07775Z"
                    fill="#6479F8"
                  />
                  <path
                    d="M5.38078 3.17778C5.072 4.18121 4.13693 4.91111 3.03333 4.91111C1.92882 4.91111 0.994035 4.18035 0.68563 3.17687C0.303759 3.15781 -1.07791e-06 2.84216 -1.06101e-06 2.45556C-1.04411e-06 2.06896 0.303748 1.75331 0.685606 1.73425C0.994098 0.730343 1.9294 -4.83911e-07 3.03333 -4.35657e-07C4.13752 -3.87392e-07 5.07206 0.730315 5.38076 1.73333L12.2778 1.73333C12.6767 1.73333 13 2.05668 13 2.45556C13 2.85443 12.6766 3.17778 12.2778 3.17778L5.38078 3.17778ZM3.03333 1.44444C2.47606 1.44444 2.02222 1.89793 2.02222 2.45556C2.02222 3.01283 2.47571 3.46667 3.03333 3.46667C3.5906 3.46667 4.04444 3.01318 4.04444 2.45556C4.04444 1.89828 3.59096 1.44444 3.03333 1.44444Z"
                    fill="#6479F8"
                  />
                  <path
                    d="M7.403 14.4444C7.09422 15.4478 6.15916 16.1777 5.05556 16.1777C3.95137 16.1777 3.01682 15.4474 2.70813 14.4444L0.722221 14.4444C0.323349 14.4444 -1.07845e-06 14.121 -1.06101e-06 13.7222C-1.04357e-06 13.3233 0.323349 12.9999 0.722221 12.9999L2.70811 12.9999C3.01689 11.9965 3.95195 11.2666 5.05556 11.2666C6.15974 11.2666 7.09429 11.9969 7.40298 12.9999L12.2778 12.9999C12.6767 12.9999 13 13.3233 13 13.7222C13 14.121 12.6766 14.4444 12.2778 14.4444L7.403 14.4444ZM5.05556 12.711C4.49828 12.711 4.04444 13.1645 4.04444 13.7222C4.04444 14.2794 4.49793 14.7333 5.05556 14.7333C5.61283 14.7333 6.06667 14.2798 6.06667 13.7222C6.06667 13.1649 5.61318 12.711 5.05556 12.711Z"
                    fill="#6479F8"
                  />
                </svg>
                All Filters
              </button>
            </div>
            <Modal
              isOpen={showFilterModal}
              onRequestClose={checkAndShowConfirmationModal}
              contentLabel="Filter Modal"
              className="new-filter-modal"
              style={{
                content: {
                  height: "50%",
                  width: "50%",
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-25%, -25%)",
                  borderRadius: "10px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "#fff",
                  padding: "20px",
                  // overflow: "auto",
                },
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                },
              }}
            >
              <div className="new-nodal-invoice-outer-sec">

                <div className="new-modal-top-sec">
                  <div className="new-modal-heading">
                    All filters
                  </div>
                  <a className="close-image-clr1" onClick={checkAndShowConfirmationModal}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                      <path d="M1 13L13 1" stroke="black" />
                      <path d="M1 0.999999L13 13" stroke="black" />
                    </svg>{" "}
                  </a>
                </div>
                <div className="invoice-filter-inner-section">
                  <div className="filter-inner invoice-status">
                    <label for="user">User Name</label>
                    <div className="custom-dropdown1" ref={teamModalDropdownRef}>
                      <div className="drop-down-inner">
                        <div className="selected-options1" onClick={toggleTeamModalDropdown} >
                          {selectedTeams.length === 0 ? (
                            <div className="placeholder">Select</div>
                          ) : (
                            <>
                              <div
                                className="selected-option1"
                                key={selectedTeams[0]}
                              >
                                {selectedTeams.length === userProfiles.length ? (
                                  `All`
                                ) : (
                                  <>
                                    <TruncateFilterFieldView
                                      textContent={
                                        userProfiles.find((item) => item._id === selectedTeams[0])?.first_name
                                          ? `${userProfiles.find((item) => item._id === selectedTeams[0]).first_name} ${userProfiles.find((item) => item._id === selectedTeams[0]).middle_name
                                            ? userProfiles.find((item) => item._id === selectedTeams[0]).middle_name : ""} ${userProfiles.find((item) => item._id === selectedTeams[0]).last_name
                                              ? userProfiles.find((item) => item._id === selectedTeams[0]).last_name : ""}`
                                          : userProfiles.find((item) => item._id === selectedTeams[0]).company_first_name ?
                                            `${userProfiles.find((item) => item._id === selectedTeams[0]).company_first_name} ${userProfiles.find((item) => item._id === selectedTeams[0]).company_last_name ?
                                              userProfiles.find((item) => item._id === selectedTeams[0]).company_last_name : ""}`
                                            : `${userProfiles.find((item) => item._id === selectedTeams[0]).company_name} (Company)`
                                      }
                                    />
                                    {selectedTeams.length > 1 &&
                                      selectedTeams.length !== userProfiles.length && (
                                        <div className="selected-option-count">
                                          {selectedTeams.length - 1}+
                                        </div>
                                      )}
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                        {isTeamModalDropdownOpen && (
                          <div className="dropdown-list1">
                            {userProfiles.length > 0 && (
                              <>
                                <label className="checkbox-label1" key="selectAll" >
                                  <input type="checkbox" checked={selectAllTeam} onChange={handleSelectAllTeamChange} />
                                  All Teams
                                </label>
                                {userProfiles
                                  .slice()
                                  .sort((a, b) => {
                                    const nameA = a.first_name
                                      ? `${a.first_name} ${a.middle_name || ''} ${a.last_name || ''}`.trim()
                                      : a.company_first_name
                                        ? `${a.company_first_name} ${a.company_last_name || ''}`.trim()
                                        : a.company_name || '';

                                    const nameB = b.first_name
                                      ? `${b.first_name} ${b.middle_name || ''} ${b.last_name || ''}`.trim()
                                      : b.company_first_name
                                        ? `${b.company_first_name} ${b.company_last_name || ''}`.trim()
                                        : b.company_name || '';

                                    return nameA.localeCompare(nameB);
                                  })
                                  .map((team) => (
                                    <label className="checkbox-label1" key={team._id} >
                                      <input
                                        type="checkbox"
                                        checked={selectedTeams.includes(team._id)}
                                        onChange={() =>
                                          handleCheckboxTeamChange(team._id)
                                        }
                                      />
                                      {team.first_name
                                        ? `${team.first_name} ${team.middle_name ? team.middle_name : ""
                                        } ${team.last_name}`
                                        : team.company_first_name
                                          ? `${team.company_first_name} ${team.company_last_name
                                            ? team.company_last_name
                                            : ""
                                          }`
                                          : `${team.company_name} (Company)`}
                                    </label>
                                  ))}
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="filter-inner user-category">
                    <label htmlFor="userCategory">User Category</label>
                    <div className="custom-dropdown1" ref={userCategoryModalDropdownRef}>
                      <div className="drop-down-inner">
                        <div
                          className="selected-options1"
                          onClick={toggleCategoryModalDropdown}
                        >
                          {selectedCategory.length === 0 ? (
                            <div className="placeholder">Select</div>
                          ) : (
                            <>
                              <div className="selected-option1" key={selectedCategory[0]}>
                                {selectedCategory.length === userCategoryList.length ? (
                                  "All"
                                ) : (
                                  <>
                                    <TruncateFilterFieldView
                                      textContent={
                                        userCategoryList.find(
                                          (item) => item.value === selectedCategory[0]
                                        )?.name
                                      }
                                    />
                                    {selectedCategory.length > 1 &&
                                      selectedCategory.length !== userCategoryList.length && (
                                        <div className="selected-option-count">
                                          {selectedCategory.length - 1}+
                                        </div>
                                      )}
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        </div>

                        {isCategoryModalDropdownOpen && (
                          <div className="dropdown-list1">
                            {userCategoryList.length > 0 && (
                              <>
                                <label className="checkbox-label1" key="selectAll">
                                  <input
                                    type="checkbox"
                                    checked={selectAllCategory}
                                    onChange={handleSelectAllCategoryChange}
                                  />
                                  All
                                </label>
                                {userCategoryList.map((category) => (
                                  <label className="checkbox-label1" key={category.value}>
                                    <input
                                      type="checkbox"
                                      checked={selectedCategory.includes(category.value)}
                                      onChange={() =>
                                        handleCheckboxCategoryChange(category.value)
                                      }
                                    />
                                    {category.name}
                                  </label>
                                ))}
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="filter-inner payment-status">
                    <label htmlFor="typeofAccount">Type of Account</label>
                    <div className="custom-dropdown1" ref={accountTypeModalDropdownRef}>
                      <div className="drop-down-inner">
                        <div className="selected-options1" onClick={toggleAccountTypeModalDropdown}>
                          {selectedAccountType.length === 0 ? (
                            <div className="placeholder">Select</div>
                          ) : (
                            <>
                              <div className="selected-option1" key={selectedAccountType[0]}>
                                {selectedAccountType.length === filteredRoleId.length ? (
                                  "All"
                                ) : (
                                  <>
                                    <TruncateFilterFieldView
                                      textContent={
                                        filteredRoleId.find(
                                          (item) => item._id === selectedAccountType[0]
                                        )?.user_type
                                      }
                                    />
                                    {selectedAccountType.length > 1 &&
                                      selectedAccountType.length !== filteredRoleId.length && (
                                        <div className="selected-option-count">
                                          {selectedAccountType.length - 1}+
                                        </div>
                                      )}
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        </div>

                        {isAccountTypeModalDropdownOpen && (
                          <div className="dropdown-list1">
                            {filteredRoleId.length > 0 && (
                              <>
                                <label className="checkbox-label1" key="selectAll">
                                  <input
                                    type="checkbox"
                                    checked={selectAllAccountType}
                                    onChange={handleSelectAllAccountTypeChange}
                                  />
                                  All
                                </label>
                                {filteredRoleId.map((accountType) => (
                                  <label className="checkbox-label1" key={accountType._id}>
                                    <input
                                      type="checkbox"
                                      checked={selectedAccountType.includes(accountType._id)}
                                      onChange={() =>
                                        handleCheckboxAccountTypeChange(accountType._id)
                                      }
                                    />
                                    {accountType.user_type && `${accountType.user_type}`}
                                  </label>
                                ))}
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="filter-inner invoice-type">
                    <label for="invoice-type">Status</label>
                    <div className="custom-dropdown1" ref={statusModalDropdownRef}>
                      <div className="drop-down-inner">
                        <div
                          className="selected-options1"
                          onClick={toggleStatusModalDropdown}
                        >
                          {selectedStatus ? global.config.locate(appSettings && appSettings.language, selectedStatus) : (appSettings && appSettings.language ? global.config.locate(appSettings.language, 'ALL') : null)}
                        </div>
                        {statusModalDropdownOpen && (
                          <div className="custom-dropdown-new">
                            <ul className="type-list">
                              <li onClick={() => selectStatusOption(null)}>All</li>
                              <li onClick={() => selectStatusOption('Active')}>{global.config.locate(appSettings && appSettings.language, 'Active')}</li>
                              <li onClick={() => selectStatusOption('Inactive')}>{global.config.locate(appSettings && appSettings.language, 'Inactive')}</li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <Modal
                    className="delete-modal"
                    isOpen={showConfirmationModal}
                    onRequestClose={handleCancel}
                    contentLabel="Confirm Delete"
                    style={{
                      content: {
                        height: "41%",
                        width: "30%",
                        position: "fixed",
                        top: "36%",
                        left: "50%",
                        transform: "translate(-24.75%, 0%)",
                      },
                    }}
                  >
                    <div className="delete-modal-content">
                      <h2>Apply or Discard changes?</h2>
                      <p>
                        Do you want to Apply or Discard filter changes?
                      </p>

                      <div className="delete-modal-buttons">
                        <button className="delete" onClick={handleApplyConfirm} >
                          Apply Changes
                        </button>
                        <button onClick={clearFilters}>
                          Discard Changes
                        </button>
                      </div>
                    </div>
                  </Modal>
                </div>
              </div>
              <div className="filter-buttons">
                <button className="clear-all" onClick={clearFilters}>Clear All</button>
                <button className="apply-filter" onClick={applyFilters}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="17" viewBox="0 0 13 17" fill="none">
                    <path d="M11.7363 8.81108C11.4276 9.81451 10.4925 10.5444 9.38889 10.5444C8.28471 10.5444 7.35016 9.8141 7.04147 8.81108L0.722221 8.81108C0.323349 8.81108 -1.07845e-06 8.48773 -1.06101e-06 8.08886C-1.04357e-06 7.68998 0.323349 7.36663 0.722221 7.36663L7.04144 7.36663C7.35022 6.3632 8.28529 5.6333 9.38889 5.6333C10.4931 5.6333 11.4276 6.36362 11.7363 7.36663L12.2778 7.36663C12.6767 7.36663 13 7.68998 13 8.08886C13 8.48773 12.6766 8.81108 12.2778 8.81108L11.7363 8.81108ZM9.38889 7.07775C8.83162 7.07775 8.37778 7.53123 8.37778 8.08886C8.37778 8.64613 8.83127 9.09997 9.38889 9.09997C9.94616 9.09997 10.4 8.64648 10.4 8.08886C10.4 7.53158 9.94651 7.07775 9.38889 7.07775Z" fill="white" />
                    <path d="M5.38078 3.17778C5.072 4.18121 4.13693 4.91111 3.03333 4.91111C1.92882 4.91111 0.994035 4.18035 0.68563 3.17687C0.303759 3.15781 -1.07791e-06 2.84216 -1.06101e-06 2.45556C-1.04411e-06 2.06896 0.303748 1.75331 0.685606 1.73425C0.994098 0.730343 1.9294 -4.83911e-07 3.03333 -4.35657e-07C4.13752 -3.87392e-07 5.07206 0.730315 5.38076 1.73333L12.2778 1.73333C12.6767 1.73333 13 2.05668 13 2.45556C13 2.85443 12.6766 3.17778 12.2778 3.17778L5.38078 3.17778ZM3.03333 1.44444C2.47606 1.44444 2.02222 1.89793 2.02222 2.45556C2.02222 3.01283 2.47571 3.46667 3.03333 3.46667C3.5906 3.46667 4.04444 3.01318 4.04444 2.45556C4.04444 1.89828 3.59096 1.44444 3.03333 1.44444Z" fill="white" />
                    <path d="M7.403 14.4444C7.09422 15.4478 6.15916 16.1777 5.05556 16.1777C3.95137 16.1777 3.01682 15.4474 2.70813 14.4444L0.722221 14.4444C0.323349 14.4444 -1.07845e-06 14.121 -1.06101e-06 13.7222C-1.04357e-06 13.3233 0.323349 12.9999 0.722221 12.9999L2.70811 12.9999C3.01689 11.9965 3.95195 11.2666 5.05556 11.2666C6.15974 11.2666 7.09429 11.9969 7.40298 12.9999L12.2778 12.9999C12.6767 12.9999 13 13.3233 13 13.7222C13 14.121 12.6766 14.4444 12.2778 14.4444L7.403 14.4444ZM5.05556 12.711C4.49828 12.711 4.04444 13.1645 4.04444 13.7222C4.04444 14.2794 4.49793 14.7333 5.05556 14.7333C5.61283 14.7333 6.06667 14.2798 6.06667 13.7222C6.06667 13.1649 5.61318 12.711 5.05556 12.711Z" fill="white" />
                  </svg>
                  Apply Filters
                </button>
              </div>
            </Modal>
          </div>
          <div className="filter-invoice-right">
              <div className="filter-inner showing-result">
                <label>Showing</label>
                {/* <select
                value={
                  totalResults < 10
                    ? totalResults.toString()
                    : rowsPerPage.toString()
                }
                onChange={handleRowsPerPageChange}
              >
                {totalResults >= 10 && <option value="10">10</option>}
                {totalResults >= 20 && <option value="20">20</option>}
                {totalResults >= 30 && <option value="30">30</option>}
                {totalResults >= 40 && <option value="40">40</option>}
                {totalResults > 10 && <option value={totalResults.toString()}>ALL</option>}
                {totalResults < 10 ? (
                  <option value={totalResults.toString()}>
                    {totalResults}
                  </option>
                ) : null}
              </select> */}
                <div
                  ref={showingDropdownRef}
                  className="select-modal-mainM custom-dropdown-user-new-outer"
                >
                  <div
                    className="selected-option custom-dropdown-user-new"
                    onClick={() => setShowingDropdownOpen(!showingDropdownOpen)}
                  >
                    {total < 10 ? total.toString() : rowsPerPage.toString()}
                  </div>
                  {showingDropdownOpen && (
                    <div className="custom-dropdown-new">
                      <ul className="showing-options-list">
                        {total >= 10 && (
                          <li
                            className={rowsPerPage === 10 ? "selected-item" : ""}
                            onClick={() => {
                              handleRowsPerPageChange({ target: { value: 10 } });
                              setShowingDropdownOpen(false);
                            }}
                          >
                            10
                          </li>
                        )}
                        {total >= 20 && (
                          <li
                            className={rowsPerPage === 20 ? "selected-item" : ""}
                            onClick={() => {
                              handleRowsPerPageChange({ target: { value: 20 } });
                              setShowingDropdownOpen(false);
                            }}
                          >
                            20
                          </li>
                        )}
                        {total >= 30 && (
                          <li
                            className={rowsPerPage === 30 ? "selected-item" : ""}
                            onClick={() => {
                              handleRowsPerPageChange({ target: { value: 30 } });
                              setShowingDropdownOpen(false);
                            }}
                          >
                            30
                          </li>
                        )}
                        {total >= 40 && (
                          <li
                            className={rowsPerPage === 40 ? "selected-item" : ""}
                            onClick={() => {
                              handleRowsPerPageChange({ target: { value: 40 } });
                              setShowingDropdownOpen(false);
                            }}
                          >
                            40
                          </li>
                        )}
                        {total > 10 && (
                          <li
                            className={
                              total > 10 && rowsPerPage === total
                                ? "selected-item"
                                : ""
                            }
                            onClick={() => {
                              handleRowsPerPageChange({
                                target: { value: total },
                              });
                              setShowingDropdownOpen(false);
                            }}
                          >
                            ALL
                          </li>
                        )}
                        {total < 10 ? (
                          <li
                            className={total < 10 ? "selected-item" : ""}
                            onClick={() => {
                              handleRowsPerPageChange({
                                target: { value: total },
                              });
                              setShowingDropdownOpen(false);
                            }}
                          >
                            {total}
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  )}
                </div>
                <label>of {total} Results</label>
              </div>
            </div>
        </div>
        <div className="card-outer1">
          <div className="card-outer">
            <div className="card-outer-user">
              <h3>Recent Users</h3>
            </div>


            {/* <div className="card">
              {isLoading === true ? (
                <div className="outter-load-table">
                  <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color="#405BFF"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : (
                <DataTable
                  value={filteredData}
                  first={first}
                  rows={rows}
                  removableSort
                  paginator={true}
                  paginatorPosition="bottom"
                  totalRecords={filteredData.length}
                  onPage={(e) => setFirst(e.first)}
                  tableStyle={{
                    minWidth: "50rem",
                    width: "100%",
                    textAlign: "left",
                  }}
                  paginatorClassName="recent-invoices-pagination"
                >
                  <Column
                    body={useRenderMemberName}
                    field="fullName"
                    sortField="fullName"
                    header="User Name"
                    sortable
                    style={{ minWidth: "300px", paddingRight: "44px" }}
                    sortFunction={(e) => {
                      const dataCopy = [...filteredData];
                      dataCopy.sort((rowData1, rowData2) => {
                        const fullName1 =
                          rowData1?.user_category &&
                            [
                              userCategory.Business,
                              userCategory.Vendor,
                              userCategory.Client,
                            ].includes(rowData1.user_category)
                            ? rowData1.company_first_name
                              ? `${rowData1.company_first_name} ${rowData1.company_last_name
                                ? rowData1.company_last_name
                                : ""
                              }`
                              : `${rowData1.company_name} (Company)`
                            : rowData1.first_name + " " + rowData1.last_name;

                        const fullName2 =
                          rowData2?.user_category &&
                            [
                              userCategory.Business,
                              userCategory.Vendor,
                              userCategory.Client,
                            ].includes(rowData2.user_category)
                            ? rowData2.company_first_name
                              ? `${rowData2.company_first_name} ${rowData2.company_last_name
                                ? rowData2.company_last_name
                                : ""
                              }`
                              : `${rowData2.company_name} (Company)`
                            : rowData2.first_name + " " + rowData2.last_name;

                        // Implement your custom comparison logic here
                        return e.order === 1
                          ? fullName1.localeCompare(fullName2)
                          : fullName2.localeCompare(fullName1);
                      });
                      return dataCopy;
                    }}
                  ></Column>
                  <Column
                    field="job_title"
                    header="Job Title"
                    sortable
                    style={{ paddingRight: "20px" }}
                  ></Column>
                  <Column
                    field="email"
                    header="Email"
                    sortable
                    style={{ paddingRight: "44px" }}
                  ></Column>
                  <Column
                    field="user_wx_comapny.wx_company"
                    header="Recipient"
                    sortable
                    style={{ paddingRight: "44px" }}
                  ></Column>
                  <Column
                    field="bank_account_verified"
                    body={(rowData) => (
                      <BankAccountVerification email={rowData.email} wiseBanks={wiseBanks} isLoading={wiseBanksLoading} isError={wiseBanksError} />
                    )}
                    header="Bank Account"
                    // sortable
                    style={{ paddingRight: "44px" }}
                  ></Column>
                  <Column
                    field="is_active"
                    body={(rowData) => renderUserStatus(rowData)}
                    header="Status"
                    sortable
                    style={{ paddingRight: "44px" }}
                  ></Column>
                  <Column
                    field="_id"
                    body={RenderUserCTA}
                    style={{ paddingRight: "8px", width: "80px" }}
                  ></Column>
                </DataTable>
              )}
            </div> */}


            <div className="time-table">
              <table style={{ minWidth: "50rem", width: "100%", textAlign: "left" }} className={`${isUpdating ? "loading-fade" : ""}`} >
                <thead>
                  <tr>
                    <th style={{ minWidth: "300px" }}>User Name</th>
                    <th>Job Title</th>
                    <th>Email</th>
                    <th>Recipient</th>
                    <th>Bank Account</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    // userProfileLoading   ? (
                    //   <tr>
                    //     <td colSpan="7" style={{ textAlign: "center" }}>
                    //       <RotatingLines
                    //         strokeColor="grey"
                    //         strokeWidth="5"
                    //         animationDuration="0.75"
                    //         width="40"
                    //         visible={true}
                    //       />
                    //     </td>
                    //   </tr>
                    // ) :

                    paginatedTeams?.total === 0 ? (
                      <tr>
                        <td colSpan="7" style={{ textAlign: "center" }}>No Data Available</td>
                      </tr>
                    ) : (
                      paginatedTeams.map((row, index) => (
                        <tr key={row._id || index}>
                          <td>
                            <UseRenderMemberName rowData={row} />
                          </td>
                          <td>{row.job_title}</td>
                          <td>{row.email}</td>
                          <td>{row.user_wx_comapny?.wx_company || "N/A"}</td>
                          <td>
                            <BankAccountVerification
                              email={row.email}
                              wiseBanks={wiseBanks}
                              isLoading={wiseBanksLoading}
                              isError={wiseBanksError}
                            />
                          </td>
                          <td>{renderUserStatus(row)}</td>
                          <td>{RenderUserCTA(row)}</td>
                        </tr>
                      ))

                    )}



                  {isUpdating && (
                    <>
                    { paginatedTeams.length === 0 && (<tr className="loader-height"><td></td></tr>) }
                      
                      <div className="loasding-spinner-fade">
                        <div className="loadsing-spinner">
                          <RotatingLines
                            strokeColor="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="40"
                            visible={true}
                          />

                        </div>
                      </div>
                    </>
                  )}

                </tbody>
              </table>

            </div>

            {/* Pagination */}
            {paginatedTeams.length > 0 && (
              <ul className="pagination">
                <li className="back">
                  <button
                    className={currentPage === 1 ? "disabled" : ""}
                    disabled={currentPage === 1}
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    Back
                  </button>
                </li>
                {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                  <li key={page} className={currentPage === page ? "active" : ""}>
                    <button onClick={() => handlePageChange(page)}>{page}</button>
                  </li>
                ))}
                <li className="next">
                  <button
                    className={currentPage === totalPages ? "disabled" : ""}
                    disabled={currentPage === totalPages || paginatedTeams.length < rowsPerPage}
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    Next
                  </button>
                </li>
              </ul>
            )}




          </div>
        </div>
      </div>
    </motion.div>
  );
};
export default Users;
