import axios from "axios";
import Modal from "react-modal";
import { AES, enc } from 'crypto-js';
import { DatePicker } from "rsuite";
import moment from 'moment-timezone';
import subDays from "date-fns/subDays";
import { toast } from "react-toastify";
import "./expenseOverview.css";
import { DateRangePicker } from "rsuite";
import { ThreeDots } from "react-loader-spinner";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import React, { useState, useEffect, useRef } from "react";
import userCategory from "../../constants/userCategory.js";
import Currencies from "../../constants/currenciesList.js";
import { logout, reset } from "../../features/auth/authSlice";
import dletImage from "../../images/singleinvoice/delete.svg";
import cancelImage from "../../images/singleinvoice/cancel.svg";
import addicoplus from "../../images/singleinvoice/plus-add-icon.svg";
import crssrImage from "../../images/singleinvoice/cross-red.svg";
import { reset as invoiceReset } from "../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../features/appSettings/appSettingsSlice.js";
import { useExpenseId, } from "../../features/expenseData/useExpenseData.jsx";
import { useWXCompanies } from "../../components/User/UserDetails/UserProfile/useUserView.jsx";
import { useFetchExpCategory } from "../../components/AppSettings/expenseCategoryApi.jsx";
import { useGetClients, useGetProjects, useGetActiveTeams } from "../../components/TimeTracker/TimeTrackerOverview/useTimeTracker.jsx";
import Close from "../../images/icons/black-close.svg";
import Tick from "../../images/singleinvoice/tick-green.svg";
import { setNotification } from "../../features/notification/notificationSlice.js";

const CreateExpense = () => {
  const [expCurrencySearchTerm, setExpCurrencySearchTerm] = useState('');
  const [expCurrencyDropdownOpen, setExpCurrencyDropdownOpen] = useState(false);
  const [expenseCurrencyIsValid, setExpenseCurrencyIsValid] = useState(false);
  const [expIsBillable, setExpIsBillable] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [expenseUserDropdownOpen, setExpenseUserDropdownOpen] = useState(false);
  const [expenseUserIsValid, setExpenseUserIsValid] = useState(false);
  const expenseUserRef = useRef(null);
  const [expenseDate, setExpenseDate] = useState(null);
  const [expenseClient, setExpenseClient] = useState("");
  const [expenseProject, setExpenseProject] = useState("");
  const [expenseCompany, setExpenseCompany] = useState("");
  const [expNote, setExpNote] = useState("");
  const [expFileAttchFiles, setExpFileAttchFiles] = useState([]);
  const [expFileAttchFileName, setExpFileAttchFileName] = useState("");
  const [expProjectDropdownOpen, setExpProjectDropdownOpen] = useState(false);
  const [expProjectSearchTerm, setExpProjectSearchTerm] = useState('');
  const [expClientDropdownOpen, setExpClientDropdownOpen] = useState(false);
  const [expenseClientIsValid, setExpenseClientIsValid] = useState(false);
  const [expenseCompanyIsValid, setExpenseCompanyIsValid] = useState(false);
  const [expCurrencyList, setExpCurrencyList] = useState([]);
  const [expenseUser, setExpenseUser] = useState("");
  const [expDescription, setExpDescription] = useState("");
  const [expCurrency, setExpCurrency] = useState("");
  const [expSubTotal, setExpSubTotal] = useState("");
  const [expTax, setExpTax] = useState("");
  const [expAmount, setExpAmount] = useState(0);
  const [expenseDescriptionIsValid, setExpenseDescriptionIsValid] = useState(false);
  const [expSubTotalIsValid, setExpSubTotalIsValid] = useState(false);
  const [expTaxIsValid, setExpTaxIsValid] = useState(false);
  const [expenseDateIsValid, setExpenseDateIsValid] = useState(false);
  const [expenseCategoryDropdownOpen, setExpenseCategoryDropdownOpen] = useState(false);
  const [expenseCategoryIsValid, setExpenseCategoryIsValid] = useState(false);
  const expenseCategoryDropdownRef = useRef(null);
  const expenseCategoryRef = useRef(null);
  const [expCategory, setExpCategory] = useState("");
  const expenseCurrencyRef = useRef(null);
  const expenseCompanyRef = useRef(null);
  const [expCompanyDropdownOpen, setExpCompanyDropdownOpen] = useState(false);
  const [expCompanySearchTerm, setExpCompanySearchTerm] = useState('');
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [userSearchTerm, setUserSearchTerm] = useState('');
  const expCurrencyDropdownRef = useRef(null);
  const [expClientSearchTerm, setExpClientSearchTerm] = useState('');
  const expenseClientDropdownRef = useRef(null);
  const expenseClientRef = useRef(null);
  const expenseProjectRef = useRef(null);
  const expenseProjectDropdownRef = useRef(null);
  const expenseCompanyDropdownRef = useRef(null);
  const expenseUserSelectDropdownRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const expenseDateRef = useRef(null);
  const expenseDescriptionRef = useRef(null);
  const expSubTotalRef = useRef(null);
  const expTaxRef = useRef(null);
  const expFileAttchRef = useRef(null);
  const { appSettings } = useSelector((state) => state.appSettings);
  // const expCategoryDropdownRef = useRef(null);
  let token = null;
  let createdBy = null;
  const { user } = useSelector((state) => state.auth);
  if (user) {
    if (user && user.token) {
      token = user.token;
      createdBy = user._id;
    }
  }

  const { data: expenseCategory = [], isLoading: isLoadingExpCategory } = useFetchExpCategory(token);
  const { data: clients = [], isLoading: clientLoading, isError: clientError } = useGetClients(token);
  const { data: projects = [], isLoading: projectLoading, isError: projectError } = useGetProjects(token);
  const { data: wXCompany = [], isLoading: WXCompaniesLoading, isError: WXCompaniesError } = useWXCompanies();
  const { data: activeTeams = [], isLoading: activeTeamLoading, isError: activeTeamError } = useGetActiveTeams(token);
  const { data: expenseId = [], isLoading: isLoadingExpenseId, refetch: refetchExpenseId } = useExpenseId(token);

  useEffect(() => {
    setExpCurrencyList(Currencies.sourceCurrencies[0].targetCurrencies);
  }, [Currencies]);

  const filteredUsers = activeTeams.filter((user) => {
    const fullName = user.first_name
      ? `${user.first_name} ${user.middle_name || ''} ${user.last_name}`
      : `${user.company_first_name ? user.company_first_name + (user.company_last_name && user.company_last_name) : user.company_name}`;
    return fullName?.toLowerCase().includes(userSearchTerm?.toLowerCase());
  });

  const handleToggleExpClientDropdown = () => {
    setExpClientSearchTerm('');
    setExpClientDropdownOpen(!expClientDropdownOpen);
  };

  const handleExpClientSearchInputChange = (e) => {
    setExpClientSearchTerm(e.target.value);
  };

  const handleToggleExpCompanyDropdown = () => {
    setExpCompanySearchTerm('');
    setExpCompanyDropdownOpen(!expCompanyDropdownOpen);
  };

  const handleExpCompanySearchInputChange = (e) => {
    setExpCompanySearchTerm(e.target.value);
  };
  const filteredCompany = wXCompany && wXCompany
    .filter((expCompany) =>
      expCompany.wx_company?.toLowerCase().includes(expCompanySearchTerm && expCompanySearchTerm?.toLowerCase())
    )
    .sort((a, b) =>
      a.wx_company?.toLowerCase().localeCompare(b.wx_company?.toLowerCase())
    );

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        document.activeElement.blur();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const ranges = [
    {
      label: "today",
      value: new Date(), // Use a single date instead of an array
    },
    {
      label: "yesterday",
      value: subDays(new Date(), 1),
    },
  ];

  const handleDateChange = (date) => {
    setExpenseDate(date);
  };

  const disabledDate = date => {
    return date > new Date();
  };

  function ConvertHelper(currencyFrom, currencyTo, amount, forex) {
    const currencyFromRate = forex[currencyFrom];
    const currencyToRate = forex[currencyTo];
    const amountConvertedUSD = amount / currencyFromRate;
    const amountConvertTo = amountConvertedUSD * currencyToRate;
    return amountConvertTo;
  }
  const handleToggleExpenseUserDropdown = () => {
    setUserSearchTerm('');
    setExpenseUserDropdownOpen((prevOpen) => !prevOpen);
  };

  const handleSelectExpenseUser = (selectedExpenseUser) => {
    setExpenseUser(selectedExpenseUser);
    setUserSearchTerm('');
    setExpenseUserDropdownOpen(false);
  };

  const handleExpenseUserSearchInputChange = (e) => {
    setUserSearchTerm(e.target.value);
  };

  const calculateAmount = (expSubTotal, expTax) => {
    const subTotalAmount = expSubTotal ? parseFloat(expSubTotal) : 0;
    const taxPercentage = expTax ? parseFloat(expTax) : 0;

    if (!isNaN(subTotalAmount) && !isNaN(taxPercentage)) {
      const taxAmount = (subTotalAmount * taxPercentage) / 100;
      return subTotalAmount + taxAmount;
    } else {
      return '';
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setExpenseCategoryIsValid(false);
    setExpenseCurrencyIsValid(false);
    setExpenseDateIsValid(false);
    setExpenseUserIsValid(false);
    setExpenseClientIsValid(false);
    setExpenseCompanyIsValid(false);
    if (
      expenseId === "" ||
      expDescription === "" ||
      expenseDate === null ||
      expCategory === "" ||
      expCurrency === "" ||
      expSubTotal === "" ||
      expTax === "" ||
      expenseUser === "" ||
      (expIsBillable && expenseClient === "" ? true : false) ||
      (!expIsBillable && expenseCompany === "" ? true : false)
    ) {
      if (expenseDate === null) {
        setExpenseDateIsValid(true);
        expenseDateRef && expenseDateRef.current && expenseDateRef.current.focus();
        toast.error("Expense date cannot be empty!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      } else if (expDescription === "") {
        setExpenseDescriptionIsValid(true);
        expenseDescriptionRef && expenseDescriptionRef.current.focus();
        toast.error("Expense description cannot be empty!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      } else if (expCategory === "") {
        setExpenseCategoryIsValid(true);
        expenseCategoryRef && expenseCategoryRef.current.focus();
        toast.error("Expense category cannot be empty!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      } else if (expCurrency === "") {
        setExpenseCurrencyIsValid(true);
        expenseCurrencyRef && expenseCurrencyRef.current.focus();
        toast.error("Expense currency cannot be empty!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      } else if (expSubTotal === "") {
        setExpSubTotalIsValid(true);
        expSubTotalRef && expSubTotalRef.current.focus();
        toast.error("Expense sub total cannot be empty!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      } else if (expTax === "") {
        setExpTaxIsValid(true);
        expTaxRef && expTaxRef.current.focus();
        toast.error("Expense Tax cannot be empty!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      } else if (expenseUser === "") {
        setExpenseUserIsValid(true);
        expenseUserRef && expenseUserRef.current.focus();
        toast.error("User has to be seleted!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      } else if (expIsBillable && expenseClient === "") {
        setExpenseClientIsValid(true);
        expenseClientRef && expenseClientRef.current.focus();
        toast.error("Client has to be seleted!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      } else if (!expIsBillable && expenseCompany === "") {
        setExpenseCompanyIsValid(true);
        expenseCompanyRef && expenseCompanyRef.current.focus();
        toast.error("Company has to be seleted!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }

      toast.error("Please fill all required fields!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    const requestData = {
      exp_id: expenseId,
      exp_description: expDescription,
      exp_date: expenseDate,
      exp_category: expCategory,
      exp_currency: expCurrency,
      exp_sub_total: expSubTotal,
      exp_tax: expTax,
      exp_amount: expAmount,
      exp_is_billable: expIsBillable,
      exp_user: expenseUser,
      exp_clientValue: expIsBillable ? expenseClient : null,
      exp_projectValue: (expIsBillable && expenseClient) ? expenseProject : null,
      exp_CompanyValue: !expIsBillable ? expenseCompany : null,
      exp_note: expNote,
    };

    const formData = new FormData();
    // formData.append("exp_file_attch", expFileAttch);
    for (let i = 0; i < expFileAttchFiles.length; i++) {
      formData.append("exp_file_attch", expFileAttchFiles[i]);
    }
    for (let key in requestData) {
      formData.append(key, requestData[key]);
    }
    try {
      setIsSubmitLoading(true);
      const response = await axios.post("/api/expense/create", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response.data;
      if (data.status === "error") {
        toast.error("Something went wrong", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.success("Expense data created successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        dispatch(setNotification(Date.now()));
        navigate("/user/expense/overview");
        global.config.activityLog(window.location.href, 'Expense', `Expense, (${expenseId}) data created Successfully`);
      }
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error('This user is deleted!', {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      alert("Something went wrong");
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const allowedExtensions = ["pdf", "doc", "docx", "msword", "xls", "xlsx", "csv", "jpeg", "jpg", "png", "gif"];

  const handleExpFileAttchFileUpload = (event) => {
    const selectedFiles = event.target.files;

    if (selectedFiles.length > 0) {
      if (selectedFiles.length + expFileAttchFiles.length > 10) {
        // Display toast error message when the total number of files exceeds 10
        toast.error('Maximum of 10 files can be uploaded!', {
          position: 'bottom-right',
          autoClose: 3500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
      const newFiles = Array.from(selectedFiles);

      // Validate file extensions and update state
      const validFiles = newFiles.filter((file) => {
        const fileExtension = file.name.split('.').pop()?.toLowerCase();
        if (allowedExtensions.includes(fileExtension)) {
          return true;
        } else {
          toast.error(`Invalid file format for ${file.name}. Allowed formats are pdf, doc, docx, msword, xls, xlsx, csv.!!`, {
            position: 'bottom-right',
            autoClose: 3500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return false;
        }
      });

      setExpFileAttchFiles((prevFiles) => [...prevFiles, ...validFiles]);
      expFileAttchRef.current.value = '';
    }
  };

  const removeFile = (index) => {
    // Remove the file at the specified index
    setExpFileAttchFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });
  };

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideExpenseUserDropdown = expenseUserSelectDropdownRef.current && expenseUserSelectDropdownRef.current.contains(event.target);
    const isClickInsideExpCurrencyDropdown = expCurrencyDropdownRef.current && expCurrencyDropdownRef.current.contains(event.target);
    const isClickInsideExpClientDropdown = expenseClientDropdownRef.current && expenseClientDropdownRef.current.contains(event.target);
    const isClickInsideExpCompanyDropdown = expenseCompanyDropdownRef.current && expenseCompanyDropdownRef.current.contains(event.target);
    const isClickInsideExpProjectDropdown = expenseProjectDropdownRef.current && expenseProjectDropdownRef.current.contains(event.target);
    const isClickInsideExpCategoryDropdown = expenseCategoryDropdownRef.current && expenseCategoryDropdownRef.current.contains(event.target);

    if (!isClickInsideExpenseUserDropdown) {
      setExpenseUserDropdownOpen(false);
    }
    if (!isClickInsideExpCurrencyDropdown) {
      setExpCurrencyDropdownOpen(false);
    }
    if (!isClickInsideExpClientDropdown) {
      setExpClientDropdownOpen(false);
    }
    if (!isClickInsideExpCompanyDropdown) {
      setExpCompanyDropdownOpen(false);
    }
    if (!isClickInsideExpProjectDropdown) {
      setExpProjectDropdownOpen(false);
    }
    if (!isClickInsideExpCategoryDropdown) {
      setExpenseCategoryDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideDropdown);
    return () => {
      document.removeEventListener('click', handleClickOutsideDropdown);
    };
  }, []);

  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur()
    e.stopPropagation()

    setTimeout(() => {
      e.target.focus()
    }, 0)
  }
  const handleToggleExpCurrencyDropdown = () => {
    setExpCurrencySearchTerm('');
    setExpCurrencyDropdownOpen((prevOpen) => !prevOpen);
  };
  const handleExpCurrencySearchInputChange = (e) => {
    setExpCurrencySearchTerm(e.target.value);
  };

  const handleToggleExpProjectDropdown = () => {
    setExpProjectSearchTerm('');
    setExpProjectDropdownOpen(!expProjectDropdownOpen);
  };

  const handleExpProjectSearchInputChange = (e) => {
    setExpProjectSearchTerm(e.target.value);
  };

  const filteredClients = clients && clients
    .filter((expClient) =>
      expClient.client_name?.toLowerCase().includes(expClientSearchTerm && expClientSearchTerm?.toLowerCase())
    )
    .sort((a, b) =>
      a.client_name?.toLowerCase().localeCompare(b.client_name?.toLowerCase())
    );

  const filteredProjects = projects && projects
    .filter((expProject) =>
      expProject.client_name?._id === expenseClient &&
      expProject?.project_name?.toLowerCase().includes(expProjectSearchTerm && expProjectSearchTerm?.toLowerCase())
    )
    .sort((a, b) =>
      a.project_name?.toLowerCase().localeCompare(b.project_name?.toLowerCase())
    );

  const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false);

  const filteredExpCurrencies = expCurrencyList.filter((expCurrency) => {
    const expCurrencyName = expCurrency.currencyCode
      && `${expCurrency.currencyCode} (${expCurrency.currencyName})`;
    return expCurrencyName?.toLowerCase().includes(expCurrencySearchTerm?.toLowerCase());
  });

  return (
    <div className="s1-containauto s1-containauto-client new-exp-create">
      <form onSubmit={handleSubmit}>
        <div className="fs-head-autoinvo">
          <div className="bill-num-si">
            <Link className="invoice-num-01">{expenseId}</Link>
          </div>
          <div className="btn-aary">
            <Link to={`/user/expense/overview`} className="cancelbtn-link">
              <img src={cancelImage} alt="cancel" /> Cancel
            </Link>
          </div>
        </div>
        <div className="m-edit-main exp-new-top-create">
          <div className="edit-sheet-manual01">
            <div className="headder-outter-res-mainu">
              <div className="sheet-manual-header">
                <div className="form-field-client">
                  <label htmlFor="category" className="form-label">
                    Category
                  </label>

                  {isLoadingExpCategory == true ? (
                    <div className="outter-load-table">
                      <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="#405BFF"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div>
                  ) : (
                    <div
                      ref={expenseCategoryDropdownRef}
                      className={`cursor ${expenseCategoryDropdownOpen ? 'open' : ''}`}
                    >
                      <span
                        id="user-select"
                        onClick={() => setExpenseCategoryDropdownOpen(!expenseCategoryDropdownOpen)}
                        className={`custom-dropdown-user-new custom-dropdown-select ${expenseCategoryIsValid ? 'input-validation-error' : ''}`}
                        ref={expenseCategoryRef}
                      >
                        {expCategory ? expenseCategory.find((exp) => exp._id === expCategory)?.exp_category_name : 'Select'}
                      </span>
                      {expenseCategoryDropdownOpen && (
                        <div className="custom-dropdown-new">
                          {expenseCategory && expenseCategory.length > 0 ? (
                            <ul className="leave-type-list">
                              {expenseCategory &&
                                expenseCategory
                                  .slice()
                                  .sort((a, b) => a.exp_category_name?.toLowerCase().localeCompare(b.exp_category_name?.toLowerCase()))
                                  .map((exp) => (
                                    <li
                                      key={exp._id}
                                      className={expCategory === exp._id ? 'selected-item' : ''}
                                      onClick={() => {
                                        setExpCategory(exp._id);
                                        setExpenseCategoryDropdownOpen(false);
                                      }}
                                    >
                                      {exp.exp_category_name}
                                    </li>
                                  ))}
                            </ul>
                          ) : (
                            <div className="text-gray-500 text-sm italic">No categories available</div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  {expenseCategoryIsValid && (
                    <span className="text-red-500 text-xs italic">
                      Equipment Category Field Cannot be empty
                    </span>
                  )}
                </div>
                <div className="input-field-inof input-field-inof-new input-field-creat expense-currency-create-new">
                  <label htmlFor="client" >
                    Currency
                  </label>
                  <div ref={expCurrencyDropdownRef} className={`cursor ${expCurrencyDropdownOpen ? 'open' : ''}`} >
                    <span
                      id="user-select"
                      onClick={handleToggleExpCurrencyDropdown}
                      className={`custom-dropdown-user-new custom-dropdown-user ${expenseCurrencyIsValid ? 'input-validation-error' : ''}`}
                      ref={expenseCurrencyRef}
                    >
                      {expCurrency ? expCurrencyList.find((c) => c.currencyCode === expCurrency)?.currencyCode : 'Select Currency'}
                    </span>
                    {expCurrencyDropdownOpen && (
                      <div className="custom-dropdown-new">
                        <input
                          type="text"
                          className="search-bar"
                          placeholder="Search..."
                          value={expCurrencySearchTerm}
                          onChange={handleExpCurrencySearchInputChange}
                        />
                        <ul className="currency-list">
                          {filteredExpCurrencies.length > 0 &&
                            filteredExpCurrencies.map((c) => (
                              <li
                                key={c.currencyCode}
                                className={expCurrency === c.currencyCode ? 'selected-item' : ''}
                                onClick={() => {
                                  setExpCurrency(c.currencyCode);
                                  setExpCurrencyDropdownOpen(false);
                                }}
                              >
                                {`${c.currencyCode} (${c.currencyName})`}
                              </li>
                            ))}
                        </ul>
                      </div>
                    )}
                  </div>

                  {expenseCurrencyIsValid && (
                    <span className="text-red-500 text-xs italic">
                      Expense currency needs to be selected
                    </span>
                  )}
                </div>

                <div className="date-due-manual-edm input-field-create">
                  <div className="invo-date-issue-tile also-for-dued-cal">
                    Date
                  </div>
                  <div className="invo-date-due-cal">
                    <div className="calender-date-due-pic">
                      <div className="date-picker-outer date-picker-outer-all expense-create-page-date">
                        <div className="custom-picker-icon custom-picker-icon-all">
                          <svg
                            width="1em"
                            height="1em"
                            viewBox="0 0 14 14"
                            fill="currentColor"
                            aria-hidden="true"
                            focusable="false"
                            class="rs-picker-toggle-caret rs-icon"
                            aria-label="calendar"
                            data-category="legacy"
                          >
                            <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                          </svg>
                        </div>
                        <div className="custoom-icon-calender custoom-icon-calender-all">
                          <DatePicker
                            oneTap
                            format="dd/MM/yyyy"
                            ranges={ranges}
                            value={expenseDate}
                            onChange={handleDateChange}
                            placement="auto"
                            className="invoice-filter-top-date"
                            isoWeek={appSettings.weekStartDay && appSettings.weekStartDay === 'Monday'}
                            timeZone={global.config.getTimeZone()}
                            disabledDate={disabledDate}
                          />
                          {expenseDateIsValid && (
                            <span className="text-red-500 text-xs italic">
                              Select the Expense date
                            </span>
                          )}
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div className="form-field-client radio-sec radio-expense-create">
                  <label htmlFor="expIsBillable" className="form-label">
                    Billable
                  </label>
                  <div className="radio-container">
                    <div className="radio-option yes">
                      <input
                        type="radio"
                        id="yes"
                        name="expIsBillable"
                        value={expIsBillable}
                        checked={expIsBillable === true}
                        onChange={(e) => {
                          setExpIsBillable(true);
                          setExpenseClient("");
                          setExpenseProject("");
                          setExpenseCompany("");
                        }}
                      />
                      <label htmlFor="yes">
                        Yes <img src={Tick} alt="Tick" />
                      </label>
                    </div>
                    <div className="radio-option no">
                      <input
                        type="radio"
                        id="no"
                        name="expIsBillable"
                        value={expIsBillable}
                        checked={expIsBillable === false}
                        onChange={(e) => {
                          setExpIsBillable(false);
                          setExpenseClient("");
                          setExpenseProject("");
                          setExpenseCompany("");
                        }}
                      />
                      <label htmlFor="no">
                        No <img src={Close} alt="Edit Icon" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="sheed-sec-after-headem">
              <div className="mobile-revert">
                <div className="inp-sec2-with2 inp-sec2-with-client user-and-client-sec">
                  {isMobile && (
                    <div className="form-field-client-mobile-billable radio-sec radio-expense-create">
                      <label htmlFor="expIsBillable" className="form-label">
                        Billable
                      </label>
                      <div className="radio-container">
                        <div className="radio-option yes">
                          <input
                            type="radio"
                            id="yes"
                            name="expIsBillable"
                            value={expIsBillable}
                            checked={expIsBillable === true}
                            onChange={(e) => {
                              setExpIsBillable(true);
                              setExpenseClient("");
                              setExpenseProject("");
                              setExpenseCompany("");
                            }}
                          />
                          <label htmlFor="yes">
                            Yes <img src={Tick} alt="Tick" />
                          </label>
                        </div>
                        <div className="radio-option no">
                          <input
                            type="radio"
                            id="no"
                            name="expIsBillable"
                            value={expIsBillable}
                            checked={expIsBillable === false}
                            onChange={(e) => {
                              setExpIsBillable(false)
                              setExpenseClient("");
                              setExpenseProject("");
                              setExpenseCompany("");
                            }}
                          />
                          <label htmlFor="no">
                            No <img src={Close} alt="Edit Icon" />
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="Select Expense User">
                    <label htmlFor="client" className="form-label">
                      User
                    </label>
                    {activeTeamLoading == true ? (
                      <div className="outter-load-table">
                        <ThreeDots
                          height="18"
                          width="20"
                          radius="9"
                          color="#405BFF"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div>
                    ) : (
                      <div ref={expenseUserSelectDropdownRef} className={`cursor ${expenseUserDropdownOpen ? 'open' : ''}`} >
                        <span
                          id="user-select"
                          onClick={handleToggleExpenseUserDropdown}
                          className={`custom-dropdown-user-new custom-dropdown-user ${expenseUserIsValid ? 'input-validation-error' : ''}`}
                          ref={expenseUserRef}
                        >
                          {expenseUser ? (
                            activeTeams?.find((user) => user._id === expenseUser)?.first_name ? (
                              <>
                                {activeTeams?.find((user) => user._id === expenseUser)?.first_name}{' '}
                                {activeTeams?.find((user) => user._id === expenseUser)?.middle_name &&
                                  activeTeams?.find((user) => user._id === expenseUser)?.middle_name !== 'null'
                                  ? activeTeams?.find((user) => user._id === expenseUser)?.middle_name + ' '
                                  : ''}
                                {activeTeams?.find((user) => user._id === expenseUser)?.last_name}{' '}
                              </>
                            ) : (
                              <>
                                {activeTeams.find((user) => user._id === expenseUser)?.company_first_name
                                  ? `${activeTeams.find((user) => user._id === expenseUser)?.company_first_name} ${activeTeams.find((user) => user._id === expenseUser)?.company_last_name
                                    ? activeTeams.find((user) => user._id === expenseUser)?.company_last_name
                                    : ''
                                  }`
                                  : `${activeTeams.find((user) => user._id === expenseUser)?.company_name || ''}(Company)`}
                              </>
                            )
                          ) : (
                            'Select Expense User'
                          )}
                        </span>
                        {expenseUserDropdownOpen && (
                          <div className="custom-dropdown-new">
                            <input
                              type="text"
                              className="search-bar"
                              placeholder="Search..."
                              value={userSearchTerm}
                              onChange={handleExpenseUserSearchInputChange}
                            />
                            <ul className="client-list">
                              {filteredUsers.length > 0 &&
                                filteredUsers
                                  .slice()
                                  .sort((a, b) => {
                                    const nameA = a.first_name
                                      ? `${a.first_name} ${a.middle_name || ''} ${a.last_name}`
                                      : `${a.company_first_name ? a.company_first_name + (a.company_last_name && a.company_last_name) : a.company_name}`;
                                    const nameB = b.first_name
                                      ? `${b.first_name} ${b.middle_name || ''} ${b.last_name}`
                                      : `${b.company_first_name ? b.company_first_name + (b.company_last_name && b.company_last_name) : b.company_name}`;
                                    return nameA.localeCompare(nameB);
                                  })
                                  .map((item) => (
                                    <li key={item._id} className={expenseUser === item._id ? 'selected-item' : ''} onClick={() => handleSelectExpenseUser(item._id)}>
                                      {item.first_name ? (
                                        <>
                                          {item.first_name}{' '}
                                          {item.middle_name && item.middle_name !== 'null' ? item.middle_name + ' ' : ''}
                                          {item.last_name}{' '}
                                        </>
                                      ) : (
                                        <>
                                          {item.company_first_name
                                            ? `${item.company_first_name} ${item.company_last_name ? item.company_last_name : ''}`
                                            : `${item.company_name || ''}(Company)`}
                                        </>
                                      )}
                                    </li>
                                  ))
                              }
                            </ul>
                          </div>
                        )}
                      </div>
                    )}
                    {expenseUserIsValid && (
                      <span className="text-red-500 text-xs italic">
                        Expense User needs to be selected
                      </span>
                    )}
                  </div>

                  {expIsBillable ? (
                    <>
                      <div className="form-field-client">
                        <label htmlFor="client" className="form-label">
                          Client
                        </label>

                        <div
                          className={`custom-dropdown-user-new-outer custom-dropdown-select ${expClientDropdownOpen ? 'open' : ''}`}
                          ref={expenseClientDropdownRef}
                        >
                          <div
                            className="selected-option custom-dropdown-user-new"
                            ref={expenseClientRef}
                            onClick={handleToggleExpClientDropdown}
                            style={{ cursor: 'pointer' }}
                          >
                            {expenseClient ? (
                              clients.find((client) => client._id === expenseClient)?.client_name
                            ) : (
                              'Select'
                            )}
                          </div>
                          {expClientDropdownOpen && (
                            <div className="custom-dropdown-new">
                              <input
                                type="text"
                                className="search-bar"
                                placeholder="Search..."
                                value={expClientSearchTerm}
                                onChange={handleExpClientSearchInputChange}
                              />
                              {filteredClients.length > 0 ? (
                                <ul className="client-list">
                                  {filteredClients.map((client) => (
                                    <li
                                      key={client._id}
                                      className={expenseClient === client._id ? 'selected-item' : ''}
                                      onClick={() => {
                                        setExpenseClient(client._id);
                                        setExpenseProject("");
                                        setExpClientDropdownOpen(false);
                                      }}
                                    >
                                      {client.client_name}
                                    </li>
                                  ))}
                                </ul>
                              ) : (
                                <div className="text-gray-500 text-sm italic">No clients available</div>
                              )}
                            </div>
                          )}
                        </div>
                        {expenseClientIsValid && (
                          <span className="text-red-500 text-xs italic">
                            Client Field Cannot be empty
                          </span>
                        )}
                      </div>
                      {expenseClient && (
                        <div className="form-field-client">
                          <label htmlFor="project" className="form-label">
                            Project
                          </label>

                          <div
                            className={`custom-dropdown-user-new-outer custom-dropdown-select ${expProjectDropdownOpen ? 'open' : ''
                              }`}
                            ref={expenseProjectDropdownRef}
                          >
                            <div
                              className="selected-option custom-dropdown-user-new"
                              ref={expenseProjectRef}
                              onClick={handleToggleExpProjectDropdown}
                              style={{ cursor: 'pointer' }}
                            >
                              {expenseProject ? (
                                projects.find((project) => project._id === expenseProject)?.project_name
                              ) : (
                                'Select'
                              )}
                            </div>
                            {expProjectDropdownOpen && (
                              <div className="custom-dropdown-new">
                                <input
                                  type="text"
                                  className="search-bar"
                                  placeholder="Search..."
                                  value={expProjectSearchTerm}
                                  onChange={handleExpProjectSearchInputChange}
                                />
                                {filteredProjects.length > 0 ? (
                                  <ul className="client-list">
                                    {filteredProjects.map((project) => (
                                      <li
                                        key={project._id}
                                        className={expenseProject === project._id ? 'selected-item' : ''}
                                        onClick={() => {
                                          setExpenseProject(project._id);
                                          setExpProjectDropdownOpen(false);
                                        }}
                                      >
                                        {project.project_name}
                                      </li>
                                    ))}
                                  </ul>
                                ) : (
                                  <div className="no-projects-message">
                                    No projects available for the selected client.
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="form-field-client">
                      <label htmlFor="company" className="form-label">
                        Company
                      </label>

                      <div
                        className={`custom-dropdown-user-new-outer custom-dropdown-select ${expCompanyDropdownOpen ? 'open' : ''}`}
                        ref={expenseCompanyDropdownRef}
                      >
                        <div
                          className="selected-option custom-dropdown-user-new"
                          ref={expenseCompanyRef}
                          onClick={handleToggleExpCompanyDropdown}
                          style={{ cursor: 'pointer' }}
                        >
                          {expenseCompany ? (
                            wXCompany.find((company) => company._id === expenseCompany)?.wx_company
                          ) : (
                            'Select'
                          )}
                        </div>
                        {expCompanyDropdownOpen && (
                          <div className="custom-dropdown-new">
                            <input
                              type="text"
                              className="search-bar"
                              placeholder="Search..."
                              value={expCompanySearchTerm}
                              onChange={handleExpCompanySearchInputChange}
                            />
                            <ul className="client-list">
                              {filteredCompany.map((company) => (
                                <li
                                  key={company._id}
                                  className={expenseCompany === company._id ? 'selected-item' : ''}
                                  onClick={() => {
                                    setExpenseCompany(company._id);
                                    setExpCompanyDropdownOpen(false);
                                  }}
                                >
                                  {company.wx_company}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                      {expenseCompanyIsValid && (
                        <span className="text-red-500 text-xs italic">
                          Company Field Cannot be empty
                        </span>
                      )}
                    </div>
                  )}
                </div>

                <div className="detail-add-parteditmanual">
                  <table className="invoice-table-editmanual">
                    <thead className="the-abantant-edittxh">
                      <tr>
                        <th>Description</th>
                        <th>Subtotal</th>
                        <th>Taxes</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="description-manual">
                          <input
                            type="text"
                            value={expDescription}
                            name="description"
                            onChange={(e) => setExpDescription(e.target.value)}
                            className="input-validation-error1"
                            placeholder="Description"
                            autoComplete="off"
                            ref={expenseDescriptionRef}
                            required
                          />
                          {expenseDescriptionIsValid && (
                            <span className="text-red-500 text-xs italic">
                              Equipment description field cannot be empty
                            </span>
                          )}
                        </td>
                        <td className="edit-manual-costtr">
                          <input
                            type="number"
                            step="any"
                            id="expSubTotal"
                            name="expSubTotal"
                            min="0"
                            placeholder="Cost"
                            className="input-validation-error1"
                            value={expSubTotal}
                            onChange={(e) => {
                              setExpSubTotal(parseFloat(e.target.value));
                              setExpAmount(calculateAmount(parseFloat(e.target.value), expTax));
                            }}
                            autoComplete="off"
                            ref={expSubTotalRef}
                            onWheel={numberInputOnWheelPreventChange}
                            required
                          />
                        </td>
                        <td className="editmanual-vattd">
                          <input
                            type="number"
                            step="any"
                            id="expTax"
                            name="expTax"
                            min="0"
                            placeholder="Tax %"
                            value={expTax}
                            onChange={(e) => {
                              setExpTax(parseFloat(e.target.value));
                              setExpAmount(calculateAmount(expSubTotal, parseFloat(e.target.value)));
                            }}
                            className="input-validation-error1"
                            autoComplete="off"
                            ref={expTaxRef}
                            onWheel={numberInputOnWheelPreventChange}
                            required
                          />
                          {expTaxIsValid && (
                            <span className="text-red-500 text-xs italic">
                              Expense tax field cannot be empty
                            </span>
                          )}
                        </td>
                        <td className="editmanual-amount-tr">
                          <div className="amount-cell">
                            <span className="delete-icon">
                              <span>
                                {new Intl.NumberFormat("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                }).format(calculateAmount(expSubTotal, expTax))}
                              </span>
                            </span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="detail-add-parteditmanual-mobile">
                  <tbody>
                    <tr>
                      <div className="mobile-label-with-content">
                        <div className="mobile-expense-label-head">Description</div>
                        <td className="description-manual">
                          <input
                            type="text"
                            value={expDescription}
                            name="description"
                            onChange={(e) => setExpDescription(e.target.value)}
                            className="input-validation-error1"
                            placeholder="Description"
                            autoComplete="off"
                            ref={expenseDescriptionRef}
                            required
                          />
                          {expenseDescriptionIsValid && (
                            <span className="text-red-500 text-xs italic">
                              Equipment description field cannot be empty
                            </span>
                          )}
                        </td>
                      </div>
                      <div className="mobile-label-with-content">
                        <div className="mobile-expense-label-head">Subtotal</div>
                        <td className="edit-manual-costtr">
                          <input
                            type="number"
                            id="expSubTotal"
                            name="expSubTotal"
                            step="any"
                            min="0"
                            placeholder="Sub Total"
                            className="input-validation-error1"
                            value={expSubTotal}
                            onChange={(e) => {
                              setExpSubTotal(parseFloat(e.target.value));
                              setExpAmount(calculateAmount(parseFloat(e.target.value), expTax));
                            }}
                            autoComplete="off"
                            ref={expSubTotalRef}
                            onWheel={numberInputOnWheelPreventChange}
                            required
                          />
                          {expSubTotalIsValid && (
                            <span className="text-red-500 text-xs italic">
                              Expense sub total field cannot be empty
                            </span>
                          )}
                        </td>
                      </div>
                      <div className="mobile-label-with-content">
                        <div className="mobile-expense-label-head">Taxes</div>
                        <td className="editmanual-vattd">
                          <input
                            type="number"
                            step="any"
                            id="expTax"
                            name="expTax"
                            placeholder="Tax %"
                            className="input-validation-error1"
                            value={expTax}
                            onChange={(e) => {
                              setExpTax(parseFloat(e.target.value));
                              setExpAmount(calculateAmount(expSubTotal, parseFloat(e.target.value)));
                            }}
                            autoComplete="off"
                            ref={expTaxRef}
                            onWheel={numberInputOnWheelPreventChange}
                            required
                          />
                          {expTaxIsValid && (
                            <span className="text-red-500 text-xs italic">
                              Expense tax field cannot be empty
                            </span>
                          )}
                        </td>
                      </div>
                      <div className="mobile-label-with-content">
                        <div className="mobile-expense-label-head">Amount (Subtotal + Tax)</div>
                        <td className="editmanual-amount-tr">
                          <div className="amount-cell">
                            <span className="delete-icon">
                              <input
                                type="number"
                                step="any"
                                name="expAmount"
                                id="expAmount"
                                min="0"
                                required
                                className="input-validation-error1"
                                // value={new Intl.NumberFormat("en-US", {
                                //   style: "currency",
                                //   currency: expCurrency || "USD",
                                // }).format(calculateAmount(expSubTotal, expTax))}
                                value={calculateAmount(expSubTotal, expTax)}
                                autoComplete="off"
                              // onWheel={(e) => e.preventDefault()}
                              />
                            </span>
                          </div>
                        </td>
                      </div>
                    </tr>
                  </tbody>
                </div>
              </div>
            </div>
            <div className="sec-after-tab-ls-part">
              <div className="sec-withother-and-add">
                <div className="attachment-head-mobile">File attachment</div>
                <div className="style-portion-browsetext mobile" style={{ cursor: 'pointer' }}>
                  <label className="add-item-button-end-browse">
                    <img src={addicoplus} alt="addicon" /> Browse
                    <input
                      ref={expFileAttchRef}
                      type="file"
                      id="expFileAttch"
                      name="expFileAttch"
                      style={{ display: 'none' }}
                      onChange={handleExpFileAttchFileUpload}
                      multiple
                    />
                  </label>
                  <div className="upload-file-name-br">
                    {expFileAttchFiles.map((file, index) => (
                      <div key={index} className="uploaded-file-item">
                        <span>{file.name}</span>
                        <a onClick={() => removeFile(index)}>Remove</a>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="other-secp1">
                  <div className="other-head1">Other comments</div>
                  <div className="other-head1-mobile">Notes</div>
                  <div className="other-subhead1">
                    <textarea
                      type="text"
                      className=""
                      id="expNote"
                      name="expNote"
                      placeholder="Comment here"
                      value={expNote}
                      onChange={(e) => setExpNote(e.target.value)}
                      style={{ resize: "none" }}
                    ></textarea>
                  </div>
                </div>
                <div className="mobile-cteate-expense-btn">
                  <button type="submit" disabled={isSubmitLoading}><a>Create Expense</a></button>
                </div>

                <div className="file-add-p1-attachment">
                  <div className="attachment-head">Attachments</div>
                  <div className="style-portion-browsetext" style={{ cursor: 'pointer' }}>
                    <label className="add-item-button-end-browse">
                      <img src={addicoplus} alt="addicon" /> Browse
                      <input
                        ref={expFileAttchRef}
                        type="file"
                        id="expFileAttch"
                        name="expFileAttch"
                        style={{ display: 'none' }}
                        onChange={handleExpFileAttchFileUpload}
                        multiple
                      />
                    </label>
                    <div className="upload-file-name-br">
                      {expFileAttchFiles.map((file, index) => (
                        <div key={index} className="uploaded-file-item">
                          <span>{file.name}</span>
                          <a onClick={() => removeFile(index)}>Remove</a>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="invo-total-specialcard">
                <div className="specialcard-outter">
                  <table className="to-ino-to-special">
                    <tr>
                      <th className="sub-lem-tb-fisl">Subtotal</th>
                      <td className="sub-price-tb-fisl">
                        {expCurrency ? (
                          <>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: expCurrency || 'USD',
                            }).format(expSubTotal)}
                          </>
                        ) : (
                          <span>{expSubTotal || 0.00}</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th className="sub-vat-tb-fisl">Taxes</th>
                      <td className="sub-vatpr-tb-fisl">
                        {expCurrency ? (
                          <>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: expCurrency || 'USD',
                            }).format(expTax)}
                          </>
                        ) : (
                          <span>{expTax || 0.00}</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th className="sub-invoto-tb-fisl">Amount<br></br><span>(Subtotal + Tax)</span></th>
                      <td className="sub-ivvoto-price-tb-fisl">
                        {expCurrency ? (
                          <>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: expCurrency || 'USD',
                            }).format(calculateAmount(expSubTotal, expTax))}
                          </>
                        ) : (
                          <span>{calculateAmount(expSubTotal, expTax)}</span>
                        )}
                      </td>
                    </tr>
                  </table>
                  <button
                    className="largecreate-invo-btn"
                    type="submit"
                    disabled={isSubmitLoading}
                  >
                    {isSubmitLoading ? <ThreeDots
                      height="18"
                      width="20"
                      radius="9"
                      color="white"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    /> : "Create Invoice"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateExpense;