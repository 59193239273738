import React, { useMemo ,useEffect,useState } from 'react';
import { useSelector } from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import './ReportingOverviewRoiOverviewPerClient.css';
import { convertCurrencyHelper } from '../../../helpers/forexConvertor';
import typeOfContract from '../../../constants/typeOfContract';

const useProcessedData = (trackReport, projectCost, sprints, reportingOverview, preferredCurrency, forex) => {
  return useMemo(() => {
    const currentYear = reportingOverview.year || new Date().getFullYear();

    const sprintsByProject = sprints.reduce((acc, sprint) => {
      const projectId = sprint.project?._id;
      if (projectId) {
        if (!acc[projectId]) {
          acc[projectId] = [];
        }
        acc[projectId].push(sprint);
      }
      return acc;
    }, {});

    const projectData = {};

    trackReport.forEach(entry => {
      const project = entry.project;
      const projectId = project?._id;

      if (projectId) {
        const projectYear = new Date(entry.startTime).getFullYear();
        const preferredClientCurrency = entry?.clientId?.preferred_currency || 'USD';

        if (projectYear === currentYear && !project.is_delete && (reportingOverview.project && reportingOverview.project.includes(projectId)) && (reportingOverview.currency && reportingOverview.currency.includes(preferredClientCurrency))) {
          if (!projectData[projectId]) {
            const totalSprintBudget = (sprintsByProject[projectId] || []).reduce((sum, sprint) => {
              return sum + convertCurrencyHelper(preferredClientCurrency, preferredCurrency, sprint.budget, forex);
            }, 0);

            projectData[projectId] = {
              name: project.project_name,
              budget: totalSprintBudget,
              cost: 0
            };
          }

          // const userCost = entry.user.cost || 0;
          const projectSpecificCost = projectCost.find(cost => cost.userId === entry.user?._id && cost.projectId === projectId && !cost.is_delete);
          const paymentCurrency = entry?.user?.payment_currency || 'USD';
          // const userCost = projectSpecificCost && projectSpecificCost.amount > 0 ? projectSpecificCost.amount : (entry.user.cost || 0);
          let userCost;
          if (projectSpecificCost && projectSpecificCost.amount > 0) {
            userCost = convertCurrencyHelper(
              preferredClientCurrency,
              paymentCurrency,
              projectSpecificCost.amount,
              forex
            );
          } else {
            userCost = entry.user.cost || 0;
          }
          const userTypeOfContract = entry.user.typeOfContract || typeOfContract.Hourly_Rate;
          const userOverheadCost = entry.user.overhead_cost || 0;

          let finalCost;
          if (userTypeOfContract === typeOfContract.Hourly_Rate) {
            finalCost = userCost;
          } else {
            finalCost = userCost / parseInt(entry?.user?.monthly_hours ? entry?.user?.monthly_hours : 168); // Assuming a different type of contract with monthly rate divided by 168 hours
          }
          const cost = (finalCost * (entry.duration / 3600)) + (userOverheadCost * (entry.duration / 3600));
          const costConverted = convertCurrencyHelper(paymentCurrency, preferredCurrency, cost, forex);

          projectData[projectId].cost += costConverted;
        }
      }
    });

    return Object.values(projectData).map(project => ({
      name: project.name,
      roi: project.cost ? ((project.budget - project.cost) / project.cost * 100).toFixed(2) : 0
    }));
  }, [trackReport, projectCost, sprints, reportingOverview, preferredCurrency, forex]);
};


function RoiOverviewPerProject({ trackReport, projectCost, sprints }) {
  const reportingOverview = useSelector((state) => state.reportingOverview);
  const { forex } = useSelector((state) => state.forex);
  const settings = JSON.parse(localStorage.getItem("settings"));

  const processedData = useProcessedData(trackReport, projectCost, sprints, reportingOverview, settings.currency, forex);
  // Dummy data
  // const series = [44, 55, 13, 43, 46, 55, 32, 30, 34, 12, 11, 12, 13];
  // const labels = [
  //   'Project A', 'Project B', 'Project C', 'Project D', 'Project E', 'Project F',
  //   'Project G', 'Project H', 'Project I', 'Project J', 'Project K', 'Project L', 'Project M'
  // ];

  const series = processedData.map(project => parseFloat(project.roi));
  const labels = processedData.map(project => project.name);
  // Colors array for all clients
  const allColors = [
    '#8AA9D2', '#234266', '#6D98CF', '#4263A5',
    '#6BA9E2', '#9ACBFB', '#75AECF', '#3f4b57',
    '#6A9DCB', '#A4C4E6', '#9AC9F3', '#000',
    '#89A7C4', '#8EA9C6', '#BBCFE7', '#B7D4F0',
    '#A6C0D6', '#4B6F98', '#A2B5CB', '#86A0BC'
  ];

  // Function to get top 3 series and corresponding labels and colors
  const getTop3Series = () => {
    // Create an array of objects { value, index } to keep track of original indices
    const seriesWithIndices = series.map((value, index) => ({ value, index }));

    // Sort by value in descending order
    seriesWithIndices.sort((a, b) => b.value - a.value);

    // Extract top 3 series values and labels
    const top3Series = seriesWithIndices.slice(0, 3);
    const top3Labels = top3Series.map(item => labels[item.index]);
    const top3Colors = top3Series.map(item => allColors[item.index]);

    return { top3Labels, top3Colors };
  };

  const { top3Labels, top3Colors } = getTop3Series();

  const options = {
    chart: {
      width: 193.845,
      height: 193.845,
      type: 'donut',
    },
    plotOptions: {
      pie: {
        startAngle: -45,
        endAngle: 315,
        expandOnClick: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'gradient',
    },
    legend: {
      show: false, // Hide the default legend
    },
    labels: labels, // All clients
    tooltip: {
      enabled: true,
      y: {
        formatter: function (val) {
          return val + '%';
        },
      },
      custom: function ({ series, seriesIndex, w }) {
        const project = labels[seriesIndex];
        const roiValue = series[seriesIndex];
        const totalROI = series.reduce((total, val) => total + val, 0);
        const percentage = ((roiValue / totalROI) * 100).toFixed(2);
        return `<div class="apexcharts-tooltip-custom" style="background: #FFFFFF; box-shadow: 0px 1px 8px rgba(110, 110, 110, 0.1); border-radius: 0px;height: 44pxxpx;padding: 5px 10px;">
                  <span class="apexcharts-tooltip-marker"></span>
                  <span class="apexcharts-tooltip-text" style="font-weight: 300; font-size: 11px; line-height: 13.42px; letter-spacing: 0.5px; color: #282828;">${project}</span>
                  <span class="apexcharts-tooltip-value" style="font-weight: 300; font-size: 11px; line-height: 13.42px; letter-spacing: 0.5px; color: #282828;">${roiValue} - ${percentage}%</span>
                </div>`;
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    colors: allColors, // Colors for each segment (all clients)
    states: {
      active: {
        filter: {
          type: "none", // No visual change for active state
        },
      },
      normal: {
        filter: {
          type: "none", // No visual change for normal state
        },
      },
    },
  };
  const allNegative = series.every(value => value <= 0);
  const [showMessage, setShowMessage] = useState(false);
  useEffect(() => {
    if (allNegative) {
      // Set a delay of 2 seconds before showing the message
      const timer = setTimeout(() => {
        setShowMessage(true);
      }, 2000);

      // Clear the timer if component unmounts
      return () => clearTimeout(timer);
    }
  }, [allNegative]);
  // Custom Legend Component
  const CustomLegend = () => {
    return (
      <div className='custom-legend'>
        <div className='custom-legend-title'>Top 3 Projects</div>
        {top3Labels.map((label, index) => (
          <div key={label} className='custom-legend-item'>
            <div className='custom-legend-value'>
              <span className='custom-legend-marker' style={{ backgroundColor: top3Colors[index] }}></span>
              <span className='custom-legend-text'>{label}</span>
            </div>
            <div className='custom-llegend-value'>{series[labels.indexOf(label)]}%</div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className='outer-piechart-report'>
      <div className='outer-piechart-title outer-piechart-title-desktop'>
        ROI Overview per Project
      </div>
      <div className='outer-piechart-section'>
        <div className='outer-piechart-title outer-piechart-title-mobile'>
          ROI Overview per Project
        </div>
        {allNegative && showMessage ? (
          <p className='no-data-text'>No data to display</p>

        ) : (
        <ReactApexChart
          options={options}
          series={series}
          type='donut'
          width={200}
          height={235}
        />
        )}
      </div>
      <CustomLegend />
    </div>
  );
}

export default RoiOverviewPerProject;
